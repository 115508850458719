/**
 * ProductTypeSwitcher component
 * @description Navigation component for switching between different product types
 */
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  width: 100%;
`;

const TypeButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${props => props.$isActive ? 'var(--text-colour)' : 'var(--text-colour)'};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  transition: color 0.2s ease;

  &:hover {
    color: var(--text-colour);
  }
`;

/**
 * ProductTypeSwitcher component
 * @param {Object} props - Component props
 * @param {Array} props.types - Array of product type objects
 * @param {string} props.activeType - ID of the currently active type
 * @param {Function} props.onTypeChange - Callback when type is changed
 * @param {number} props.direction - Animation direction
 * @param {Function} props.onDirectionChange - Callback when direction changes
 */
const ProductTypeSwitcher = ({
  types = [],
  activeType,
  onTypeChange,
  direction,
  onDirectionChange
}) => {
  if (!types.length) return null;

  const handleTypeClick = (typeId) => {
    console.log('typeId', typeId)
    const oldIndex = types.findIndex(type => type === activeType);
    const newIndex = types.findIndex(type => type === typeId);
    onDirectionChange(oldIndex > newIndex ? -1 : 1);
    onTypeChange(typeId);
  };

  console.log('activeType', activeType)

  return (
    <Container>
      {types.map(type => (
        <TypeButton
          key={type}
          $isActive={type === activeType}
          onClick={() => handleTypeClick(type)}
          whileTap={{ scale: 0.95 }}
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </TypeButton>
      ))}
    </Container>
  );
};

export default ProductTypeSwitcher;
