import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ButtonComponent } from '../components/ui/buttons';
import * as Sentry from "@sentry/react";
import { ImpactTitle } from '../components/ui/typography';
import Modal from '../components/ui/Modal';
import { UserFeedbackForm } from '../features/error-handling/components';
import { FiAlertTriangle, FiRefreshCw, FiHelpCircle } from 'react-icons/fi';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ErrorContainer = styled.div`
  display: grid;
  height: 100dvh;
  padding: 40px;
  place-items: center;
  text-align: center;
  background-color: var(--primary-colour);
  animation: ${fadeIn} 0.5s ease-in;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: var(--error-colour);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`

const ErrorMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  max-width: 600px;
  line-height: 1.6;
  color: var(--text-colour);
`

const ErrorDetails = styled.details`
  margin-top: 1.5rem;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  summary {
    cursor: pointer;
    font-weight: 600;
    color: var(--primary-colour);
  }

  pre {
    margin-top: 1rem;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 0.9rem;
    background-color: #f1f3f5;
    padding: 1rem;
    color: black;
    border-radius: 4px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`

const StyledButton = styled(ButtonComponent)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`

const ErrorIcon = styled(FiAlertTriangle)`
  font-size: 3rem;
  color: var(--error-colour);
`

function ErrorFallback({ error, resetErrorBoundary }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const friendlyMessage = getFriendlyErrorMessage(error);

  const handleReportError = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ErrorContainer>
      <ErrorIcon />
      <h1> Oops! Something went wrong</h1>
      <ErrorMessage>{friendlyMessage}</ErrorMessage>
      <ButtonContainer>
        <StyledButton variant={'outlined'} onClick={resetErrorBoundary}>
          <FiRefreshCw /> Try again
        </StyledButton>
        <StyledButton variant={'solid'} onClick={handleReportError}>
          <FiHelpCircle /> Get Help
        </StyledButton>
      </ButtonContainer>
      {error && error.stack && (
        <ErrorDetails>
          <summary>Technical Details</summary>
          <pre>{error.stack}</pre>
        </ErrorDetails>
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <UserFeedbackForm eventId={Sentry.lastEventId()} onClose={closeModal} />
      </Modal>
    </ErrorContainer>
  );
}

function getFriendlyErrorMessage(error) {
  // Map error types to user-friendly messages
  const errorMessages = {
    NetworkError: "We're having trouble connecting to our servers. Please check your internet connection and try again.",
    AuthenticationError: "Your session has expired. Please log in again.",
    ReferenceError: "Uh oh! It looks like we made a silly mistake! Please report this error below.",
    TypeError: "Uh oh! It looks like we made a silly mistake! Please report this error below.",
    // Add more error types and messages as needed
  };

  return errorMessages[error?.name] || "An unexpected error occurred. We're working on fixing it!";
}

export default ErrorFallback;
