import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { premiumAnimation, premiumInteractive } from '../../../tokens/animations';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${premiumInteractive}
`;

const SwitchTrack = styled(motion.div)`
  width: 48px;
  height: 22px;
  background: #B55959;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px;
  ${premiumAnimation}
  
  ${props => props.$checked && css`
    background: #59B593;
  `}

  ${props => props.$disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

const SwitchThumb = styled(motion.div)`
  width: 24px;
  height: 24px;
  background: var(--text-colour);
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: 0;
`;

const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const Label = styled.span`
  color: var(--text-colour);
  font-size: 14px;
  user-select: none;
  opacity: ${props => props.$disabled ? 0.5 : 1};
`;

const ErrorMessage = styled(motion.div)`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 4px;
  opacity: 0.9;
`;

const Switch = forwardRef(({
  checked = false,
  onChange,
  label,
  error = false,
  errorMessage,
  disabled = false,
  className,
  ...props
}, ref) => {
  const handleChange = (event) => {
    if (!disabled && onChange) {
      onChange(event);
    }
  };

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
  };

  return (
    <div className={className}>
      <Container
        onClick={() => !disabled && onChange?.(!checked)}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <HiddenInput
          ref={ref}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        <SwitchTrack
          $checked={checked}
          $disabled={disabled}
          whileHover={!disabled && { scale: 1.05 }}
          whileTap={!disabled && { scale: 0.95 }}
        >
          <SwitchThumb
            layout
            transition={spring}
            animate={{
              x: checked ? 20 : -4,
              scale: checked ? 1.1 : 1
            }}
          />
        </SwitchTrack>
        {label && <Label $disabled={disabled}>{label}</Label>}
      </Container>
      <AnimatePresence>
        {error && errorMessage && (
          <ErrorMessage
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </AnimatePresence>
    </div>
  );
});

Switch.displayName = 'Switch';

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.node,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Switch;
