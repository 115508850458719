import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import styled from 'styled-components';
import { motion } from "framer-motion"
import { convertToCurrency } from '../../../../js/helpers/helpers';
import BasketItem from './BasketItem';
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {BasketFees} from "../../basket/index.js";

const OrderItemsContainer = styled(motion.div)`

  width: 100%;
  max-height: 'fit-content';
  box-sizing: border-box;
  transition: max-height 2s ease-in-out;
  color: white;

  @media screen and (min-width: 768px){
    max-width: var(--medium);
  }

`
const OrderLinesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  width: 100%;
  color: var(--text-colour);
`

const TotalContainer = styled(motion.div)`
  /* padding-top: 10px; */

  margin-top: 20px;
`

function CheckoutBasket({orderLines, fees, total}) {
  const [collapsed, setCollapsed] = useState(true);
  const [height, setHeight] = useState(0);
  const { groupedFees } = useEcommerce();
  const heightRef = useRef(0);

  // Memoize grouped fees calculation
  const memoizedGroupedFees = useMemo(() => groupedFees(), [fees]);

  // Use a ref to track previous total for optimization
  const prevTotalRef = useRef(total);

  useEffect(() => {
    // Only update if total has changed significantly
    if (Math.abs(prevTotalRef.current - total) > 0.01) {
      prevTotalRef.current = total;
    }
  }, [total]);

  const handleCollapseToggle = useCallback(() => {
    if(collapsed) {
      const element = document.getElementById('order-lines-container');
      const orderItemsContainer = document.getElementById('order-items-container');
      
      if (element && orderItemsContainer) {
        const elementHeight = element.scrollHeight;
        const orderItemsContainerHeight = orderItemsContainer.scrollHeight;
        heightRef.current = elementHeight + orderItemsContainerHeight;
        setHeight(heightRef.current);
      }
    }
    setCollapsed(!collapsed);
  }, [collapsed]);

  // Memoize basket items for performance
  const basketItems = useMemo(() => (
    <BasketItems items={orderLines} collapsed={collapsed} />
  ), [orderLines, collapsed]);

  return (
    <OrderItemsContainer
      id='order-items-container'
      collapsed={collapsed}
    >
      <OrderLinesContainer
        id='order-lines-container'
        animate={{
          height: collapsed ? 0 : height,
          opacity: collapsed ? 0 : 1
        }}
        initial={{
          height: 0,
          opacity: 0
        }}
        transition={{ duration: 0.75}}
      >
        <DividerWithText text={'Basket'} />
        {basketItems}

        {memoizedGroupedFees.length > 0 && (
          <>
            <DividerWithText text={'Fees'} />
            <BasketFees />
          </>
        )}
      </OrderLinesContainer>

      <TotalContainer id='total-container'>
        <BasketItem
          id={'total'}
          name={'Total'}
          info={`Tap to ${collapsed ? 'show' : 'hide'} order summary`}
          value={total}
          style={{
            backgroundColor: collapsed && 'var(--light-colour)',
            color: !collapsed && 'black'
          }}
          onClick={handleCollapseToggle}
        />
      </TotalContainer>
    </OrderItemsContainer>
  )
}

const BasketItems = ({items, collapsed}) => {
  return (
    items?.length > 0 
    && items.map((item, index) => {
      return (
        <BasketItem
          key={index}
          collapsed={collapsed}
          quantity={item.quantity}
          name={item.name}
          value={item.subTotal}
          addons={item.addons}
          category={item.category}
          item={item}
        />
      )
    })  
    
  )
}

export default CheckoutBasket