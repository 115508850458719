/**
 * Validates critical event data that is required for the UI to function properly.
 * @param {Object} event - The event object to validate
 * @returns {Object} - Object containing validation result and any missing fields
 */
export const validateCriticalEventData = (event) => {
  if (!event) {
    return {
      isValid: false,
      missingFields: ['event'],
      error: new Error('Event object is null or undefined')
    };
  }

  const critical = {
    id: event.id,
    slug: event.slug,
    title: event.title,
    theme: event.theme,
  };

  const missing = Object.entries(critical)
    .filter(([_, value]) => !value)
    .map(([key]) => key);

  return {
    isValid: missing.length === 0,
    missingFields: missing,
    error: missing.length > 0 
      ? new Error(`Missing critical event data: ${missing.join(', ')}`)
      : null
  };
};

/**
 * Gets a fallback value for a missing field based on its type
 * @param {string} field - The name of the missing field
 * @returns {any} - A safe fallback value
 */
export const getFallbackValue = (field) => {
  const fallbacks = {
    id: 'unknown',
    slug: 'unknown',
    title: 'Untitled Event',
    theme: {
      primary: '#000000',
      secondary: '#ffffff',
      background: '#f5f5f5'
    }
  };

  return fallbacks[field] || null;
};

/**
 * Creates a safe event object with fallbacks for missing critical data
 * @param {Object} event - The potentially incomplete event object
 * @returns {Object} - A safe event object with fallbacks for missing data
 */
export const createSafeEvent = (event) => {
  if (!event) {
    return Object.fromEntries(
      Object.entries(getFallbackValue('theme')).map(([key, value]) => [key, value])
    );
  }

  const { missingFields } = validateCriticalEventData(event);
  
  return {
    ...event,
    ...Object.fromEntries(
      missingFields.map(field => [field, getFallbackValue(field)])
    )
  };
};
