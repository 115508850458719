import React, { useState } from 'react'
import styled from 'styled-components';
import { useParams } from 'react-router-dom'
import { AddToAppleWallet } from '../../components/ui/AddToAppleWallet';
import LogoComponent from '../../components/ui/LogoComponent';
import { useApiQuery } from '../../hooks/customHooks';
import { API_URL } from '../../js/Helper';
import { ButtonComponent } from '../../components/ui/buttons';
import { ticketEmoji } from '../../js/emojis';
import { toast } from 'react-toastify';
import { useDrawer } from '../../js/hooks/context/DrawerContext';
import OrderActions from '../../features/ecommerce/orders/components/OrderActions';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
    width: 100%;

`

const Order = ({ order }) => {

    const [isLoading, setIsLoading] = useState(false);
    const { closeDrawer } = useDrawer();

    const handleClick = () => {
        setIsLoading(true)

        window.location.href = `${API_URL}/v1/order/${order.id}/download`

        setTimeout(() => {
            setIsLoading(false)
            closeDrawer();
            toast.success('Your tickes will be in your downloads folder once complete.')
        }, 2500);
    }
  return (
    <Container>

        
        <OrderActions order={order} />
        
        {/* <AddToAppleWallet id={id} type={'EventTicket'} /> */}
        {/* <ButtonComponent
        variant={'solid'}
        text={'Download Tickets'}
        isLoading={isLoading}
        icon={ticketEmoji}
        onClick={handleClick}
        >
        </ButtonComponent> */}
    </Container>
  )
}

export default Order