import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useEffect, useState } from 'react'
import { CheckoutForm } from '../../../checkout/components/CheckoutForm'
import { StripePaymentFormContainer } from '../../../basket/styles/ecommeroce.styles';
import { ButtonComponent } from '../../../../../components/ui/buttons';
import { IoCheckmark, IoCheckmarkSharp, IoTicket } from 'react-icons/io5';
import axios from 'axios';
import {API_URL, scrollToElement} from '../../../../../js/Helper';
import { useMutation } from '@tanstack/react-query';
import { useDrawer } from '../../../../../js/hooks/context/DrawerContext';
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import { AuthContext } from '../../../../authentication';
import { useEcommerce } from '../../../js/context/EcommerceContext';
import { useOrder } from '../../../orders/hooks/OrderContext';
import { extractColorCode } from '../../../../themes/js/utils';
import {useHandleError} from "../../../../error-handling/js/hooks/index.js";
import {useCheckout} from "../../../js/context/CheckoutContext.jsx";
import {useEvent} from "../../../../events/js/hooks/index.js";


async function initializeStripe(retries = 3, delay = 1000) {
    for (let attempt = 1; attempt <= retries; attempt++) {
        try {
            const stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY);
            if (!stripe) {
                throw new Error('Stripe initialization failed.');
            }
            return stripe;
        } catch (error) {
            console.error(`Attempt ${attempt} - Failed to load Stripe:`, error);
            if (attempt < retries) {
                await new Promise(res => setTimeout(res, delay)); // Delay before retrying
            } else {
                // Handle final failure after all retries
                console.error('All attempts to load Stripe failed.');
                // Optionally, notify the user about the failure or take other actions
            }
        }
    }
}

const stripePromise = initializeStripe()




function StripePaymentForm({ 
    payment_intent, 
    theme,
    amount,
    onSuccess
}) {

    const [buttonMessage, setButtonMessage] = useState('Complete Order');
    const navigate = useNavigate();
    const { openDrawer } = useDrawer();
    const { isAuthenticated, header } = useContext(AuthContext)
    const { config, updateConfig } = useEcommerce();
    const { setOrder } = useOrder();
    const { showErrorToast } = useHandleError();
    const { checkout } = useCheckout();

    const submitMutation = useMutation({
        mutationFn: () => axios.put(`${API_URL}/checkout/complete-order/${checkout.id}`, null, {
          headers: header()
        }),
        onSuccess: (data) => {
            const res = data.data;
            updateConfig({
                confirmationMessage: res.message,
                order: res?.data
            })
            setOrder(res?.data)
        },
        onError: (error) => {
            console.error('Error completing order', checkout);
            showErrorToast(error.message ?? 'There has been an error')
        }
    })

    const handleClick = async () => {
        if(!config.isPaymentAllowed && !isAuthenticated) {
            toast.warning('Please confirm your information details')
            scrollToElement('order-summary')
            return;
        }

        if(config?.hasQuestions === true && config?.questionsComplete !== true) {
            toast.warn('Please complete the questions');
            scrollToElement('questions')
            return;
        }

        submitMutation.mutate()
    }       

    return (
        <StripePaymentFormContainer
            id='payment-form'
            style={{
                paddingTop: config?.hasQuestions === false && '150px',
                paddingBottom: config?.hasQuestions == false && '150px'
            }}
        >
            {amount > 0 ? (
                <CheckoutForm
                    intent={payment_intent}
                    amount={amount}
                    onSuccess={onSuccess}
                />
            ) : (
                <ButtonComponent
                    text={buttonMessage}
                    variant={'solid'}
                    icon={<IoTicket />}
                    onClick={handleClick}
                    isLoading={submitMutation.isLoading}
                />
            )}
        </StripePaymentFormContainer>
    )
}

export default StripePaymentForm