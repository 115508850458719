import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { animations, glowEffects, buttonSizes, buttonVariants } from './theme';

const StyledButton = styled(motion.button)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-weight: ${props => props.$variant === 'cta' ? '600' : '500'};
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.2s ease;
  overflow: visible;
  
  ${props => buttonSizes[props.$size]}
  ${props => {
    const variant = buttonVariants[props.$variant];
    return `
      background: ${variant.background};
      color: ${variant.color};
      ${variant.additionalStyles || ''}
    `;
  }}
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  ${props => props.$useGradient ? `
    background: var(--accent-colour);
    
    &:hover:not(:disabled):not([loading]) {
      background: linear-gradient(45deg, var(--gradient-start-hover), var(--gradient-end-hover));
      transform: translateY(-1px);
      box-shadow: var(--shadow-hover-default);
    }
  ` : `
    &:hover:not(:disabled):not([loading]) {
      transform: translateY(-1px);
      ${props => {
        const variant = buttonVariants[props.$variant];
        if (!variant?.hoverStyles) return '';
        return `
          background: ${variant.hoverBackground};
          ${variant.hoverStyles}
        `;
      }}
    }
  `}

  @media (max-width: 768px) {
    ${props => props.$variant === 'cta' && `
      width: 100%;
      height: 50px;
      border-radius: 12px 4px 12px 4px;
    `}
  }

  &:hover:not(:disabled):not([loading]) {
    transform: translateY(-1px);
    ${props => {
      const variant = buttonVariants[props.$variant];
      return variant.hoverStyles ? `
        background: ${variant.hoverBackground};
        ${variant.hoverStyles}
      ` : '';
    }}
  }
`;

const ContentWrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: ${props => buttonSizes[props.$size]?.iconSize || '20px'};
    height: ${props => buttonSizes[props.$size]?.iconSize || '20px'};
    transition: all 0.2s ease;
  }
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background: var(--primary-colour);
  transition: width 0.3s ease;
  border-radius: 0 2px 2px 0;
`;

const Tooltip = styled(motion.div)`
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--background-colour);
  color: var(--text-colour);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 1;
`;

const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  icon,
  iconPosition = 'left',
  loading = false,
  disabled = false,
  onClick,
  className,
  useGradient = false,
  tooltip,
  progress,
  status,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    if (tooltip) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (tooltip) {
      setShowTooltip(false);
    }
  };

  const handleClick = (e) => {
    if (!disabled && !loading && onClick) {
      onClick(e);
    }
  };

  return (
    <StyledButton
      $variant={variant}
      $size={size}
      $useGradient={useGradient}
      disabled={disabled || loading}
      className={className}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      whileHover={!disabled && !loading ? { scale: 1.02 } : undefined}
      whileTap={!disabled && !loading ? { scale: 0.98 } : undefined}
      initial={{ scale: 1 }}
      animate={{ scale: 1 }}
      transition={{ 
        type: 'spring',
        stiffness: 500,
        damping: 30
      }}
      {...props}
    >
      <ContentWrapper>
        {iconPosition === 'left' && icon && (
          <IconWrapper $size={size}>
            {icon}
          </IconWrapper>
        )}
        {children}
        {iconPosition === 'right' && icon && (
          <IconWrapper $size={size}>
            {icon}
          </IconWrapper>
        )}
        {tooltip && showTooltip && (
          <Tooltip
            initial={{ opacity: 0, y: -4 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -4 }}
          >
            {tooltip}
          </Tooltip>
        )}
        {progress > 0 && (
          <ProgressBar
            style={{
              width: `${progress}%`,
              background: status === 'success' ? 'var(--success-colour)' :
                        status === 'error' ? 'var(--error-colour)' :
                        'var(--primary-colour)'
            }}
          />
        )}
      </ContentWrapper>
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'success', 'warning', 'danger', 'outline', 'cta']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  useGradient: PropTypes.bool,
  tooltip: PropTypes.string,
  progress: PropTypes.number,
  status: PropTypes.oneOf(['success', 'error']),
};

export default Button;
