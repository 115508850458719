import React from 'react';
import {Outlet} from "react-router-dom";
import styled from "styled-components";
import {Footer} from "./components/Footer.jsx";
import {FloatingNav} from "./components/FloatingNav/index.jsx";
import {userAreaMenu} from "./menus/js/userAreaMenu.js";

const PageContainer = styled.section``
const UserPagesLayout = () => {
    return (
        <>
            <PageContainer>
                <Outlet/>
            </PageContainer>
            <FloatingNav items={userAreaMenu}  />
            <Footer/>
        </>

    );
};

export default UserPagesLayout;