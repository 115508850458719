import React, { useContext } from 'react'
import styled from 'styled-components'
import { StandardSection } from '../../sections/StandardSection'
import LogoComponent from '../../../components/ui/LogoComponent'
import { Divider } from '../../../components/ui/Divider'
import { AuthenticatedFooterLinks } from '../AuthenticatedFooterLinks'
import { UnauthenticatedFooterLinks } from '../UnauthenticatedFooterLinks'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { useDrawer } from '../../../js/hooks/context/DrawerContext'
import { UserInfoBox } from '../../../features/authentication/components/UserInfoBox'
import { gearsEmoji, padlockUnlockedEmoji, personSilihouette } from '../../../js/emojis'
import { AuthContext } from '../../../features/authentication'
import { Paragraph } from '../../../components/ui/typography'
import { LoginForm } from '../../../features/authentication/components/LoginForm'
import {IoIosCloseCircleOutline} from "react-icons/io";

const Container = styled.section`
    position: relative;
    display: grid;
    grid-template-rows: 1fr 3fr 1fr;
    /* min-height: 100%; */
    height: 100dvh;
    max-height: 100dvh;
    width: 100%;
    padding: 0px 20px;
`

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
`

const TopSection = styled(Section)`
    `
const MiddleSection = styled(Section)`
    font-size: 1.2em;
    text-transform: uppercase;
`
const BottomSection = styled(Section)`
`
const UnorderedList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
    padding: 0;

`

export const SlideOutMenu = () => {
    
    const { closeDrawer, openDrawer } = useDrawer();
    const { user, logout } = useContext(AuthContext)

  return (
    <Container>
        <TopSection>
            <LogoComponent />
            <Divider />

        </TopSection>

        <MiddleSection>
            <UnorderedList>
                <AuthenticatedFooterLinks />
                <UnauthenticatedFooterLinks/>
            </UnorderedList>
        </MiddleSection>
        
        <BottomSection>
            {/*<Divider />*/}
        </BottomSection>
    </Container>
  )
}
