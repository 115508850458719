import React from 'react'
import styled from 'styled-components';

const ParagraphStyle = styled.p`
  z-index: 2;
  margin: 0;
  margin-bottom: 0px;
  box-sizing: border-box;
  color: var(--text-colour);
  text-align: ${props => props.textAlign || 'left'};

  @media (min-width: 768px) {
    // font-size: 1rem;
    width: 50%;
    margin: 0 auto;
  }
`

export const Paragraph = ({ children, style, content, onclick, onClick, id }) => {

  return (
    <ParagraphStyle id={id} onClick={onclick || onClick} style={style}>{content || children}</ParagraphStyle>
  )
}
