import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 100vw;

  // max-width: 100%;
  margin: 0 auto;
  height: ${({ height }) => {
    switch (height) {
      case 'full':
        return '80svh';
      case 'auto':
        return 'auto';
      default:
        return `${height}px`;
    }
  }};
  flex-direction: ${({ reverseMobile }) => reverseMobile ? 'column-reverse' : 'column'};
  gap: ${({ gap }) => gap}px;

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: ${({ padding }) => padding}px;
  width: 100%;

  @media (max-width: 767px) {
    ${({ mobileLayout, isRight }) => {
      if (mobileLayout === 'leftOnly' && isRight) {
        return 'display: none;';
      }
      if (mobileLayout === 'fullHeight') {
        return 'height: 100svh;';
      }
      return '';
    }}
  }

  @media (min-width: 768px) {
    width: ${({ ratio }) => ratio}%;
  }
`;

/**
 * TwoColumnLayout - A flexible two-column layout component
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.leftContent - Content for left column
 * @param {React.ReactNode} props.rightContent - Content for right column
 * @param {number} props.leftRatio - Width ratio for left column (1-100)
 * @param {number} props.rightRatio - Width ratio for right column (1-100)
 * @param {('full'|'auto'|number)} props.height - Container height ('full' for 100vh, 'auto', or number for fixed height)
 * @param {number} props.gap - Gap between columns in pixels
 * @param {number} props.padding - Padding inside columns in pixels
 * @param {boolean} props.reverseMobile - Whether to reverse column order on mobile
 * @param {('leftOnly'|'fullHeight'|'stacked')} props.mobileLayout - Mobile display variation
 */
const TwoColumnLayout = ({
  leftContent,
  rightContent,
  leftRatio = 50,
  rightRatio = 50,
  height = 'svh',
  gap = 0,
  padding = 24,
  reverseMobile = false,
  mobileLayout = 'stacked'
}) => {
  return (
    <Container height={height} gap={gap} reverseMobile={reverseMobile}>
      <Column 
        ratio={leftRatio} 
        padding={padding} 
        mobileLayout={mobileLayout}
        isRight={false}
      >
        {leftContent}
      </Column>
      <Column 
        ratio={rightRatio} 
        padding={padding}
        mobileLayout={mobileLayout}
        isRight={true}
      >
        {rightContent}
      </Column>
    </Container>
  );
};

TwoColumnLayout.propTypes = {
  leftContent: PropTypes.node.isRequired,
  rightContent: PropTypes.node.isRequired,
  leftRatio: PropTypes.number,
  rightRatio: PropTypes.number,
  height: PropTypes.oneOfType([
    PropTypes.oneOf(['full', 'auto']),
    PropTypes.number
  ]),
  gap: PropTypes.number,
  padding: PropTypes.number,
  reverseMobile: PropTypes.bool,
  mobileLayout: PropTypes.oneOf(['leftOnly', 'fullHeight', 'stacked'])
};

export default TwoColumnLayout;