import React, { forwardRef, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { premiumInteractive, premiumAnimation } from '../../../tokens/animations';

const DateInputWrapper = styled.div`
  position: relative;
  width: inherit;
`;

const InputGroup = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  background: var(--primary-colour);
  border: 1px solid rgba(191, 181, 147, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  height: 48px;
  align-items: center;
  ${premiumInteractive}

  ${props => props.$error && css`
    border-color: var(--error-colour);
  `}

  ${props => props.disabled && `
    opacity: 0.5;
    cursor: not-allowed;
    border-color: rgba(191, 181, 147, 0.05);
  `}
`;

const DateSegment = styled.input`
  background: transparent;
  border: none;
  color: var(--text-colour);
  font-size: 16px;
  text-align: center;
  outline: none;
  padding: 0;
  min-width: 0;

  /* Set flex-basis based on segment type */
  flex-basis: ${props => {
    switch (props.$type) {
      case 'day':
      case 'month':
        return '25%';
      case 'year':
        return '35%';
      default:
        return '30%';
    }
  }};

  &::placeholder {
    color: var(--text-colour);
    opacity: 0.3;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Separator = styled.span`
  color: var(--text-colour);
  opacity: ${props => props.$focused ? 0.8 : 0.3};
  transition: opacity 0.2s ease;
  user-select: none;
  flex-basis: 7.5%;
  text-align: center;
`;

const ErrorMessage = styled.div`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.9;
`;

const DateInput = forwardRef(({
  value,
  onChange,
  onBlur,
  name,
  error = false,
  errorMessage,
  disabled = false,
  maxYear = new Date().getFullYear(),
  minYear = 1900,
  placeholder = 'DD/MM/YYYY',
  className,
  autoComplete,
  ...props
}, ref) => {
  const [focused, setFocused] = useState(false);
  
  const parseValue = useCallback((val) => {
    if (!val) return null;
    if (val instanceof Date) return val;
    if (typeof val === 'string') {
      const [day, month, year] = val.split('/').map(num => parseInt(num, 10));
      const date = new Date(year, month - 1, day);
      return !isNaN(date.getTime()) ? date : null;
    }
    return null;
  }, []);

  const [segments, setSegments] = useState(() => {
    const parsedValue = parseValue(value);
    return {
      day: parsedValue ? String(parsedValue.getDate()).padStart(2, '0') : '',
      month: parsedValue ? String(parsedValue.getMonth() + 1).padStart(2, '0') : '',
      year: parsedValue ? String(parsedValue.getFullYear()) : ''
    };
  });
  
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  // Update segments when value changes externally
  React.useEffect(() => {
    const newParsedValue = parseValue(value);
    if (newParsedValue) {
      setSegments({
        day: String(newParsedValue.getDate()).padStart(2, '0'),
        month: String(newParsedValue.getMonth() + 1).padStart(2, '0'),
        year: String(newParsedValue.getFullYear())
      });
    }
  }, [value, parseValue]);

  const validateSegment = useCallback((type, value) => {
    const num = parseInt(value, 10);
    switch (type) {
      case 'day':
        return num >= 1 && num <= 31;
      case 'month':
        return num >= 1 && num <= 12;
      case 'year':
        return num >= minYear && num <= maxYear;
      default:
        return false;
    }
  }, [minYear, maxYear]);

  const notifyChange = useCallback((updatedSegments) => {
    if (updatedSegments.day && updatedSegments.month && updatedSegments.year.length === 4) {
      const day = updatedSegments.day.padStart(2, '0');
      const month = updatedSegments.month.padStart(2, '0');
      const year = updatedSegments.year;
      
      // Validate the complete date
      const date = new Date(year, parseInt(month, 10) - 1, parseInt(day, 10));
      if (!isNaN(date.getTime()) && 
          date.getDate() === parseInt(day, 10) && 
          date.getMonth() === parseInt(month, 10) - 1 && 
          date.getFullYear() === parseInt(year, 10)) {
        const formattedDate = `${day}/${month}/${year}`;
        if (onChange) {
          const event = {
            target: {
              name,
              value: formattedDate
            }
          };
          onChange(event);
        }
      }
    }
  }, [onChange, name]);

  const handleSegmentChange = useCallback((type, value) => {
    if (disabled) return;

    let newValue = value.replace(/\D/g, '');
    const maxLength = type === 'year' ? 4 : 2;
    newValue = newValue.slice(0, maxLength);
    
    setSegments(prev => {
      const updatedSegments = { ...prev, [type]: newValue };

      // Auto-advance logic
      if (type === 'day' && newValue.length === 2) {
        if (validateSegment('day', newValue)) {
          updatedSegments.day = newValue.padStart(2, '0');
          requestAnimationFrame(() => monthRef.current?.focus());
        }
      } else if (type === 'month' && newValue.length === 2) {
        if (validateSegment('month', newValue)) {
          updatedSegments.month = newValue.padStart(2, '0');
          requestAnimationFrame(() => yearRef.current?.focus());
        }
      }

      // Notify change after state update
      requestAnimationFrame(() => notifyChange(updatedSegments));

      return updatedSegments;
    });
  }, [disabled, validateSegment, notifyChange]);

  const handleKeyDown = useCallback((e, type) => {
    if (disabled) return;

    if (e.key === 'Backspace' && !e.target.value) {
      e.preventDefault();
      setSegments(prev => {
        const updatedSegments = { ...prev };
        switch (type) {
          case 'year':
            requestAnimationFrame(() => monthRef.current?.focus());
            updatedSegments.year = '';
            break;
          case 'month':
            requestAnimationFrame(() => dayRef.current?.focus());
            updatedSegments.month = '';
            break;
          case 'day':
            updatedSegments.day = '';
            break;
          default:
            break;
        }
        return updatedSegments;
      });
    }
  }, [disabled]);

  const handleBlur = useCallback(() => {
    setFocused(false);
    if (onBlur) {
      const event = {
        target: {
          name,
          value: value || ''
        }
      };
      onBlur(event);
    }
  }, [onBlur, name, value]);

  return (
    <DateInputWrapper className={className}>
      <InputGroup $error={error} disabled={disabled}>
        <DateSegment
          ref={dayRef}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={2}
          placeholder="DD"
          value={segments.day}
          onChange={(e) => handleSegmentChange('day', e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          onKeyDown={(e) => handleKeyDown(e, 'day')}
          disabled={disabled}
          $type="day"
          autoComplete={autoComplete ? `${autoComplete}-day` : 'off'}
          {...props}
        />
        <Separator $focused={focused}>/</Separator>
        <DateSegment
          ref={monthRef}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={2}
          placeholder="MM"
          value={segments.month}
          onChange={(e) => handleSegmentChange('month', e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          onKeyDown={(e) => handleKeyDown(e, 'month')}
          disabled={disabled}
          $type="month"
          autoComplete={autoComplete ? `${autoComplete}-month` : 'off'}
          {...props}
        />
        <Separator $focused={focused}>/</Separator>
        <DateSegment
          ref={yearRef}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={4}
          placeholder="YYYY"
          value={segments.year}
          onChange={(e) => handleSegmentChange('year', e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          onKeyDown={(e) => handleKeyDown(e, 'year')}
          disabled={disabled}
          $type="year"
          autoComplete={autoComplete ? `${autoComplete}-year` : 'off'}
          {...props}
        />
      </InputGroup>
      {error && errorMessage && (
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      )}
    </DateInputWrapper>
  );
});

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  maxYear: PropTypes.number,
  minYear: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
};

export default DateInput;
