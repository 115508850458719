import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { premiumAnimation, premiumInteractive } from '../../../tokens/animations';

const rippleAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0.8;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${premiumInteractive}
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const RadioCircle = styled(motion.div)`
  width: 24px;
  height: 24px;
  border: 1px solid rgba(191, 181, 147, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-colour);
  position: relative;
  ${premiumAnimation}

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--accent-colour);
    transform: scale(${props => props.$isChecked ? 1 : 0});
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 50%;
    background: var(--accent-colour);
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
  }
  
  ${props => props.$isChecked && css`
    border-color: var(--accent-colour);
    box-shadow: 0 0 0 1px var(--accent-colour-faded),
                0 2px 4px rgba(0, 0, 0, 0.2);

    &::after {
      animation: ${rippleAnimation} 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  `}

  ${props => props.$error && css`
    border-color: var(--error-colour);
    &::before {
      background: var(--error-colour);
    }
    &:focus {
      border-color: var(--error-colour);
      box-shadow: 0 0 0 1px var(--error-colour),
                  0 2px 4px rgba(181, 89, 89, 0.2);
    }
  `}

  ${props => props.$disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

const Label = styled.span`
  color: var(--text-colour);
  font-size: 14px;
  user-select: none;
  opacity: ${props => props.$disabled ? 0.5 : 1};
`;

const ErrorMessage = styled(motion.div)`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 4px;
  opacity: 0.9;
`;

const Radio = forwardRef(({
  checked = false,
  onChange,
  label,
  error = false,
  errorMessage,
  disabled = false,
  className,
  ...props
}, ref) => {
  const handleChange = (event) => {
    if (!disabled && onChange) {
      onChange(event);
    }
  };

  return (
    <div className={className}>
      <Container
        onClick={() => !disabled && onChange?.(!checked)}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <HiddenRadio
          ref={ref}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        <RadioCircle
          $isChecked={checked}
          $error={error}
          $disabled={disabled}
          whileHover={!disabled && { scale: 1.05 }}
          whileTap={!disabled && { scale: 0.95 }}
        />
        {label && <Label $disabled={disabled}>{label}</Label>}
      </Container>
      <AnimatePresence>
        {error && errorMessage && (
          <ErrorMessage
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </AnimatePresence>
    </div>
  );
});

Radio.displayName = 'Radio';

Radio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.node,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Radio;
