import React from 'react';
import styled from 'styled-components';
import { Section } from '../../../layout/components/LayoutSection';
import { ContentWrapper } from '../../moments/pages/CampaignPurchaseLandingPage';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';

const DeliverablesSection = ({ collection, handleDeliverableClick, selectedDeliverables = [], openDrawer }) => {
  return (
    <Section 
      id={'deliverables'}
      clip={"true"}
      style={{
        background: 'var(--ticket-section-bg-colour)',
        zIndex: 1,
        paddingBottom: '100px',
        // paddingTop: '150px',
        height: 'fit-content',
        minHeight: 'fit-content',
        maxHeight: 'fit-content'
      }}
    >
      <ContentWrapper>
        <ImpactTitle main={'Deliverables'} style={{ background: 'var(--primary-colour)' }} />
        <Paragraph>
            In exchange for this one of kind experience, provided by O Life, we have a small list of deliverables required for your participation.
        </Paragraph>
        <ul>
          {collection.map((deliverable, index) => (
            <li style={{color: 'var(--text-colour)'}} key={index}>
              {deliverable}
            </li>
          ))}
        </ul>
      </ContentWrapper>
    </Section>
  );
};

export default DeliverablesSection;
