import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import {AnimatePresence, motion} from 'framer-motion';


const ComponentContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // border: solid 1px rgba(255,255,255,0.1);
    border-radius: var(--border-radius-default);
    // padding: 20px;
    
    @media screen and (min-width: 1024px) {  
        max-width: 300px;
        align-self: center;
    }
    
`


const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 40px auto;
    
    &::before {
        content: '';
        position: absolute;
        top: -20px;
        right: -20px;
        width: 200px;
        height: 200px;
        background: linear-gradient(45deg, var(--success-colour), transparent);
        border-radius: 50%;
        filter: blur(60px);
        opacity: 0.3;
        z-index: -1;
    }
    
    &::after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: -20px;
        width: 150px;
        height: 150px;
        background: linear-gradient(225deg, var(--accent-colour), transparent);
        border-radius: 50%;
        filter: blur(40px);
        opacity: 0.3;
        z-index: -1;
    }
`;

const VideoContainer = styled(motion.div)`
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px;

    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    
    &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 20px;
        padding: 2px;
        background: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.1),
            var(--success-colour) 25%,
            var(--accent-colour) 75%,
            rgba(255, 255, 255, 0.1)
        );
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }
    
    /* Abstract decorative elements */
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120%;
        height: 120%;
        background: radial-gradient(
            circle at center,
            transparent 30%,
            rgba(255, 255, 255, 0.1) 60%,
            transparent 70%
        );
        pointer-events: none;
    }
`;


const PlayerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: ${props => props.aspectRatio || '16/9'};
    max-width: 100%;
    overflow: hidden;
    
    @media screen and (min-width: 1024px){
        max-width: 100%;
    }
`

const Thumbanail = styled.img`
    width: 200px;
    max-width: fit-content;
    animation: fadeIn 250ms ease-in-out;
    border-radius: var(--border-radius-default);

    @media screen and (min-width: 1024px){
        max-width: 300px;
    }

`

const ThumbnailContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: calc(100vw - 50px);
    width: 100%;
    max-width: fit-content;
    height: 200px;
    border: solid 1px var(--accent-colour);
    border-radius: var(--border-radius-default);
    background-color: var(--primary-colour);

    svg {
        position: absolute; /* Ensure the icon is absolutely positioned within the container */
        font-size: 70px;
        z-index: 10; /* Higher z-index to ensure it's on top of the thumbnail */
        color: white;

       &:active {
        color: var(--accent-colour);
        font-size: 30px; /* You might want to adjust the active state size to ensure it doesn't get too small */
       }
    }

    @media screen and (min-width: 768px) {
        min-width: 250px;
    }

    @media screen and (min-width: 1024px) {
        min-width: 375px;
    }
`;

 
export const VideoComponent = ({data, numberOfItems, style, url, thumbnail}) => {
    const [playing, setPlaying] = useState(false);
    const [aspectRatio, setAspectRatio] = useState('16/9');
    const playerRef = useRef(null);

    const handleDuration = () => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            if (videoElement) {
                const ratio = `${videoElement.videoWidth}/${videoElement.videoHeight}`;
                setAspectRatio(ratio);
            }
        }
    };

    const handlePlay = () => {
        setPlaying(true);
    };

    const handlePause = () => {
        setPlaying(false);
    };

    return (
        <ComponentContainer style={style}>  
            <PlayerContainer aspectRatio={aspectRatio}>
                <ReactPlayer 
                    ref={playerRef}
                    url={url ?? data?.cdnUrl}
                    width="100%"
                    height="100%"
                    controls
                    onPlay={handlePlay}
                    onPause={handlePause}
                    onDuration={handleDuration}
                    playing={playing}
                    pip={false}
                    stopOnUnmount={true}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            </PlayerContainer>
        </ComponentContainer>
    );
}
