import React from 'react'
import styled from 'styled-components'

const SelectComponent = styled.select`
  position: relative;
  width: 350px;
  height: 50px;
  border-radius: var(--border-radius-default);
  padding: 0px 10px;
  text-align: center;
  border: none;
  color: white;
  background-color: var(--default-primary-colour-faded);
  backdrop-filter: blur(5px);
  border: solid 1px var(--default-accent-colour);
  z-index: 2;



  appearance: none; /* removes platform-specific styling */
  -webkit-appearance: none; /* for Safari */
  -moz-appearance: none; /* for Firefox */
`

export const Select = ({
  options, placeholder, passChangeToParent
}) => {

  const handleChange = (item) => {
        
        // The item is the whole html collection from "selectionOptions" 
        // So we need to access the selectedOPtion
        let selectedItem = item[0];

        // Create an object of just the data we need
        selectedItem = {label: selectedItem.label, value:selectedItem.value};

        // // Stringify that data so we that we can store it in local storage
        // const stringify = JSON.stringify(selectedItem);
        // localStorage.setItem('city', stringify)

        passChangeToParent(selectedItem)
    }
 
  return (
    <>
        <SelectComponent defaultValue={placeholder} onChange={(e) => handleChange(e.target.selectedOptions)}>
            <option  value="" disabled selected>{placeholder}</option>

            {
              options && options.map((option, index) => {
                return (
                  <option key={index} value={option.value} name={option.label}>{option.label}</option>
                )
              })
            }

        </SelectComponent>

    </>
  )
}
