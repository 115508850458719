import React, {useState} from 'react'
import './single_page_layout.css'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import {changeCSSVariableProperty} from "../../features/themes/js/utils.js";


const SinglePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  top: 0;
  width: 100dvw;
  min-height: 100dvh;
  height: fit-content;
  max-height: fit-content;
  box-sizing: border-box;
`

const ColourSwitcher = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    //width: 100%;
    z-index: 10000;
    //background-color: rgba(0,0,0,0.1);
`

export const SinglePageLayout = () => {

    const [colour, setColour] = useState('')
    const [accentColour, setAccentColour] = useState('')
    const [textColour, setTextColour] = useState('')





  return (
      <>
      <SinglePageContainer>
          <ColourSwitcher style={{display: 'none'}}>
              <input type={'color'} onChange={(e) => {
                  const value = e.target.value;
                  setColour(value)

                  changeCSSVariableProperty('--default-primary-colour', value)
                  changeCSSVariableProperty('--primary-colour', value)

              }} value={colour}/>
              <input type={'color'} onChange={(e) => {
                  const value = e.target.value;
                  setAccentColour(value)

                  changeCSSVariableProperty('--default-accent-colour', value)
                  changeCSSVariableProperty('--accent-colour', value)

              }} value={accentColour}/>
          <input type={'color'} onChange={(e) => {
              const value = e.target.value;
              setTextColour(value)

              // changeCSSVariableProperty('--default-accent-colour', value)
              changeCSSVariableProperty('--text-colour', value)

          }} value={textColour}/>
      </ColourSwitcher>
      <Outlet/>
      </SinglePageContainer>
</>
)
}
