import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { CDN_URL, THEME } from '../../../../js/Helper'
import { theme as defaultTheme } from '../../../../js/Helper';
import { AuthContext } from '../../../authentication';
import { Link, useNavigate } from 'react-router-dom';
import { navigateAndReturn } from '../../../../js/auth';
import { InstructionTitle } from '../../../../components/ui/typography/InstructionTitle';
import { AddToAppleWallet } from '../../../../components/ui/AddToAppleWallet';
import { paths } from '../../../../js/helpers/paths';

const MemberCardContainer = styled.div``

const MemberCard = styled.div`
    position: relative;  /* Make the card a relative container */
    min-width: ${props => props.width ? props.width : "100%"};
    max-height: 350px;
    border: ${props => props.style.selected == true ? 'solid 2px' : 'none'};
    border-radius: var(--border-radius-default);
    margin-bottom: 20px;
    background-color: var(--default-primary-colour);
    background-size: cover;
    background-position: center;
    box-shadow: 10px 10px 20px var(--faded-black), -10px -10px 20px rgba(0,0,0,0.2);
    z-index: 999;
    animation: fadeIn 1s;
    overflow: hidden;
    box-sizing: border-box;


    /* This pseudo-element forces the 16:9 aspect ratio */
    &::before {
        content: '';
        display: block;
        padding-top: 56.25%;  /* 9/16 * 100% = 56.25% */
    }

    /* This is a nested div to put your content inside */
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 20px 20px;
        box-sizing: border-box;
    }

     @media screen and (min-width: 1024px){
        width: 20rem;
        min-width: 25rem;
    }

    @media screen and (min-width: 1920px){
        width: 27rem;
    }
`;

const MemberCardBox = styled.div`
    padding: 0px;
    text-align: left;
    color: var(--text-colour);
`

const MemberCardTitle = styled.div`
    font-size: medium;
    font-weight: bold;
    margin: 0px;

    @media screen and (min-width: 1920px){
        font-size: 1.3em;
    }
`

const MemberCardSubtitle = styled.div`
    @media screen and (min-width: 1920px){
        font-size: 1em;
        margin-top: -5px;
        margin-bottom: -5px;
    }
`

export const LoyaltyCard = ({ 
    tier,
    card,
    member,
    style,
    onClick,
    handleClick,
    select,
    width,
    redirect,
    message,
    options
}) => {

    const [themeStyle, setTheme] = useState(null);

    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const navigateToCard = (slug) => {
        navigate(navigateAndReturn('afterSubmit', `/${paths['card']}/${slug}?back=true`, location.pathname))
    }

    /**
     * STYLES 
     * 
     * 
     */

    const cardStyle = {
        backgroundImage: `url(${tier?.image?.cdnUrl})`,
        backgroundColour: tier && tier.theme ? tier.theme.primary_colour : 'transparent',
        // borderColor: themeStyle ? themeStyle.accent_colour : THEME.ACCENT_COLOUR,
        ...style
    }

    const titleStyle = {
        height:'40px',
        width:'200px',
        background:'var(--primary-colour)',
        paddingBottom:'10px',
        textTransform:'uppercase'
    }

    // const cardValue = card?.type == 'caschback' ? `BALANCE £${card?.type?.toUpperCase()} ` : `${tier && tier.initial_value} ${card?.type?.toUpperCase()}`
    const cardValue = card.valueSentence

  return (
    <>
            { message && <InstructionTitle title={message} style={titleStyle} /> }
            <MemberCard id={`card-${card.id}`} onClick={onClick ? () => onClick() : () => handleClick()} width={width} style={cardStyle}>
                <div>
                    <MemberCardBox>
                        <MemberCardTitle className='mc_title' style={{color: tier?.theme?.text_colour}}>{card?.name}</MemberCardTitle>
                        {card.isMultiTier ? <MemberCardSubtitle style={{color: tier?.theme?.text_colour}}>{tier?.name}</MemberCardSubtitle> : null}
                    </MemberCardBox>
                    <MemberCardBox style={{alignSelf: 'flex-end'}}>
                        { user && <MemberCardSubtitle style={{color: tier?.theme?.text_colour}}>{user ? `${user.first_name} ${user?.last_name}` : ''}</MemberCardSubtitle>}
                        <MemberCardTitle style={{color: tier?.theme?.text_colour}}>{member?.valueSentence}</MemberCardTitle>
                    </MemberCardBox>
                </div>
            </MemberCard>

            {/*{ member && <AddToAppleWallet style={{marginTop:'20px'}} type={'cardholder'} id={member.member_id} /> }*/}

    </>
  )
}
