import React, {useState} from 'react';
import styled from "styled-components";
import {VideoGallery} from "../galleries/index.js";


const HeroContainer = styled.section`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    justify-content: center;
    // border: 1px solid red;
    margin-bottom: 20px;
  `;

const HeroContent = styled.div`

    padding: 0px 20px;
    z-index: 1;
  `;

const SlideImage = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    cursor: pointer; // Add this to indicate it's clickable
  `;

const GradientOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 10%, var(--primary-colour));
    z-index: 0;
  `;

const SlideText = styled.h1`
    position: absolute;
    bottom: 20%; /* Position it in the lower third */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    color: white;
    font-size: 4em;
    text-align: center; /* Center text */
    text-transform: uppercase;
    background: var(--accent-colour);
    font-weight: 900;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 100;

  `;

const SlideButton = styled.button`
    position: absolute;
    top: 50%;
    ${props => props.direction === 'left' ? 'left: 10px;' : 'right: 10px;'}
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  `;

const MagnifyButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  `;

const FullscreenContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

const FullscreenImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `;

const FullscreenButton = styled.button`
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  `;
const HeroWithSlider = ({ data, sliderImages, videoGallery, title  }) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const slides = data?.files?.filter((file) => file.name === 'slider');

    const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
    const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);


    if(slides.length === 0) return null;

    return (
        <HeroContainer>
            {slides.map((slide, index) => (
                <SlideImage
                    key={index}
                    image={slide.cdnUrl}
                    style={{
                        position: 'absolute',
                        transform: `translateX(${100 * (index - currentSlide)}%)`,
                    }}
                    onClick={() => setIsFullscreen(true)}
                />
            ))}

            <HeroContent>
                { videoGallery && <VideoGallery data={videoGallery}  /> }
                <GradientOverlay />
                <SlideText>{'music video production'}</SlideText>

            </HeroContent>

            {slides.length > 1 && (
                <>
                    <SlideButton direction="left" onClick={prevSlide}>←</SlideButton>
                    <SlideButton direction="right" onClick={nextSlide}>→</SlideButton>
                </>
            )}

            <MagnifyButton onClick={() => setIsFullscreen(!isFullscreen)}>🔍</MagnifyButton>
            {isFullscreen && (
                <FullscreenContainer>
                    <FullscreenImage
                        src={slides[currentSlide].cdnUrl}
                        onClick={(e) => e.stopPropagation()}
                    />
                    <FullscreenButton onClick={() => setIsFullscreen(false)} style={{top: 10, right: 10}}>Close</FullscreenButton>
                    {slides.length > 1 && (
                        <>
                            <FullscreenButton onClick={prevSlide} style={{left: 10, top: '50%', transform: 'translateY(-50%)'}}>←</FullscreenButton>
                            <FullscreenButton onClick={nextSlide} style={{right: 10, top: '50%', transform: 'translateY(-50%)'}}>→</FullscreenButton>
                        </>
                    )}
                </FullscreenContainer>
            )}
        </HeroContainer>
    );
};

export default HeroWithSlider;