/**
 * Generates a consistent return URL for Stripe payments
 * @param {Object} params
 * @param {string} params.basketableType - The type of the basketable item (e.g., 'event')
 * @param {number|string} params.basketId - The ID of the basket
 * @returns {string} The complete return URL
 */
export const getReturnUrl = ({ basketId, basketableType }) => {
  const baseUrl = window.location.origin;
  return `${baseUrl}/confirmation/${basketId}?type=${basketableType}&status=success`;
}; 