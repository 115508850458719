/**
 * ProductCard Component
 * @description A reusable card component for displaying product information
 * with integrated product selection handling
 */
import React from 'react';
import styled, { css } from 'styled-components';
import Box from '../../../../../atomic/atoms/Box/Box';
import Paragraph from '../../../../../atomic/atoms/Paragraph/Paragraph';
import Tag from '../../../../../atomic/atoms/Tag/Tag';
import { borderRadius } from '../../../../../atomic/tokens/theme';
import { useProduct } from '../../context/ProductContext';
import { convertToCurrency } from '../../../../../js/helpers/helpers';
import useProductCalculations from '../../hooks/useProductCalculations';
import AnimatedElement from '../../../../../atomic/atoms/AnimatedElement/AnimatedElement';

const getAsymRadius = (variant = 'xl') => {
  return borderRadius.asymmetric[variant] || borderRadius.asymmetric.xl;
};

const ProductContainer = styled(Box)`
  position: relative;
  padding: 24px;
  background: linear-gradient(145deg, rgba(255,255,255,0.02) 0%, transparent 100%);
  border-radius: ${props => getAsymRadius(props.$asymRadius)};
  border-top: 1px solid rgba(255,255,255,0.05);
  min-width: 100%;
  width: 100%;
  max-width: 400px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  opacity: ${props => props.$sold_out ? 0.8 : 1};
  filter: ${props => props.$sold_out ? 'blur(1px)' : 'none'};
  transition: opacity 0.3s ease, filter 0.3s ease, transform 0.2s ease;
  cursor: ${props => props.$sold_out ? 'not-allowed' : 'pointer'};

  @media (min-width: 768px) {
    padding: 32px;
    height: 48px;
    min-width: 500px;
    width: 300px;
    max-width: 500px;
    margin: 0 auto;
  }

  &:hover {
    transform: ${props => props.$sold_out ? 'none' : 'translateY(-1px)'};
  }

  &:active {
    transform: ${props => props.$sold_out ? 'none' : 'translateY(0)'};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-colour);
    border-radius: inherit;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(255,255,255,0.05) 0%, transparent 100%);
    border-radius: inherit;
    pointer-events: none;
  }
`;

const Price = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: var(--accent-colour);
  color: var(--text-colour);
  padding: 2px 10px;
  margin-left: 40px;
  margin-top: -10px;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 5px 5px 5px 5px;
  opacity: ${props => props.$sold_out ? 0.6 : 1};
  transition: opacity 0.3s ease;

  @media (min-width: 768px) {
    font-size: 0.9rem;
    padding: 4px 12px;
    margin-top: -12px;
  }
`;

const TagsContainer = styled.div`
  position: absolute;
  bottom: -6px;
  left: 20px;
  right: 20px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 20px;
`;

const StyledTag = styled(Tag)`
  // transform: translateY(50%);
`;

const Name = styled(Paragraph)`
  text-align: center;
  color: var(--text-colour);
  margin: 0;
  padding: 0 32px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 1.1rem;
    padding: 0 40px;
  }
`;

const CardContent = ({ product }) => {
  const { handleProductClick } = useProduct();

  const { totalPrice, totalFees, totalPriceWithFees } = useProductCalculations(product);

  const handleClick = () => {
    if (product.sold_out) return;
    handleProductClick({
      id: product.id,
      name: product.name,
      price: product.price,
      tags: product.tags,
      asymRadius: product.asymRadius,
      sold_out: product.sold_out,
      cardholders_only: product.cardholders_only,
      category: product.category,
      requires_password: product.requires_password,
      fees: product.fees,
      total_mandatory_fees: product.total_mandatory_fees,
      remaining: product.remaining,
      price_inc_mandatory_fees: product.price_inc_mandatory_fees
    });
  };

  // Create array of tags to display
  const displayTags = [];
  
  // Add status tags first (limit to 3 total)
  if (product.sold_out) {
    displayTags.push({ text: 'Sold Out', background: 'var(--error-colour)' });
  }

  // Add special state tags
  if (product.requires_password) {
    displayTags.push({ icon: 'password', background: 'var(--accent-colour)' });
  }
  if (product.cardholders_only) {
    displayTags.push({ icon: 'member', background: 'var(--accent-colour)' });
  }

  // Add remaining regular tags until we hit 3
  const remainingSlots = 3 - displayTags.length;
  if (remainingSlots > 0) {
    product.tags?.slice(0, remainingSlots).forEach(tag => {
      displayTags.push({ text: tag });
    });
  }

  return (
    <ProductContainer 
      data-testid="product-card-container"
      // className={className}
      // $asymRadius={asymRadius}
      $sold_out={product.sold_out}
      onClick={handleClick}
    >
      <Price $sold_out={product.sold_out}>{convertToCurrency(product.price_inc_mandatory_fees)}</Price>
      <TagsContainer>
        {displayTags.map((tag, index) => (
          <Tag 
            key={index}
            icon={tag.icon}
            background={tag.background}
          >
            {tag.text}
          </Tag>
        ))}
      </TagsContainer>
      <Name>{product.name}</Name>
    </ProductContainer>
  );
};

/**
 * ProductCard component for displaying product information
 */
const ProductCard = (product) => {
  return (
    // <AnimatedElement delay={0.7} duration={1.1} threshold={0.15}>
      <CardContent product={product} />
    // </AnimatedElement>
  );
};

export default ProductCard;
