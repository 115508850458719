/**
 * @fileoverview Component for rendering basket items with quantity controls and removal options
 * 
 * Features:
 * 1. Display basket items with quantities and prices
 * 2. Handle quantity updates with optimistic feedback
 * 3. Support item removal with confirmation
 * 4. Handle addons display and management
 * 5. Support special item types (e.g., table bookings)
 */

import React, {useState} from 'react'
import {InfoItemComponent} from '../../../../components/ui/InfoItemComponent';
import {Select, StyledOption} from '../styles/basket.styles';
import {AiFillDelete} from 'react-icons/ai';
import {useHandleQuantityChange, useHandleRemoveOrderItem} from '../../js/hooks';
import {useEcommerce} from '../../js/context/EcommerceContext';
import {convertToCurrency} from '../../../../js/helpers/helpers';
import {Paragraph} from "../../../../components/ui/typography/index.js";
import styled from "styled-components";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import DepositOptions from "./DepositOptions.jsx";
import {productAcceptsDeposit} from "../js/utils/depositUtils.js";
import {orderItemSubTotal} from "../js/utils/basketUtils.js";
import {HowManyPeople} from "../../components/HowManyPeople/HowManyPeople.jsx";

/**
 * Main basket items component
 * Renders a list of items in the basket with their controls
 */
const BasketItemContainer = styled.div`
    display: flex;
    place-items: center;
    flex-direction: column;
    justify-content: stretch;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: var(--border-radius-default);
    border: solid 1px rgba(255,255,255,0.1);
    box-shadow: 2px 5px 10px rgba(0,0,0,0.05), -7px -3px 20px rgba(0,0,0,0.06);
    padding: 10px;
    background: rgba(0, 0, 0, 0.05);
`

export const BasketItems = () => {
    const handleRemoveOrderItem = useHandleRemoveOrderItem();
    const handleQuantityChange = useHandleQuantityChange();
    const [numberOfPeople, setNumberOfPeople] = useState(2);
    const { basket, basketItems, updateOrderItemValue } = useEcommerce();
    const [error, setError] = useState(null);

    /**
     * Handle updates to number of people for table bookings
     * @param {number} people - New number of people
     * @param {Object} product - Product being updated
     */
    const handleNumberOfPeople = (people, product) => {
        try {
            if (!product || !people) {
                throw new Error('Invalid input for updating number of people');
            }
            
            product.options = { ...product.options, numberOfGuests: people }
            updateOrderItemValue(product.id, 'quantity', people);
            setError(null);
        } catch (err) {
            setError(err.message);
            console.error('Error updating number of people:', err);
        }
    }

    /**
     * Handle item removal from basket
     * @param {Event} e - Click event
     * @param {Object} item - Item to remove
     */
    const handleClickDelete = (e, item) => {
        try {
            if (!item) {
                throw new Error('Cannot remove undefined item from basket');
            }
            handleRemoveOrderItem(e, basket.orderItems, item);
            setError(null);
        } catch (err) {
            setError(err.message);
            console.error('Error removing item from basket:', err);
        }
    }

    // Display any errors that occurred
    if (error) {
        return <div style={{ color: 'red', padding: '10px' }}>{error}</div>;
    }

    return (
        basketItems().map((item, index) => {
            try {
                // Calculate available quantity
                const remaining = item.remainingForTimeSlot || item.remaining;
                if (typeof remaining === 'undefined') {
                    console.warn(`Item ${item.id} has undefined remaining quantity`);
                }
                const quantityAvailable = remaining < item.maxPerOrder ? remaining : item.maxPerOrder;

                return (
                    <BasketItemContainer id={'basket-container'} key={index}>
                        <BasketItemDetail
                            item={item}
                            handleQuantityChange={handleQuantityChange}
                            handleRemoveOrderItem={(e, item) => handleClickDelete(e, item)}
                            quantityAvailable={quantityAvailable}
                        />

                        {/* Special handling for table bookings */}
                        {item.category === 'tableBooking' && (
                            <>
                                <HowManyPeople
                                    setNumberOfPeople={setNumberOfPeople}
                                    numberOfPeople={numberOfPeople}
                                    handleNumberOfPeople={handleNumberOfPeople}
                                    product={item}
                                />
                            </>
                        )}

                        {/* Display any basket comments */}
                        {item?.basketComment && (
                            <Paragraph style={{ opacity: '0.4', textAlign: 'left' }}>{item.basketComment}</Paragraph>
                        )}

                        {/* Display addons if available */}
                        {item?.addons?.length > 0 && item.addons.map((addon, addonIndex) => (
                            <BasketItemDetail
                                key={`${index}-addon-${addonIndex}`}
                                item={addon}
                                handleQuantityChange={handleQuantityChange}
                                handleRemoveOrderItem={(e, addon) => handleClickDelete(e, addon)}
                                quantityAvailable={quantityAvailable}
                            />
                        ))}

                        {/* Display deposit options if applicable */}
                        {productAcceptsDeposit(item) && (
                            <>
                                <DividerWithText text={'Deposit Options'} />
                                <DepositOptions product={item} />
                            </>
                        )}
                    </BasketItemContainer>
                );
            } catch (err) {
                console.error(`Error rendering basket item ${index}:`, err);
                return (
                    <div key={index} style={{ color: 'red', padding: '10px' }}>
                        Error displaying item
                    </div>
                );
            }
        })
    );
};


/**
 * Individual basket item component
 * Displays item details and controls
 */
const BasketItemDetail = ({ item, handleQuantityChange, handleRemoveOrderItem, quantityAvailable }) => {

    if (!item) return;

    const chargePrice = orderItemSubTotal(item)

    console.log('quantity', quantityAvailable)

    return (
        <>
            <InfoItemComponent>

                    <Select value={item.quantity} onChange={(e) => handleQuantityChange(e, item)}>
                        {
                            quantityAvailable && Array.from({length: quantityAvailable}, (_, i) => {
                                const quantity = i + 1;

                                return (
                                    <StyledOption key={i} value={quantity}>{quantity}</StyledOption>
                                )
                            })
                        }
                    </Select>

                <p id='property'>{item?.name}</p>
                <p id={'price'}>{convertToCurrency(chargePrice)}</p>
                <AiFillDelete style={{cursor: 'pointer', color: 'var(--error-colour)'}}
                              onClick={(e) => handleRemoveOrderItem(e, item)}/>
            </InfoItemComponent>
            {/*<p style={{fontSize: '0.7rem'}}> {item.paymentOption === DEPOSIT_PAYMENT_OPTION && 'Pay ' + convertToCurrency(item.depositAmount * item.quantity) + ' deposit'}</p>*/}

        </>


    )
        ;
};

export default BasketItemDetail;
