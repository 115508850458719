import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import LogoComponent from './LogoComponent';
import { LoadingAnimationComponent } from './LoadingAnimationComponent';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--default-primary-colour);
`

const MessageContainer = styled.div`
  margin-top: 20px;
  text-align: center;
  color: white;
  font-size: 16px;
`

export const Loading = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const timer5 = setTimeout(() => {
      setMessage('Hang on, this is taking longer than usual.');
    }, 6000);

    const timer10 = setTimeout(() => {
      setMessage('Checking data connection...');
    }, 10000);

    const timer20 = setTimeout(() => {
      setMessage('Failed to load.');
    }, 20000);

    return () => {
      clearTimeout(timer5);
      clearTimeout(timer10);
      clearTimeout(timer20);
    };
  }, []);

  return (
    <PageContainer>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
        <LogoComponent/>
        <LoadingAnimationComponent/>
        {message && <MessageContainer>{message}</MessageContainer>}
      </div>
    </PageContainer>
  )
}
