import React, { useContext } from 'react';
import {useForm} from "react-hook-form";
import {inputComponentMap} from "../../../components/forms/js/inputMap.js";
import {Form} from "../../../components/forms/formStyles.js";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";
import styled from "styled-components";
import {InstructionTitle, Paragraph} from "../../../components/ui/typography/index.js";
import {useMutation} from "@tanstack/react-query";
import {makeRequest} from "../../../services/axios.js";
import {toast} from "react-toastify";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import { LoadingAnimationComponent } from '../../../components/ui/LoadingAnimationComponent.jsx';
import { FaCheckCircle } from 'react-icons/fa';
import { extractColorCodeFromCssVariable } from '../../themes/js/utils.js';
import { AuthContext } from '../../../features/authentication';

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 20px 0px;
    gap: 5px !important;
    z-index: 3;
`

const Label = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: var(--border-radius-default);
    border-top-right-radius: var(--border-radius-default);
    min-width: 60%;
    max-width: 80%;
    height: fit-content;
    //transform: rotate(-2deg);
    
    text-align: center;
    font-weight: 600;
    padding: 0px 20px;
    margin-bottom: -1px;
    z-index: 1;
    background: var(--default-accent-colour);
    box-shadow: inset 10px 10px 10px rgba(0,0,0,0.15);
    color:var(--text-colour);
`


const PackForm = ({name, inputs, submitButton, onSuccess, referrer = null, endpointUrl = 'packs/1/response', style}) => {

    const { register,reset, handleSubmit, formState: { errors } } = useForm();
    const {showErrorToast} = useHandleError()
    const { user } = useContext(AuthContext);

    const mutation = useMutation(
        (data) => {
            // Call to makeRequest function to send the form data
            return makeRequest('POST', endpointUrl, data, {}, "multipart/form-data");
        },
        {
            // onError and onSuccess functions for mutation
            onError: (error, variables, context) => {
                showErrorToast(error, 'Error creating pack')
            },

            onSuccess: (data, variables, context) => {
                const toastType = {
                    success: toast.success,
                    info: toast.info,
                    error: toast.error,
                    warning: toast.warning,
                }[data.status] || toast.info; // Default to info if status is not recognized

                toastType(data.message ?? data.status); // Call the appropriate toast function

                onSuccess && onSuccess(data, variables, context);
                // reset();
            },

        },
    );

    const onSubmit = (submittedData) => {
        let formData = new FormData();

        Object.entries(submittedData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        formData.append('pack_id', 1);
        formData.append('referrer', referrer)

        // Add user data if user is logged in
        if (user) {
            formData.append('user_id', user.id);
            formData.append('email', user.email);
        }

        const data = Object.fromEntries(formData)
        mutation.mutate(data)
    }



    return (
       <>
       { !mutation.isSuccess ? (
        <FormContainer id={name + ' form'} onSubmit={handleSubmit(onSubmit)} style={style}>
            {inputs?.map((input, index) => {

                const name = input?.name ?? `input-${index}`;
                const disabled = input?.disabled ?? false;

                return (
                    <div key={index}  style={{width: '100%', minHeight: 'fit-content',  display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        { input.type !== 'hidden' && <Label text={input.label} htmlFor={name}>{input?.label}</Label> }
                        {input.type  &&
                        <input
                            style={{background: 'rgba(0,0,0,0.35)', backdropFilter: 'blur(5px)', zIndex: 2, marginBottom: input.type === 'hidden' ? '0px' : '5px', border: `solid 1px ${extractColorCodeFromCssVariable('--accent-colour')}`}}
                            {...register(name)}
                            name={name}
                            placeholder={input.placeholder ?? name}
                            disabled={disabled}
                            defaultValue={input?.value}
                            type={input?.type}
                            autoCorrect={input?.autoCorrect}
                            required={input?.required}
                        />
                        }
                    </div>

                )

            })}


            <ButtonComponent
                variant={submitButton?.variant ?? 'solid'}
                text={submitButton?.text ?? 'Submit'}
                isLoading={mutation.isLoading}
            />

            <Paragraph style={{fontSize: '0.7rem', padding: '10px 0'}}>By submitting this form you are accepting our <a
                rel={'noreferrer'} href={'/ticket-terms-conditions'} target={'_blank'}> <strong>terms and
                conditions</strong></a> and <a rel={'noreferrer'} href={'/privacy-policy'} target={'_blank'}><strong>privacy policy</strong></a> .
            </Paragraph>


        </FormContainer>
       ) : (
            <FormSuccess />
       )}  
       </>
    )
}

export default PackForm;


const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    z-index: 30;
`

export const FormSuccess = () => {
    return (
        <Container>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                textAlign: 'center',
                color: 'var(--text-colour)'
            }}>
                <div style={{
                    width: '80px',
                    height: '80px',
                    backgroundColor: 'rgba(76, 175, 80, 0.1)',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <FaCheckCircle size={40} color="var(--success-colour)" />
                </div>
                <h2 style={{color: 'var(--success-colour)'}}>Success!</h2>
                <p style={{
                    fontSize: '0.9em',
                    opacity: 0.8,
                    maxWidth: '300px'
                }}>
                    Your submission has been received. We'll be in touch shortly.
                </p>
            </div>
        </Container>
    )
}