import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const slideIn = keyframes`
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const slideOut = keyframes`
  from { transform: translateY(0); opacity: 1; }
  to { transform: translateY(-50px); opacity: 0; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  ${props => props.isOpen && css`
    opacity: 1;
    visibility: visible;
    animation: ${fadeIn} 0.3s ease forwards;
  `}

  ${props => props.isClosing && css`
    animation: ${fadeOut} 0.3s ease forwards;
  `}
`;

const ModalContainer = styled.div`
  background-color: var(--primary-colour);
  border-radius: var(--border-radius-default);
  padding: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-height: 80vh;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(-50px);

  ${props => props.isOpen && css`
    animation: ${slideIn} 0.3s ease forwards;
  `}

  ${props => props.isClosing && css`
    animation: ${slideOut} 0.3s ease forwards;
  `}

  @media (min-width: 768px) {
    padding: 60px;
    width: calc(100% - 120px);
    height: calc(100% - 120px);
  }

  @media (min-width: 1024px) {
    width: 60%;
    height: 60%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-colour);
`;

const Modal = ({ isOpen, onClose, children }) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsClosing(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300); // Match this with the animation duration
  };

  if (!isOpen && !isClosing) return null;

  return (
    <ModalOverlay isOpen={isOpen} isClosing={isClosing} onClick={handleClose}>
      <ModalContainer isOpen={isOpen} isClosing={isClosing} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={handleClose}>&times;</CloseButton>
        {children}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
