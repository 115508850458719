/**
 * Standard animation timing presets
 */
export const ANIMATION_TIMING = {
  FAST: {
    duration: 0.6,
    delay: 0.1,
    easing: 'cubic-bezier(0.22, 1, 0.36, 1)'
  },
  NORMAL: {
    duration: 0.8,
    delay: 0.2,
    easing: 'cubic-bezier(0.22, 1, 0.36, 1)'
  },
  SLOW: {
    duration: 1,
    delay: 0.3,
    easing: 'cubic-bezier(0.22, 1, 0.36, 1)'
  }
};

/**
 * Animation sequence configurations
 */
export const SEQUENCE_TIMING = {
  // For staggered list items
  STAGGER: {
    baseDelay: 0.2,
    itemDelay: 0.15
  },
  // For content sections
  SECTION: {
    baseDelay: 0.3,
    itemDelay: 0.2
  }
};

/**
 * Intersection Observer configurations
 */
export const OBSERVER_CONFIG = {
  DEFAULT: {
    threshold: 0.1,
    rootMargin: '0px 0px -50px 0px'
  },
  EAGER: {
    threshold: 0.05,
    rootMargin: '0px 0px -25px 0px'
  },
  LAZY: {
    threshold: 0.15,
    rootMargin: '0px 0px -100px 0px'
  }
};

/**
 * Animation presets for common use cases
 */
export const ANIMATION_PRESET = {
  HERO: {
    ...ANIMATION_TIMING.FAST,
    ...OBSERVER_CONFIG.EAGER
  },
  CONTENT: {
    ...ANIMATION_TIMING.NORMAL,
    ...OBSERVER_CONFIG.DEFAULT
  },
  MEDIA: {
    ...ANIMATION_TIMING.SLOW,
    ...OBSERVER_CONFIG.LAZY
  }
}; 