import axios from "axios";
import {API_URL} from "../../../../js/Helper.js";

const updateOrderItem = async (basketId, productId, orderItem) => {
    return await axios.post(`${API_URL}/basket/${basketId}/product/${orderItem.id}/update`, {
        orderItem: orderItem
    })
}


export {
    updateOrderItem
}