import { createContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeRequest } from '../../services/axios';

export const AuthContext = createContext();

export function AuthProvider({ children }) {

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    try {
        return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.removeItem('isAuthenticated')
        return null;  
    }
});


    const navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = useState(() => {
      const storedIsAuth = localStorage.getItem('isAuthenticated');
      return storedIsAuth === 'true' 
    });
    

    const [token, setToken] = useState(() => {
        try {
            const storedToken = localStorage.getItem('token');
            return storedToken ? JSON.parse(storedToken) : null;
        } catch (error) {
            console.warn('Error parsing token from localStorage:', error);
            return null;
        }
    });



    // find out where the user is 
    const pathname = window.location.pathname
    const location = useLocation();

    // useEffect(() => {
    //   tokenIsValid(token)
    // }, [user, navigate]);

    useEffect(() => {

      if(isAuthenticated == false) {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
      }

    },[isAuthenticated])

  const login = (userData) => {
      // Perform API call to authenticate the user
      const loginRequest = makeRequest('POST', 'login', userData, null, {})
      .then((responseData) => {
        console.log(responseData);
        let userData = responseData.user;
        let authData = responseData.authorisation;

        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('token', JSON.stringify(authData));
        localStorage.setItem('isAuthenticated', true);
    
        setUser(userData);
        setToken(authData);

        setIsAuthenticated(true)

          Sentry.setUser({
              email: userData.email,
              id: userData.id
          })


      })
      .catch((error) => {
        console.error(error);

        return { status: false };
      });
  
      return { 'status': true }

  };

  const logout = () => {
    // Perform API call to logout the user
    // Once logged out, set the user state to null

    makeRequest('POST', 'logout', null, {Authorization: `${token?.type} ${token?.token}`}, {})
    .then((res) => {
      // localStorage.setItem('isAuthenticated', 'false');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setUser(null);
      setToken(null)
      setIsAuthenticated(false)
    })
    .catch((error) => {
      // localStorage.setItem('isAuthenticated', 'false');
      localStorage.removeItem('isAuthenticated');
      setIsAuthenticated(false)
      setToken('')
    });

    // tokenIsValid(token)
    
  };

  const tokenIsValid = (token) => {

      makeRequest('POST', 'check_token', null, {Authorization: `${token?.type} ${token?.token}`}, {})
        .then((res) => {
          setIsAuthenticated(res.authenticated)
        })
        .catch((error) => {
          console.error(error);
        });

  };

  const externalLogin = (token, user) => {
    // Perform login logic using an external method (e.g., token-based authentication)
    // Update the authentication state based on the result

    if (token) {
      setIsAuthenticated(true);
      setToken(token);
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('isAuthenticated', true);
      return true
    } else {
      setIsAuthenticated(false);
      return false
    }

  
  };

  const setNewUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
    setUser(user)
  }

  const header = function() {
    return {Authorization: `Bearer ${token?.token}`}
  }

   const userLocale = () => {
    return navigator.language || navigator.userLanguage;
  }
  



  return (
    <AuthContext.Provider value={{ user, setUser, login, userLocale, logout, setToken, token, setIsAuthenticated, isAuthenticated, externalLogin, setNewUser, header }}>
      {children}
    </AuthContext.Provider>
  );
}
