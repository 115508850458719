import React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { Loading } from '../../../../../components/ui/Loading';
import LogoComponent from '../../../../../components/ui/LogoComponent';
import { LoadingAnimationComponent } from '../../../../../components/ui/LoadingAnimationComponent';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonBase = styled.div`
  background: linear-gradient(to right, #1a1a1a 8%, #2a2a2a 18%, #1a1a1a 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s linear infinite forwards;
  border-radius: var(--border-radius-default);
`;

// Hero Section Skeleton
const HeroContainer = styled(SkeletonBase)`
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, transparent, #000000);
`;

// Loading Overlay
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;

// Floating Nav Skeleton
const FloatingNavSkeleton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 0 20px;
  box-sizing: border-box;
`;

const NavBar = styled(SkeletonBase)`
  display: flex;
  gap: 10px;
  border-radius: var(--border-radius-default);
  width: 100%;
  max-width: 400px;
  height: 65px;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: inset 10px 10px 25px rgba(0,0,0,0.5);
`;

const NavItem = styled(SkeletonBase)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #2a2a2a;
`;

const EventSkeleton = () => {
  return (
    <>
      <HeroContainer>
        <GradientOverlay />
      </HeroContainer>

      <LoadingOverlay>
      <LogoComponent/>
      </LoadingOverlay>
      
      <FloatingNavSkeleton>
        <NavBar>
          <NavItem />
          <NavItem />
          <NavItem />
          <NavItem />
        </NavBar>
      </FloatingNavSkeleton>
    </>
  );
};

export default EventSkeleton;
