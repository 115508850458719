import styled from "styled-components";

const FAQSection = styled.section`
  padding: 40px 20px;
  min-width: unset;

  @media (min-width: 1024px) {
    padding: 60px;
  }
`;

const FAQTitle = styled.h2`
  font-size: 2em;
  color: var(--accent-colour);
  margin-bottom: 30px;
  text-align: center;
`;


export  {
    FAQSection,
    FAQTitle
}