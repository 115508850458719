import styled from "styled-components";

export const OrderComponentContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: fit-content;

    @media screen and (min-width: 768px){
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
`

export const OrderInfoSection = styled.section``