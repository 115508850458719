import { PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import * as Sentry from "@sentry/react";

/**
 * Custom error class for payment-related errors
 */
export class PaymentError extends Error {
    constructor(type, originalError = null) {
        const errorType = PAYMENT_ERROR_TYPES[type] || PAYMENT_ERROR_TYPES.UNKNOWN_ERROR;
        // Use custom message if provided, otherwise use default message
        super(originalError?.message || errorType.message);
        this.name = 'PaymentError';
        this.code = errorType.code;
        this.recoverable = errorType.recoverable;
        this.action = errorType.action;
        this.originalError = originalError;
        this.timestamp = new Date().toISOString();

        // Add additional context for Sentry
        this.sentryContext = {
            payment_error: {
                type,
                code: this.code,
                recoverable: this.recoverable,
                action: this.action,
                originalError: this.originalError
            }
        };
    }

    /**
     * Helper method to report this error to Sentry with proper context
     */
    reportToSentry() {
        Sentry.withScope((scope) => {
            scope.setTag("error_type", "payment");
            scope.setTag("payment_code", this.code);
            scope.setTag("recoverable", String(this.recoverable));
            scope.setTag("action", this.action);
            
            scope.setContext("payment_error", this.sentryContext.payment_error);
            
            if (this.originalError?.response) {
                scope.setContext("api_response", {
                    status: this.originalError.response.status,
                    data: this.originalError.response.data
                });
            }
            
            Sentry.captureException(this);
        });
    }
}

/**
 * Maps Stripe error codes to our internal error types
 * @type {Object}
 */
const STRIPE_ERROR_MAP = {
    'card_declined': 'CARD_ERROR',
    'expired_card': 'CARD_ERROR',
    'incorrect_cvc': 'CARD_ERROR',
    'processing_error': 'PROCESSING_ERROR',
    'authentication_required': 'AUTHENTICATION_ERROR',
    'rate_limit_exceeded': 'RATE_LIMIT_ERROR',
    'invalid_request_error': 'INVALID_REQUEST_ERROR'
};

/**
 * Handles payment errors and returns appropriate error response
 * @param {Error} error - The original error object
 * @param {Object} options - Additional options for error handling
 * @returns {PaymentError} Standardized payment error object
 */
export const handlePaymentError = (error, options = {}) => {
    // Early return if error is already a PaymentError instance
    if (error instanceof PaymentError) {
        error.reportToSentry();
        return error;
    }

    const paymentError = new PaymentError(
        error?.type && STRIPE_ERROR_MAP[error.type] ? STRIPE_ERROR_MAP[error.type] :
        error?.message?.includes('network') ? 'NETWORK_ERROR' :
        error?.message?.includes('session') ? 'SESSION_EXPIRED' :
        'UNKNOWN_ERROR',
        error
    );

    // Report to Sentry
    paymentError.reportToSentry();

    return paymentError;
};

/**
 * Determines if an error is recoverable and provides recovery action
 * @param {PaymentError} error - The payment error to analyze
 * @returns {Object} Recovery information
 */
export const getErrorRecoveryAction = (error) => {
    if (!(error instanceof PaymentError)) {
        error = handlePaymentError(error);
    }

    return {
        recoverable: error.recoverable,
        action: error.action,
        message: error.message
    };
};

/**
 * Executes appropriate recovery action for a payment error
 * @param {PaymentError} error - The payment error to recover from
 * @param {Object} handlers - Object containing recovery action handlers
 * @returns {Promise<void>}
 */
export const executeErrorRecovery = async (error, handlers) => {
    const { action } = getErrorRecoveryAction(error);
    
    if (handlers[action]) {
        try {
            await handlers[action](error);
        } catch (recoveryError) {
            console.error('Error recovery failed:', {
                originalError: error,
                recoveryError,
                timestamp: new Date().toISOString()
            });
            throw new PaymentError('UNKNOWN_ERROR', recoveryError);
        }
    } else {
        console.warn('No recovery handler found for action:', action);
    }
};
