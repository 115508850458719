import React from 'react';
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {FloatingNav} from "../../layout/components/FloatingNav/index.jsx";
import {useDrawer} from "../../js/hooks/context/DrawerContext.jsx";
import styled from "styled-components";

const HomeContainer = styled.div`
    padding-bottom: 100px;
`;


const BusinessHome = () => {

    const navigate = useNavigate();
    const params = useParams();
    const { openDrawer } = useDrawer();

    const slug = params.slug;

    const urlPrefix = `/p/${slug}`;

    const menuItems = [
        {
            name: 'Community',
            // destination: () => navigate(`${urlPrefix}`),
            destination: `${urlPrefix}`,
            active: true,
            icon: 'IoDocumentTextOutline'
        },
        {
            name: 'Events',
            // destina  tion: () => navigate(`${urlPrefix}/events`),
            destination: `${urlPrefix}/events`,
            to: `${urlPrefix}/events`,
            icon: 'IoTicketSharp'
        },
        {
            name: 'Automation',
            // destination: () => navigate(`${urlPrefix}/automation`),
            destination: `${urlPrefix}/automation`,
            to: `${urlPrefix}/automation`,
            icon: 'BsLightningCharge'
        },
        {
            name: 'Content',
            // destination: () => navigate(`${urlPrefix}/content`),
            destination: `${urlPrefix}/content`,
            to: `${urlPrefix}/content`,
            icon: 'IoImagesSharp'
        },
        {
            name: 'Take Action',
            destination: () => openDrawer('Action Panel Content'),
            // destination: 'menu',
            icon: 'IoCashSharp'
        }
    ];


    return (
        <HomeContainer>
            <FloatingNav items={menuItems} />
            <Outlet/>
        </HomeContainer>
    );
};

export default BusinessHome;