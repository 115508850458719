/**
 * TODO - Clear all unused variables and imports 
 * TODO - Write ProtoTypes code 
 */

import React from 'react';
import {createContext, useEffect, useState} from 'react';
import {useEcommerce} from '../../js/context/EcommerceContext';
import {useDrawer} from '../../../../js/hooks/context/DrawerContext';
import Basket from '../../basket';
import {toast} from 'react-toastify';
import {Product} from "../components/index.js";
import {useProduct} from "./useProduct.jsx";

export const ProductClickContext = createContext();

export const ProductClickProvider = ({ children, item, event }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [needsPassword, setNeedsPassword] = useState(false);

  const { basket } = useEcommerce();
  const { openDrawer } = useDrawer();
  const { displayPasswordProtection } = useProduct();


  const toggleIsClicked = () => setIsClicked(!isClicked);

  useEffect(() => {
    
    
    if ((isMember || !item?.card_holders_only) && isAllowed && isClicked) {

      if(!isClicked) return;


      // todo: this wont work once we got more product types 
      const isItemInBasket = basket.orderItems.some(
      (orderItem) => orderItem.id === item.id
      );

      if(!item.on_sale) {
        toast.warn(item.name + ' is not on sale')
        setIsClicked(false)
        return;
      }

      if(item.remaining <= 0) {
        toast.warn(`${item.name} has sold out`);
        setIsClicked(false)
        return;
      }
      

      /**
       * If this specific item is already in the basket
       * then do noting and just open the basket
       */

      const openBasket = () => openDrawer(<Basket event={event} />, 'Basket', innerWidth > 768 ? 'right' : 'bottom')
      
      if (isItemInBasket) {
        openBasket()
        setIsClicked(false)
        return;
      }

      if(needsPassword) {
        displayPasswordProtection(item)
        setIsClicked(false)
        return;
      }



      // If all dependencies are valid, process them
      // if (item.dependencies.length > 0) {
      //   item.dependencies.forEach(dependency => {
      //
      //     // Since we've already checked, this condition will always be true, but it's left for clarity
      //     if (dependency.remaining > 0) {
      //       if(basket.orderItems.find(item => item.id === dependency.id)) return;
      //       addToBasket(dependency, 1);
      //     }
      //   });
      // }

      // Add the main item to the basket
      // addToBasket(item, 1);



      // Set a timeout to delay the opening of the basket
      setTimeout(() => {
          openDrawer(<Product product={item} />, item.name, 'bottom')
      }, 500); // 500ms delay

      // Reset the click state to false after the action is completed
      setIsClicked(false);

    } 


    // TODO: Check if item is password protected and display the password ui



  }, [isMember, isAllowed, isClicked]);
  
  return (
    <ProductClickContext.Provider value={{ isClicked, setIsClicked, toggleIsClicked, isMember, setIsMember, isAllowed, setIsAllowed, needsPassword, setNeedsPassword, item }}>
      {children}
    </ProductClickContext.Provider>
  );
};
