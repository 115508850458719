import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHandleStripePayment } from '../../../features/ecommerce/services/stripe/hooks/useHandleStripePayment';
import Button from '../../atoms/Button/Button';
import styled from 'styled-components';
import { getReturnUrl } from '../../../features/ecommerce/services/stripe/utils/getReturnUrl';
import { extractColorCodeFromCssVariable } from '../../../features/themes/js/utils';
import { convertToCurrency } from '../../../js/helpers/helpers';
import { useBasket } from '../../../featuresV2/ecommerce/basket/context/BasketContext';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

const PaymentContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const PaymentForm = styled.form`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Target the Stripe Elements container */
  .StripeElement {
    width: 100%;
    max-width: 384px; /* Standard Stripe Elements width */
  }

  /* Ensure button matches Stripe Elements width when in payment mode */
  &[data-mode="payment"] {
    .payment-button {
      width: 384px; /* Match Stripe Elements width */
      max-width: 100%;
    }
  }

  /* Full width button for checkout form */
  &[data-mode="checkout"] {
    .payment-button {
      width: 100%;
    }
  }
`;

const PaymentContent = ({ amount, currency, onPaymentSuccess, onPaymentError, basketableType, paymentIntentId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const { formValidation, confirmPayment } = useHandleStripePayment();
  const { basket } = useBasket();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    try {
      // Validate form elements
      await formValidation(elements);

      // Use the updated confirmPayment method
     await confirmPayment(
        paymentIntentId,
        basket,
        basketableType,
        stripe,
        elements
      );
      

      if (paymentIntent.status === 'succeeded') {
        onPaymentSuccess(paymentIntent);
      }

    } catch (error) {
      console.error('Payment failed:', error);
      onPaymentError(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <PaymentContainer>
      <PaymentForm onSubmit={handleSubmit} data-mode="payment">
        <PaymentElement />
        <Button
          className="payment-button"
          variant="cta"
          size="large"
          type="submit"
          disabled={!stripe || isProcessing}
          loading={isProcessing}
          useGradient
          style={{ marginTop: '20px' }}
        >
          {isProcessing ? 'Processing...' : `Pay ${amount ? convertToCurrency(basket.total) : ''}`}
        </Button>
      </PaymentForm>
    </PaymentContainer>
  );
};

const StripePaymentElement = ({ 
  paymentIntentId,
  clientSecret,
  onPaymentSuccess,
  onPaymentError,
  amount,
  currency = 'gbp',
  basketableType,
  basketableId,
  basket,
}) => {
  
  // const { PaymentElement, handleSubmit, isLoading, error } = useHandleStripePayment({
  //   clientSecret: paymentIntentId,
  //   onSuccess: onPaymentSuccess,
  //   onError: onPaymentError,
  //   amount,
  //   currency,
  //   returnUrl: getReturnUrl({ basketableType, basketableId })
  // });

  const [isStripeReady, setIsStripeReady] = useState(false);

  if(!stripePromise) return null;

  return (
    <Elements 
      stripe={stripePromise} 
      options={{
        clientSecret: clientSecret,
        appearance: {
          theme: 'night',
          variables: {
            colorPrimary: extractColorCodeFromCssVariable('--accent-colour'),
            colorBackground: extractColorCodeFromCssVariable('--primary-colour'),
            colorText: '#ffffff',
            colorDanger: '#ff4444',
            fontFamily: 'Inter, system-ui, sans-serif',
            spacingUnit: '4px',
            borderRadius: '8px',
            // Focused colors
            colorPrimaryText: '#ffffff',
            colorBackgroundText: '#ffffff',
            // States
            colorSuccess: '#00c853',
            colorWarning: '#ffd600',
          },
          rules: {
            '.Input': {
              border: 'none',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
              transition: 'box-shadow 0.15s ease',
            },
            '.Input:focus': {
              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            },
            '.Input--invalid': {
              boxShadow: '0 1px 3px 0 rgba(255, 68, 68, 0.1), 0 1px 2px 0 rgba(255, 68, 68, 0.06)',
            },
            '.Label': {
              fontSize: '14px',
              fontWeight: '500',
            },
            '.Tab': {
              padding: '10px 16px',
              borderRadius: '8px',
              border: '2px solid transparent',
              transition: 'all 0.2s ease',
            },
            '.Tab:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            },
            '.Tab:focus': {
              outline: 'none',
              borderColor: `${extractColorCodeFromCssVariable('--accent-colour')}`,
              boxShadow: `0 0 0 1px ${extractColorCodeFromCssVariable('--accent-colour')}`,
            },
            '.Tab--selected': {
              borderColor: extractColorCodeFromCssVariable('--accent-colour'),
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            },
            '.Tab--selected:focus': {
              boxShadow: `0 0 0 2px ${extractColorCodeFromCssVariable('--accent-colour')}`,
            },
          },
          labels: 'floating'
        },
      }}
    >
      <PaymentContent
        amount={amount}
        currency={currency}
        onPaymentSuccess={onPaymentSuccess}
        onPaymentError={onPaymentError}
        basket={basket}
        basketableType={basketableType}
        paymentIntentId={paymentIntentId}
      />
    </Elements>
  );
};

StripePaymentElement.propTypes = {
  paymentIntentId: PropTypes.string.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  onPaymentError: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
  basketableType: PropTypes.string.isRequired,
  basketableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default StripePaymentElement;
