import React from 'react'
import styled from 'styled-components'


const LayoutSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: ${props => props.id === 'tickets'
    ? '125px' : '50px'};
    scroll-padding-right: 20px;
    box-sizing: border-box;
    clip-path: ${props => props.clip ? 'polygon(0 0, 100% 10%, 100% 100%, 0 80%)' : 'none'};
    height: fit-content;
    min-height: ${props => props.height};
    background: ${props => (props.style && props.style.background_colour) || 'var(--primary-colour)'};
    z-index: 1;

    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: var(--firstBreakMaxWidth);
    }

    @media (min-width: 1100px) {
      max-width: var(--secondBreakMaxWidth);
    }
`

const ChildContainer = styled.section`
  max-width: 100%;
  box-sizing: border-box;
  color: white;
  z-index: 1px;
  `
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 250px;
  margin-bottom: -40px;
  padding: 10px 20px;
  border-radius: var(--border-radius-small);
  z-index: 8;
  backdrop-filter: blur(5px);
  box-shadow: inset 5px 7px 20px rgba(0,0,0,0.2);
  z-index: 1;
  background: ${props => props.invert ? 'var(--primary-colour)' : 'var(--header-colour)'};
  /* rotate: 2deg; */
  `

const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 300px;
  margin-top: -35px;
  padding: 10px 20px;
  border-radius: var(--border-radius-small);
  backdrop-filter: blur(5px);
  z-index: 10;
  box-shadow: inset 5px 7px 20px rgba(0,0,0,0.2);
  background-color: whitesmoke;
`

const SectionTitle = styled.h3`
  position: relative;
  font-size: 1.1em;
  text-transform: uppercase;
  color: white;
  text-align: center;
  z-index: 10px;
`

const SectionSubTitle = styled.h3`
  font-size: 1em;
  text-transform: uppercase;
      color: var(--accent-colour);
    background: var(--accent-colour);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  text-align: center;

`


export const Section = ( { clip, invert, title, height, id, style, theme, children, subtitle,image} ) => {


    let customStyle = style;

    if(theme == 'clipped') {
      customStyle = {
        background: 'var(--featured-section-bg-colour)' || 'var(--accent-colour)',
        minHeight: '400px',
        width:'100%',
        height: 'fit-content',
        padding: '120px 20px',
        clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 80%)',
        zIndex: 3,
        position:'relative'
      }
    }

    const combinedStyles = {...customStyle, ...style}


  return (
    <LayoutSection clip={clip} style={combinedStyles} height={height} id={id}>
      {
        title && 
        <TitleContainer invert={invert}>
          { title && <SectionTitle>{title}</SectionTitle> }
        </TitleContainer>
      }

      {image}
      {children}

      {
        subtitle && (
          <SubTitleContainer>
            <SectionSubTitle>{subtitle}</SectionSubTitle> 
          </SubTitleContainer>
        )
      }

    </LayoutSection>
  )
}
