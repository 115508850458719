import React from 'react'
import styled from 'styled-components';
import { GradientOverlayComponent } from '../../GradientOverlayComponent';


const Image = styled.img`
  border-radius: var(--border-radius-default);
  /* box-shadow: var(--default-shadow); */

  @media screen and (min-width: 1024px) {
    border-radius: 5px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
    animation: fadeIn 0.5s ease-in-out;
  /* width: 100%; */
  z-index: 2;
    border-radius: var(--border-radius-default);
    // border: solid 1px rgba(255,255,255,0.1);
    box-shadow: var(--shadow-default);
;
`

export const ImageComponent = ({ src, height, width, style, id, onClick }) => {

  return (
    <>
      <ImageContainer name={'image-component'} id={id} style={style && style} onClick={onClick}>
        <Image width={width ? width : '100%'} height={height}  loading='lazy' src={src}/>
      </ImageContainer>
    </>
  )
}
