import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import CheckoutBasket from '../CheckoutBasket/CheckoutBasket';
import StripePaymentElement from '../../../../../atomic/molecules/StripePaymentElement/StripePaymentElement';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 24px;
  width: 350px
  background-colour: red;
`;

/**
 * CheckoutModalContent component that handles both the initial checkout form
 * and the payment form states
 */
const CheckoutModalContent = ({
  isPaymentStep,
  onCheckoutSubmit,
  onPaymentSuccess,
  onPaymentError,
  clientSecret,
  paymentIntentId,
  amount,
  basketableType,
  basket
}) => {
  if (isPaymentStep) {
    return (
      <StripePaymentElement
        clientSecret={clientSecret}
        paymentIntentId={paymentIntentId}
        onPaymentSuccess={onPaymentSuccess}
        onPaymentError={onPaymentError}
        amount={amount}
        currency="gbp"
        basketableType={basketableType}
        basket={basket}
      />
    );
  }

  return (
    <Container>
      <CheckoutBasket />
      <CheckoutForm 
        onSubmit={onCheckoutSubmit}
      />
    </Container>
  );
};

CheckoutModalContent.propTypes = {
  isPaymentStep: PropTypes.bool,
  onCheckoutSubmit: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func,
  onPaymentError: PropTypes.func,
  clientSecret: PropTypes.string,
  paymentIntentId: PropTypes.string,
  amount: PropTypes.number,
  basketableType: PropTypes.string,
  basket: PropTypes.object
};

export default CheckoutModalContent; 