import React from 'react';
import styled from 'styled-components';
import PrimaryLocationCard from '../../molecules/PrimaryLocationCard/PrimaryLocationCard';

/**
 * LocationSection component displays information about an event's location
 * using the PrimaryLocationCard component.
 *
 * @component
 * @example
 * const location = {
 *   name: 'HARVEY NICHOLS',
 *   description: 'Luxury nightlife venue...',
 *   image: 'path/to/image.jpg',
 *   address: {
 *     firstLine: '109-125 Knightsbridge',
 *     postcode: 'SW1X 7RJ',
 *     city: 'London',
 *     country: 'United Kingdom'
 *   },
 *   socialMedia: [
 *     { platform: 'instagram', url: 'https://instagram.com/location' }
 *   ]
 * };
 *
 * return (
 *   <LocationSection {...location} />
 * );
 */

const Section = styled.section`
  width: 100%;
  padding: 48px 24px;
  background: var(--background-colour);

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px 24px;
  }
`;

const Container = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
`;

const LocationSection = ({ 
  name,
  description,
  image,
  address,
  socialMedia,
  className 
}) => {
  return (
    <Section data-testid="location-section" className={className} id="location">
      <Container>
        <PrimaryLocationCard
          name={name}
          description={description}
          image={image}
          address={address}
          socialMedia={socialMedia}
        />
      </Container>
    </Section>
  );
};


export default LocationSection;
