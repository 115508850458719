import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfoStrip from '../../../../atomic/atoms/InfoStrip/InfoStrip';
import { ANIMATION_TIMING } from '../../../../atomic/atoms/AnimatedElement/animations';

const StripContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  opacity: ${props => props.$isAnimated ? 0 : 1};
  animation: ${props => props.$isAnimated ? `fadeIn ${ANIMATION_TIMING.FAST.duration}s ${ANIMATION_TIMING.FAST.easing} forwards` : 'none'};
  animation-delay: ${props => props.$isAnimated ? `${ANIMATION_TIMING.FAST.delay + 0.3}s` : '0s'};
`;

const EventInfoStrips = ({ 
  location, 
  time, 
  date, 
  dressCode, 
  baseDelay = 0.2,
  staggerDelay = 0.15,
  isAnimated = true,
  className 
}) => {
  return (
    <StripContainer $isAnimated={isAnimated} className={className}>
      {location && (
        <InfoStrip 
          icon="location" 
          text={location}
          delay={baseDelay + staggerDelay}
        />
      )}
      {time && (
        <InfoStrip 
          icon="time" 
          text={time}
          delay={baseDelay + staggerDelay * 2}
        />
      )}
      {date && (
        <InfoStrip 
          icon="calendar" 
          text={date}
          delay={baseDelay + staggerDelay * 3}
        />
      )}
      {dressCode && (
        <InfoStrip 
          icon="tag" 
          text={dressCode}
          delay={baseDelay + staggerDelay * 4}
        />
      )}
    </StripContainer>
  );
};

EventInfoStrips.propTypes = {
  location: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  dressCode: PropTypes.string,
  baseDelay: PropTypes.number,
  staggerDelay: PropTypes.number,
  isAnimated: PropTypes.bool,
  className: PropTypes.string,
};

export default EventInfoStrips; 