import React from 'react';
import styled from "styled-components";
import FeatureBreakdown from "./components/feature-breakdown/FeatureBreakdown.jsx";

const PackageContainer = styled.div`
    display: flex;
    max-width: 100vw;
    overflow: scroll;
`

const Packages = ({ packages, onClick }) => {
    return (
        <>
            {/*<ImpactTitle main={'Packages'}/>*/}
            <PackageContainer >
                {
                    packages?.length > 0 && packages.map((pack, index) => (
                        <FeatureBreakdown key={index} title={pack.name} features={pack.addons} price={pack.price} onClick={onClick}/>
                    ))
                }
            </PackageContainer>
        </>


    );
};

export default Packages;