// DrawerContext.js
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import DrawerPanel from '../../../layout/components/DrawerPanel';

const DrawerContext = createContext();

export const useDrawer = () => {
    const context = useContext(DrawerContext);

    if (!context) {
        throw new Error('useDrawer must be used within a DrawerProvider');
    }

    return context;
};

export const DrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [previousDrawer, setPreviousDrawer] = useState(null);
  const [anchor, setAnchor] = useState('bottom');
  const [style, setStyle] = useState({});
  const [title, setTitle] = useState(null);

  const previousContent = useCallback(() => {
    if (content !== null) {
      setPreviousDrawer({ content, title, style });
    }
  }, [content, title, style]);

  const openPreviousDrawer = useCallback(() => {
    if (previousDrawer) {
      openDrawer(previousDrawer.content, previousDrawer.title, anchor, previousDrawer.style);
    }
  }, [previousDrawer, anchor]);

  const openDrawer = useCallback((newContent, newTitle, newAnchor = null, newStyle = {}) => {
    previousContent();

    const autoAnchor = window.innerWidth > 768 ? 'right' : 'bottom';
    
    setContent(newContent);
    setAnchor(newAnchor || anchor || autoAnchor);
    setIsOpen(true);
    setStyle(newStyle);
    setTitle(newTitle);
  }, [previousContent, anchor]);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const contextValue = useMemo(() => ({
    openPreviousDrawer,
    closeDrawer,
    openDrawer,
    setTitle,
    previousDrawer,
    style,
    anchor,
    isOpen,
  }), [openPreviousDrawer, closeDrawer, openDrawer, setTitle, previousDrawer, style, anchor, isOpen]);

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
      <DrawerPanel style={style} anchor={anchor} isOpen={isOpen} toggleDrawer={closeDrawer} title={title}>
        {content}
      </DrawerPanel>
    </DrawerContext.Provider>
  );
};
