import React, { useContext, useEffect } from 'react';
import { useCheckout } from "../../js/context/CheckoutContext.jsx";
import { useEcommerce } from "../../js/context/EcommerceContext.jsx";
import { useHandleError } from "../../../error-handling/js/hooks/index.js";
import { AuthContext } from "../../../authentication/AuthContext.jsx";
import { useUrlParams, ConvertBase64IdFromParams } from "../../../../js/hooks/useParam.js";
import { API_URL } from "../../../../js/Helper.js";
import axios from "axios";
import { Loading } from '../../../../components/ui/Loading.jsx';
import { PageContainer } from "../styles/checkoutPage.styles.js";
import PaymentSection from "../components/PaymentSection.jsx";
import OrderSummarySection from "../components/OrderSummarySection.jsx";
import QuestionsSection from "../components/QuestionsSection.jsx";
import { useBasket } from "../../basket/hooks/useBasket.jsx";
import { FadedBgImage } from '../../../../components/ui/FadedBgImage.jsx';
import { updateTheme } from '../../../themes/js/utils.js';

const CheckoutPage = () => {
  const { header, user } = useContext(AuthContext);
  const { config, basket, setBasket } = useEcommerce();
  const params = useUrlParams();
  const { showErrorPage, showErrorToast } = useHandleError();
  const id = ConvertBase64IdFromParams(params.id);
  const { useGetBasket } = useBasket();

  const { 
    setTotal,
    setFees,
    setStripe,
    setQuestions,
    setEvent,
    event,
    checkoutQuestions
  } = useCheckout();

  // Only fetch basket data if we don't have checkout
  const { data: basketData, isLoading: basketLoading, error: basketError } = useGetBasket(id);


  useEffect(() => {
    if (basket.status === 'inactive' && basketData) {
      // Update the basket in EcommerceContext
      setBasket(basketData);
      
      // Update other checkout-related state
      setTotal(basketData.total);
      setFees(basketData.fees);
      setStripe(basketData.paymentIntent);
      setQuestions(basketData.checkoutQuestions);
      setEvent(basketData?.basketable);
      updateTheme(basketData?.basketable?.theme);
    }
    
    if (basketError) {
      console.error('Error fetching basket:', basketError);
      showErrorPage(basketError.message);
    }
  }, [basket, basketData, basketError]);

  useEffect(() => {
    const eventListener = async (event) => {
      const req = axios.post(`${API_URL}/basket/${basket.id}/add-user`, {
            user_id: event.detail.user.id,
            method: 'logged_in'
          },
          {
            headers: header()
          })
          .then((req) => {

            setTimeout(() => {
              scrollToElement('payment-form')
            }, 500)
          })
          .catch((error) => {
            showErrorToast(error)
            console.error('Error in event listener', error);
          })
    };

    // Add the event listener
    window.addEventListener('loggedIn', eventListener);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('loggedIn', eventListener);
    };
  }, [])

  if (!basket) return <Loading />;


    const isTableProduct = basket?.order_items?.filter((item) => item?.item?.category === CATEGORY_TABLE);


  return (
    <PageContainer>
      { innerWidth < 1024 && <FadedBgImage image={`${event?.image?.cdnUrl}`} />}
      <OrderSummarySection />
        {/*{ isTableProduct.length > 0 &&  <CheckoutMenuSection products={isTableProduct} />}*/}
        { config?.hasQuestions && <QuestionsSection /> }
        { user && <PaymentSection /> }
    </PageContainer>
  )
}

export default CheckoutPage