import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ActivitySelector } from '../components/ui/ActivitySelector/ActivitySelector'
import { FloatingNav } from '../layout/components/FloatingNav'
import { Footer } from '../layout/components/Footer'
import { CDN_URL, SetLocalStorageJSON, getLocalStorageJSON } from '../js/Helper'
import { InstructionTitle } from '../components/ui/typography/index.js'
import { StandardSection } from '../layout/sections/StandardSection'
import LogoComponent from '../components/ui/LogoComponent'
import { getCities } from '../api/cities'
import {Select} from "../components/forms/index.js";
import {mainMenu} from "../layout/menus/js/mainMenu.js";
import { NetworkError } from '../utils/errors.js'

// JavaScript variables
const primaryColorR = 42; // Replace with your color value
const primaryColorG = 45; // Replace with your color value
const primaryColorB = 58; // Replace with your color value

const PageContainer = styled.div`
  display: grid;
  //grid-template-rows: 1fr 2fr 1fr;
  align-items: center;
  //gap: 20px;
  height: 100dvh;
  min-height: 100dvh;
  background-image: 
  linear-gradient(to top,
    rgba(${primaryColorR}, ${primaryColorG}, ${primaryColorB}, 0) 0%,
    /* rgba(${primaryColorR}, ${primaryColorG}, ${primaryColorB}, 1) 40%, */
    /* rgba(${primaryColorR}, ${primaryColorG}, ${primaryColorB}, 1) 60%, */
    rgba(${primaryColorR}, ${primaryColorG}, ${primaryColorB}, 0) 90%),
    url(${props => props.image});

  @media screen and (min-width: 1024px){
  }
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
  `
const ActivityContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: red;
  z-index: 10;

`

const ImageContainer = styled.section`
`

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: -0.5px;
  background: linear-gradient( to top, transparent, var(--default-primary-colour) 100%);
  z-index: 2;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Home =  () => {
  
  const [image, setImage] = useState();
  
  const cities =  getCities();

  const format = `?auto=format&w=${innerWidth}&h=${innerHeight}&fit=crop&losless=true`

  useEffect(() => {
    setImage(`${CDN_URL}/images/ui/home${getCity()}.jpeg${format}`)
    //
    // const relatedApps = getRelatedApps();
    // relatedApps.then((response) => {
    // })

  }, [])
  
  const handleCityChange = (item) => {
    SetLocalStorageJSON('city', item)
    setImage(`${CDN_URL}/images/ui/home${getCity(cities)}.jpeg${format}`)
  }
  
  const placeholder = getLocalStorageJSON('city') ? getLocalStorageJSON('city').label : ''
  
  function getCity() {
    return getLocalStorageJSON('city') ? getLocalStorageJSON('city').value : ''
  }
  
  return (
    <>
    <PageContainer image={image} id='page-container'>
      
        <Section>
          <ImageContainer>
          <LogoComponent />
          </ImageContainer>
        </Section>

        <GradientOverlay/>

        <Section>
          <InstructionTitle title="What city are you in?" style={{marginBottom: '-2px'}}  />
          <Select options={cities && cities} placeholder={placeholder}  passChangeToParent={handleCityChange} />
        </Section>

        <StandardSection style={{display:'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center', zIndex:'2', minWidth:'100vw'}} id='activity-section'>
            <InstructionTitle title="What would you like to do?" />
            <ActivitySelector />
        </StandardSection>

        <Section>
        <FloatingNav items={mainMenu}/>
        </Section>
        
      </PageContainer>
    <Footer/>
    </>
  )
}
