/**
 * PrimaryEventCard component
 * @description Main card component for displaying event information
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AsymmetricCard from "../../../../atomic/molecules/AsymmetricCard/index.js";
import TypographyGroup from "../../../../atomic/molecules/TypographyGroup/TypographyGroup.jsx";
import Paragraph from "../../../../atomic/atoms/Paragraph/index.js";


const EventCard = styled(AsymmetricCard)`
  @media (min-width: 768px) {
    height: fit-content;
    width: fit-content;
    max-height: fit-content;
    max-width: fit-content;
    min-height: fit-content;
    min-width: fit-content;

    #asymmetric-card-header {
      height: fit-content;
      width: fit-content;
      max-height: fit-content;
      max-width: fit-content;
      min-height: fit-content;
      min-width: fit-content;
    }
  }
`;

const EventDescription = styled(Paragraph)`
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  color: var(--text-primary);
`;

/**
 * PrimaryEventCard component
 * @param {Object} props - Component props
 * @param {string} props.title - Event title
 * @param {string} props.date - Event date
 * @param {string} props.organiser - Event organiser
 * @param {string} props.description - Event description
 * @param {string} props.className - Additional CSS classes
 */
const PrimaryEventCard = ({
  title,
  date,
  organiser,
  description,
  className,
  ...props
}) => {
  const headerContent = (
    <TypographyGroup
      data-testid="primary-event-card-header-content"
      subtitle={`${organiser} Presents`}
      title={title}
      paragraph={description}
    />
  );

  return (
    <EventCard
      variant="extended"
      asymRadius="xxl"
      headerContent={headerContent}
      className={className}
      contentBackground="var(--accent-colour)"
      data-testid="primary-event-card"
      reverse={true}
      {...props}
    >
      {/* <EventDescription data-testid="primary-event-card-description">
        {date}
      </EventDescription> */}
    </EventCard>
  );
};

PrimaryEventCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  organiser: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default PrimaryEventCard;
