import axios from 'axios';
import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../features/authentication';
import { API_URL, APP_URL } from '../../js/Helper';
import {isiOS, isSafari} from '../../js/utils/browserUtils';
import { toast } from 'react-toastify';
import { LoadingAnimationComponent } from './LoadingAnimationComponent';
import {useHandleError} from "../../features/error-handling/js/hooks/index.js";

const WalletImage = styled.img`
    &:active {
        border: solid 1px white;
        box-shadow: var(--default-shadow);
    }
`

export const AddToAppleWallet = ({ type, id, style }) => {


    const { token } = useContext(AuthContext);
    const { showErrorToast } = useHandleError();

    const path = `${API_URL}/pass/create?model=${type}&id=${id}`
      
    const mutationFn = (data) =>
    axios.get(path, data, {
        headers: {
            'Authorization': `${token?.type} ${token?.token}`,
            'Content-Type': 'application/json'
        }
    });

    const createPassMutation = useMutation({
        mutationFn: mutationFn,
        onMutate: () => {
        },
        onSuccess: (res) => {
          
            /**
             * we are doing it this way because was getting cors issues
             * when trying to redirect directly from the server 
             * */

            window.location.href = res?.data?.url
        },
        onError: (error) => {
            showErrorToast(error.response.data.error_message ?? error)
        }
    })

    const handleClick = () => {


      const data = {
          type: type,
          id: id
      }

      createPassMutation.mutate(data)
        
  
    }
  return (
    <>

        { isSafari() && (
          !createPassMutation.isLoading ? (
            <WalletImage onClick={handleClick} src='/US-UK_Add_to_Apple_Wallet_RGB_101421.svg' width={style?.width ||  '175px'}/>
          )  : (
            <LoadingAnimationComponent />
          )
        )}
    </>
  )
}

