import React, { useState } from 'react'
import styled from 'styled-components';
import { Loading } from '../components/ui/Loading';

const PageContainer = styled.div`
  display: grid;
  height: 100dvh;
  padding: 0px 20px;
  place-items: center;
  background-color: var(--primary-colour);
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 7px 5px whitesmoke;
  background-color: var(--accent-colour);
  gap: -20px;
`

const Header = styled.h2`
  font-size: large;
`

const Text = styled.p`
  margin: 10px 0px;
`

const Link = styled.button`
  padding: 10px;
  width: 150px;
  margin-top: 15px;
  font-size: 1em;
  font-weight: bold;
  border: solid 1px white;
  background-color: transparent;
  color: white;
`


export const FileNotFoundPage = () => {
  const [show, setShow] = useState(false);

  setTimeout(() => {
    setShow(true)
  }, 2000);

  return (
    
      show &&
      <PageContainer>
        <Container>
          <div>
          <Header>The page you are looking for does not exist !</Header>
          </div>
            <Text>If you think this is a bug, try reloading the page, if that don't work please close the browser and try again.</Text>
            <Text>If you are still experiencing problems please contact customer service.</Text>
            <a  target='_blank' href='https://nctrl.atlassian.net/servicedesk/customer/portal/2'>
              <Link>Report Bug</Link>
            </a>
        </Container>
      </PageContainer> 
    
  )
}
