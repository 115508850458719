import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

/**
 * Outer container that handles background and full-width styles
 */
const OuterContainer = styled(motion.section)`
  position: relative;
  width: 100%;
  background: ${props => props.$background || 'transparent'};
  padding: ${props => props.$spacing || '64px 24px'};
  min-height: ${props => props.$minHeight || 'auto'};
  overflow: ${props => props.$overflow || 'visible'};

  
  /* Handle background image if provided */
  ${props => props.$backgroundImage && `
    background-image: url(${props.$backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
  
  /* Handle background overlay if provided */
  ${props => props.$overlay && `
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background: ${props.$overlay};
      z-index: 1;
    }
  `}
  
  @media (max-width: 768px) {
    padding: ${props => props.$mobileSpacing || '48px 16px'};
  }
`;

/**
 * Inner container that handles max-width and content alignment
 */
const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.$maxWidth || '1200px'};
  margin: 0 auto;
  z-index: 2;
  
  /* Handle content alignment */
  display: flex;
  flex-direction: ${props => props.$direction || 'column'};
  align-items: ${props => props.$align || 'flex-start'};
  justify-content: ${props => props.$justify || 'flex-start'};
  gap: ${props => props.$gap || '0'};
  
  /* Handle text alignment */
  text-align: ${props => props.$textAlign || 'left'};
`;

/**
 * Base Section component that can be extended for specific section layouts
 * @param {node} children - Content to render inside the section
 * @param {string} background - Background color
 * @param {string} backgroundImage - Background image URL
 * @param {string} overlay - Overlay color (with opacity)
 * @param {string} maxWidth - Maximum width of the content
 * @param {string} spacing - Padding around the section
 * @param {string} mobileSpacing - Padding on mobile devices
 * @param {string} minHeight - Minimum height of the section
 * @param {string} direction - Flex direction of content
 * @param {string} align - Vertical alignment of content
 * @param {string} justify - Horizontal alignment of content
 * @param {string} textAlign - Text alignment within the section
 * @param {string} gap - Gap between flex items
 * @param {string} overflow - Overflow behavior
 * @param {object} animation - Framer Motion animation props
 */
const Section = ({
  children,
  background,
  backgroundImage,
  overlay,
  maxWidth = '1200px',
  spacing = '64px 24px',
  mobileSpacing = '48px 16px',
  minHeight = 'auto',
  direction = 'column',
  align = 'flex-start',
  justify = 'flex-start',
  textAlign = 'left',
  gap = '0',
  overflow = 'visible',
  animation,
  ...props
}) => {
  return (
    <OuterContainer
      $background={background}
      $backgroundImage={backgroundImage}
      $overlay={overlay}
      $spacing={spacing}
      $mobileSpacing={mobileSpacing}
      $minHeight={minHeight}
      $overflow={overflow}
      {...animation}
      {...props}
    >
      <InnerContainer
        $maxWidth={maxWidth}
        $direction={direction}
        $align={align}
        $justify={justify}
        $textAlign={textAlign}
        $gap={gap}
      >
        {children}
      </InnerContainer>
    </OuterContainer>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  backgroundImage: PropTypes.string,
  overlay: PropTypes.string,
  maxWidth: PropTypes.string,
  spacing: PropTypes.string,
  mobileSpacing: PropTypes.string,
  minHeight: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column', 'row-reverse', 'column-reverse']),
  align: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  gap: PropTypes.string,
  overflow: PropTypes.string,
  animation: PropTypes.object,
};

export default Section;
