import React from 'react';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {Loading} from '../../../components/ui/Loading';
import {useHandleTierChange} from '../js/hooks';
import {modelsMap} from '../../../js/maps/modelsMap';
import {useCard} from '../js';
import {updateTheme} from '../../themes/js/utils';
import {useApiQuery} from "../../../hooks/customHooks.js";
import {API_URL, scrollToElement} from "../../../js/Helper.js";
import {FileNotFoundPage} from "../../../pages/FileNotfoundPage.jsx";
import PrimaryCardPage from "./PrimaryCardPage.jsx";


const Card =  ({ page }) => {

  const { setTier, setCard, selectedTier, setSelectedTier } = useCard();

  const params = useParams();


  // get the slug from the url
  const slug = params.slug;

  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get('section')

  if(section) {
    scrollToElement(section)
  }

  const { data, isLoading, isError, refetch } = useApiQuery(`${API_URL}/card/${slug}`,
        data => {

          if(data.status === 'success') {
            updateTheme(data?.card?.default_tier?.theme)
            setCard(data.card)
            // updateTheme(data.card.default_tier.theme)
            setSelectedTier(data.card.default_tier)
          }

        },
        error => console.error(error.message)
    );

  if (isLoading) {
    return <Loading/>;
  }

  if (isError) {
    return <h1>Error</h1>
  }

  if(!data) {
    return <Loading/>;
  }

  // hooks 

  
  // fetch the data
  // const { data, isLoading, isError } = fetchCardBySlug(slug);
  
  // update the theme 
  // useUpdateTheme(data && data?.card?.default_tier?.theme)


  const feature = modelsMap['cards']; // todo: want to change this to featuresMap


  
  
  const card = data && data.status == 'success' && data.card
  const activeTier = card && card.default_tier
  
  if(!card || !activeTier) {  return <FileNotFoundPage/>  }

  return (
    <>
      {/*<TopNavigation/>*/}

    {

     <Helmet>
        {/* Meta tags */}
        
        <title>{`${card.name} ~ ${activeTier.name} / Nocturnal`}</title>
        <meta name="description" content={activeTier.description} />
        <meta name="keywords" content="Nocturnal, Nocturnal Lifestyle, Membership, Rewards, Discounts, Loyalty Card" />
        <meta name="theme-color" content={activeTier.theme?.primary_colour} />

        {/* Open Graph tags */}
        <meta property="og:title" content={`${card.name} ~ ${activeTier.name} // Nocturnal`} />
        <meta property="og:description" content={activeTier.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="https://www.example.com/images/pwa-image.jpg" />

        {/* Twitter Card tags */}

      </Helmet>
    }


      <PrimaryCardPage
      feature={feature}
      card={card}
      activeTier={activeTier}
      setActiveTier={(tier) => useHandleTierChange(tier)}
      />



     
    </>
  )

}


  export default Card