import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EventErrorBoundary from '../components/EventErrorBoundary';

// Component that simulates different types of errors
const ErrorTrigger = ({ errorType }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (errorType === 'api') {
      // Simulate API error
      setError(new Error('API Error: Failed to fetch event data'));
    }
  }, [errorType]);

  switch (errorType) {
    case 'render':
      throw new Error('Render Error: Component failed to render');
    case 'api':
      if (error) throw error;
      return <div>Loading...</div>;
    case 'null':
      // @ts-ignore: Testing null handling
      return null.someProperty;
    default:
      return <div>No error (yet)</div>;
  }
};

// Test component with controls
const EventTest = () => {
  const [errorType, setErrorType] = useState('none');
  const { type } = useParams();

  // If type is provided in URL, use that
  useEffect(() => {
    if (type) {
      setErrorType(type);
    }
  }, [type]);

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Event Error Boundary Test</h1>
      
      <div style={{ marginBottom: '2rem' }}>
        <button 
          onClick={() => setErrorType('render')}
          style={{ marginRight: '1rem' }}
        >
          Trigger Render Error
        </button>
        <button 
          onClick={() => setErrorType('api')}
          style={{ marginRight: '1rem' }}
        >
          Trigger API Error
        </button>
        <button 
          onClick={() => setErrorType('null')}
          style={{ marginRight: '1rem' }}
        >
          Trigger Null Error
        </button>
        <button 
          onClick={() => setErrorType('none')}
        >
          Clear Error
        </button>
      </div>

      <div style={{ 
        padding: '2rem',
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}>
        <EventErrorBoundary>
          <ErrorTrigger errorType={errorType} />
        </EventErrorBoundary>
      </div>

      <div style={{ marginTop: '2rem' }}>
        <h3>Current Test:</h3>
        <pre>
          {JSON.stringify({ errorType }, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default EventTest;
