import React from 'react';
import styled from 'styled-components';

const WalletContainer = styled.div`
  width: 100%;
  max-width: 375px;
  background: linear-gradient(145deg, #2d2d2d 0%, #1a1a1a 100%);
  border-radius: 16px;
  color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 20px auto;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

const ImageStrip = styled.div`
  width: 100%;
  height: 200px;
  background-image: ${props => props.image ? `url(${props.image})` : 'linear-gradient(45deg, #FF6B6B, #4ECDC4)'};
  background-size: cover;
  background-position: center;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.7));
  }
`;

const PassContent = styled.div`
  padding: 20px;
`;

const PassHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
  position: relative;
  z-index: 1;
`;

const Logo = styled.div`
  width: 50px;
  height: 50px;
  background: ${props => props.logoImage ? 'transparent' : '#333'};
  background-image: ${props => props.logoImage ? `url(${props.logoImage})` : 'none'};
  background-size: cover;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.div`
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  p {
    margin: 4px 0 0;
    font-size: 14px;
    color: #999;
  }
`;

const PassInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const InfoItem = styled.div`
  h3 {
    font-size: 12px;
    color: #999;
    margin: 0 0 5px;
    font-weight: normal;
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
  }
`;

const Barcode = styled.div`
  height: 70px;
  background: #333;
  border-radius: 8px;
  margin-top: 20px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
  }
`;

const WalletPass = ({ 
  eventName = "Event Name",
  date = "DEC 23",
  time = "8:00 PM",
  venue = "Venue Name",
  ticketType = "VIP Access",
  ticketId = "#0123456789",
  headerImage,
  logoImage
}) => {
  return (
    <WalletContainer>
      <PassContent>
        <PassHeader>
          <Logo logoImage={logoImage} />
          <HeaderText>
            <h2>{eventName}</h2>
            <p>Event Pass</p>
          </HeaderText>
        </PassHeader>

      <ImageStrip image={headerImage} />

        
        <PassInfo>
          <InfoItem>
            <h3>DATE</h3>
            <p>{date}</p>
          </InfoItem>
          <InfoItem>
            <h3>TIME</h3>
            <p>{time}</p>
          </InfoItem>
          <InfoItem>
            <h3>VENUE</h3>
            <p>{venue}</p>
          </InfoItem>
          <InfoItem>
            <h3>TYPE</h3>
            <p>{ticketType}</p>
          </InfoItem>
        </PassInfo>
        
        <InfoItem>
          <h3>TICKET ID</h3>
          <p>{ticketId}</p>
        </InfoItem>
        
        <Barcode />
      </PassContent>
    </WalletContainer>
  );
};

export default WalletPass;
