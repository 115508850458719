
// New Gallery styled components
import styled from "styled-components";
import ReactPlayer from "react-player";
import {AiFillPlayCircle} from "react-icons/ai";
import React, {useState} from "react";
import {GalleryItem, GalleryScroll, GallerySection} from "./js/gallery.styles.js";
import {VideoComponent} from "../ui/VideoComponent.jsx";


const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */

`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  
`;

const ThumbnailContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const PlayIcon = styled(AiFillPlayCircle)`
  position: absolute;
  font-size: 50px;
  color: white;
  opacity: 0.8;
`;

// Video Component
export const VideoGallery = ({ data, style }) => {

    console.log('item', data)

    return (
       <GallerySection style={style}>
           {data.length > 0 && (
               <GalleryScroll style={{padding: data.length > 1 ? '0 20px' : '0'}}>
                   {data.map((item, index) => (
                       <GalleryItem key={index}>
                           <VideoComponent data={item} url={item?.url} numberOfItems={data.length} style={{width: '100%'}} />
                       </GalleryItem>
                   ))}
               </GalleryScroll>
           )}
       </GallerySection>
    );
};