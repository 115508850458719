import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../authentication';

export const SessionContext = createContext();

const SESSION_STORAGE_KEY = 'nocturnal_session';

const generateAnonymousId = () => {
  const storedId = localStorage.getItem('anonymous_id');
  if (storedId) return storedId;
  
  const newId = crypto.randomUUID();
  localStorage.setItem('anonymous_id', newId);
  return newId;
};

const getStoredSession = () => {
  try {
    const stored = localStorage.getItem(SESSION_STORAGE_KEY);
    return stored ? JSON.parse(stored) : null;
  } catch (err) {
    console.error('Error reading session from storage:', err);
    return null;
  }
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

export const SessionProvider = ({ children }) => {
  const { user, token, isAuthenticated } = useContext(AuthContext);
  const [session, setSession] = useState(() => getStoredSession());

  // Create or update session for both authenticated and anonymous users
  useEffect(() => {
    const anonymousId = generateAnonymousId();
    const now = new Date().toISOString();
    
    const newSession = {
      sessionId: crypto.randomUUID(),
      anonymousId,
      createdAt: now,
      lastActive: now,
      isAuthenticated,
      // Add user data if authenticated
      ...(isAuthenticated && user ? {
        userId: user.id,
        userEmail: user.email,
      } : {}),
    };

    setSession(newSession);
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newSession));
  }, [user, isAuthenticated]);

  // Update last active timestamp periodically
  useEffect(() => {
    if (session) {
      const interval = setInterval(() => {
        const updatedSession = {
          ...session,
          lastActive: new Date().toISOString(),
        };
        setSession(updatedSession);
        localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(updatedSession));
      }, 5 * 60 * 1000); // Update every 5 minutes

      return () => clearInterval(interval);
    }
  }, [session]);

  const getSessionHeaders = () => {
    if (!session) return {};
    
    const headers = {
      'X-Session-ID': session.sessionId,
      'X-Anonymous-ID': session.anonymousId,
      'X-Last-Active': session.lastActive,
    };

    // Add authenticated user headers if available
    if (session.isAuthenticated && session.userId) {
      headers['X-User-ID'] = session.userId;
    }

    return headers;
  };

  return (
    <SessionContext.Provider value={{ 
      session, 
      getSessionHeaders,
      isAuthenticated: session?.isAuthenticated || false,
      anonymousId: session?.anonymousId,
    }}>
      {children}
    </SessionContext.Provider>
  );
}; 