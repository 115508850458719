import { useState, useCallback, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useEcommerce } from '../../../js/context/EcommerceContext';
import { handlePaymentError, executeErrorRecovery } from '../utils/handlePaymentError';
import { PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import { useHandleError } from '../../../../error-handling/js/hooks';
import { API_URL } from '../../../../../js/Helper';
import { AuthContext } from '../../../../authentication';
import { getReturnUrl } from '../utils/getReturnUrl';

/**
 * Hook to manage Stripe payment processing
 * @returns {Object} Payment handling functions and utilities
 */
export const useHandleStripePayment = () => {
    const { updateConfig, basket } = useEcommerce();
    const { showErrorToast } = useHandleError();
    const { setUser, setIsAuthenticated, setToken } = useContext(AuthContext);
    const [isProcessing, setIsProcessing] = useState(false);
    const [lastError, setLastError] = useState(null);


    /**
     * Initialize Stripe with retry mechanism
     */
    const initializeStripe = async (retries = 3, delay = 1000) => {
        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                const stripe = await loadStripe(import.meta.env.VITE_STRIPE_KEY);
                if (!stripe) {
                    throw new Error('Stripe initialization failed.');
                }
                return stripe;
            } catch (error) {
                const paymentError = handlePaymentError(error);
                console.error(`Attempt ${attempt} - Failed to load Stripe:`, paymentError);
                
                if (attempt === retries) {
                    throw paymentError;
                }
                
                await new Promise(resolve => setTimeout(resolve, delay * attempt));
            }
        }
    };

    /**
     * Validates if payment can proceed based on authentication status
     */
    const isPaymentAllowed = (isPaymentAllowed, isAuthenticated) => {
        if (!isPaymentAllowed) {
            throw handlePaymentError({
                type: 'invalid_request_error',
                message: 'Payment is not allowed at this time'
            });
        }

        if (!isAuthenticated) {
            throw handlePaymentError({
                type: 'authentication_error',
                message: 'Please log in to complete payment'
            });
        }

        return true;
    };

    /**
     * Validates form elements before payment
     */
    const formValidation = async (elements) => {
        if (!elements) {
            throw handlePaymentError({
                type: 'invalid_request_error',
                message: 'Payment form not properly initialized'
            });
        }

        const { error } = await elements.submit();
        if (error) {
            throw handlePaymentError(error);
        }

        return true;
    };

    /**
     * Validates completion of required questions
     * @param {boolean} hasQuestions - Whether there are required questions
     * @param {boolean} questionsComplete - Whether all required questions are completed
     * @returns {boolean} True if validation passes
     */
    const hasCompletedQuestions = (hasQuestions, questionsComplete) => {
        // If hasQuestions is undefined or false, no questions are required
        if (!hasQuestions) {
            return true;
        }

        // Only check questionsComplete if hasQuestions is true
        if (!questionsComplete) {
            throw handlePaymentError({
                type: 'invalid_request_error',
                message: 'Please complete all required questions'
            });
        }
        return true;
    };


    /**
     * Retrieves client secret for payment processing
     */
    const getClientSecret = async (intent) => {
        if (!intent) {
            throw handlePaymentError({ 
                type: 'invalid_request_error',
                message: 'Payment Intent is required for processing payment'
            });
        }

        try {
            console.log('Got   intent', intent);

            const res = await axios.post(
                `${API_URL}/stripe/payment_intent/${intent}/client_secret`,
                { basket_id: basket?.id }
            );

            console.log('Got client secret', res);

            if (!res.data) {
                throw handlePaymentError({
                    type: 'processing_error',
                    message: 'Invalid response from payment server. Please try again.'
                });
            }

            updateConfig({
                confirmationMessage: res.data.message,
                order: res.data.order
            });

            return {
                clientSecret: res.data.client_secret,
                order: res.data.order,
                user: res.data.user,
                message: res.data.message
            };
        } catch (error) {
            // If it's an axios error with a response, use that message
            if (error.response?.data?.message) {
                throw handlePaymentError({
                    type: 'processing_error',
                    message: error.response.data.message
                });
            }
            throw handlePaymentError(error);
        }
    };

    /**
     * Confirms payment with Stripe
     * @param {string} paymentIntentId - The ID of the payment intent
     * @param {Object} basket - The basket object
     * @param {string} basketableType - Type of basketable item
     * @param {Object} stripe - Stripe instance
     * @param {Object} elements - Stripe Elements instance
     * @param {Object} paymentDetails - Optional payment details for express checkout
     */
    const confirmPayment = async (
        paymentIntentId,
        basket,
        basketableType,
        stripe,
        elements,
        paymentDetails = null
    ) => {
        try {
            // Get client secret just before confirming payment
            const { clientSecret, order, user } = await getClientSecret(paymentIntentId);

            console.log('Payment processing with:', { clientSecret, order, user });

            // Submit the form if regular payment
            if (!paymentDetails) {
                const { error: submitError } = await elements.submit();
                if (submitError) {
                    throw handlePaymentError(submitError);
                }
            }

            const confirmParams = {
                elements,
                clientSecret,
                confirmParams: {
                    return_url: getReturnUrl({
                        basketId: basket?.id,
                        basketableType
                    }),
                    payment_method_data: {
                        billing_details: paymentDetails ? {
                            name: paymentDetails.name,
                            email: paymentDetails.email,
                            phone: paymentDetails.phone,
                            address: paymentDetails.billingAddress
                        } : {
                            name: basket?.user?.name,
                            email: basket?.user?.email
                        }
                    }
                }
            };

            console.log('confirmParams', confirmParams);

            const { error: confirmError, paymentIntent } = await stripe.confirmPayment(confirmParams);

            if (confirmError) {
                throw handlePaymentError(confirmError);
            }

            return paymentIntent;
        } catch (error) {
            throw handlePaymentError(error);
        }
    };

    /**
     * Handles payment error recovery
     */
    const handleErrorRecovery = useCallback(async (error) => {
        const recoveryHandlers = {
            UPDATE_CARD: () => {
                // Implementation for updating card
                showErrorToast('Please update your card details');
                // TODO: Show card update form
            },
            AUTHENTICATE: () => {
                // Implementation for authentication
                showErrorToast('Please log in to continue');
                // TODO: Show login form
            },
            RETRY: async () => {
                setLastError(null);
                showErrorToast('Retrying payment...');
                // Retry logic will be handled by the component
            },
            REFRESH: () => {
                showErrorToast('Refreshing payment session...');
                window.location.reload();
            },
            RESET: () => {
                setLastError(null);
                setIsProcessing(false);
                showErrorToast('Payment reset. Please try again.');
            }
        };

        await executeErrorRecovery(error, recoveryHandlers);
    }, [showErrorToast]);

    return {
        initializeStripe,
        confirmPayment,
        handleErrorRecovery,
        isPaymentAllowed,
        hasCompletedQuestions,
        formValidation,
        isProcessing,
        lastError
    };
};
