import styled from "styled-components";
import {Section} from "../../../../layout/components/LayoutSection.jsx";
import {CardBase} from "../../components/MemberCard/MemberCardComponent.jsx";

export const TierCard = styled(CardBase)`
    min-width: 266px; /* 33% less than the main MemberCard's width (350px * 0.67) */
    height: 160px; // Ensure height remains consistent with previous adjustments
    max-width: 230px;
    opacity: ${(props) => (props.isSelected ? 1 : 0.5)}; // Adjust opacity based on selection
    scroll-snap-align: center; // Snap to center

    @media  (min-width: 1024px) {
        height: 300px;
    }
`;

export  const TierSection = styled(Section)`
    width: 100vw;
    //background: var(--accent-colour);
    padding: 20px 20px 20px 25px;
`

export const ScrollContainer = styled.div`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden; // Ensure no vertical scroll
    padding: 10px 10px;
    margin: 0 -20px; /* Negative margin to pull scrollbars off-screen */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x mandatory; // Enable snappy scrolling
    scroll-behavior: smooth; // Smooth scrolling

    &::-webkit-scrollbar {
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: var(--border-radius-default);
    }
`;