import {filterPartnersByRoles, filterProductsByCategory} from "./utils.js";
import {PRODUCT_TABLE, PRODUCT_TICKET} from "./constants.js";

export const eventData = (eventData) => {
    return {
        "tickets": eventData ? filterProductsByCategory(eventData.products, PRODUCT_TICKET) : [],
        "tables": eventData ? filterProductsByCategory(eventData.products, PRODUCT_TABLE) : [],
        "djs_artists": eventData ? filterPartnersByRoles(eventData.partners, ['dj', 'artist']) : [],
        "sponsors": eventData ? filterPartnersByRoles(eventData.partners, ['sponsor']) : []
    }
}