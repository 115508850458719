import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import './css/faded_bg_image.css'
import { CDN_URL } from '../../js/Helper'
import { GradientOverlayComponent } from '../GradientOverlayComponent';
import { FullPageAbsoluteContainer } from '../../layout/styles/layoutStyles';
import { ImageComponent } from './images/ImageComponent';
import { TopBottomGradient } from '../../js/styles/gradients';

export const FadedBgImage = (props) => {
  const { tier, image } = props;

  return (
  <FullPageAbsoluteContainer name='faded-image-container'>
    <TopBottomGradient name={'image-gradient'} blur color='var(--primary-colour)' image={image}/>
  </FullPageAbsoluteContainer>
  );
};




