import styled from "styled-components";

const ProductInfoContainer = styled.section`
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    color: var(--text-colour);
    background-color: var(--secondary-colour);
    border-radius: 15px;
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 2;
  `;

const ProductName = styled.h1`
    font-size: 2.5em;
    margin-bottom: 15px;
    color: var(--accent-colour);
  `;

const ProductDescription = styled.p`
    margin-bottom: 25px;
    line-height: 1.6;
    font-size: 1.1em;

    p {
      margin-bottom: 10px;
    }
  `;

const ProductMetaContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  `;

const MetaItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-size: 1.1em;
    width: fit-content;
  `;

const PriceTag = styled(MetaItem)`
    font-size: 1.2em;
    font-weight: bold;
    color: var(--accent-colour);
    background-color: rgba(191, 181, 147, 0.2);
  `;

const StockIndicator = styled.span`
    color: ${props => props.inStock ? 'var(--success-colour)' : '#F44336'};
    font-weight: bold;
  `;

export  {
    ProductInfoContainer,
    ProductName,
    ProductDescription,
    ProductMetaContainer,
    MetaItem,
    PriceTag,
    StockIndicator
}