import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '..';
import { LoginForm } from '../components/LoginForm';
import { CDN_URL } from '../../../js/Helper';

const primaryColorR = 42; // Replace with your color value
const primaryColorG = 45; // Replace with your color value
const primaryColorB = 58; // Replace with your color value

const Left = styled.section`
  background-image:
  linear-gradient(to top, transparent, var(--default-primary-colour)),
  linear-gradient(to bottom, transparent, var(--default-primary-colour)),
  url(${props => props.image});
  background-position: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (min-width: 1024px) {
    background: none;
  }
`

export const LoginPage = ({ create, redirect }) => {

  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const afterLogin = queryParams.get('al');

  console.log('After Login', afterLogin)

  // useEffect(() => {
  //   if(isAuthenticated){
  //     navigate(redirect || '/user/my-account')
  //   }
  // }, [])

  return (
    <>
    <OrdersPageContainer name='left-section' image={`${CDN_URL}/images/ui/${create ? 'create-account-bg' : 'login-bg'}.jpeg`} >
      <LogoComponent/>
      <LoginForm create={create} afterLogin={afterLogin || redirect}/>
    </OrdersPageContainer>
    {/* <FloatingNav items={menuItems['home']} theme={'default'}/> */}
    </>

  );
};
