import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { 
  baseColors, 
  typography, 
  spacing, 
  dimensions, 
  media, 
  mixins,
  borderRadius,
  breakpoints
} from '../tokens/theme';

// Create a theme object that combines all our tokens
const theme = {
  colors: baseColors,
  typography,
  spacing,
  dimensions,
  media,
  mixins,
  borderRadius,
  breakpoints
};

export const ThemeProvider = ({ children }) => {
  return (
    <StyledThemeProvider theme={theme}>
      {children}
    </StyledThemeProvider>
  );
}; 