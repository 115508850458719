import React from 'react';
import { Box, Typography, Button, Container, Paper, Grid, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { motion } from 'framer-motion';

// Styled components with futuristic design
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  marginBottom: theme.spacing(3),
  background: 'rgba(42, 45, 58, 0.5)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(191, 181, 147, 0.1)',
  boxShadow: '0 8px 32px 0 rgba(191, 181, 147, 0.1)',
  color: 'var(--text-colour)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '1px',
    background: 'var(--default-accent-colour)',
    opacity: 0.3
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 12px 40px 0 rgba(191, 181, 147, 0.1)',
    '&::before': {
      opacity: 0.5
    }
  }
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  color: 'var(--default-accent-colour)',
  marginBottom: theme.spacing(3),
  fontWeight: 600,
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -6,
    left: 0,
    width: '60%',
    height: '2px',
    background: 'var(--default-accent-colour)',
    boxShadow: '0 0 10px var(--default-accent-colour)',
    opacity: 0.7
  }
}));

const BenefitItem = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: '12px',
  background: 'rgba(42, 45, 58, 0.3)',
  backdropFilter: 'blur(5px)',
  border: '1px solid rgba(191, 181, 147, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(191, 181, 147, 0.1), transparent)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease'
  },
  '&:hover::after': {
    transform: 'translateX(100%)'
  }
}));

const FixedCTAContainer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '20px',
  background: 'linear-gradient(180deg, transparent, rgba(42, 45, 58, 0.9) 40%)',
  backdropFilter: 'blur(10px)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const CTAButton = styled(Button)(({ theme }) => ({
  background: 'var(--default-accent-colour)',
  color: 'var(--default-primary-colour)',
  padding: '12px 32px',
  borderRadius: '30px',
  fontSize: '1.1rem',
  fontWeight: 'bold',
  textTransform: 'none',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateX(-100%) rotate(45deg)',
    transition: 'transform 0.5s ease'
  },
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 0 20px rgba(191, 181, 147, 0.1)',
    '&::before': {
      transform: 'translateX(100%) rotate(45deg)'
    }
  }
}));

// Chart styles with modern look
const chartStyles = {
  background: 'rgba(42, 45, 58, 0.3)',
  borderRadius: '16px',
  padding: '20px',
  border: '1px solid rgba(191, 181, 147, 0.1)',
  backdropFilter: 'blur(5px)'
};

// Enhanced tooltip style
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{
        background: 'rgba(42, 45, 58, 0.95)',
        border: '1px solid rgba(191, 181, 147, 0.2)',
        borderRadius: '8px',
        padding: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(10px)'
      }}>
        <Typography sx={{ color: 'var(--default-accent-colour)', mb: 1, fontWeight: 'bold' }}>
          {label}
        </Typography>
        {payload.map((entry, index) => (
          <Typography key={index} sx={{ 
            color: 'var(--text-colour)',
            fontSize: '0.9rem',
            opacity: 0.9
          }}>
            {entry.name}: {entry.value}%
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};

// Sample data for the chart
const projectedReturns = [
  { year: '2024', returns: 15, expectedGrowth: 20 },
  { year: '2025', returns: 25, expectedGrowth: 35 },
  { year: '2026', returns: 40, expectedGrowth: 55 },
  { year: '2027', returns: 60, expectedGrowth: 80 },
  { year: '2028', returns: 85, expectedGrowth: 100 }
];

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
};

const Invest = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4, pb: 10 }}>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Introduction Section */}
        <motion.div variants={itemVariants}>
          <StyledPaper elevation={0}>
            <Typography variant="h4" gutterBottom sx={{ 
              color: 'var(--default-accent-colour)',
              fontWeight: 'bold',
              mb: 3,
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: '40%',
                height: '2px',
                background: 'var(--default-accent-colour)',
                boxShadow: '0 0 10px var(--default-accent-colour)'
              }
            }}>
              Investment Overview
            </Typography>
            <Typography variant="body1" sx={{ 
              color: 'var(--text-colour)', 
              opacity: 0.9,
              fontSize: '1rem',
              lineHeight: 1.2
            }} paragraph>
              Join us in shaping the future of technology. Our innovative approach and strong market position
              make this an exceptional opportunity for visionary investors.
            </Typography>
          </StyledPaper>
        </motion.div>

        {/* Projected Returns Chart */}
        <motion.div variants={itemVariants}>
          <StyledPaper elevation={0}>
            <SectionHeader variant="h5">Projected Returns</SectionHeader>
            <Box sx={{ ...chartStyles, width: '100%', height: 400 }}>
              <ResponsiveContainer>
                <BarChart data={projectedReturns}>
                  <CartesianGrid 
                    strokeDasharray="3 3" 
                    stroke="rgba(191, 181, 147, 0.1)"
                    vertical={false}
                  />
                  <XAxis 
                    dataKey="year" 
                    stroke="var(--text-colour)"
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis 
                    stroke="var(--text-colour)"
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar 
                    dataKey="returns" 
                    fill="var(--default-accent-colour)"
                    radius={[8, 8, 0, 0]}
                    fillOpacity={0.8}
                  />
                  <Bar 
                    dataKey="expectedGrowth" 
                    fill="var(--default-accent-colour-faded)"
                    radius={[8, 8, 0, 0]}
                    fillOpacity={0.4}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </StyledPaper>
        </motion.div>

        {/* Benefits Section */}
        <motion.div variants={itemVariants}>
          <StyledPaper elevation={0}>
            <SectionHeader variant="h5">Key Benefits</SectionHeader>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <BenefitItem
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Typography variant="body1" sx={{ color: 'var(--text-colour)' }}>
                    • Early Investor Privileges & Premium Access
                  </Typography>
                </BenefitItem>
                <BenefitItem
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Typography variant="body1" sx={{ color: 'var(--text-colour)' }}>
                    • Real-time Performance Analytics
                  </Typography>
                </BenefitItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <BenefitItem
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Typography variant="body1" sx={{ color: 'var(--text-colour)' }}>
                    • Priority Access to Future Investment Rounds
                  </Typography>
                </BenefitItem>
                <BenefitItem
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Typography variant="body1" sx={{ color: 'var(--text-colour)' }}>
                    • Exclusive Tech Preview Events
                  </Typography>
                </BenefitItem>
              </Grid>
            </Grid>
          </StyledPaper>
        </motion.div>
      </motion.div>

      {/* Fixed CTA Button */}
      <FixedCTAContainer>
        <CTAButton
          variant="contained"
          size="large"
          component={motion.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {/* Handle investment proceed action */}}
          style={{
            borderRadius: 'var(--border-radius-default)',
            boxShadow: 'inset 0 4px 20px rgba(0, 0, 0, 0.4)',
            color: 'white'
          }}
        >
          Submit Investment Request
        </CTAButton>
      </FixedCTAContainer>
    </Container>
  );
};

export default Invest;