import React from 'react';
import styled from "styled-components";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {useForm} from "react-hook-form";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {useMutation} from "@tanstack/react-query";
import {makeRequest} from "../../../services/axios.js";
import {toast} from "react-toastify";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import {Paragraph} from "../../../components/ui/typography/index.js";

const Form = styled.form`

`

const PageContainer = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    width: 100%;
`
const GuestlistForm = ({ guestlist }) => {

    const { closeDrawer } = useDrawer();

    if(!guestlist) return ;

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const { showErrorToast, reportError } = useHandleError();

    const mutation = useMutation({
        mutationFn: (data) => makeRequest('POST', `guestlist/1`, data, null),
        onSuccess: () => {
            toast.success('You have been added to the guestlist')
            closeDrawer();
        },
        onError: error => {
            console.log('Error in Guestlist form mutation', error);
            showErrorToast(error)

        }
    })

    const onSubmit = (data) => {

        try {

            let formData = new FormData();

            Object.entries(data).forEach(([key, value]) => {
                if (value !== null && value !== '') {
                    formData.append(key, value);
                }
            });

            formData.append('guestlistId', guestlist.id);

            const entries = Object.fromEntries(formData)
            mutation.mutate(entries)

        } catch (e) {

            reportError('Error on guest list submission')
        }


    }

    return (
        <PageContainer>
            <Paragraph>{guestlist.description}</Paragraph>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <input {...register("first_name")} required={true} name={'first_name'} autoComplete={'given-name'}
                       type={'text'}
                       placeholder={'First Name'}/>
                <input {...register("last_name")} required={true} name={'last_name'} autoComplete={'family-name'}
                       type={'text'}
                       placeholder={'Last Name'}/>
                <input {...register("email")} name={'email'} required={true} autoComplete={'email'} type={'email'}
                       placeholder={'Email'}/>
                <input {...register("quantity")} name={'quantity'} required={true}  type={'number'}
                       placeholder={'Number of People'}/>

                <ButtonComponent variant={'solid'} text={'Add Me To Guestlist'} isLoading={mutation.isLoading}/>

            </Form>
        </PageContainer>
    );
};

export default GuestlistForm;