/**
 * PrimaryEventPage component
 * @description Main event page displaying event details, lineup, and tickets
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavigationProvider } from '../../../../contexts/NavigationContext';
import { DrawerProvider } from '../../../../js/hooks/context/DrawerContext';
import { EcommerceProvider } from '../../../../features/ecommerce/js/context/EcommerceContext';
import FloatingNav from '../../../../atomic/molecules/FloatingNav/FloatingNav';
import PrimaryEventHero from "../../sections/PrimaryEventHero/index.js";
import ArtistLineup from "../../../../atomic/sections/ArtistLineup/ArtistLineup.jsx";
import LocationSection from "../../../../atomic/sections/LocationSection/LocationSection.jsx";
import ProductListSection from "../../../ecommerce/products/sections/ProductListSection/index.js";
import {PRODUCT_TICKET} from "../../../../features/events/js/constants.js";
import PrimaryEventInfoSection from '../../sections/PrimaryEventInfoSection/PrimaryEventInfoSection.jsx';
import AnimatedElement from '../../../../atomic/atoms/AnimatedElement/AnimatedElement.jsx';
import Image from '../../../../atomic/atoms/Image/Image.jsx';
import { Footer } from '../../../../layout/components/Footer.jsx';

// Mock artist data
const mockArtists = [
  // {
  //   id: 'a1',
  //   name: 'Carl Cox',
  //   role: 'Headliner',
  //   image: 'https://fastly.picsum.photos/id/64/400/400.jpg?hmac=qXjF0uPBEh3VJVEpNZKx3jXgEMXZL7nXf9gW6X_JRVI',
  //   startTime: '23:00',
  //   endTime: '01:00',
  //   stage: 'Main Room'
  // },
  // {
  //   id: 'a2',
  //   name: 'Charlotte de Witte',
  //   role: 'Headliner',
  //   image: 'https://fastly.picsum.photos/id/65/400/400.jpg?hmac=bMhzxwUqvz9wXHJy3wvyP9tKGADUBt_3Jy2Zc3Xz5Hk',
  //   startTime: '01:00',
  //   endTime: '03:00',
  //   stage: 'Main Room'
  // },
  // {
  //   id: 'a3',
  //   name: 'Adam Beyer',
  //   role: 'Special Guest',
  //   image: 'https://fastly.picsum.photos/id/66/400/400.jpg?hmac=bMhzxwUqvz9wXHJy3wvyP9tKGADUBt_3Jy2Zc3Xz5Hk',
  //   startTime: '21:00',
  //   endTime: '23:00',
  //   stage: 'Main Room'
  // },
  // {
  //   id: 'a4',
  //   name: 'Amelie Lens',
  //   role: 'Special Guest',
  //   image: 'https://fastly.picsum.photos/id/67/400/400.jpg?hmac=bMhzxwUqvz9wXHJy3wvyP9tKGADUBt_3Jy2Zc3Xz5Hk',
  //   startTime: '03:00',
  //   endTime: '05:00',
  //   stage: 'Main Room'
  // },
];

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--primary-colour);
  position: relative;
  isolation: isolate;
`;

const MainContent = styled.div`
  position: relative;
  z-index: 1;
`;

const FloatingNavWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

/**
 * PrimaryEventPage component
 * @param {Object} props - Component props
 * @param {Object} props.event - Event object containing all event details
 * 
 */

  // Extract event info for the info section
 

const PrimaryEventPage = ({ event }) => {

  console.log('PrimaryEventPage event:', event.productCategories);

  const eventInfo = {
    location: event?.location?.name,
    time: event?.dates?.startTime,
    date: event?.dates?.startDate,
    dressCode: event?.dress_code,
    shortDescription: event?.short_description,
    image: event?.image?.cdnUrl
  };

    // Base delay for the first animation
    const baseDelay = 0.2;
    // Additional delay for each subsequent item
    const staggerDelay = 0.15;

    console.log('eventInfo', eventInfo);
  return (
    <NavigationProvider>
      <DrawerProvider>
        <EcommerceProvider>
          <PageContainer>
            <MainContent>
              <PrimaryEventHero
                id="hero"
                backgroundImage={eventInfo.image}
                eventImage={eventInfo.image}
                title={event?.name}
                date={event?.date}
                organiser={event?.business?.name}
                description={eventInfo.shortDescription}
              />

              <PrimaryEventInfoSection
                id="info"
                location={eventInfo.location}
                time={eventInfo.time}
                date={eventInfo.date}
                description={eventInfo.shortDescription}
                dressCode={eventInfo.dressCode}
                image={eventInfo.image}
              />

              <ArtistLineup
                id="lineup"
                artists={mockArtists}
              />

              <AnimatedElement delay={0.4} duration={1} threshold={0.15}>
                <LocationSection
                  id="location"
                  name={event?.location?.name}
                  // description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi tincidunt ac sed aliquam proin non tortor integer malesuada. '}
                  image={event?.location?.image?.cdnUrl}
                  address={event?.location}
                  mapUrl={event?.location?.map_url}
                />
              </AnimatedElement>

              <AnimatedElement delay={0.4} duration={1} threshold={0.15}>
                <ProductListSection
                  id="tickets"
                  productTypes={event.productCategories || []}
                  productsByType={event?.products || {}}
                  event={event}
                />
              </AnimatedElement>
            </MainContent>

            {/* <FloatingNavWrapper>
              <FloatingNav 
                configId="event"
                position="right"
                vertical={false}
                useLiquidEffect={true}
                asymRadius="xxl"
              />
            </FloatingNavWrapper> */}
          </PageContainer>
          {/* <Footer /> */}
        </EcommerceProvider>
      </DrawerProvider>
    </NavigationProvider>
  );
};

PrimaryEventPage.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
    hero_image: PropTypes.string,
    organiser: PropTypes.string,
    location: PropTypes.object,
    address: PropTypes.string,
    map_url: PropTypes.string,
    product_types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    products_by_type: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
        })
      )
    ),
  }),
};

export default PrimaryEventPage;
