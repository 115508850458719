import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { timings, easings } from '../../tokens/animations';

const SVGContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
`;

const StyledSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const LiquidPath = styled.path`
  fill: var(--accent-colour);
  opacity: 0.08;
  transform-origin: center;
  filter: url(#liquid-filter);
`;

const createBlobPath = (x, y, width, height, progress = 0) => {
  const radius = Math.min(width, height) / 2;
  
  // Base circle when not moving
  if (progress === 0) {
    return `
      M ${x + width/2},${y + height/2 - radius}
      A ${radius},${radius} 0 1,1 ${x + width/2},${y + height/2 + radius}
      A ${radius},${radius} 0 1,1 ${x + width/2},${y + height/2 - radius}
    `;
  }

  // Calculate control points for liquid effect
  const stretchFactor = 1 + progress * 0.5;
  const waveFactor = Math.sin(progress * Math.PI) * radius * 0.8;
  
  // Create wavy path during transition
  return `
    M ${x + width/2},${y + height/2 - radius}
    C ${x + width/2 + waveFactor},${y + height/2 - radius * stretchFactor}
      ${x + width/2 + waveFactor},${y + height/2 + radius * stretchFactor}
      ${x + width/2},${y + height/2 + radius}
    C ${x + width/2 - waveFactor},${y + height/2 + radius * stretchFactor}
      ${x + width/2 - waveFactor},${y + height/2 - radius * stretchFactor}
      ${x + width/2},${y + height/2 - radius}
  `;
};

const LiquidBackground = ({ targetRef, isActive }) => {
  const svgRef = useRef(null);
  const pathRef = useRef(null);
  const animationRef = useRef(null);
  const previousPosition = useRef(null);

  useEffect(() => {
    if (!targetRef?.current || !svgRef.current || !pathRef.current || !isActive) return;

    const updatePosition = () => {
      const target = targetRef.current;
      const svg = svgRef.current;
      const path = pathRef.current;
      
      if (!target || !svg || !path) return;

      const targetRect = target.getBoundingClientRect();
      const parentRect = svg.parentElement.getBoundingClientRect();

      // Calculate position relative to SVG container
      const x = targetRect.left - parentRect.left;
      const y = targetRect.top - parentRect.top;
      const width = targetRect.width;
      const height = targetRect.height;

      // If we have a previous position, animate between positions
      if (previousPosition.current) {
        const { x: prevX, y: prevY, width: prevWidth, height: prevHeight } = previousPosition.current;
        
        // Cancel any existing animation
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }

        let startTime = null;
        const duration = parseFloat(timings.interaction) * 1000;

        const animate = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const progress = Math.min((timestamp - startTime) / duration, 1);
          
          // Use custom easing
          const eased = progress < 0.5 
            ? 4 * progress * progress * progress 
            : 1 - Math.pow(-2 * progress + 2, 3) / 2;

          // Interpolate between positions
          const currentX = prevX + (x - prevX) * eased;
          const currentY = prevY + (y - prevY) * eased;
          const currentWidth = prevWidth + (width - prevWidth) * eased;
          const currentHeight = prevHeight + (height - prevHeight) * eased;

          const newPath = createBlobPath(
            currentX,
            currentY,
            currentWidth,
            currentHeight,
            Math.sin(progress * Math.PI) // Sine wave for smooth morphing
          );
          
          path.setAttribute('d', newPath);

          if (progress < 1) {
            animationRef.current = requestAnimationFrame(animate);
          }
        };

        animationRef.current = requestAnimationFrame(animate);
      } else {
        // First render, just set position
        const initialPath = createBlobPath(x, y, width, height);
        path.setAttribute('d', initialPath);
      }

      // Store current position for next update
      previousPosition.current = { x, y, width, height };
    };

    updatePosition();

    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(targetRef.current);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [targetRef, isActive]);

  return (
    <SVGContainer>
      <StyledSVG ref={svgRef}>
        <defs>
          <filter id="liquid-filter">
            <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0
                      0 1 0 0 0
                      0 0 1 0 0
                      0 0 0 20 -10"
            />
          </filter>
        </defs>
        <LiquidPath
          ref={pathRef}
          d=""
          style={{ 
            opacity: isActive ? 0.08 : 0,
            transition: `opacity ${timings.interaction} ${easings.smooth}`
          }}
        />
      </StyledSVG>
    </SVGContainer>
  );
};

export default LiquidBackground;
