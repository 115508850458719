import React, { createContext, useState, useContext } from "react";
import { API_URL, SetLocalStorageJSON, getLocalStorageJSON, setSessionStorageJSON } from "../../../../js/Helper";
import { useApiQuery } from "../../../../hooks/customHooks";
import { createMembership } from "../../memberships";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDrawer } from "../../../../js/hooks/context/DrawerContext";
import { updateTheme } from "../../../themes/js/utils";

// Initial state
const initialState = {
  card: null,
  tiers: [],
  member: null,
  rewards: [],
};

// Create context
export const CardContext = createContext();

// Create provider
export const CardProvider = ({ children }) => {
  const [card, setCard] = useState(() => {
    const storedTier = getLocalStorageJSON('card')
    return storedTier ? storedTier : {}
  });
  const [tiers, setTiers] = useState([]);
  
  const [selectedTier, setSelectedTier] = useState();
  const [member, setMember] = useState(null);
  const [rewards, setRewards] = useState([]);
  
  /**
   * --------------------
   * HOOKS 
   * --------------------
  */
 
 const navigate = useNavigate();


  
  /**
   * ---------------------
   * CARD FUNCTIONS
   * ----------------------
   */



  const selectCard = (newCard) => {
    setCard(newCard);
  };




  /**
   * ---------------------
   * TIER FUNCTIONS
   * ----------------------
   */

  const changeTiers = (newTiers) => {
    setTiers(newTiers);
  };


  /**
   * ---------------------
   * MEMBER FUNCTIONS
   * ----------------------
   */



  const setTier = (tier) => {
    // Some logic to update your backend
    setSelectedTier(tier);
  };

  const updateMember = (newMember) => {
    setMember(newMember);
  };

  const setRewardsData = (newRewards) => {
    setRewards(newRewards);
  };

  const fetchCardBySlug = (slug) => {
    const { data, isLoading, isError } = useApiQuery(`${API_URL}/card/${slug}`,
      data => {
        // updateTheme(data.card.default_tier.theme)
        setCard(data.card)
        setSelectedTier(data.card.default_tier)
      },
      error => console.error(error.message)
    );

    return { data, isLoading, isError };
  };

  return (
    <CardContext.Provider
    value={{
      card,
      setCard,
      member,
        setMember,
      rewards,
      selectCard,
      updateMember,
      setRewardsData,
      fetchCardBySlug,
      tiers,
      changeTiers,
      selectedTier,
      setSelectedTier,
    }}>
      {children}
    </CardContext.Provider>
  );
};

// Custom hook to use this context
export const useCard = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error("useCard must be used within a LoyaltyCardProvider");
  }
  return context;
};
