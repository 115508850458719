import styled from "styled-components";

export const PillContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--featured-section-bg-colour);
    background: var(--accent-colour);
    flex-grow: 1;
    padding: 5px 15px;
    border-radius:var(--border-radius-small);
    color: var(--text-colour);

    text-transform: capitalize;
    

`