import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacing,
  textStyles,
} from '../../tokens/typography';

/**
 * Base text styles that will be shared across all text variants
 */
const baseStyles = css`
  margin: 0;
  font-family: var(--font-tommy);
  color: ${props => props.$color || 'var(--text-primary)'};
  text-align: ${props => props.$align || 'left'};
  text-transform: ${props => props.$transform || 'none'};
  text-decoration: ${props => props.$decoration || 'none'};
  
  /* Optional truncation */
  ${props => props.$truncate && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  /* Optional line clamp */
  ${props => props.$lines && css`
    display: -webkit-box;
    -webkit-line-clamp: ${props.$lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`;

/**
 * StyledText component with all possible style variations
 */
const StyledText = styled.span`
  ${baseStyles}
  
  /* Apply predefined style if variant is specified */
  ${props => props.$variant && css`
    font-size: ${textStyles[props.$variant].fontSize};
    font-weight: ${textStyles[props.$variant].fontWeight};
    line-height: ${textStyles[props.$variant].lineHeight};
    letter-spacing: ${textStyles[props.$variant].letterSpacing};
  `}
  
  /* Override with specific props if provided */
  ${props => props.$size && css`font-size: ${fontSizes[props.$size]};`}
  ${props => props.$weight && css`font-weight: ${fontWeights[props.$weight]};`}
  ${props => props.$lineHeight && css`line-height: ${lineHeights[props.$lineHeight]};`}
  ${props => props.$spacing && css`letter-spacing: ${letterSpacing[props.$spacing]};`}
`;

/**
 * Text component for rendering text content with various style options
 * 
 * @param {Object} props - Component props
 * @param {string} [props.variant] - Predefined text style variant
 * @param {string} [props.as] - HTML element to render as
 * @param {string} [props.size] - Font size
 * @param {string} [props.weight] - Font weight
 * @param {string} [props.color] - Text color (CSS variable or direct color value)
 * @param {string} [props.align] - Text alignment
 * @param {string} [props.transform] - Text transform
 * @param {string} [props.decoration] - Text decoration
 * @param {string} [props.lineHeight] - Line height
 * @param {string} [props.spacing] - Letter spacing
 * @param {boolean} [props.truncate] - Enable text truncation
 * @param {number} [props.lines] - Number of lines to show before truncating
 * @param {string} [props.className] - Additional CSS class
 */
const Text = ({ 
  children,
  variant,
  as,
  size,
  weight,
  color,
  align,
  transform,
  decoration,
  lineHeight,
  spacing,
  truncate,
  lines,
  className,
  ...props
}) => {
  // Determine the HTML element based on variant or 'as' prop
  const element = as || (variant?.startsWith('h') ? variant : 'span');
  
  return (
    <StyledText
      as={element}
      $variant={variant}
      $size={size}
      $weight={weight}
      $color={color}
      $align={align}
      $transform={transform}
      $decoration={decoration}
      $lineHeight={lineHeight}
      $spacing={spacing}
      $truncate={truncate}
      $lines={lines}
      className={className}
      {...props}
    >
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body', 'bodySmall', 'caption']),
  as: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(fontSizes)),
  weight: PropTypes.oneOf(Object.keys(fontWeights)),
  color: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  transform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'none']),
  decoration: PropTypes.oneOf(['underline', 'line-through', 'none']),
  lineHeight: PropTypes.oneOf(Object.keys(lineHeights)),
  spacing: PropTypes.oneOf(Object.keys(letterSpacing)),
  truncate: PropTypes.bool,
  lines: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Text;
