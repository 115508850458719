import {useApiQuery} from "../../../../hooks/customHooks.js";
import {useState} from "react";

export const useProposal = () => {
    const [proposal, setProposal] = useState(null);

    const getProposal = (id) => {
        try {
            return useApiQuery(`proposal/${id}`, setProposal, null, null);
        } catch (error) {
            console.error('Error fetching proposal:', error);
        }
    }


    return {
        proposal,
        setProposal,
        getProposal
    }

}