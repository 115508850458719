import React from 'react';
import styled from 'styled-components';
import { ButtonComponent } from './buttons';

const StyledButton = styled(ButtonComponent)`
  // Add any additional styles specific to the modal trigger button
`;

const ModalTriggerButton = ({ onClick, text, ...props }) => {
  return (
    <StyledButton
      variant="solid"
      onClick={onClick}
      text={text}
      {...props}
    />
  );
};

export default ModalTriggerButton;
