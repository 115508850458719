import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import {object} from 'prop-types'
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {useEcommerce} from "../../js/context/EcommerceContext";
import {LOADING_STATE} from "../../js/hooks/useEcommerceLoadingState";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import Basket from "../../basket/index.js";
import {useEvent} from "../../../events/js/hooks/index.js";
import {useProduct} from "../hooks/index.js";
import {Addons} from "./index.js";
import {StandardSection} from "../../../../layout/sections/StandardSection.jsx";
import {HowManyPeople} from "../../components/HowManyPeople/HowManyPeople.jsx";
import {VideoComponent} from "../../../../components/ui/VideoComponent.jsx";
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {toast} from 'react-toastify';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100dvh;
    max-width: 100vw;
    overflow: hidden;
`

const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto;
    padding-bottom: 120px;
    -webkit-overflow-scrolling: touch;
`

const FixedButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    padding: 60px 16px 16px;
    background: linear-gradient(to top, var(--primary-colour), transparent 100%);
    z-index: 100;
`

const ProductDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--text-colour);
    padding-bottom: 20px;
    text-align: left;
    
    h3 {
        text-align: center;
        color: var(--accent-colour);
        background: var(--accent-colour);
        font-size: 1.2rem;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const DepositContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;

`

export const Product = ({ product, callToAction }) => {
    if (!product) {
        console.error('Product component received null/undefined product');
        return <div>Error: Product not found</div>;
    }

    const [selectedAddons, setSelectedAddons] = useState([]);
    const { event } = useEvent();
    const { addToBasket, loadingState } = useEcommerce();
    const { closeDrawer, openDrawer } = useDrawer();
    const { addDependenciesToBasket } = useProduct();
    const { showErrorToast } = useHandleError();

    // Reset loading state when product changes or component mounts
    useEffect(() => {
        if (loadingState.state !== LOADING_STATE.LOADING) {
            loadingState.reset();
        }
    }, [product.id]); // Only reset when product changes

    console.log('loadingState', loadingState)
    const [paymentOption, setPaymentOption] = useState('full');
    const [numberOfPeople, setNumberOfPeople] = useState(product.min_quantity || 1);

    const hasCatalogue = product?.catalogues?.length > 0;

    useEffect(() => {
        try {
            handleNumberOfPeople();
        } catch (err) {
            console.error('Error updating number of people:', err);
            showErrorToast('Error updating quantity');
        }
    }, [numberOfPeople]);

    const openBasket = () => {
        try {
            openDrawer(<Basket event={event} />, 'Basket', innerWidth > 768 ? 'right' : 'bottom');
        } catch (err) {
            console.error('Error opening basket:', err);
            showErrorToast('Unable to open basket');
        }
    };

    const handleAddonToggle = (addon) => {
        setSelectedAddons(prev => {
            const exists = prev.find(a => a.id === addon.id);
            if (exists) {
                return prev.filter(a => a.id !== addon.id);
            } else {
                const newAddon = {
                    ...addon,
                    quantity: numberOfPeople,
                    parentProductId: product.id
                };
                return [...prev, newAddon];
            }
        });
    };

    const handleCallToAction = async () => {
        try {
            if (callToAction?.function === 'addToBasket') {
                // Preserve existing validation
                if (!event?.id) {
                    showErrorToast('Please select an event first');
                    return;
                }

                if (!product?.id) {
                    showErrorToast('Invalid product configuration');
                    return;
                }

                await addToBasket(product, numberOfPeople, selectedAddons);
                
                // Only show success toast if not in error state
                if (loadingState.state !== 'ERROR') {
                    toast.success('Added to basket!', {
                        position: "top-right",
                        autoClose: 3000
                    });
                }
            } else {
                // Preserve existing custom function handling
                callToAction?.function();
            }
        } catch (error) {
            // Error toast will be handled by the loading state error handler
            console.error('Action failed:', error);
        }
    };

    const handleNumberOfPeople = () => {
        if (!product || typeof numberOfPeople !== 'number') {
            console.warn('Invalid input for price calculation');
            return;
        }
        product.sub_total = product.price * numberOfPeople;
    };

    const handleAddToBasket = async () => {
        try {
            // Validate event
            // if (!event?.id) {
            //     showErrorToast('Please select an event first');
            //     return;
            // }

            if (!product?.id) {
                showErrorToast('Invalid product configuration');
                return;
            }

            // Create a copy of the product with its selected addons
            const productWithAddons = {
                ...product,
                quantity: numberOfPeople,
                paymentOption,
                addons: selectedAddons
            };

            // Add to basket
            await addToBasket(productWithAddons, numberOfPeople);
            await addDependenciesToBasket(product, numberOfPeople);
            
            // Only show success toast if not in error state
            if (loadingState.state !== 'ERROR') {
                closeDrawer();
                openBasket();
            }
        } catch (error) {
            console.error('Error adding product to basket:', error);
            // Error toast will be handled by the loading state error handler
        }
    };

    const callToActionText = callToAction?.text ?? 'Add To Basket';
    const callToActionFn = callToAction?.function ?? handleAddToBasket;

    // Add debug logging
    useEffect(() => {
        console.log('Loading State:', {
            state: loadingState.state,
            metadata: loadingState.metadata,
            productId: product.id
        });
    }, [loadingState, product.id]);

    return (
        <Container>
            <ScrollableContent>
                <StandardSection style={{maxWidth: '100%'}}>
                    {product.file_links && product.file_links.find(file => file.file.category === 'videos') && <VideoComponent data={product.file_links.find(file => file.file.category === 'videos').file} />}

                    {product.description && (
                        <ProductDescription
                            dangerouslySetInnerHTML={{ __html: product.description }}
                        />
                    )}

                    {product.category === 'tableBooking' && (
                        <HowManyPeople 
                            product={product} 
                            numberOfPeople={numberOfPeople} 
                            setNumberOfPeople={setNumberOfPeople} 
                            style={{marginBottom: '20px'}} 
                        />
                    )}
                </StandardSection>

                {/* Only render the Addons section if the product has the addons property */}
                {product.addons && (
                    <StandardSection>
                        <Addons 
                            product={product}
                            selectedAddons={selectedAddons}
                            onAddonToggle={handleAddonToggle}
                            numberOfPeople={numberOfPeople}
                        />
                    </StandardSection>
                )}
            </ScrollableContent>

            <FixedButtonContainer>
                <ButtonComponent
                    variant={'solid'}
                    text={callToActionText}
                    onClick={callToAction?.function ?? handleAddToBasket}
                    isLoading={loadingState.state === LOADING_STATE.LOADING && 
                              loadingState.metadata.productId === product.id}
                    isLoadingText={'Adding to basket...'}
                    isDisabled={loadingState.state === LOADING_STATE.LOADING}
                    style={{
                        width: '100%',
                        height: '40px'
                    }}
                />
            </FixedButtonContainer>
        </Container>
    );
};

Product.propTypes = {
    product: object
}