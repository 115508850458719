import React, { useEffect } from 'react'
import styled from 'styled-components'
import { InstructionTitle } from './typography/InstructionTitle'
import { Select } from '../forms/Select'
import { getAreas, getCities } from '../../api/cities'
import { makeRequest } from '../../services/axios'
import { getLocalStorageJSON } from '../../js/Helper'

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    min-height: 150px;
    min-width: var(--small);
    width: 100%;
`

const SearchBox = (props) => {
    
    const { handleCityChange, handleAreaChange, handleVibeChange } = props;
    
    const city = getLocalStorageJSON('city');
    const area = getLocalStorageJSON('area');

    const cities =  getCities();
    const areas = city && getAreas(city.value);


  return (
    <SearchContainer>
        {
          handleCityChange && 
          <>
          <InstructionTitle title='Which city to search?' />
          <Select options={cities} placeholder={city && city.label} passChangeToParent={handleCityChange} />
          </>
        }

        {
          handleAreaChange && city &&
          <>
          <InstructionTitle title='Which area to search?' />
          <Select options={areas} placeholder={area && area.label} passChangeToParent={handleAreaChange} />
          </>
        }

        {
          handleVibeChange && 
          <>
          <InstructionTitle title='What vibe are you feeling?' />
          <Select options={cities} passChangeToParent={handleVibeChange} />
          </>
        }

    </SearchContainer>
  )
}

export default SearchBox