import axios from "axios";
import { API_URL } from "../js/Helper";
import { toast } from "react-toastify";

const makeRequest = async (method, url, data, headers, contentType = null) => {
  try {
    // if the url does begins with a forward slash, do nothing
    // if it does not have a forward slash then prepend the CMS_API_URL
    let endpoint = url[0] == '/' ? `${url}` : `${API_URL}/${url}`;

    // if the url does not have a forward slash
    // and the url does not include http
    // then add the forward slash
    if (!url[0] === ('/') && !url.includes('http')) endpoint = '/' + endpoint;

    // if the url includes http then we keep it as it is
    if (url.includes('http')) endpoint = url;

    // Get session from localStorage
    const session = localStorage.getItem('nocturnal_session');
    const sessionData = session ? JSON.parse(session) : null;
    
    // Get anonymous ID even if no session exists
    const anonymousId = localStorage.getItem('anonymous_id');
    
    // Prepare session headers
    const sessionHeaders = {
      ...(sessionData ? {
        'X-Session-ID': sessionData.sessionId,
        'X-Last-Active': sessionData.lastActive,
        ...(sessionData.isAuthenticated ? { 'X-User-ID': sessionData.userId } : {}),
      } : {}),
      ...(anonymousId ? { 'X-Anonymous-ID': anonymousId } : {}),
    };

    const response = await axios({
      method: method,
      url: endpoint,
      data: data,
      headers: {
        AccessControlAllowOrigin: '*',
        'Content-Type': contentType != null ? contentType : 'application/json',
        'ngrok-skip-browser-warning': true,
        'Accept': 'application/json',
        ...sessionHeaders,
        ...headers,
      },
    });

    // Check if the response status is not successful
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.data.message || 'Something went wrong');
    }

    return response.data;
  } catch (error) {
    console.error('Error making request', error);
    throw error;
  }
};

export { makeRequest };
