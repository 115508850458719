import { useParams } from "react-router-dom"
import { Buffer } from 'buffer';
import { useState } from "react";

export const useUrlParams = () => {
   return  useParams();
}

export const ConvertBase64IdFromParams = (value) => {

   if(!value) {
      console.warn('tried to convert a null or undefined value');
      return;
   }
   
   return Buffer.from(value, 'base64').toString();
}