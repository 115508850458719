import React, { useState } from 'react';
import Modal from '../../components/ui/Modal';
import ModalTriggerButton from '../../components/ui/ModalTriggerButton';

const YourComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <ModalTriggerButton onClick={openModal} text="Open Feedback Form" />
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {/* Your UserFeedbackForm component would go here */}
        <h2>User Feedback Form</h2>
        {/* Add your form elements here */}
      </Modal>
    </div>
  );
};

export default YourComponent;
