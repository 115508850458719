import styled from "styled-components";
import { FullPageContainer } from "./layoutStyles";

export const FullPageFlexCenter = styled(FullPageContainer)`
    justify-content: space-evenly;
    padding: 20px;
    align-items: center;

    @media screen and (min-width: 768px){
        justify-content: flex-start;
        gap: 20px;
    }
    
`