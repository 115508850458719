import React from 'react';
import styled from 'styled-components';
import Text from '../../atoms/Text';
import Heading from '../../atoms/Heading';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.$gap || '0.5rem'};
`;

const StyledHeader = styled(Text)`
  text-transform: ${props => props.$transform || 'none'};
  opacity: ${props => props.$headerOpacity || 0.8};
`;

/**
 * TextBlock component that combines a header with main text
 * 
 * @param {Object} props - Component props
 * @param {string} props.header - Header text
 * @param {string} props.text - Main text content
 * @param {string} [props.headerSize='sm'] - Size of the header text
 * @param {string} [props.headerWeight='medium'] - Weight of the header text
 * @param {string} [props.headerColor] - Color of the header text
 * @param {string} [props.headerTransform='uppercase'] - Text transform for header
 * @param {number} [props.headerOpacity=0.8] - Opacity of the header text
 * @param {string} [props.textSize='base'] - Size of the main text
 * @param {string} [props.textWeight='regular'] - Weight of the main text
 * @param {string} [props.textColor] - Color of the main text
 * @param {string} [props.gap] - Gap between header and text
 * @param {string} [props.align='left'] - Text alignment
 * @param {number} [props.maxLines] - Maximum lines for the main text
 * @param {boolean} [props.expandable] - Whether the text can be expanded
 */
const TextBlock = ({
  header,
  text,
  headerSize = 'sm',
  headerWeight = 'medium',
  headerColor,
  headerTransform = 'uppercase',
  headerOpacity = 0.8,
  textSize = 'base',
  textWeight = 'regular',
  textColor,
  gap,
  align = 'left',
  maxLines,
  expandable = false,
  className,
  ...props
}) => {
  return (
    <Container $gap={gap} className={className} {...props}>
      <StyledHeader
        size={headerSize}
        weight={headerWeight}
        color={headerColor}
        $transform={headerTransform}
        $headerOpacity={headerOpacity}
        align={align}
      >
        {header}
      </StyledHeader>
      
      {typeof text === 'string' ? (
        <Text
          size={textSize}
          weight={textWeight}
          color={textColor}
          align={align}
          maxLines={maxLines}
          expandable={expandable}
        >
          {text}
        </Text>
      ) : text}
    </Container>
  );
};


export default TextBlock;
