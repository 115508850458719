import React from 'react';
import {DefaultHero} from "../../../components/heroes/index.js";
import {HeroImage} from "../../../components/heroes/HeroImage.jsx";
import {ImpactTitle} from "../../../components/ui/typography/index.js";
import {HeroInfo} from "../../../components/heroes/HeroInfo.jsx";
import {ModernTextBlock, TextBlock1} from "../../../components/ui/sections/components/TypgographySections/index.js";
import {ProposalSection} from "../../../features/proposals/components/sections/proposals.sections.styles.jsx";
import styled from "styled-components";
import {useEventApi} from "../../../features/events/js/hooks/useEventApi.jsx";
import ArtistsSections from "../../../features/events/pages/PrimaryEventPage/components/ArtistsSections.jsx";
import {useEvent} from "../../../features/events/js/hooks/index.js";
import TextBlock2 from "../../../components/ui/sections/components/TypgographySections/TextBlock2.jsx";
import {VideoComponent} from "../../../components/ui/VideoComponent.jsx";
import {useCollection} from "../../../features/collections/js/hooks/useCollection.jsx";
import ProductCollection from "../../../features/collections/components/ProductCollection.jsx";
import WalletPass from "../../../components/ui/wallet/WalletPass.jsx";

const Section = styled(ProposalSection)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const HomeEvents = () => {

    const { getEvent } = useEventApi()
    const { event } = useEvent();
    const { getCollection } = useCollection();

    const { data, refetch, isSuccess, isLoading, error } = getEvent('bmf-late-night-dinner');
    const collection = getCollection(206)
    const productList = collection?.data ? collection.data.data : [];

    console.log('collection', productList)

    if (isLoading || !isSuccess || !event || error) {
        return '';
    }




    return (
        <>
            <DefaultHero image={<HeroImage src={'https://picsum.photos/1350/1350'} />}
                         title={<ImpactTitle header={'Your Brand'}  subHeader={'presents'} main={'Event Name'}  />}
                         info={<HeroInfo data={[{property:'Date', value:'25th May 2022'}, {property:'Time', value:'10:00am'}, {property:'Venue', value:'Event Hall'}]} />}
                         description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit. consectetur adipiscing elit. consectetur adipiscing elit'}
            />


            <Section>
                <ModernTextBlock
                    maxWidth={'100%'}
                    textItems={[
                        { type: 'creative', content: 'The Future' },
                        { type: 'small', content: 'Belongs to the brands who build'},
                        { type: 'creative', content: 'Communities' },
                        { type: 'small', content: 'So what is our role in this future?' },
                        { type: 'creative', content: 'WE BUILD THE BRIDGES' },
                    ]}
                />

            </Section>

            <Section>
                <TextBlock2 title={'Artists'} text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit. consectetur adipiscing elit. consectetur adipiscing elit'} />
                <VideoComponent url={'https://pub-a305029e2a79415898ef080b84d16b39.r2.dev/videos/Nocturnal-Company-Reveal.MP4'} />
                <TextBlock2 align={'right'} title={'Artists'} text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit. consectetur adipiscing elit. consectetur adipiscing elit'} />
            </Section>

            <ArtistsSections artists={event.artists}  />

            <Section>
                <TextBlock1 title={'PRODUCT TYPES'} subtitle={'A DIVERSE SELECTION OF PRODUCT TYPES'} paragraph={'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit. consectetur adipiscing elit. consectetur adipiscing elit'} />
                <ProductCollection data={productList}/>
            </Section>

            <Section>
                <TextBlock2 title={'Your Digital Pass'} text={'Access your event details anytime, anywhere with our digital pass'} />
                <WalletPass
                    eventName={event.name || "Event Name"}
                    date={event.date || "DEC 23"}
                    time={event.time || "8:00 PM"}
                    venue={event.venue || "Venue Name"}
                    ticketType="VIP Access"
                    ticketId="#0123456789"
                    headerImage={event.coverImage || "https://picsum.photos/800/400"}
                    logoImage={event.logo || "https://picsum.photos/100/100"}
                />
            </Section>
        </>
    );
};

export default HomeEvents;