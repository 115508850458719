import axios from "axios";
import { makeRequest } from "../services/axios";
import { API_URL } from "../js/Helper";
import { Loading } from "../components/ui/Loading";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { useApiQuery } from "../hooks/customHooks";

export const getCities =  () =>  {

    // Make the data request
    const { data, isLoading, isError, error } = useApiQuery(`${API_URL}/${'cities'}`,
    data => {
        // data.data.unshift({label:'All Cities', value:0})
    },
    error => console.error(error.message)

    );
            
            
    return data && data.data;
}

export const getAreas =  (cityId) =>  {

    const { data, isLoading, isError } = useApiQuery(`${API_URL}/cities/${cityId}/areas`, 
    data => {
        // data.data.unshift({label:'All Areas', value:0})
    },
    error => console.error(error.message),
        
    );

    if(data) {
        return data.data;
    }
           
}