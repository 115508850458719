
export const HOME_ICON = 'IoHomeOutline'
export const CALENDAR_ICON = 'AiFillCalendar';
export const USER_ICON = 'IoPersonSharp';
export const CARD_ICON = 'IoCardSharp';
export const MENU_ICON = 'ImMenu'
export const REWARD_ICON = 'IoStarSharp';
export const TICKET_ICON = 'IoTicketSharp';
export const SETTINGS_ICON = 'IoSettingsSharp';
export const MUSIC_ICON = 'IoMusicalNoteSharp';
export const INFO_ICON = 'IoInformationCircleSharp';
export const LOCATION_ICON = 'IoLocationSharp';
export const IMAGE_ICON = 'IoImagesSharp';
export const SERVICE_ICON = 'FaServicestack';
export const BACK_ICON = 'IoArrowBackSharp';
