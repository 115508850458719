import {useContext, useCallback} from "react";
import {AuthContext} from "../features/authentication";
import {useQuery} from '@tanstack/react-query'
import {makeRequest} from "../services/axios";
import {useSession} from '../features/session';

export function useApiQuery(path, onSuccess, onError, options) {
  const { header } = useContext(AuthContext);
  const { getSessionHeaders } = useSession();

  if (!path) {
    console.error('Path cannot be null or undefined');
    return {};
  }

  const fetchData = useCallback(async () => {
    console.log('Executing fetchData with path:', path);
    console.log('Fetching data from:', path);
    const response = await makeRequest('GET', path, null, {
      ...header(),
      ...getSessionHeaders(),
    });
    console.log('Response received:', response);
    return response;
  }, [path, header, getSessionHeaders]);

  const defaultOptions = {
    gcTime: 1000 * 60 * 30, // 30 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: true,
    retry: 3, // Retry up to 3 times
    staleTime: 1000 * 60 * 5, // 5 minutes
    // select: onSuccess
  };

  const handleSuccess = useCallback((data) => {
    console.log('Query success handler called with:', data);
    if (onSuccess) onSuccess(data);
  }, [onSuccess]);

  const handleError = useCallback((error) => {
    console.log('Query error handler called with:', error);
    if (onError) onError(error);
  }, [onError]);

  console.log('Configuring useQuery with:', {
    queryKey: [path],
    queryFn: fetchData,
    onSuccess: handleSuccess,
    onError: handleError,
    ...defaultOptions,
    ...options
  });

  const { data, isLoading, error, refetch, isSuccess } = useQuery({
    queryKey: [path],
    queryFn: fetchData,
    onSuccess: handleSuccess,
    // onError: handleError,
    ...defaultOptions,
    ...options, // Ensure custom options override defaults
  });

  return { data, isLoading, error, refetch, isSuccess };
}
