import React from 'react'
import styled from 'styled-components'


    const InfoContainer = styled.div`
        display: grid;
        align-items: center;
        justify-items: center;
        align-self: center;
        gap: 10px;
        width: 100%;
        height: fit-content;
        border-radius: var(--border-radius-default);
        margin-top: 10px;
        color: var(--text-colour);
        
        `;


const InfoItem = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        padding: 10px;
        max-height: 40px;

        border-radius: var(--border-radius-default);
        backdrop-filter: blur(10px);
        background-color: rgba(0,0,0,0.1);

        @media screen and (min-width: 768px) {
            max-width: 25rem;
        }
        
        @media screen and (min-width: 1920px) {
            max-width: 30rem;
            padding: 1rem;
            font-size: 1.5rem;
        }
        
    `


export const HeroInfo = (props) => {
    
    const { data } = props


  return (
    <InfoContainer>
    {
        data && data.length > 0 && data.map((item, index) => {
            return (
                <InfoItem key={`${item.property}-${index}`}>
                    {item.property && (<p>{item?.property}</p>)}{item.value}
                </InfoItem>
            )
        })
    }

    </InfoContainer>
  )
}
