import React, {useContext} from 'react';
import styled from 'styled-components';
import {useForm} from "react-hook-form";
import {Form} from "../../../components/forms/formStyles.js";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {Divider} from "../../../components/ui/Divider.jsx";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {API_URL} from "../../../js/Helper.js";
import {AuthContext} from "../AuthContext.jsx";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {toast} from "react-toastify";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import PasswordResetForm from "./PasswordResetForm.jsx";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    width: 100%;
    padding: 20px 25px;
`

const UserDetailsForm = () => {

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const { header, user, setNewUser, logout } = useContext(AuthContext)
    const { showErrorToast } = useHandleError();
    const { openDrawer } = useDrawer();

    const updateUserMutation = useMutation({

        mutationFn: (data) => axios.put(`${API_URL}/user/update`, data, {
            headers: header()
        }),
        onSuccess: (response) => {

            if(response.data.success === true) {
                setNewUser(response.data.data)
                toast.success('Details updated successfully!')
            }
        },
        onError: (error) => {
            console.log(error)
            showErrorToast(error?.response?.data?.message || error.message)
        }
    })

    const onSubmit = (data) => {

        let formData = new FormData();
        console.log(data)

        Object.entries(data).forEach(([key, value]) => {
            if (value !== null && value !== '') {
                formData.append(key, value);
            }
        });


        const entries = Object.fromEntries(formData)

        console.log(entries)

        updateUserMutation.mutate(entries)

    }

    if(!user) return;

    return (
        <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <input {...register("first_name")}  autoComplete={'name'} name="first_name" type="text" placeholder={user.first_name || "First Name"}  />
                    <input {...register("last_name")} autoComplete={'family-name'} name="last_name" type="text" placeholder={user.last_name || "Last Name"} />
                    {/*<Divider/>*/}
                    <input {...register("email")} autoComplete={'email'} name="email" type="email" placeholder={user.email || "Email"}/>
                    <input {...register("mobile")} autoComplete={'mobile tel-country-code'} name="mobile" type="text" placeholder={user?.user_contact_details?.mobile || "Mobile"}/>
                    <Divider/>
                    {/*<input {...register("password")} autoComplete={'current-password webauthn'} name="password" type="password" placeholder="Password"/>*/}
                    <ButtonComponent text={'Update Details'} variant={'solid'} isLoading={updateUserMutation.isLoading} />

                </Form>

            <ButtonComponent text={'Reset Password'} variant={'outlined'} onClick={() => openDrawer(<PasswordResetForm />)} />
            <ButtonComponent text={'Log Out'} variant={'tertiary'} onClick={() => logout() } />

        </Container>
    );
};

export default UserDetailsForm;