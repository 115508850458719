import React, {useContext, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FloatingNav } from '../../../layout/components/FloatingNav';
import { Footer } from '../../../layout/components/Footer';
import {API_URL, scrollToElement} from '../../../js/Helper';
import { Loading } from '../../../components/ui/Loading';
import { updateTheme } from '../../themes/js/utils';
import { useCard } from '../../cards/js';
import { useApiQuery } from '../../../hooks/customHooks';
import { useDrawer } from '../../../js/hooks/context/DrawerContext';
import { toast } from 'react-toastify';
import IntroSection from '../components/IntroSection';
import OverviewSection from '../components/OverviewSection';
import HighlightsSection from '../components/HighlightsSection';
import DeliverablesSection from '../components/DeliverablesSection';
import MenuSection from '../components/MenuSection';
import CardSection from '../components/CardSection';
import {BACKGROUND_IMAGE, MAIN_IMAGE} from '../../moments/pages/CampaignPurchaseLandingPage';
import { ButtonComponent } from '../../../components/ui/buttons';
import { Paragraph } from '../../../components/ui/typography';
import EventsSection from '../components/EventsSection';
import { DividerWithText } from '../../../components/ui/LogoWall/DividerWithText';
import influencerData from '../js/influencerData.js';
import { useMutation } from '@tanstack/react-query';
import axios from "axios";
import {AuthContext} from "../../authentication/index.js";
import {VideoComponent} from "../../../components/ui/VideoComponent.jsx";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--primary-colour);
  max-width: 100dvw;
  overflow-x: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  width: 100vw;

  @media (min-width: 768px) {
    max-width: 75%
    padding: 40px;
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 1.2rem;
  text-align: center;
  padding: 2rem;
`;

const ProposalsPage = options => {
  const { influencerType } = useParams();
  const navigate = useNavigate();
  const { openDrawer } = useDrawer();
  const { user } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get('section')
  const [sectionName, setSectionName] = useState(section)
  const { setCard, setSelectedTier } = useCard();
  const [campaign, setCampaign] = useState();
  const [selectedDeliverables, setSelectedDeliverables] = useState(null);
  const [selectedMenuItems, setSelectedMenuItems] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const params = useParams();
  const slug = params.slug;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [member, setMember] = useState(null);

  const submitProposal = async (data) => {
    const res = await axios.post(`${API_URL}/campaign-submissions`, data)
    return res.data;
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: submitProposal,
    onSuccess: () => {
      toast.success('Submitted Successfully! A member of our team will be in touch shortly to confirm.');
      // Optionally navigate to a success page or clear form
    },
    onError: (error) => {
      toast.error(error.message || 'Failed to submit proposal');
    }
  });

  useEffect(() => {
    setSectionName(section)

    if(section) {
      scrollToElement(section)
    }
  
  }, [section])


  useApiQuery(`campaigns/${slug}?with=fileLinks,catalogueLinks.catalogue,theme,events,cards.tiers`, (data) => {
    if(data.status !== 'success') console.log('error')

    const res = data.data;

    // Modify the collection to set hide_price to true for all deliverables
    if (res.collection && res.collection.length > 0) {
      res.collection = res.collection.map(item => {
        if (item.catalogue && item.catalogue.product_list) {
          Object.keys(item.catalogue.product_list).forEach(category => {
            item.catalogue.product_list[category] = item.catalogue.product_list[category].map(product => ({
              ...product,
              hide_price: true
            }));
          });
        }
        return item;
      });
    }
    setCampaign(res)
    updateTheme(res.theme)
  });
  

  if(!campaign) return <Loading />

  const SELECT_EVENT_TEXT = 'Select an event to attend';

  const collections = campaign?.collection;
  const collection = collections[0]?.catalogue;
  const influencerDeliverables = collections.find((item) => item?.catalogue?.name === 'influencer_deliverables')?.catalogue;
  const files = campaign.files;
  const events = campaign.events;
  const mainImage = files.find((file) => file.name === MAIN_IMAGE);
  const mainVideo = files.find((file) => file.name === 'InfluencerVideo');
  const backgroundImage = files.find((file) => file.name === BACKGROUND_IMAGE);
  const menu = collections.find((item) => item?.catalogue?.name === 'food_menu')?.catalogue;
  const cards = campaign.cards;
  const numberOfMenuItemsPerCategoryAllowed = 1;
  const numberOfDeliverablesAllowed = 2;


  if (!influencerType) {
    console.error('Error: Influencer type is missing.');
    return null;
  }

  const contentData = influencerData[influencerType];

  if (!contentData) {
    navigate('/404', { replace: true });
    return null;
  }

  const hostedBy = {
    'bmf-campaign': 'Lil Meech',
    'clermont-twins-campaign': 'Clermont Twins',
  }

  const replacementMap = {
    'bmf-campaign': {
      'Lil meech': hostedBy[slug],
      'BMF': 'Black Mafia Family'
    },
    'clermont-twins-campaign': {
      'Lil meech': hostedBy[slug],
      'From BMF': 'From the States',
      'he': 'the twins',
      'his': 'the twins',
    }
  }

  // Function to recursively replace multiple texts in objects and arrays
  const replaceMultipleTexts = (data, replacements) => {
    if (typeof data === 'string') {
      let result = data;
      for (const [searchText, replaceWith] of Object.entries(replacements)) {
        result = result.replace(new RegExp(searchText, 'gi'), replaceWith);
      }
      return result;
    }
    if (Array.isArray(data)) {
      return data.map(item => replaceMultipleTexts(item, replacements));
    }
    if (typeof data === 'object' && data !== null) {
      const newObj = {};
      for (const key in data) {
        newObj[key] = replaceMultipleTexts(data[key], replacements);
      }
      return newObj;
    }
    return data;
  };

  // Process content data with multiple replacements
  const replacements = replacementMap[slug] || {};
  const processedContentData = replaceMultipleTexts(contentData, replacements);

  // Function to handle the click event on a deliverable item
  const handleDeliverableClick = (deliverable) => {
    // Check if the deliverable is undefined or null
    if (!deliverable) {
      console.error('Error: Deliverable is undefined or null');
      return;
    }

    // Update the state of selected deliverables based on the click event
    setSelectedDeliverables((prevDeliverables) => {
      // If only one deliverable is allowed and a different one is clicked, replace the existing one
      if (numberOfDeliverablesAllowed === 1 && prevDeliverables.length === 1 && prevDeliverables[0] !== deliverable) {
        return [deliverable];
      }
      // If no deliverables are selected, add the clicked one
      if (!prevDeliverables) {
        return [deliverable];
      }
      // If the clicked deliverable is already selected, remove it
      if (prevDeliverables.includes(deliverable)) {
        return prevDeliverables.filter((item) => item !== deliverable);
      }
      // If the maximum number of deliverables is reached, do not add more
      if (prevDeliverables.length >= numberOfDeliverablesAllowed) {
        toast.warn(`You can only select ${numberOfDeliverablesAllowed} deliverables`);
        return prevDeliverables;
      }
      // Add the clicked deliverable to the list of selected deliverables
      return [...prevDeliverables, deliverable];
    });
  }

  // Function to handle the click event on a menu item
  const handleMenuClick = (menuItem) => {
    // Check if the menu item is undefined or null
    if (!menuItem) {
      console.error('Error: MenuItem is undefined or null');
      return;
    }
    
    // Extract the category of the menu item
    const category = menuItem.category;
    // Check if the category is undefined or null
    if (!category) {
      console.error('Error: Category is undefined or null');
      return;
    }

    // Update the state of selected menu items based on the click event
    setSelectedMenuItems((prevMenuItems) => {
      // If only one menu item per category is allowed and a different one is clicked in the same category, replace the existing one
      if (numberOfMenuItemsPerCategoryAllowed === 1 && prevMenuItems?.length === 1 && prevMenuItems[0] !== menuItem && prevMenuItems[0].category === category) {
        return [menuItem];
      }
      // If no menu items are selected, add the clicked one
      if (!prevMenuItems) return [menuItem];
      // If the clicked menu item is already selected, remove it
      if (prevMenuItems.includes(menuItem)) {
        return prevMenuItems.filter((item) => item !== menuItem);
      }
      // Count the number of selected menu items in the same category
      const categoryCount = prevMenuItems.filter(item => item.category === category).length;
      // If the maximum number of menu items per category is reached, do not add more
      if (categoryCount >= numberOfMenuItemsPerCategoryAllowed) {
        toast.warn(`You can only select ${numberOfMenuItemsPerCategoryAllowed} menu items per category`);
        return prevMenuItems;
      }
      // Add the clicked menu item to the list of selected menu items
      return [...prevMenuItems, menuItem];
    });
  }

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  const menuItems = [
    { name: 'Overview', destination: `${window.location.pathname}?section=overview`, icon: 'IoInformationCircleOutline', order: 1 },
    { name: 'Highlights', destination: `${window.location.pathname}?section=highlights`, icon: 'IoStarOutline', order: 2 },
    ...(isMember ? [
    { name: 'Food', destination: `${window.location.pathname}?section=menu`, icon: 'IoFastFood', order: 4 },
      { name: 'Deliverables', destination: `${window.location.pathname}?section=deliverables`, icon: 'IoListOutline', order: 3 },
    ] : [
      { name: 'Card', destination: `${window.location.pathname}?section=card`, icon: 'IoCardOutline', order: 5 },
    ]),
  ];




  return (
    <PageContainer>
      <IntroSection campaign={campaign} backgroundImage={backgroundImage} />
      
      <ContentContainer>
        <OverviewSection campaign={campaign} contentData={processedContentData} />
        { mainVideo && <VideoComponent data={mainVideo} /> }
        <EventsSection events={events} />
        {events && events.length > 0 && (
          <div style={{width: '100%', maxWidth: '500px', margin: '20px 0'}}>
            <DividerWithText text={SELECT_EVENT_TEXT} style={{margin: '10px 0'}} />
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
              {events.map((event, index) => (
                <ButtonComponent
                  key={index}
                  variant={selectedEvent === event ? 'solid' : 'outlined'}
                  text={event.name}
                  onClick={() => handleEventSelect(event)}
                />
              ))}
            </div>
          </div>
        )}
        <HighlightsSection contentData={processedContentData} />
      </ContentContainer>

      {campaign.cards && campaign.cards.length > 0 && !isMember && (
        <CardSection 
          campaign={campaign} 
          navigate={navigate}
          setMember={setMember}
          setIsMember={setIsMember}
          isMember={isMember}
        />
      )}


      {isMember && (
        <>
          <DeliverablesSection
            collection={processedContentData.deliverables}
            handleDeliverableClick={handleDeliverableClick}
            selectedDeliverables={selectedDeliverables}
            openDrawer={openDrawer}
          />

          <MenuSection
            menu={menu}
            handleMenuClick={handleMenuClick}
            selectedMenuItems={selectedMenuItems}
          />
        </>
      )}
      <ContentContainer>
        {isMember && selectedMenuItems?.length > 0 && (
          <>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
              {selectedEvent && (
                <>
                  <DividerWithText text={'Selected Event'} style={{margin: '10px 0'}} />
                  <li style={{color: 'var(--text-colour)'}}>{selectedEvent.name}</li>
                </>
              )}
              <DividerWithText text={'Food'} style={{margin: '10px 0'}} />

                {selectedMenuItems?.map((menuItem, index) => (
                  <li style={{color: 'var(--text-colour)'}} key={index}>{menuItem.name}</li>
                ))}
            </div>
            <Paragraph>Click the button below to submit your proposal. A member of our team will be in touch shortly to confirm.</Paragraph>
            <ButtonComponent 
              variant={'solid'} 
              text="Submit"
              isLoading={isLoading}
              onClick={() => {
                if (!selectedEvent) {
                  toast.info("Please " + SELECT_EVENT_TEXT);
                  scrollToElement('events-section');
                  return;
                }
                mutate({
                  slug,
                  campaign_id: campaign.id,
                  form_id: 1,
                  menuItems: selectedMenuItems.map(({id, name}) => ({id, name})),
                  // deliverables: selectedDeliverables.map(({id, name}) => ({id, name})),
                  selectedEvent: {id: selectedEvent.id, name: selectedEvent.name},
                  email: user.email,
                  representative_name: user?.name,
                  type: 'InfluencerCampaign'
                });
              }} 
              disabled={isLoading || !selectedEvent}
            />
          </>
        )}
      </ContentContainer>

      <FloatingNav items={menuItems} />
    </PageContainer>
  );
};

export default ProposalsPage;
