import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { StandardSection } from '../../../layout/sections/StandardSection';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';
import { LoyaltyCard } from '../../cards/components/LoyaltyCard/LoyaltyCard';
import { ButtonComponent } from '../../../components/ui/buttons';
import { checkIfMember } from '../../cards/api/cardholders';
import { useApiQuery } from '../../../hooks/customHooks';
import { makeRequest } from '../../../services/axios';
import { AuthContext } from '../../authentication';
import { toast } from 'react-toastify';
import { PackForm } from '../../packs/components';
import { useDrawer } from '../../../js/hooks/context/DrawerContext';
import { LoginButton } from '../../authentication/components/LoginButton';
import { smirkingFaceEmoji } from '../../../js/emojis';

export const CardScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin: 20px -20px;
  padding: 10px 0;
  width: calc(100% + 40px);

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;


  @media screen and (min-width: 1024px){
    justify-content: center;
    margin: 0 auto;
  }
`;

export const CardWrapper = styled.div`
  flex: 0 0 auto;
  width: 90%;
  scroll-snap-align: center;
  padding: 0 10px;

  &:first-child {
    margin-left: 20px;
  }

  &:last-child {
    margin-right: 20px;
  }

  @media screen and (min-width: 1024px){
    width: 20rem;
    min-width: 25rem;
    margin: 0 10px
  }
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 100%;
  width: 100%;
  background-color: var(--accent-colour);
  padding: 20px;
//   border: 1px solid var(--colour-light);
  border-radius: var(--border-radius-default);
  background-color: rgba(255, 255, 255, 0.1);
`;

const CardSection = ({ campaign, navigate, setIsMember, isMember, setMember }) => {

    const cardTiers = campaign.cards.map(card => card.default_tier.id);
    const { header, user, setUser } = useContext(AuthContext);
    const [selectedCard, setSelectedCard] = useState(campaign.cards[0])
    const { openDrawer, isOpen, closeDrawer } = useDrawer();


    const res = makeRequest('POST', 'member/check_if_member', {card_tiers: cardTiers}, header(), 'application/json');
    res.then(data => {
        setIsMember(data.valid);
    })

    const handleCardClick = (card) => {
        setSelectedCard(card);
    }

    const onSuccess = (data, variables, context) => {
        console.log('membersuccess', data);
        setMember(data.data);
        setIsMember(data.next_actions.set_member_validated);

        if(data.user) {
            setUser(data.user);
        }
        closeDrawer();
    }

  return (
    <StandardSection
      id="card"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        '@media (min-width: 1920px)': { maxWidth: '400px' },
        backgroundColor: 'var(--accent-colour)',
        overflow: 'hidden',
      }}
    >
      <ImpactTitle header={selectedCard ? `Signup to ${selectedCard?.name}` : campaign.cards[0].name} />
      <Paragraph>This event is invite only. Signup to {selectedCard.name} to get access to this and other exclusive events.</Paragraph>
      <Paragraph>Once signed up you will be able to see the rest of the details for this campaign.</Paragraph>
      {/* <Paragraph>{selectedCard.default_tier.description}</Paragraph> */}
      
      <CardScrollContainer>
        {campaign.cards.map((card, index) => (
          <CardWrapper key={index}>

            <LoyaltyCard
              card={card}
              tier={card.default_tier}
              onClick={() => handleCardClick(card)}
              style={selectedCard === card ? {border: '1px solid var(--success-colour)'} : null}
            />
          </CardWrapper>
        ))}
      </CardScrollContainer>
      
      {selectedCard && (
      
      <CardContentContainer>
        <div style={{color: 'var(--text-colour)'}} dangerouslySetInnerHTML={{__html: selectedCard.default_tier.description}} />
        
                <ButtonComponent variant={'solid'} text={'Sign Up'} onClick={() => {
                    openDrawer(<PackForm
                    inputs={[
                        {name: 'first_name', type: 'text', label: 'First Name', placeholder: 'Jack', value: user?.first_name || '', autoCorrect: 'first_name', required: true},
                        {name: 'last_name', type: 'text', label: 'Last Name', placeholder: 'Black', value: user?.last_name || ''},
                        {name: 'email', type: 'text', label: 'Email', placeholder: 'j.black@yourbusiness.com', value: user?.email || '', autoCorrect: 'email', required: true},
                        {name: 'city', type: 'text', label: 'City', placeholder: 'Manchester', autoCorrect: 'city', required: true},
                        {name: 'tiktok', type: 'text', label: 'Tik Tok', placeholder: '@j.black', autoCorrect: 'tiktok'},
                        // {name: 'tiktok_followers', type: 'text', label: 'Tik Tok Followers', placeholder: '1.25M', autoCorrect: 'tiktok'},
                        {name: 'instagram', type: 'text', label: 'Instagram', placeholder: '@j.black', autoCorrect: 'instagram', required: true},
                        // {name: 'instagram_followers', type: 'text', label: 'Instagram Followers', placeholder: '100K', autoCorrect: 'instagram', required: true},
                        {name: 'tier', type: 'hidden', value: selectedCard?.default_tier?.id},
                        
                        {name: 'card', type: 'hidden', value: selectedCard?.id},
                        {name: 'drawerIsOpen', type: 'hidden', isOpen},
                    ]}
                    endpointUrl='member/create'
                    onSuccess={onSuccess}
                    name='card-signup'
                    />, 'Sign Up')
                }}
            />
                <LoginButton login loginText={`Already have an account? Log In`} icon={smirkingFaceEmoji} style={{variant: {name: 'tertiary'}}} />

      </CardContentContainer>
        )}



    </StandardSection>
  );
};

export default CardSection;
