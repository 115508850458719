import React from 'react';
import {DividerWithText} from "../ui/LogoWall/DividerWithText.jsx";
import Accordian from "../ui/Accordian/Accordian.jsx";
import {FAQSection} from "./js/faqStyles.js";

const FaqsComponent = ({ faqs }) => {
    return (
        <FAQSection id="faq">
            <DividerWithText text='Frequently Asked Questions' style={{marginBottom: '20px'}} />
            <Accordian data={faqs} accordionStyle={{
                backgroundColor: 'var(--primary-colour)',
                color: 'var(--text-colour)',
                border: '1px solid var(--text-colour)',
                marginBottom: '10px',
            }} />
        </FAQSection>
    );
};

export default FaqsComponent;