import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AuthContext } from '../AuthContext'
import { waveEmojo } from '../../../js/Helper'
import { gearsEmoji, padlockUnlockedEmoji } from '../../../js/emojis'
import { useDrawer } from '../../../js/hooks/context/DrawerContext'
import { CustomerInfoForm } from '../../ecommerce/checkout/components/CustomerInfoForm'


const UserInfoContainer = styled.div`
    display: grid;
    width: 100%;
    max-width: 400px;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 12px 15px;
    box-sizing: border-box;
    border-radius: 12px;
    background: var(--accent-colour);
    box-shadow: inset 10px 10px 20px rgba(0,0,0,0.2), inset -10px -10px 10px rgba(0,0,0,0.1);
    z-index: 10;


  @media screen and (min-width: 768px){
    max-width: var(--medium);
  }
`

const UserPropertyContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`
const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`

const Icon = styled.div`
  cursor: pointer27;
`

const MessageContainer = styled.div`
  text-align: center;
`

export const UserInfoBox = ({ icon, message, actions }) => {
    

    const [isVisible, setIsVisible] = useState(true);

    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const { openDrawer } = useDrawer();

    
    useEffect(() => {
      const handleScroll = () => {
          const currentScrollY = window.scrollY;
          if (currentScrollY > window.innerHeight) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        };
        
        window.addEventListener('scroll', handleScroll);
        
        // Cleanup the event listener on unmount
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    
    const handleLogout = () => {
        logout()
      }
      
      if(!isAuthenticated) return;
  return (
    
        <UserInfoContainer id='logged-in-user-quick-nav' >
           <UserPropertyContainer>
            {icon}
           </UserPropertyContainer>
           <MessageContainer>
            {message}
           </MessageContainer>
            <IconsContainer>
              {
                actions && actions.map((icon, index) => {
                  return (
                    <Icon key={index} onClick={() => icon.onClick()}>{icon.icon}</Icon>
                  )
                })
              }
            </IconsContainer>
        </UserInfoContainer>
  )
}
