import React from 'react'
import styled from 'styled-components';
import { Paragraph } from './typography';

const LoadingOverlay = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
`;

const LoadingIndicator = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid ${(props) => props.theme.textColour};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  max-height: 45px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingAnimationComponent = ({ text }) => {
  return (
    <LoadingOverlay>
        <LoadingIndicator />
        { text && <Paragraph>{text}</Paragraph> }
    </LoadingOverlay>
  )
}
