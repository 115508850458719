import React, { useState, useEffect } from 'react';
import EventErrorBoundary from '../EventErrorBoundary';

// Component that throws an error during render
const RenderErrorComponent = () => {
  throw new Error('Test render error');
  return <div>This won't render</div>;
};

// Component that throws an error during data fetching
const ApiErrorComponent = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const simulateApiCall = async () => {
      try {
        throw new Error('Test API error');
      } catch (err) {
        setError(err);
      }
    };

    simulateApiCall();
  }, []);

  if (error) throw error;
  return <div>Loading...</div>;
};

// Test component that lets us switch between different error scenarios
const ErrorBoundaryTest = () => {
  const [testCase, setTestCase] = useState('none');

  const renderTestCase = () => {
    switch (testCase) {
      case 'render':
        return <RenderErrorComponent />;
      case 'api':
        return <ApiErrorComponent />;
      default:
        return <div>Select a test case</div>;
    }
  };

  return (
    <div style={{ padding: '2rem' }}>
      <div style={{ marginBottom: '2rem' }}>
        <button onClick={() => setTestCase('render')} style={{ marginRight: '1rem' }}>
          Test Render Error
        </button>
        <button onClick={() => setTestCase('api')}>
          Test API Error
        </button>
      </div>

      <EventErrorBoundary>
        {renderTestCase()}
      </EventErrorBoundary>
    </div>
  );
};

export default ErrorBoundaryTest;
