import styled from "styled-components"
import { ImageComponent } from "../../../../images/ImageComponent"

export const imageContainerStyle = {
    width:'140px',
    maxWidth: '140px',
    zIndex: 3,
    border: 'none',
    outline: 'none',
    height:'140px',
    maxHeight:'140px'
}


export const RectangleCardImage = styled.section`
    display: block;
    z-index: 1;
    width: ${props => props.size ? props.size : '50px'};
    height: ${props => props.size ? props.size : '50px'};
    background: linear-gradient(to left, var(--primary-colour) 5%, transparent),
    linear-gradient(to left, var(--primary-colour) 10%, transparent),
    url(${props => props.source});
    background-size: contain;

/* 
    &:hover {
    transition: opacity 350ms ease-in-out;
    opacity: 1;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-image:  linear-gradient(to left, var(--primary-colour), rgba(255, 0, 0, 0));
    } */


`