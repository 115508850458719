import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import ProductPasswordForm from "../components/ProductPasswordForm.jsx";
import {getBookingId} from "../../../bookings/js/utils/storage.js";
import {useEvent} from "../../../events/js/hooks/index.js";

export const useProduct = () => {

    const { basket, addToBasket, time } = useEcommerce();
    const { openDrawer } = useDrawer();
    const { event } = useEvent()

    const bookingIdIsForCurrentEvent = getBookingId().slug === event?.slug;

    const addDependenciesToBasket = (product, quantity ) => {
        if (product?.dependencies?.length > 0) {
            product.dependencies.forEach(dependency => {

                if(dependency.category === 'tableBooking' && bookingIdIsForCurrentEvent) return;

                if (dependency.remaining > 0) {
                    if(basket.orderItems.find(item => item.id === dependency.id)) return;
                    if(time) dependency.time = time;
                    addToBasket(dependency, quantity ?? 0);
                }
            });
        }
    }



    const displayPasswordProtection = (product) => {
        // eslint-disable-next-line react/react-in-jsx-scope
        openDrawer(<ProductPasswordForm product={product} />, `Password For ${product.name}`)
    }

    return {
         addDependenciesToBasket,
         displayPasswordProtection
    }


}