import { useBasket } from '../../../../../featuresV2/ecommerce/basket/context/BasketContext';
import { makeRequest } from '../../../../../services/axios';
import { useHandleError } from '../../../../error-handling/js/hooks';

/**
 * Create an express checkout session
 * @param {Object} params
 * @param {number} params.amount - Amount in cents
 * @param {string} params.product_id - Product ID
 * @param {number} params.quantity - Quantity
 * @param {string} params.paymentType - Type of payment (apple_pay/google_pay)
 * @param {Object} [params.customerDetails] - Optional customer details if available
 * @param {string} [params.customerDetails.email] - Customer email
 * @param {string} [params.customerDetails.phone] - Customer phone
 * @param {string} [params.customerDetails.name] - Customer name
 * @param {Object} [params.customerDetails.billingAddress] - Customer billing address
 * @returns {Promise<{clientSecret: string, intentId: string, basket: Object} | null>}
 */
export const createExpressCheckoutSession = async ({
  amount,
  product_id,
  quantity,
  paymentType,
  customerDetails,
  basketableId,
  basketableType,
  basketId
}) => {
  const { showErrorToast } = useHandleError();

  try {
    const response = await makeRequest(
      'POST',
      'basket/express-checkout',
      {
        amount,
        product_id: product_id,
        quantity: quantity,
        // payment_type: paymentType,
        customer_details: customerDetails,
        basketable_id: basketableId,
        basketable_type: basketableType,
        basket_id: basketId
      }
    );

    if (!response?.status === 'success' || !response?.data?.stripe?.secret) {
      showErrorToast('Invalid response from payment server');
      return null;
    }

    return {
      clientSecret: response.data.stripe.secret,
      intentId: response.data.stripe.intent_id,
      basket: response.data.basket
    };
  } catch (error) {
    if (error.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      showErrorToast('An error occurred while processing your payment');
    }
    return null;
  }
};
