import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AsymmetricCard from '../AsymmetricCard/AsymmetricCard';
import TextBlock from '../TextBlock/TextBlock';
import SocialMediaIcons from '../SocialMediaIcons/SocialMediaIcons';
import Paragraph from '../../atoms/Paragraph/Paragraph';

const ContentContainer = styled.div`
  // padding: 24px;
  color: var(--text-colour-inverse);
  
  /* Align with header content */
  ${props => props.$hasImage && css`
    padding: 24px 0px;
  `}
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 24px;
`;

const Address = styled.div`
  margin-bottom: 24px;
`;

const StyledSocialMediaIcons = styled(SocialMediaIcons)`
  margin-top: 16px;
`;

const PrimaryLocationCard = ({
  image,
  name,
  tagline,
  description,
  address: { first_line_address, post_code, city, country } = {},
  socialMedia,
  className,
  ...props
}) => {
  return (
    <AsymmetricCard
      className={className}
      headerImage={image}
      headerBanner={name}
      headerContent={
        <TextBlock
          text={tagline}
          textColor="white"
        />
      }
      {...props}
    >
      {description && (
        <ContentContainer $hasImage={!!image}>
          <StyledParagraph>
            {description}
          </StyledParagraph>
          <Address>
            {first_line_address && <Paragraph>{first_line_address}</Paragraph>}
            {post_code && <Paragraph>{post_code}</Paragraph>}
            {city && <Paragraph>{city?.city}</Paragraph>}
            {country && <Paragraph>{country}</Paragraph>}
          </Address>
          {socialMedia?.length > 0 && (
            <StyledSocialMediaIcons links={socialMedia} />
          )}
        </ContentContainer>
      )}
    </AsymmetricCard>
  );
};

PrimaryLocationCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  tagline: PropTypes.string,
  description: PropTypes.string,
  address: PropTypes.shape({
    first_line_address: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      platform: PropTypes.oneOf(['instagram', 'facebook', 'twitter', 'tiktok']),
      url: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

export default PrimaryLocationCard;
