import React from 'react';
import styled from 'styled-components';
import { StandardSection } from '../../../layout/sections/StandardSection';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';
import Accordian from '../../../components/ui/Accordian/Accordian';

const HighlightsSection = ({ contentData }) => {
  return (
    <StandardSection id="highlights" >
      {/* <ImpactTitle header="Highlights" /> */}
      <div style={{padding: '0px 20px'}}>
      <Paragraph>We will be partnering with sponsors, brands and influencers to make this to foster a collaborative campaign that benefits all parties involed.</Paragraph>
      <Paragraph>Everything in the opening paragraph above includes our exclusive offerings to you, below is the highlights of the night.</Paragraph>
      </div>
    
      <Accordian
        accordionStyle={{
          background: 'var(--accent-colour)',
          color: 'var(--text-colour)',
          borderRadius: 'var(--border-radius-default)',
          border: 'none'
        }}
        data={contentData.highlights.map((highlight, index) => {
          const [placeholder, description] = highlight.split(': ');
          return {
            placeholder: placeholder,
            description: description,
          };
        })}
      />
    </StandardSection>
  );
};

export default HighlightsSection;
