import { useApiQuery } from "../../../hooks/customHooks";

export const getEvents = (params) => {
    const events = useApiQuery(`events?${params}`);
    return events;
}

export const useEvent = (slug) => {
    const path = `event/${slug}`;

    const { data, isLoading, isError } = useApiQuery(
      path,
      (data) => { /* handle success */ },
      (error) => console.error(error.message)
    );
  
    let output = '';

    if (isLoading) {
      output = '';
    } else if (isError) {
      output = isError.message;
    } else if (data) {
      output = data.data.data;
    }
  
    return output;
  };

  