/**
 * EventSkeleton component
 * @description Loading skeleton for the Event page
 */
import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const SkeletonContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--primary-colour);
`;

const SkeletonBlock = styled.div`
  background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
  background-size: 2000px 100%;
  animation: ${shimmer} 2s linear infinite;
  border-radius: ${props => props.$radius || '4px'};
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || '20px'};
  margin: ${props => props.$margin || '0'};
`;

const Section = styled.div`
  padding: 2rem;
`;

const EventSkeleton = () => (
  <SkeletonContainer>
    {/* Hero Section */}
    <SkeletonBlock $height="100px" />

    {/* Lineup Section */}
    <Section >
      <SkeletonBlock $height="40px" $width="200px" $margin="0 0 2rem" />
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '2rem' }}>
        {[1, 2, 3, 4].map(i => (
          <div key={i} style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
            <SkeletonBlock $height="250px" $margin="0 0 1rem" />
            <SkeletonBlock $height="24px" $width="80%" $margin="0 0 0.5rem" />
            <SkeletonBlock $height="20px" $width="60%" />
          </div>
        ))}
      </div>
    </Section>

    {/* Location Section */}
    <Section>
      <SkeletonBlock $height="40px" $width="200px" $margin="0 0 2rem" />
      <SkeletonBlock $height="300px" />
    </Section>

    {/* Tickets Section */}
    <Section>
      <SkeletonBlock $height="40px" $width="200px" $margin="0 0 2rem" />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {[1, 2, 3].map(i => (
          <SkeletonBlock key={i} $height="100px" />
        ))}
      </div>
    </Section>
  </SkeletonContainer>
);

export default EventSkeleton;
