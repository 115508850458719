import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AccessAccountPage } from '../../features/user/pages/AccessAccountPage/AccessAccountPage';
import Orders from '../../features/ecommerce/orders/components/Orders';
import { AuthContext } from '../../features/authentication';
import { useDrawer } from '../../js/hooks/context/DrawerContext';
import Basket from "../../features/ecommerce/basket/index.js";

export const AuthenticatedFooterLinks = () => {

    const { isAuthenticated, logout } = useContext(AuthContext);
    const { openDrawer } = useDrawer();


  return (
    <>
    {
        isAuthenticated &&
        <>
        <Link onClick={() => openDrawer(<Basket />, 'Basket')}>Basket</Link>
        <Link onClick={() => openDrawer(<Orders />, 'Your Orders')} >My Orders</Link>
        <Link onClick={() => logout()}>Log Out</Link>

        </>
    }
    </>
  )
}
