
// Add these styled components
import styled from "styled-components";
import {extractColorCodeFromCssVariable} from "../themes/js/utils.js";

const ReviewFormContainer = styled.div`
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    max-height: ${props => props.isOpen ? '600px' : '0px'};
    opacity: ${props => props.isOpen ? '1' : '0'};
    transform: translateY(${props => props.isOpen ? '0' : '-20px'});
    transition: all 0.3s ease-out;
  `;


const ReviewSectionContainer = styled.section`
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 20px 30px;
  // border: 1px solid ${extractColorCodeFromCssVariable('--accent-colour')};
  border-radius: var(--border-radius-default);
  background-color: var(--secondary-colour);

  &::-webkit-scrollbar {
    display: none;
  }
  margin: 0 -20px;
`;

const ReviewList = styled.div`
  display: inline-flex;
  gap: 20px;
  // padding: 0 20px; /* Add horizontal padding */

  /* Ensure the first review aligns with the left edge of the screen */
  &::before {
    content: '';
    padding-left: 00px;
  }

  /* Ensure the last review aligns with the right edge of the screen */
  &::after {
    content: '';
    padding-right: 20px;
  }
`;

const ReviewCard = styled.div`
  background-color: var(--secondary-colour);
  border-radius: 10px;
  padding: 15px;
  min-width: 250px;
  max-width: 350px;
  text-wrap: balance;
`;

const ReviewerName = styled.h3`
  font-size: 1.6em;
  margin-bottom: 10px;
`;

const ReviewText = styled.p`
  margin-bottom: 10px;
`;

const StarRating = styled.div`
  color: ${extractColorCodeFromCssVariable('--accent-colour')};
`;


export {
    ReviewFormContainer,
    ReviewSectionContainer,
    ReviewList,
    ReviewCard,
    ReviewerName,
    ReviewText,
    StarRating
}