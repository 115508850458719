import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import basketApi from '../api/basketApi';

export const BasketContext = createContext(null);
const BASKET_STORAGE_KEY = 'nocturnal_basket';

// Helper to get stored basket
const getStoredBasket = () => {
  try {
    const stored = localStorage.getItem(BASKET_STORAGE_KEY);
    console.log('🔔 stored', JSON.parse(stored));
    return stored ? JSON.parse(stored) : null;
  } catch (err) {
    console.error('Error reading basket from storage:', err);
    return null;
  }
};

export const useBasket = () => {
  const context = useContext(BasketContext);
  if (!context) {
    throw new Error('useBasket must be used within a BasketProvider');
  }
  return context;
};

export const BasketProvider = ({ children }) => {
  // Initialize state from localStorage or default
  const [basketState, setBasketState] = useState(() => 
    getStoredBasket() || {
      id: null,
      orderItems: [],
      total: 0,
      status: 'active',
      isPaymentAllowed: true
    }
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Persist to localStorage when basket changes
  useEffect(() => {
    try {
      localStorage.setItem(BASKET_STORAGE_KEY, JSON.stringify(basketState));
    } catch (err) {
      console.error('Error saving basket to storage:', err);
    }
  }, [basketState]);

  const refreshBasket = useCallback(() => {
    try {
      const freshBasket = getStoredBasket();
      if (freshBasket) {
        setBasketState(freshBasket);
      }
      return true;
    } catch (err) {
      console.error('Error refreshing basket:', err);
      return false;
    }
  }, []);

  const isProductInBasket = (productId) => {
    return basketState.orderItems.some(item => item.productId === productId);
  };

  const addToBasket = async (product, quantity = 1) => {
    setLoading(true);
    setError(null);

    try {
      console.log('Adding to basket:', { product, quantity });
      const response = await basketApi.addItem(product, basketState.id, quantity);
      console.log('Add item response:', response);

      if (response) {
        setBasketState(response?.basket);
        return true;
      }
      return false;
    } catch (err) {
      console.error('Error adding to basket:', err);
      setError(err.message || 'Failed to add item to basket');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const removeFromBasket = async (itemId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await basketApi.removeItem(itemId, basketState.id);
      if (response) {
        setBasketState(response?.basket);
        return true;
      }
      return false;
    } catch (err) {
      setError(err.message || 'Failed to remove item from basket');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateQuantity = async (itemId, quantity) => {
    setLoading(true);
    setError(null);

    // Store previous state for rollback
    const previousState = basketState;

    try {
      // Validate quantity
      if (quantity < 1) {
        throw new Error('Quantity must be at least 1');
      }

      // Find the item
      const item = basketState.orderItems.find(item => item.id === itemId);
      
      if (!item) {
        throw new Error('Item not found in basket');
      }

      // Check against max quantity if it exists
      if (item.maxPerOrder && quantity > item.maxPerOrder) {
        throw new Error(`Maximum ${item.maxPerOrder} items allowed`);
      }

      // Check against remaining quantity
      if (item.remaining && quantity > item.remaining) {
        throw new Error(`Only ${item.remaining} items available`);
      }

      // Optimistically update the UI
      const updatedItems = basketState.orderItems.map(item =>
        item.id === itemId ? { ...item, quantity } : item
      );

      setBasketState(prev => ({
        ...prev,
        orderItems: updatedItems
      }));

      // Make API call
      const response = await basketApi.updateQuantity(itemId, quantity, basketState.id);

      console.log('updateQuantity response:', response);
      
      if (response?.basket) {
        setBasketState(response.basket);
        return true;
      }

      // If API call fails, rollback
      setBasketState(previousState);
      return false;
    } catch (err) {
      // Rollback on error
      setBasketState(previousState);
      setError(err.message || 'Failed to update quantity');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateBasket = (key, value) => {
    setBasketState(
      {...basketState,
      [key]: value
    });
  };

  const emptyBasket = () => {
    setBasketState({
      id: null,
      orderItems: [],
      total: 0,
      status: 'active',
      isPaymentAllowed: true
    });
  };

  return (
    <BasketContext.Provider
      value={{
        basket: basketState,
        storedBasket: getStoredBasket(),
        loading,
        error,
        addToBasket,
        removeFromBasket,
        emptyBasket,
        updateQuantity,
        isProductInBasket,
        refreshBasket,
        setBasketState,
        updateBasket
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};

export default BasketContext;
