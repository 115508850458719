import React, {useContext, useEffect, useState} from "react";
import {useEcommerce} from "../../../../js/context/EcommerceContext.jsx";
import {AuthContext} from "../../../../../authentication/index.js";
import axios from "axios";
import {API_URL} from "../../../../../../js/Helper.js";
import {
    MetaItem,
    PriceTag,
    ProductDescription,
    ProductInfoContainer,
    ProductMetaContainer, StockIndicator
} from "../js/productPageInfo.styles.js";
import ImpactTitle from "../../../../../../components/ui/typography/ImpactTitle.jsx";
import { DiscountedPrice,OriginalPrice, PriceDisplay} from "../../offers/js/productOffers.styles.js";
import {FaCheckCircle, FaTag, FaTimesCircle} from "react-icons/fa";
import {convertToCurrency} from "../../../../../../js/helpers/helpers.js";
import {useHandleError} from "../../../../../error-handling/js/hooks/index.js";
import {calculateDiscountedPrice} from "../../../js/utils/index.js";
import {useRewards} from "../../../../../rewards/js/hooks/RewardsContext.jsx";
import {StandardOffersSection} from "../../../../../rewards/components/index.js";

/**
 * Product Info Section
 * @param {*} product
 * @returns
 */
export const ProductInfoSection = ({ product, offers }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const { showErrorToast } = useHandleError();
    const { addToBasket, basket, setConfig, emptyBasket } = useEcommerce();
    const { selectedReward } = useRewards();
    const { user} = useContext(AuthContext);
    const [formData, setFormData] = useState({
        business_name: '',
        full_name: user ? `${user.first_name} ${user.last_name}` : '',
        email: user?.email || ''
    });
    const [pendingCheckout, setPendingCheckout] = useState(false);

    const purchasePrice = selectedReward
        ? calculateDiscountedPrice(product.price, selectedReward.value)
        : product.price;


    return (
        <>
            {/* <InstructionTitle title={'Product Information'} style={{marginBottom: '-25px', zIndex: 1, margin: '0 auto', textTransform: 'uppercase'}} /> */}

            <ProductInfoContainer id="info">
                {/* <ProductName>{product.name}</ProductName> */}
                <ImpactTitle subHeader={product?.business?.name} main={product?.name} />

                <ProductDescription id='description' dangerouslySetInnerHTML={{ __html: product.description }} />
            </ProductInfoContainer>

            <StandardOffersSection  />
            <ProductInfoContainer>
                <ProductMetaContainer id='meta'>
                    <PriceDisplay>
                        <PriceTag>
                            <FaTag />
                            <OriginalPrice hasDiscount={!!selectedReward}>
                                {convertToCurrency(product.price)}
                            </OriginalPrice>
                            {selectedReward && (
                                <DiscountedPrice>
                                    {convertToCurrency(purchasePrice)}
                                </DiscountedPrice>
                            )}
                        </PriceTag>
                    </PriceDisplay>
                    <MetaItem>
                        {product.remaining > 0 ? <FaCheckCircle color="var(--success-colour)" /> : <FaTimesCircle color="var(--error-colour)" />}
                        <StockIndicator id='stock' inStock={product.remaining > 0}>
                            {product.remaining > 0 ? 'Available' : 'Unavailable'}
                        </StockIndicator>
                    </MetaItem>
                </ProductMetaContainer>
                {/*{*/}
                {/*    selectedOffer && product.remaining > 0 && (*/}
                {/*        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '20px'}}>*/}
                {/*            <Paragraph>Lock in this price and secure your <strong>{selectedOffer.name}</strong> offer by paying a deposit of<strong> only {convertToCurrency(purchasePrice / 2)}</strong> now.</Paragraph>*/}

                {/*            {!showPaymentForm ? (*/}
                {/*                <>*/}
                {/*                    <Divider />*/}
                {/*                    <StandardSection>*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            name="business_name"*/}
                {/*                            placeholder="Business Name"*/}
                {/*                            value={formData.business_name}*/}
                {/*                            onChange={handleInputChange}*/}
                {/*                            required*/}
                {/*                        />*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            name="full_name"*/}
                {/*                            placeholder="Full Name"*/}
                {/*                            value={formData.full_name}*/}
                {/*                            onChange={handleInputChange}*/}
                {/*                            required*/}
                {/*                        />*/}
                {/*                        <input*/}
                {/*                            type="email"*/}
                {/*                            name="email"*/}
                {/*                            placeholder="Email Address"*/}
                {/*                            value={formData.email}*/}
                {/*                            onChange={handleInputChange}*/}
                {/*                            required*/}
                {/*                        />*/}
                {/*                    <Divider style={{marginBottom: '20px'}}/>*/}
                {/*                    <ButtonComponent*/}
                {/*                        id='lockOffer'*/}
                {/*                        text='Lock in Offer'*/}
                {/*                        icon={<IoCartOutline />}*/}
                {/*                        variant='solid'*/}
                {/*                        onClick={handleLockInOffer}*/}
                {/*                        disabled={isProcessing || !formData.business_name || !formData.full_name || !formData.email}*/}
                {/*                        isLoading={checkoutLoading}*/}
                {/*                    />*/}

                {/*                        {!showPaymentForm && (*/}
                {/*                            <ButtonComponent*/}
                {/*                                id='enquiry'*/}
                {/*                                text='Make Enquiry'*/}
                {/*                                icon={<IoCartOutline />}*/}
                {/*                                variant='outlined'*/}
                {/*                                onClick={() => ProductEnquiry()}*/}
                {/*                            />*/}
                {/*                        )}*/}

                {/*                    </StandardSection>*/}

                {/*                </>*/}
                {/*            ) : (*/}
                {/*                paymentIntent && (*/}
                {/*                    <StripePaymentForm*/}
                {/*                        payment_intent={paymentIntent}*/}
                {/*                        style={{padding: '50px 0', width: '100%'}}*/}
                {/*                        // theme={theme}*/}

                {/*                        onSuccess={() => toast.success('Payment successful')}*/}
                {/*                    />*/}
                {/*                )*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

            </ProductInfoContainer>
        </>
    );
};