import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useHandleRemoveOrderItem} from "../../js/hooks/index.js";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import {useApiQuery} from "../../../../hooks/customHooks.js";
import {IoAddSharp, IoInformationCircleSharp, IoTrashBinSharp} from "react-icons/io5";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {InstructionTitle, Paragraph} from "../../../../components/ui/typography/index.js";
import {AddonProduct} from "./index.js";
import {convertToCurrency} from "../../../../js/helpers/helpers.js";
import React from "react";
import styled from "styled-components";
import { LoadingAnimationComponent } from "../../../../components/ui/LoadingAnimationComponent.jsx";
import { color } from "framer-motion";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;
`

const AddonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const AddonItem = styled.div`
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr) 80px 40px;
    gap: 16px;
    align-items: center;
    padding: 10px 16px;
    min-width: 100%;
    background-color: ${props => props.isSelected ? 'var(--success-colour)' : 'var(--colour-light)'};
    border-radius: var(--border-radius-default);
    margin-bottom: 8px;
    transition: all 0.2s ease-in-out;
    
    &:hover {
        transform: scale(1.01);
    }
`;

const AddonName = styled(Paragraph)`
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${props => props.isSelected ? '#ffffff' : '#000000'};
    margin: 0;
    padding-right: 8px;
`;

const AddonPrice = styled(Paragraph)`
    color: ${props => props.isSelected ? '#ffffff' : '#000000'};
    margin: 0;
    white-space: nowrap;
    text-align: right;
    width: 80px;
`;

const InfoIcon = styled.span`
    cursor: pointer;
    color: var(--info-colour);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    
    &:hover {
        opacity: 0.8;
    }
`;

const ToggleButton = styled.span`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 4px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    
    &:hover {
        transform: scale(1.1);
        opacity: 0.9;
    }
`;

const iconMap = {
    'add': <IoAddSharp color={'var(--success-colour)'} size={20} />,
    'remove': <IoTrashBinSharp color={'white'} size={15} />
};

export const Addons = ({ product, selectedAddons, onAddonToggle, numberOfPeople }) => {
    const { openDrawer } = useDrawer();
    const { data: addons, isLoading, error } = useApiQuery(`products/${product.id}/addons`);

    // Don't render anything if there's an error, no addons, or still loading
    if (error || !addons || addons.length === 0 || isLoading) {
        return null;
    }

    return (
        <Container id={product.id + '-addon-container'}>
            <InstructionTitle title={'Add-ons'} style={{alignSelf: 'center', minWidth: '200px', marginBottom: -10}} />
            <AddonContainer>
                {addons.map((addon, index) => {
                    const isSelected = selectedAddons.some(a => a.id === addon.id);
                    
                    return (
                        <AddonItem 
                            key={index}
                            isSelected={isSelected}
                        >
                            <InfoIcon onClick={() => openDrawer(<AddonProduct product={addon} />, addon.name, 'bottom')}>
                                <IoInformationCircleSharp size={20} />
                            </InfoIcon>
                            <AddonName style={{color: isSelected ? '#ffffff' : '#000000'}} isSelected={isSelected}>{addon.name}</AddonName>
                            <AddonPrice style={{color: isSelected ? '#ffffff' : '#000000'}} isSelected={isSelected}>{convertToCurrency(addon.price)}</AddonPrice>
                            <ToggleButton 
                                isSelected={isSelected}
                                onClick={() => onAddonToggle(addon)}
                            >
                                {isSelected ? iconMap.remove : iconMap.add}
                            </ToggleButton>
                        </AddonItem>
                    );
                })}
            </AddonContainer>
        </Container>
    );
};
