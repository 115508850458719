/**
 * @typedef {Object} Fee
 * @property {number} amount - Fee amount in pence
 * @property {boolean} is_mandatory - Whether the fee is mandatory
 */

/**
 * @typedef {Object} Product
 * @property {number} price - Base price in pence
 * @property {Fee[]} fees - Array of fees associated with the product
 */

/**
 * @typedef {Object} ProductCalculations
 * @property {number} basePrice - The base price of the product in pence
 * @property {number} totalFees - Sum of all fees in pence
 * @property {number} mandatoryFeesTotal - Sum of mandatory fees in pence
 * @property {number} totalPrice - Final price including mandatory fees in pence
 * @property {Fee[]} mandatoryFees - Array of mandatory fees
 * @property {Fee[]} optionalFees - Array of optional fees
 */

/**
 * Custom hook for calculating various price-related values for a product
 * @param {Product} product - The product object containing price and fees
 * @returns {ProductCalculations} Object containing various price calculations
 */
const useProductCalculations = (product, quantity = 1) => {
  if (!product) {
    return {
      basePrice: 0,
      totalFees: 0,
      mandatoryFeesTotal: 0,
      totalPrice: 0,
      mandatoryFees: [],
      optionalFees: []
    };
  }
  // Separate mandatory and optional fees
  const mandatoryFees = product.fees?.filter(fee => fee.is_mandatory) || [];
  const optionalFees = product.fees?.filter(fee => !fee.is_mandatory) || [];

  // Calculate total of all fees
  const totalFees = (product.fees?.reduce((sum, fee) => sum + fee.amount, 0) || 0) * quantity;

  // Calculate total of mandatory fees
  const mandatoryFeesTotal = mandatoryFees.reduce((sum, fee) => sum + (fee.amount * quantity), 0);

  // Calculate final price (base price + mandatory fees)
  const totalPrice = (product.price * quantity) + mandatoryFeesTotal;

  // Calculate total price with fees
  const totalPriceWithFees = totalPrice + totalFees;

  return {
    basePrice: product.price || 0,
    totalFees,
    mandatoryFeesTotal,
    totalPrice,
    mandatoryFees,
    optionalFees,
    totalPriceWithFees
  };
};

export default useProductCalculations; 