import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TypographyGroup from '../../molecules/TypographyGroup/TypographyGroup';
import ScrollList from '../../layouts/ScrollList/ScrollList';
import ImageCard from '../../molecules/ImageCard/ImageCard';
import Tag from '../../atoms/Tag/Tag';
import AnimatedElement from '../../atoms/AnimatedElement/AnimatedElement';
import { ANIMATION_PRESET, SEQUENCE_TIMING } from '../../atoms/AnimatedElement/animations';

const Section = styled.section`
  padding: 40px 0;
  background: var(--surface-colour);
  overflow: visible;
  height: auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    gap: 4rem;
  }
`;

const StyledTypographyGroup = styled(TypographyGroup)`
  padding: 0 20px;
  text-align: center;
  
  @media (min-width: 768px) {
    padding: 0;
    margin: 0 auto;
    max-width: 1200px;
    
    h1 {
      font-size: 5rem;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  h1 {
    color: var(--accent-colour);
    margin-bottom: 1rem;
  }
`;

const GenreList = styled.div`
  display: flex;
  gap: 8px;
  // margin-top: 16px;
  // padding: 0 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -20px
  
  @media (min-width: 768px) {
    padding: 0;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const TagWrapper = styled(AnimatedElement)`
  display: inline-flex;
`;

const MobileArtistList = styled.div`
  display: block;
  
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopArtistGrid = styled.div`
  display: none;
  
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 24px;
    padding: 0 80px;
    max-width: 1600px;
    margin: 0 auto;
  }
`;

const DesktopImageCard = styled(ImageCard)`
  width: 100% !important;
  height: auto !important;
`;

const ArtistLineup = ({ 
  artists = [], 
  title = "Music on the Night", 
  subtitle = "Featured Artists",
  genres = ['Hip Hop', 'R&B', 'Dancehall'] 
}) => {
  const { baseDelay, itemDelay } = SEQUENCE_TIMING.SECTION;
  const tagDelay = 0.08;

  const renderMobileArtist = (artist) => (
    <ImageCard
      key={String(artist?.id)}
      src={artist?.image?.cdnUrl || artist?.image || "https://fastly.picsum.photos/id/650/1080/1920.jpg?hmac=uWMMqWpIfHhCKAFgBLkrpooauzfIaIpCX47VqDYeYV8"}
      alt={artist?.name}
      headline={artist?.name}
      width="75vw"
      aspectRatio="9/16"
      borderRadius="24px"
      overlayDirection="bottom"
      overlayStartColor="transparent"
      overlayEndColor="var(--primary-colour)"
      overlayStartPosition="0%"
      overlayEndPosition="95%"
      textPosition="bottom"
      textAlign="left"
      textColor="#ffffff"
      fontSize="1.75rem"
      fontWeight="700"
      padding="24px"
    />
  );

  const renderDesktopArtist = (artist, index) => (
    <AnimatedElement 
      key={String(artist?.id)}
      delay={baseDelay + (index * 0.1)}
      duration={0.8}
    >
      <DesktopImageCard
        src={artist?.image?.cdnUrl || artist?.image || "https://fastly.picsum.photos/id/650/1080/1920.jpg?hmac=uWMMqWpIfHhCKAFgBLkrpooauzfIaIpCX47VqDYeYV8"}
        alt={artist?.name}
        headline={artist?.name}
        aspectRatio="9/16"
        borderRadius="24px"
        overlayDirection="bottom"
        overlayStartColor="transparent"
        overlayEndColor="var(--primary-colour)"
        overlayStartPosition="0%"
        overlayEndPosition="95%"
        textPosition="bottom"
        textAlign="left"
        textColor="#ffffff"
        fontSize="1.5rem"
        fontWeight="700"
        padding="24px"
      />
    </AnimatedElement>
  );

  return (
    <Section id='lineup'>
      <AnimatedElement {...ANIMATION_PRESET.CONTENT} delay={baseDelay}>
        <StyledTypographyGroup
          title={title}
          subtitle={artists?.length ? subtitle : ''}
          // paragraph={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi tincidunt ac sed aliquam proin non tortor integer malesuada. '}
          align="center"
        />
      </AnimatedElement>

      <GenreList>
        {genres?.map((genre, index) => (
          <TagWrapper 
            key={index}
            delay={baseDelay + (index * tagDelay)}
            duration={1.2}
            easing="cubic-bezier(0.4, 0, 0.2, 1)"
          >
            <Tag background="var(--accent-colour)">{genre}</Tag>
          </TagWrapper>
        ))}
      </GenreList>

      {artists.length > 0 && (
        <>
          <MobileArtistList>
            <AnimatedElement {...ANIMATION_PRESET.MEDIA} delay={baseDelay + itemDelay}>
              <ScrollList
                items={artists}
                renderItem={renderMobileArtist}
                direction="horizontal"
                gap="12px"
                snap="start"
                showScrollbar={false}
                showArrows={false}
                padding="0 0 0 20px"
                itemWidth="75vw"
                height="calc(75vw * 16/9)"
              />
            </AnimatedElement>
          </MobileArtistList>

          <DesktopArtistGrid>
            {artists?.map(renderDesktopArtist)}
          </DesktopArtistGrid>
        </>
      )}
    </Section>
  );
};

ArtistLineup.propTypes = {
  artists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      name: PropTypes.string,
      image: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          cdnUrl: PropTypes.string
        })
      ])
    })
  ),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string)
};

export default ArtistLineup;
