/**
 * ProductListSection component
 * @description Section component for displaying a list of products with type switching
 */
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import ProductList from '../../components/ProductList';
import ProductTypeSwitcher from '../../components/ProductTypeSwitcher';
import { DrawerProvider } from '../../../../../atomic/molecules/Drawer/DrawerContext';
import { ProductProvider } from '../../context/ProductContext';
import { BasketProvider } from '../../../basket/context/BasketContext';
import BasketErrorBoundary from '../../../basket/components/BasketErrorBoundary';

// Styled components
const Section = styled.section`
  width: 100vw;
  padding: 100px 0;
  padding-bottom: 120px;
  background: var(--surface-colour);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  @media (min-width: 768px) {
    gap: 4rem;
  }
`;

const Container = styled.div`
  width: 100vw;
  max-width: ${props => props.$maxWidth}px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 768px) {
    padding: 0 80px;
  }
`;

const AnimatedProductList = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// Animation variants
const listVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0
  })
};

const transition = {
  x: { type: "spring", stiffness: 300, damping: 30 },
  opacity: { duration: 0.2 }
};

/**
 * ProductListSection component
 * @param {Object} props - Component props
 * @param {Array} props.productTypes - Array of product type objects
 * @param {Object} props.productsByType - Object mapping type IDs to product arrays
 * @param {number} props.maxWidth - Maximum width of the container
 * @param {Object} props.event - Event object to pass to product cards
 * @param {string} props.className - Additional CSS classes
 */
const ProductListSection = ({
  productTypes = [],
  productsByType = {},
  maxWidth = 1200,
  event,
  className
}) => {
  const [activeType, setActiveType] = useState(productTypes[0]);
  const [direction, setDirection] = useState(0);


  // Validate and filter product types
  const validProductTypes = useMemo(() => {
    return productTypes.filter(type => 
      type && 
      productsByType[type]?.length > 0
    );
  }, [productTypes, productsByType]);

  const handleTypeChange = (type) => {
    const oldIndex = validProductTypes.findIndex(type => type === activeType);
    const newIndex = validProductTypes.findIndex(type => type === type);
    setDirection(oldIndex > newIndex ? -1 : 1);
    setActiveType(type);
  };

  console.log('productTypes', productTypes)
  console.log('validProductTypes', validProductTypes)


  // Return null if no valid product types
  if (!validProductTypes.length) {
    console.warn('No valid product types found');
    return null;
  }

  const activeProducts = productsByType[activeType];

  return (
    <BasketErrorBoundary>
      <DrawerProvider>
        <BasketProvider>
          <ProductProvider>
            <Section className={className} id="tickets">
              <Container $maxWidth={maxWidth}>
                {validProductTypes.length > 1 && (
                  <ProductTypeSwitcher
                    types={validProductTypes}
                    activeType={activeType}
                    onTypeChange={handleTypeChange}
                    direction={direction}
                    onDirectionChange={setDirection}
                  />
                )}
                
                <AnimatePresence initial={false}  mode="popLayout" custom={direction}>
                  <AnimatedProductList
                    data-testid="animated-product-list"
                    key={activeType}
                    custom={direction}
                    variants={listVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={transition}
                  >
                    {activeProducts && (
                      <ProductList
                        products={activeProducts}
                        event={event}
                        maxWidth={maxWidth}
                      />
                    )}
                  </AnimatedProductList>
                </AnimatePresence>
              </Container>
            </Section>
          </ProductProvider>
        </BasketProvider>
      </DrawerProvider>
    </BasketErrorBoundary>
  );
};

export default ProductListSection;
