import * as Sentry from "@sentry/react";

const ErrorSeverity = {
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
  CRITICAL: "critical",
};

export const handleError = (error, severity = ErrorSeverity.MEDIUM) => {
  // Log all errors to Sentry
  Sentry.captureException(error);

  switch (severity) {
    case ErrorSeverity.LOW:
      // Silent logging, no user notification
      console.warn(error);
      break;
    case ErrorSeverity.MEDIUM:
      // Show a non-intrusive notification to the user
      showErrorToast(error.message);
      break;
    case ErrorSeverity.HIGH:
      // Show an error page or modal to the user
      showErrorPage(error);
      break;
    case ErrorSeverity.CRITICAL:
      // Show a critical error message and possibly reload the app
      showCriticalError(error);
      break;
  }
};

// Implement these functions based on your UI components
const showErrorToast = (message) => {
  // Use your toast library to show a non-intrusive error message
};

const showErrorPage = (error) => {
  // Navigate to or render your ErrorFallback component

};

const showCriticalError = (error) => {
  // Show a critical error message and possibly reload the app
};
