import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

/**
 * Container that handles the overall layout and positioning
 */
const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.$height || 'auto'};
  padding: ${props => props.$padding || '0'};
  margin: 40px 0;
  overflow: visible;  /* Allow content to overflow container */
`;

/**
 * ScrollArea that handles the scrolling behavior and appearance
 */
const ScrollArea = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow-x: ${props => props.$direction === 'horizontal' ? 'auto' : 'hidden'};
  overflow-y: hidden;
  
  /* Firefox */
  scrollbar-width: ${props => props.$showScrollbar ? 'thin' : 'none'};
  
  /* Chrome, Safari, Edge */
  &::-webkit-scrollbar {
    width: ${props => props.$showScrollbar ? '6px' : '0'};
    height: ${props => props.$showScrollbar ? '6px' : '0'};
  }
  
  /* Touch behavior */
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: ${props => props.$direction === 'horizontal' ? 'x mandatory' : 'y mandatory'};
  
  /* Prevent scroll chaining */
  overscroll-behavior: contain;
`;

/**
 * List that handles item layout and spacing
 */
const List = styled.div`
  display: flex;
  flex-direction: ${props => props.$direction === 'horizontal' ? 'row' : 'column'};
  gap: ${props => props.$gap || '16px'};
  padding: ${props => props.$padding || '0'};
  width: ${props => props.$direction === 'horizontal' ? 'max-content' : '100%'};
  
  /* Handle different scroll snap alignments */
  > * {
    scroll-snap-align: ${props => props.$snapAlign || 'start'};
    flex: ${props => props.$itemFlex || '0 0 auto'};
  }
`;

/**
 * Navigation arrow button styling
 */
const ArrowButton = styled(motion.button)`
  position: absolute;
  top: 50%;
  ${props => props.$direction === 'prev' ? 'left: 8px;' : 'right: 8px;'}
  transform: translateY(-50%);
  background: var(--background-colour);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  svg {
    width: 24px;
    height: 24px;
    color: var(--text-colour);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  @media (hover: hover) {
    opacity: 0;
    transition: opacity 0.2s ease;
    
    ${Container}:hover & {
      opacity: 1;
    }
  }
`;

/**
 * ScrollList component for displaying items in a scrollable list
 * @param {Array} items - Array of items to render
 * @param {Function} renderItem - Function to render each item
 * @param {string} direction - Scroll direction ('horizontal' or 'vertical')
 * @param {string} gap - Gap between items
 * @param {string} snap - Snap behavior ('start', 'center', 'end', or null)
 * @param {boolean} showScrollbar - Whether to show the scrollbar
 * @param {boolean} showArrows - Whether to show navigation arrows
 * @param {string} height - Height of the container
 * @param {string} padding - Padding around the list
 * @param {string} itemWidth - Width of each item (for horizontal lists)
 * @param {string} itemHeight - Height of each item (for vertical lists)
 */
const ScrollList = ({
  items,
  renderItem,
  direction = 'horizontal',
  gap = '16px',
  snap = null,
  showScrollbar = false,
  showArrows = true,
  height = 'auto',
  padding = '0',
  itemWidth,
  itemHeight,
  ...props
}) => {
  const scrollRef = useRef(null);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Update arrow visibility based on scroll position
  const updateArrowVisibility = useCallback(() => {
    if (!scrollRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setShowPrevArrow(scrollLeft > 0);
    setShowNextArrow(scrollLeft < scrollWidth - clientWidth - 1);
  }, []);

  // Handle scroll events
  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    scrollElement.addEventListener('scroll', updateArrowVisibility);
    window.addEventListener('resize', updateArrowVisibility);

    return () => {
      scrollElement.removeEventListener('scroll', updateArrowVisibility);
      window.removeEventListener('resize', updateArrowVisibility);
    };
  }, [updateArrowVisibility]);

  // Handle mouse/touch drag scrolling
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Handle arrow navigation
  const scroll = (direction) => {
    const { current: scroll } = scrollRef;
    if (!scroll) return;

    const itemWidth = scroll.children[0]?.children[0]?.offsetWidth || 0;
    const scrollAmount = itemWidth + parseInt(gap);
    
    scroll.scrollBy({
      left: direction === 'next' ? scrollAmount : -scrollAmount,
      behavior: 'smooth',
    });
  };

  return (
    <Container
      $height={height}
      $padding={padding}
      {...props}
      name="scroll-list"
    >
      <ScrollArea
        ref={scrollRef}
        $direction={direction}
        $showScrollbar={showScrollbar}
        $snap={snap}
        onMouseDown={direction === 'horizontal' ? handleMouseDown : undefined}
        onMouseMove={direction === 'horizontal' ? handleMouseMove : undefined}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <List
          $direction={direction}
          $gap={gap}
          $snapAlign={snap}
          $itemFlex={direction === 'horizontal' ? `0 0 ${itemWidth || 'auto'}` : '1'}
          style={{
            height: direction === 'vertical' ? itemHeight : 'auto',
          }}
        >
          {items.map((item, index) => (
            <div key={index}>
              {renderItem(item, index)}
            </div>
          ))}
        </List>
      </ScrollArea>

      {showArrows && direction === 'horizontal' && (
        <AnimatePresence>
          {showPrevArrow && (
            <ArrowButton
              $direction="prev"
              onClick={() => scroll('prev')}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <FiChevronLeft />
            </ArrowButton>
          )}
          {showNextArrow && (
            <ArrowButton
              $direction="next"
              onClick={() => scroll('next')}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <FiChevronRight />
            </ArrowButton>
          )}
        </AnimatePresence>
      )}
    </Container>
  );
};

ScrollList.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  gap: PropTypes.string,
  snap: PropTypes.oneOf(['start', 'center', 'end', null]),
  showScrollbar: PropTypes.bool,
  showArrows: PropTypes.bool,
  height: PropTypes.string,
  padding: PropTypes.string,
  itemWidth: PropTypes.string,
  itemHeight: PropTypes.string,
};

export default ScrollList;
