import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FiCheck } from 'react-icons/fi';
import { premiumAnimation, premiumInteractive } from '../../../tokens/animations';

const checkAnimation = keyframes`
  0% {
    stroke-dashoffset: 20;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${premiumInteractive}
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const CheckboxBox = styled(motion.div)`
  width: 24px;
  height: 24px;
  border: 1px solid rgba(191, 181, 147, 0.2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-colour);
  ${premiumAnimation}
  
  ${props => props.$isChecked && css`
    border-color: var(--accent-colour);
    background: linear-gradient(
      145deg,
      var(--accent-colour) 0%,
      var(--accent-colour-faded) 100%
    );
    box-shadow: 0 0 0 1px var(--accent-colour-faded),
                0 2px 4px rgba(0, 0, 0, 0.2);
  `}

  ${props => props.$error && css`
    border-color: var(--error-colour);
    &:focus {
      border-color: var(--error-colour);
      box-shadow: 0 0 0 1px var(--error-colour),
                  0 2px 4px rgba(181, 89, 89, 0.2);
    }
  `}

  ${props => props.$disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}

  svg {
    color: var(--text-colour);
    width: 16px;
    height: 16px;
    stroke-width: 2.5;
    stroke-dasharray: 20;
    animation: ${props => props.$isChecked ? css`${checkAnimation} 0.3s ease forwards` : 'none'};
  }
`;

const Label = styled.span`
  color: var(--text-colour);
  font-size: 14px;
  user-select: none;
  opacity: ${props => props.$disabled ? 0.5 : 1};
`;

const ErrorMessage = styled(motion.div)`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 4px;
  opacity: 0.9;
`;

const Checkbox = forwardRef(({
  checked = false,
  onChange,
  label,
  error = false,
  errorMessage,
  disabled = false,
  className,
  ...props
}, ref) => {
  const handleChange = (event) => {
    if (!disabled && onChange) {
      onChange(event);
    }
  };

  return (
    <div className={className}>
      <Container
        onClick={() => !disabled && onChange?.(!checked)}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <HiddenCheckbox
          ref={ref}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
        <CheckboxBox
          $isChecked={checked}
          $error={error}
          $disabled={disabled}
          whileHover={!disabled && { scale: 1.05 }}
          whileTap={!disabled && { scale: 0.95 }}
        >
          {checked && <FiCheck />}
        </CheckboxBox>
        {label && <Label $disabled={disabled}>{label}</Label>}
      </Container>
      <AnimatePresence>
        {error && errorMessage && (
          <ErrorMessage
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </AnimatePresence>
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.node,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Checkbox;
