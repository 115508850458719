import styled from "styled-components";

const GallerySection = styled.section`

`;

const GalleryScroll = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar { 
    display: none;
  }

  /* Add padding to the container */
  padding: 0 0px;
  border-radius: 30px;

`;

const GalleryItem = styled.div`
  flex: 0 0 auto;
  width: 100%;
  scroll-snap-align: start;

  &:not(:only-child) {
    width: 300px;
    margin-right: 20px;
  }
`;

export {
    GallerySection,
    GalleryScroll,
    GalleryItem
}