import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Section from './Section';
import { InfoItem } from '../../../../components/ui/InfoItemComponent';
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {convertToCurrency} from "../../../../js/helpers/helpers.js";
import {extractColorCodeFromCssVariable} from "../../../themes/js/utils.js";

// #region constants

// #endregion

// #region styled-components
const OrderItemContainer = styled(InfoItem)`
    width: 100%;
    padding: 10px;
    border: 1px solid ${extractColorCodeFromCssVariable('--accent-colour')};
    background: rgba(255,255,255,0.03);
`

const ProductName = styled.p`
    max-width: 200px;
    text-align: center !important;

`
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

/**
 * 
 */
const OrderItems = ({ items }) => {

    if(!items) return;

    return (
        <Section display='flex'>
            <DividerWithText text={'Items'} />
            {
                items.map((item, index) => {
                    return (
                        <OrderItem key={index} item={item} />
                    )
                })
            }
        </Section>
    );
}

const OrderItem = ({ item }) => {

    if(!item) return;


    return (
        <OrderItemContainer>
            <p>{item.quantity}</p>
            <ProductName >{item.name}</ProductName>
            <p>{convertToCurrency(item.total)}</p>
        </OrderItemContainer>
    )
}

OrderItems.propTypes = propTypes;
// #endregion

export default OrderItems;
