import Basket from "../index.js";
import React from "react";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import {useEvent} from "../../../events/js/hooks/index.js";
import { useApiQuery } from "../../../../hooks/customHooks.js";

export const useBasket = () => {

    const { openDrawer, closeDrawer, innerWidth } = useDrawer();
    const { event } = useEvent();
    const openBasket = () => openDrawer(<Basket event={event} />, 'Basket', innerWidth > 768 ? 'right' : 'bottom')
    const close = () => closeDrawer();

    const useGetBasket = (id) => {
        const {data, isLoading, isSuccess, error} = useApiQuery(`basket/${id}`);
        return {
            isLoading,
            isSuccess,
            error,
            data: data?.data
        }
    }

    return {
        open: openBasket,
        close,
        useGetBasket
    }
}