import React from 'react';
import {useProposal} from "../js/hooks/useProposal.jsx";
import styled from "styled-components";
import {ModernTextBlock, TextBlock1} from "../../../components/ui/sections/components/TypgographySections/index.js";
import {RectangleCardComponent} from "../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent.jsx";
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {DefaultHero} from "../../../components/heroes/index.js";
import {HeroImage} from "../../../components/heroes/HeroImage.jsx";
import {ImageWithFade} from "../../../components/ui/images/ImageWithFade.jsx";
import {ProposalSection} from "../components/sections/proposals.sections.styles.jsx";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import ScrollingTextChevron from '../../../components/ui/ScrollingTextChevron/ScrollingTextChevron';
import { Car } from 'lucide-react';
import { MembersCardComponent } from '../../cards/components/indx.js';
import { HorizontalFeaturedListLayout } from '../../../layout/ListLayouts/HorizontalFeaturedListLayout.jsx';
import TextBlock2 from "../../../components/ui/sections/components/TypgographySections/TextBlock2.jsx";
import {VideoComponent} from "../../../components/ui/VideoComponent.jsx";
import {FloatingNav} from "../../../layout/components/FloatingNav";
import {useNavigate, useParams} from "react-router-dom";
import { IoDocumentTextOutline, IoTicketSharp, IoCashSharp, IoImagesSharp } from "react-icons/io5";
import { BsLightningCharge } from "react-icons/bs";
import {useCards} from "../../cards/js/hooks/useCards.jsx";

const PageContainer = styled.section``
const HeroContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height:100dvh;
    padding-bottom: 120px;
`
const Section = styled(ProposalSection)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 100px 20px;
    gap: 20px;
`

const CardsSection = styled(Section)``

const PrimaryProposalPage = () => {

    const  {setProposal, getProposal} = useProposal();
    const { openDrawer } = useDrawer();
    const { getCards } = useCards();
    const params = useParams();
    const slug = params.slug;
    const navigate = useNavigate();



    const cards = getCards().data?.data.filter(card => card.tiers[0].image !== null);


    const chevronSections = [
        "THESE ARE NOT SIMPLY LOYALTY CARDS!",
        "THEY'RE",
        "NOCTURNAL MEMBERSHIP",
        "The restaurant's interior is tastefully decorated with elegant fixtures and comfortable seating, creating a relaxed and comfortable dining space."
    ];

    return (
        <PageContainer>
            <HeroContainer>
                <ImageWithFade image={'https://picsum.photos/1350/1350'}  />
                <TextBlock1 title={'CHINAWHITE'} subtitle={'AS MESSAGE TO'} paragraph={'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit. consectetur adipiscing elit. consectetur adipiscing elit'} />

            </HeroContainer>
            <Section>
                <ModernTextBlock
                maxWidth={innerWidth} 
                    textItems={[
                        { type: 'creative', content: 'The Future' },
                        { type: 'small', content: 'Belongs to the brands who build'},
                        { type: 'creative', content: 'Communities' },
                        { type: 'small', content: 'So what is our role in this future?' },
                        { type: 'creative', content: 'WE BUILD THE BRIDGES' },
                        { type: 'generic', content: 'THAT FACILITATE VALUE DRIVEN TRANSACTIONS.' },
                        { type: 'small', content: 'BETWEEN YOU, YOUR PARTNERS AND YOUR CUSTOMERS'},
                        { type: 'generic', content: 'TURING EVERY'},
                        { type: 'creative', content: 'Interaction' },
                        { type: 'small', content: 'INTO AN OPPORTUNITY FOR GROWTH' }
                    ]}
                />
            </Section>
            {/* <Section>
                <ScrollingTextChevron sections={chevronSections} />
            </Section> */}
                    <HorizontalFeaturedListLayout card={'MembersCardComponent'} data={cards} />
            <Section>
                <TextBlock2
                    text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit. consectetur adipiscing elit. consectetur adipiscing elit'}
                    title={'THESE ARE NOT SIMPLY LOYALTY CARDS!'}
                />
                <VideoComponent url={'https://pub-a305029e2a79415898ef080b84d16b39.r2.dev/videos/Nocturnal-Company-Reveal.MP4'} />
                <TextBlock2
                    text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit. consectetur adipiscing elit. consectetur adipiscing elit'}
                    title={'THESE ARE NOT SIMPLY LOYALTY CARDS!'}
                    align={'right'}
                />
            </Section>
        </PageContainer>
    );
};

export default PrimaryProposalPage;