import React from 'react'
import styled from 'styled-components';
import { FeaturedCardComponent } from '../../components/ui/cards/FeaturedCardComponent';
import { cardComponentMap } from '../../components/ui/cards/cardComponentMap';
import { InstructionTitle } from '../../components/ui/typography/InstructionTitle';

const ListContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 20px;
    min-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    
    /* Hide scrollbar but keep functionality */
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }

    /* Ensure container allows children to maintain their width */
    & > * {
        flex: 0 0 auto;
        width: auto;
    }

    /* Remove center alignment on desktop as it interferes with natural scrolling */
    @media screen and (min-width: 1024px) {
        padding: 20px 40px;
        &::after {
            content: '';
            padding-right: 20px;
        }
    }
`

export const HorizontalFeaturedListLayout = ({ data, card, metadata, model, onClick, style }) => {

    console.log(data)
  return (
        <div style={{
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
            ...style
        }}>
            <ListContainer name={'horizontal-list-container'}>
                {
                    data && data.reverse().map((item, index) => {
                        const CardComponent = card ? cardComponentMap[card] : cardComponentMap['FeaturedCardComponent']
                        return (
                            <CardComponent
                                key={index}
                                data={item}
                                src={item?.cdnUrl}
                                height={metadata?.height}
                                width={metadata?.width}
                                numberOfItems={data.length}
                                data_model={model}
                                card={item}
                                tier={item.default_tier}
                                onClick={onClick}
                                cardName={item?.name}
                                tierName={item?.tiers[0]?.name}
                                points={item.tiers[0]?.initial_value}
                                cardType={item?.type}
                                image={item.tiers[0]?.image?.cdnUrl}
                            />
                        )
                    })
                }
            </ListContainer>
        </div>
  )
}
