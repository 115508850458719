import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { DrawerContainer } from './DrawerContainer';

const DrawerContext = createContext(null);

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};

export const DrawerProvider = ({ children }) => {
  const [drawers, setDrawers] = useState({
    activeDrawerId: null,
    drawerStates: {},
    nestedDrawers: new Set()
  });

  const registerNestedDrawer = useCallback((drawerId) => {
    setDrawers(prev => ({
      ...prev,
      nestedDrawers: new Set([...prev.nestedDrawers, drawerId])
    }));
  }, []);

  const unregisterNestedDrawer = useCallback((drawerId) => {
    setDrawers(prev => {
      const newNestedDrawers = new Set(prev.nestedDrawers);
      newNestedDrawers.delete(drawerId);
      return {
        ...prev,
        nestedDrawers: newNestedDrawers
      };
    });
  }, []);

  const openDrawer = useCallback((drawerId, content, title, position = 'bottom', isNested = false) => {
    setDrawers(prev => {
      if (isNested) {
        return {
          ...prev,
          drawerStates: {
            ...prev.drawerStates,
            [drawerId]: {
              isOpen: true,
              content,
              title,
              position,
              history: []
            }
          }
        };
      }

      const updatedDrawerStates = { ...prev.drawerStates };
      Object.keys(updatedDrawerStates).forEach(key => {
        if (!prev.nestedDrawers.has(key)) {
          updatedDrawerStates[key] = {
            ...updatedDrawerStates[key],
            isOpen: false,
            history: []
          };
        }
      });

      return {
        ...prev,
        activeDrawerId: drawerId,
        drawerStates: {
          ...updatedDrawerStates,
          [drawerId]: {
            isOpen: true,
            content,
            title,
            position,
            history: []
          }
        }
      };
    });
  }, []);

  const closeDrawer = useCallback((drawerId) => {
    setDrawers(prev => ({
      ...prev,
      activeDrawerId: prev.activeDrawerId === drawerId ? null : prev.activeDrawerId,
      drawerStates: {
        ...prev.drawerStates,
        [drawerId]: {
          ...prev.drawerStates[drawerId],
          isOpen: false,
          content: null,
          title: '',
          history: []
        }
      }
    }));
  }, []);

  const goBack = useCallback((drawerId) => {
    setDrawers(prev => {
      const drawerState = prev.drawerStates[drawerId];
      if (!drawerState) return prev;

      const newHistory = [...drawerState.history];
      const lastItem = newHistory.pop();

      if (!lastItem) {
        return {
          ...prev,
          drawerStates: {
            ...prev.drawerStates,
            [drawerId]: {
              ...drawerState,
              isOpen: false,
              content: null,
              title: '',
              history: []
            }
          }
        };
      }

      return {
        ...prev,
        drawerStates: {
          ...prev.drawerStates,
          [drawerId]: {
            ...drawerState,
            content: lastItem.content,
            title: lastItem.title,
            history: newHistory
          }
        }
      };
    });
  }, []);

  const value = useMemo(() => ({
    openDrawer,
    closeDrawer,
    goBack,
    registerNestedDrawer,
    unregisterNestedDrawer,
    getDrawerState: () => drawers.drawerStates
  }), [
    openDrawer,
    closeDrawer,
    goBack,
    registerNestedDrawer,
    unregisterNestedDrawer,
    drawers.drawerStates
  ]);

  return (
    <DrawerContext.Provider value={value}>
      {children}
      <DrawerContainer />
    </DrawerContext.Provider>
  );
};

export default DrawerContext;
