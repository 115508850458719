// useCardHolderCheck.js
import { useContext } from 'react';
import { makeRequest } from '../../../../services/axios';
import { AuthContext } from '../../../authentication';
import { toast } from 'react-toastify';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import CardSelectorComponent from '../../components/CardSelectorComponent';
import Basket from '../../../ecommerce/basket';
import {useHandleError} from "../../../error-handling/js/hooks/index.js";

/**
 * Custom hook for checking if a user is a card holder and can select an item.
 * @return {Function} - checkCardHolder
 */
export const useCardHolderCheck = () => {
  // Pulling header generation function from AuthContext
  const { header } = useContext(AuthContext);
  const { openDrawer } = useDrawer();
  const { showErrorToast } = useHandleError();

  /**
   * Function to check if user is a card holder.
   * @param {Object} item - The item to check.
   * @param {Boolean} isAuthenticated - If user is authenticated.
   * @return {Boolean} - Returns false if the check fails.
   */
  
  const checkCardHolder = async (item, isAuthenticated) => {
    // Check if item is only for card holders and user is not authenticated
    // if (item.card_holders_only && !isAuthenticated) {
    //   toast.warning('You need to log in.');
    //   return false;
    // }

    // If the item is restricted to card holders
    if (item.card_holders_only) {
      const data = {
        item_id: item.id,
        card_tiers: item.cardTiers
      };

      try {
        const response = await makeRequest('POST', 'member/check_if_member', data, header(), 'application/json');

        if (!response.valid) {
          // toast.warning(response.message);
          // eslint-disable-next-line react/react-in-jsx-scope
          openDrawer(<CardSelectorComponent tiers={response.tiers} />, 'Members Only Product', 'bottom');
          return false;
        }

      } catch (error) {
        console.log('Error checking if member', error)
        showErrorToast(error);
        return false;
      }
    }
    
    return true; // If everything is fine, return true
  };

  return checkCardHolder;  // Returning the function for external use
};
