export const modelsMap = {
    businesses: {single: 'business', plural: 'businesses', relations:'location,category,gallery.images'},
    rewards: {single: 'reward', plural: 'rewards'},
    events: {single: 'event', plural: 'events', relations: 'theme,image,location,category', card: 'RectangleCardComponent', featuredCard: 'SquareCardComponent'},
    cards: {single: 'card', plural: 'cards', relations: 'tiers,default_tier,category', card: 'LoyaltyCard', featuredCard: 'LoyaltyCard'},
};

export function getModelData(query) {
    // Search each model to see if the single or plural matches the query
    for (let key in modelsMap) {
        if (modelsMap[key].single === query || modelsMap[key].plural === query) {
            return modelsMap[key];
        }
    }
    // Return null or a default value if no match is found
    return null;
}
