import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../../../services/axios';
import { AuthContext } from '..';
import { REPORT_BUG_URL } from '../../../js/Helper';
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import LogoComponent from "../../../components/ui/LogoComponent.jsx";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";

const Div = styled.div`
    justify-content: center !important;

    @media screen and (min-width: 768px){
        justify-content: flex-start !important;
    }
`
const Form = styled.form`
    max-width: 300px;
`

export const PasswordReset = () => {

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [displaySupport, setDisplaySupport] = useState('none');

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token')
    const emailParam = searchParams.get('email')
    const navigate = useNavigate();

    const { externalLogin } = useContext(AuthContext)

    const handleSubmit = (event) => {
        event.preventDefault();

        if(!password) {
            setMessage('Must provide a password');
            setIsLoading(false)
            return
        } 
        
        setIsLoading(true)
        
        if(password !== confirmPassword) {
            setMessage('Passwords do not match');
            setIsLoading(false)
            return;
        }

        const data = {
            token: token,
            password: password,
            email: emailParam
        };

        const req = makeRequest('POST', 'password_reset', data, null )
        req.then((res) => {
            console.log('Response', res)
            if(res.status == 'success') {
                setMessage(res.message)
                externalLogin(res.authorisation, res.user)
                setIsLoading(false)
            } else {
                setMessage(res.message)
                setIsLoading(false)
                setDisplaySupport('block')
            }
        }).catch((error) => {
            console.log('Res Error', error)
            setMessage(error.message)
            setIsLoading(false)
        })
    }

    return (
        <>
            <StandardSection style={{justifyContent:'center', alignItems: 'center', display:'flex', height:'100vh', width: '100%'}}>

        {/*<img src='/nocturnal-512x512.png' width={'150px'}></img>*/}
          <Form onSubmit={handleSubmit}>
              <LogoComponent />
            <input type='email' name='email' value={emailParam} disabled={true} autoComplete='username' />
            <input type='password' name='password' placeholder='Password' onChange={(e) => setPassword(e.currentTarget.value)} autoComplete='new-password' required></input>
            <input type='password' name='confirmPassword' placeholder='Confirm Password' onChange={(e) => setConfirmPassword(e.currentTarget.value)} autoComplete='new-password' required></input>
            <ButtonComponent isLoading={isLoading} text="Reset Password" onClick={handleSubmit}/>
            {/* <button style={{width:'100%', maxWidth:'350px'}}>Submit Password</button> */}
            <p>{message && message}</p>
            <span style={{display:displaySupport}}>
                <p>If you are experiencing technical difficulties and need to report a bug please <a href={REPORT_BUG_URL} target='__blank'>click here</a></p>
            </span>
          </Form>
            </StandardSection>

        </>
      );
}
