import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useApiQuery} from '../../../../hooks/customHooks.js';
import {useDrawer} from '../../../../js/hooks/context/DrawerContext';
import {FloatingNav} from '../../../../layout/components/FloatingNav/index.jsx';
import {Loading} from '../../../../components/ui/Loading.jsx';
import {convertToCurrency} from '../../../../js/helpers/helpers.js';
import {ButtonComponent} from '../../../../components/ui/buttons/index.js';
import {scrollToElement, STRIPE_PUBLISHABLE_KEY} from '../../../../js/Helper.js';
import {DividerWithText} from '../../../../components/ui/LogoWall/DividerWithText.jsx';
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {updateTheme} from '../../../themes/js/utils.js';
import HeroWithSlider from "../../../../components/heroes/HeroWithSlider.jsx";
import {ProductInfoSection} from "../components/product-page-info/components/ProductInfoSection.jsx";
import {FaqsComponent} from "../../../../components/FAQs/index.js";
import {Reviews} from "../../../reviews/index.js";
import {VideoGallery} from "../../../../components/galleries/index.js";
import PaymentSection from "../../checkout/components/PaymentSection.jsx";
import StripePaymentForm from "../../services/stripe/components/StripePaymentForm.jsx";
import PaymentSectionComponent from "../../components/payment-section/PaymentSectionComponent.jsx";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useRewards} from "../../../rewards/js/hooks/RewardsContext.jsx";

const PageContainer = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -20px;
  position: relative;
  z-index: 3;
  gap: 20px;
  

  @media (min-width: 768px) {
    padding: 40px;
    margin-top: -40px;
  }

  @media (min-width: 1024px) {
    padding: 60px;
    margin-top: -60px;
  }
`;


// Move stripePromise outside component
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

// Add new StripeWrapper component
const StripeWrapper = ({ clientSecret, children }) => {
  const options = {
    clientSecret,
    // Add any additional Stripe options here
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

// Create a styled container for the payment form
const PaymentFormContainer = styled.form`
  width: 100%;
  max-width: 500px; // Optional: set a max-width if needed
  
  .StripeElement {
    width: 100%;
    margin-bottom: 20px;
  }
`;

// Update the PaymentForm component
const PaymentForm = ({ clientSecret, onPayment, isProcessing, amount, user }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    
    onPayment(stripe, elements);
  };

  return (
    <PaymentFormContainer onSubmit={handleSubmit}>
      <PaymentElement 
        stripe={stripePromise}
        options={{
          labels: "floating",
          appearance: {
            theme: 'night',
            labels: "floating",
            variables: {
              fontWeightNormal: '500',
              borderRadius: '12px',
              colorBackground: 'var(--primary-colour)',
              colorPrimary: 'var(--accent-colour)',
              accessibleColorOnColorPrimary: '#1A1B25',
              colorText: 'white',
              colorTextSecondary: 'white',
              colorTextPlaceholder: '#727F96',
              tabIconColor: 'white',
              logoColor: 'dark',
              gridRowSpacing: '10px',
            },
          }
        }}
      />
      <ButtonComponent 
        id='pay' 
        text={`Pay ${convertToCurrency(amount)} now`}
        variant='solid' 
        type="submit" 
        disabled={!stripe || isProcessing}
        isLoading={isProcessing}
        style={{marginTop: '20px', width: '100%', background: 'var(--success-colour)'}}
      />
    </PaymentFormContainer>
  );
};

const ProductPage = () => {
  const { id } = useParams();
  const { setRewards, rewards, selectedReward, discountedProductPrice } = useRewards();
  const path = `products/${id}?with=fileLinks,reviews,faqs,offers,business`;

  const onSuccess = (data) => {
    console.log('data', data);
    setRewards(data?.offers || data?.rewards);
  }

  const { data: product, isLoading, error } = useApiQuery(path, onSuccess);
  const { openDrawer } = useDrawer();
  const { addToBasket, isInBasket, emptyBasket } = useEcommerce();
  
  const handleBuyNow = () => {

    const productInBasket = isInBasket(product);

    if(!productInBasket) {
      emptyBasket();
      product.reward_id = selectedReward?.id;
      addToBasket(product, 1, selectedReward);
    }

    openDrawer(<PaymentSectionComponent product={product}  />);
  };


  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get('section');

  if(product?.business) {
    updateTheme(product.business.theme);
  }

  if (section) {
    scrollToElement(section);
  }


  const floatingNavItems = [
    { name: 'Info', destination: `?section=info`, icon: 'IoInformationCircleSharp', position: 1 },
    ...(rewards ? [{ name: 'Offers', destination: `?section=offers`, icon: 'IoGiftSharp', position: 4 }] : []),
    ...(product?.reviews?.length ? [{ name: 'Reviews', destination: `?section=reviews`, icon: 'IoStarSharp', position: 2 }] : []),
    ...(product?.faqs?.length ? [{ name: 'FAQ', destination: `?section=faq`, icon: 'IoCardSharp', position: 3 }] : []),
    { name: 'Buy Now', destination: handleBuyNow, icon: 'IoCartSharp', position: 5 },
  ];

  // FAQ data
  const faqData = product?.faqs?.map(faq => ({
    placeholder: faq.question,
    description: faq.answer
  }));

  // Sample gallery data
  const galleryData = product?.files?.filter((file) => file.name === 'videoGallery');

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <PageContainer>
      <HeroWithSlider data={product} videoGallery={galleryData} />
        <ContentSection>
            <ProductInfoSection product={product} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', maxWidth: '400px', margin: '20px auto' }}>
              <ButtonComponent
                onClick={handleBuyNow}
                text="Buy Now"
                variant="solid"
                style={{ width: '100%' }}
              />
              {/*<ButtonComponent*/}
              {/*  onClick={handleBuyNow}*/}
              {/*  text="Pay with Apple Pay"*/}
              {/*  variant="outline"*/}
              {/*  style={{ width: '100%', background: 'black', color: 'white', border: 'none' }}*/}
              {/*  icon={<img src="/apple-pay-logo.svg" alt="Apple Pay" style={{ height: '20px' }} />}*/}
              {/*/>*/}
            </div>
            {/* Commenting out make enquiry functionality */}
            {/* <MakeEnquiry /> */}
            <Reviews product={product} />
            <DividerWithText text='Business Information' style={{marginBottom: '20px'}} />

            <ContentSection style={{textAlign: 'center'}}>
              <h3>{product?.business?.name}</h3>
              <p>{product?.business?.description}</p>
            </ContentSection>

            {faqData.length > 0 && ( <FaqsComponent faqs={faqData} /> )}
        </ContentSection>

        <FloatingNav items={floatingNavItems} />
    </PageContainer>
  );
};

export default ProductPage;
