import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import { EventConfirmationLeft, EventConfirmationRight } from '../EventConfirmation/EventConfirmation';
import TwoColumnLayout from '../../../../atomic/templates/TwoColumnLayout/TwoColumnLayout';

const Container = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
`;

/**
 * BaseConfirmation Component
 * Acts as a router/controller for different types of confirmation pages
 * Determines which confirmation component to render based on the basketable type
 * and provides a consistent layout template
 */
const BaseConfirmation = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const basketableType = searchParams.get('type');
  const status = searchParams.get('status');

  const renderContent = (side) => {
    switch (basketableType) {
      case 'event':
        return side === 'left' 
          ? <EventConfirmationLeft id={id} status={status} />
          : <EventConfirmationRight id={id} />;
      default:
        return side === 'left' ? (
          <div>
            <h1>Confirmation</h1>
            <p>Thank you for your purchase!</p>
          </div>
        ) : null;
    }
  };

  return (
    // <Container>
      <TwoColumnLayout
        leftContent={renderContent('left')}
        rightContent={renderContent('right')}
        height={'full'}
        mobileLayout="leftOnly"
        leftRatio={50}
        rightRatio={50}
      />
    // </Container>
  );
};

BaseConfirmation.propTypes = {
  // Add any additional props if needed
};

export default BaseConfirmation;
