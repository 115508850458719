import {BACK_ICON, CARD_ICON, HOME_ICON, MENU_ICON, REWARD_ICON, TICKET_ICON} from "../../../js/icons.js";

const home = {
    name: 'User Home',
    destination: `/user/my-account`,
    icon: HOME_ICON,
    position: 1,
}

const menu = {
    name: 'Exit',
    destination: `/`,
    icon: BACK_ICON,
    position: 5,
}

const rewards = {
    name: 'Rewards',
    destination: `/user/rewards`,
    icon: REWARD_ICON,
    position: 2,
}

const cards = {
    name: 'Cards',
    destination: `/user/cards`,
    icon: CARD_ICON,
    position: 4,
}

const tickets = {
    name: 'Tickets',
    destination: `/user/tickets`,
    icon: TICKET_ICON,
    position: 3,

}
export const userAreaMenu = [home, tickets, rewards, cards, menu]
