import styled from "styled-components";

export const BasketContainer = styled.div`
    display: ${props => props.display};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* margin-top: 50px; */
    width: 100%;
    max-width: 450px;
    min-height: fit-content;
    height: fit-content;
    border-radius: var(--border-radius-default);
    /* box-shadow: var(--shadow-primary); */
    padding:30px;
    box-sizing: border-box;
    animation: fadeIn 1s;
    background-color: var(--primary-colour);
    color: white;

    @media screen and (min-width: 1920px){
        min-width: 30rem;
    }

`

export const Select = styled.select`
    width: 35px;
    height: 100%;
    font-size: small !important;
    box-shadow: none;
    border: none;
    text-align: center;
    background-color: rgba(0,0,0,0.01);
    color: white;
`
export const StyledOption = styled.option`
    text-align: left;
    color: white; /* Set the color for the options */
    color: red;
    -webkit-color: red;
    -moz-color: red;
`;

export const Header = styled.h2`
    margin: 0;
    margin-bottom: -20px;
    padding: 0;
    line-height: 1;
    color: var(--accent-colour);
    text-transform: uppercase;

`