import React from 'react';
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {StandardSection} from "../../../../layout/sections/StandardSection.jsx";
import {useMutation} from "@tanstack/react-query";
import {makeRequest} from "../../../../services/axios.js";
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useBasket} from "../../basket/hooks/useBasket.jsx";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";

const ProductPasswordForm = ({ product }) => {

    const { register,reset, handleSubmit, formState: { errors } } = useForm();
    const {showErrorToast} = useHandleError();
    const { open } = useBasket();
    const { addToBasket, updateConfig } = useEcommerce();

    const mutation = useMutation(
        (data) => {
            return makeRequest(
                'PUT',
                `products/${product.id}/passcode/${data.password}`,
                {passcode: data.password, productId: product.id}
            )
        },
        {
            // onError and onSuccess functions for mutation
            onError: (error, variables, context) => {
                showErrorToast(error)

            },
            onSuccess: (data, variables, context) => {
                if(data.valid) {
                    toast.success('Correct Password')
                    addToBasket(product, 1)
                    open()
                } else {
                    toast.error('Incorrect passcode')
                }

            },

        }
    );

    const onSubmit = (submitData) => {

        let formData = new FormData();

        Object.entries(submitData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        const data = Object.fromEntries(formData)

        try {
            mutation.mutate(data);
        } catch (error) {
            console.log('Error', error.message)
        }
    }

    return (
        <StandardSection>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type={'text'} {...register('password')} name={'password'} placeholder={'password'} required={true}/>
                <ButtonComponent isLoading={mutation.isLoading} variant={'solid'} text={'Submit'} />
            </form>
        </StandardSection>
    );
};

export default ProductPasswordForm;