import React from 'react'
import styled from 'styled-components'


    const TicketListContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        min-width: 100%;
        width: 100%;
        max-width: 500px;
        margin-top: 2rem;
        gap: 10px;


        @media screen  and (min-width: 768px) {
          max-height: fit-content;
        }

        @media screen  and (min-width: 1920px) {
          max-width: fit-content;
          max-height: fit-content;
          min-width: fit-content;
        }
    `

export const TicketList = (props) => {

    const { data, children, style } = props;


  return (
    <>
    <TicketListContainer style={style} >
        {children}
    </TicketListContainer>
    </>
  )
}
