/**
 * Event Page Container
 * @description Main event page container that handles data fetching, routing, and error handling
 */
import React, { useEffect, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FileNotFoundPage } from '../../../../pages/FileNotfoundPage';
import PrimaryEventPage from '../PrimaryEventPage';
import EventSkeleton from './components/EventSkeleton';
import EventErrorBoundary from './components/EventErrorBoundary';
import { useEvent } from '../../../../features/events/js/hooks';
import { useAffiliate } from '../../../../features/.feature_folder/js/hooks/useAffiliate';
import { useHandleError } from '../../../../features/error-handling/js/hooks';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import { updateTheme } from '../../../../features/themes/js/utils';
import { setBookingId } from '../../../../features/bookings/js/utils/storage';
import { LOCAL_STORAGE_AFFILIATE } from '../../../../features/.feature_folder/js/constants';
import { scrollToElement } from '../../../../js/Helper';
import { filterPartnersByRoles, filterProductsByCategory } from '../../../../features/events/js/utils';
import { PRODUCT_TABLE, PRODUCT_TICKET } from '../../../../features/events/js/constants';
import { useApiQuery } from '../../../../hooks/customHooks';
import PrimaryEventHeroSkeleton from '../../sections/PrimaryEventHero/PrimaryEventHeroSkeleton';
import PrimaryEventInfoSection from '../../sections/PrimaryEventInfoSection/PrimaryEventInfoSection';

/**
 * Event component
 * @description Handles event data fetching, routing, and rendering
 */
const Event = React.memo(() => {

  // Hooks
  const { setEvent, event: contextEvent } = useEvent();
  const { checkIfAffiliateExists, storeAffiliate } = useAffiliate();
  const { showErrorPage, reportError, showErrorToast } = useHandleError();
  const params = useParams();
  const location = useLocation();
  const { isOpen } = useDrawer();

  // URL parameters
  const slug = params.slug;
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const section = searchParams.get('section');


  // API Query
  const { data: eventData, isLoading, error } = useApiQuery(
    `event/${slug}?with=location,business,theme,partners.image,products.dependencies,fileLinks.file,eventDates`,
    (data) => {
      setEvent(data?.data);
      updateTheme(data?.data?.theme);
    },
    (error) => {
      console.error('Error fetching event:', error);
      reportError(error);
      showErrorToast('Failed to load event');
    },
    { enabled: !!slug }
  );


  // Side effects
  useEffect(() => {
    setBookingId(slug);
    handleAffiliate();
  }, [slug]);

  useEffect(() => {
    if (!isOpen && contextEvent?.theme) {
      // updateTheme(contextEvent.theme);
    }
  }, [isOpen, contextEvent?.theme]);

  // Affiliate handling
  const handleAffiliate = useCallback(async () => {
    try {
      const affiliate = searchParams.get('affiliate');
      if (!affiliate) return;

      const affiliateExists = await checkIfAffiliateExists(affiliate);
      if (affiliateExists) {
        storeAffiliate(affiliate);
        localStorage.setItem(LOCAL_STORAGE_AFFILIATE, affiliate);
      }
    } catch (error) {
      reportError(error);
      showErrorToast('Failed to process affiliate');
    }
  }, [searchParams, checkIfAffiliateExists, storeAffiliate, reportError, showErrorToast]);

  // Data transformation
  const transformEventData = useCallback((data) => {
    if (!data) return null;

    return {
      ...data,
      partners: filterPartnersByRoles(data.partners),
      products_by_type: {
        [PRODUCT_TICKET]: filterProductsByCategory(data.products, PRODUCT_TICKET),
        [PRODUCT_TABLE]: filterProductsByCategory(data.products, PRODUCT_TABLE),
      },
      product_types: [
        { id: PRODUCT_TICKET, name: 'Tickets' },
        { id: PRODUCT_TABLE, name: 'Tables' },
      ],
    };
  }, []);

  // Loading and error states
  if (isLoading) {
    return <PrimaryEventHeroSkeleton />;
  }

  // Only show error page if there's an actual error
  if (error) {
    return <FileNotFoundPage />;
  }

  // Transform and validate event data
  const transformedEventData = transformEventData(eventData);
  console.log('V2 Event transformed data:', transformedEventData);

  if (!transformedEventData) {
    console.log('No event data available');
    return <FileNotFoundPage />;
  }

  // Render event page
  return (
    <>
      <Helmet>
        <title>{transformedEventData?.name || 'Event'}</title>
        <meta name="description" content={transformedEventData?.description || ''} />
      </Helmet>

      <PrimaryEventPage event={eventData?.data} />
      
   

      {section && (
        <script
          dangerouslySetInnerHTML={{
            __html: `window.addEventListener('load', function() { 
              ${scrollToElement}('${section}')
            })`,
          }}
        />
      )}
    </>
  );
});

Event.displayName = 'Event';

/**
 * EventWithErrorBoundary component
 * @description Wraps the Event component with error boundary
 */
const EventWithErrorBoundary = () => (
  <EventErrorBoundary>
    <Event />
  </EventErrorBoundary>
);

EventWithErrorBoundary.displayName = 'EventWithErrorBoundary';

export default EventWithErrorBoundary;
