import {React, useContext, useState} from 'react';
import styled from 'styled-components';
import {useMutation,} from '@tanstack/react-query'
import axios from 'axios';
import PropTypes from 'prop-types';
import {API_URL, SERVER_URL} from '../../../js/Helper';
import {AuthContext} from '../../authentication';
import {useNavigate} from 'react-router-dom';
import {Divider} from '../../../components/ui/Divider';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDrawer} from '../../../js/hooks/context/DrawerContext';
import PasswordResetForm from './PasswordResetForm';
import {ButtonComponent} from '../../../components/ui/buttons';
import {StandardSection} from '../../../layout/sections/StandardSection';
import {useEcommerce} from '../../ecommerce/js/context/EcommerceContext';
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {makeRequest} from "../../../services/axios.js";


const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  height: fit-content;
  max-height: fit-content;
  flex-grow: 2;
`
const Input = styled.input`
  height: 50px !important;
  max-height: 50px;
  width: 100%;
  color: var(--default-primary-colour);
  /* background-color: rgba(0,0,0,0.2) !important; */
  /* border: solid 2px var(--default-accent-colour) !important; */

`
const Button = styled.button`
  width: 100%;
  height: 45px;
  /* margin-top: 20px; */
  padding: 0px 20px;
  /* border-radius: var(--border-radius-default); */
  background-color: var(--cta-background-colour);
`



export function LoginForm({  afterLogin, create }) {

  const [displayPasswordReset, setDisplayPasswordReset] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [rememberMe, setRememberMe] = useState('');
  const [display, setDisplay] = useState();
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { externalLogin } = useContext(AuthContext);

  const navigate = useNavigate();
  const { openDrawer, closeDrawer } = useDrawer();
  const { updateConfig } = useEcommerce();
  const { showErrorPage, showErrorToast, reportError} = useHandleError()




  const mutationFn = (data) =>
    axios.post(`${API_URL}/${create ? 'register' : 'auth/login'}`, data, {
   
    });

  const { mutate, isError, isSuccess, error } = useMutation(
    mutationFn,
    {
      onSuccess: (userData) => {
        
        if(userData.status !== 200) {
          showErrorToast(userData.data.message)
          setIsLoading(false)
          return;
        }

        if(userData.status === 200) {
          toast.success(userData.data.message)
        
          let loginAttempt;

          loginAttempt = externalLogin(userData.data.authorisation, userData.data.user)
   
          if(!loginAttempt) {
            showErrorToast('Failed to log you in!');
            setIsLoading(false)
          }

          
          const loginEvent = new CustomEvent('loggedIn', {
            detail: {user: userData.data.user}
          })
          
          toast.success('Successfully Logged In')
          const afterAuth = sessionStorage.getItem('afterAuth');
          
          if(afterAuth == 'closeDrawer' || afterLogin == 'closeDrawer') {
            setIsLoading(false)
            closeDrawer()
            if(location.pathname.includes('checkout')) {
              window.dispatchEvent(loginEvent)
              updateConfig({isPaymentAllowed: true})
              sessionStorage.setItem('paymentAllowed', true)
              
            }
          } else if(afterAuth) {
            sessionStorage.removeItem('afterAuth')
            setIsLoading(false)
            navigate(afterAuth)
            return;
          } else if (afterLogin){
            closeDrawer()
            setIsLoading(false)
            navigate(afterLogin)
          } else {
            // navigate('/orders')
            closeDrawer()
            setIsLoading(false)
          }
        }
      },
      onError: (error) => {
        console.log('Error',  error)
        setIsLoading(false)
        toast.error(error?.response?.data?.message ?? error?.message ?? 'There was an error whilst trying to log you in');

        // showErrorToast(error?.response?.data?.message ?? error.message ?? 'There was an error whilst trying to log you in');
      },
      onSettled: () => {
        setIsLoading(false)
      }
    }
  );

  
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true)
    mutate({ email, password, rememberMe, firstName });
  };

  const handleAuth0Login = (provider) => {

    // window.location.replace(SERVER_URL + '/auth/google');

    const response = makeRequest('GET', SERVER_URL + '/auth/' + provider)
        .then((res) => {
          console.log(res)
        })
        .catch((error) => console.log(error))
  }

  return (
    <StandardSection >

      {/*<div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '10px'}}>*/}
      {/*  <ButtonComponent text={'Login With Google'} variant={'solid'} onClick={() => handleAuth0Login('google')} />*/}
      {/*  <ButtonComponent text={'Login With Facebook'} variant={'solid'} onClick={() => handleAuth0Login('facebook')} />*/}
      {/*</div>*/}

      <Divider style={{margin: '15px 0px'}} />
      <Form style={{display:display}} onSubmit={handleSubmit}>
        {
          create && 
          <Input
          type="text"
          id="first_name"
          value={firstName}
          placeholder="First Name"
          autoComplete='first_name'
          required
          onChange={(event) => setFirstName(event.target.value)}
        />

        }
        <Input
          type="email"
          id="login-email"
          value={email}
          placeholder="Email Address"
          autoComplete='email'
          required
          onChange={(event) => setEmail(event.target.value)}
        />

        <Input
          type="password"
          id="login-password"
          placeholder='Password'
          autoComplete='current-password'
          value={password}
          required
          onChange={(event) => setPassword(event.target.value)}
        />

        <Divider  />

        <ButtonComponent
        text={isLoading ? create ? 'Creating New Account' : 'Logging You In...' : create ? 'Create Account' : 'Log In'}
        variant={'solid'}
        isLoading={isLoading}
        isDisabled={isLoading}
        />

        {
          create && 
          <ButtonComponent
          variant={'outlined'}
          text={'Already Have An Account? Log in! '}
          onClick={() => openDrawer(<LoginForm afterLogin={afterLogin} />, 'Log In')}
          />
        }

        {
          !create &&
          <ButtonComponent
          variant={'outlined'}
          text={'Need to create an account?'}
          onClick={() => openDrawer(<LoginForm create afterLogin={afterLogin} />,  'Create Account')}
          />
        }

        
        {!create &&
        <ButtonComponent
        variant={'tertiary'}
        text={'REset Password'}
        onClick={() => openDrawer(<PasswordResetForm />, 'Reset Password')}
        />
        }


        {response && (
          <div>
            {response}
          </div>
        )}
      </Form>
    </StandardSection>
  );

}

LoginForm.propTypes = {
  afterLogin: PropTypes.string,
  redirect: PropTypes.bool,
  create: PropTypes.bool
};
