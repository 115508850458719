import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../../atoms/Box/Box';
import Heading from '../../atoms/Heading/Heading';
import Paragraph from '../../atoms/Paragraph/Paragraph';

const Container = styled(Box)`
  text-align: ${props => props.$align};
  display: flex;
  flex-direction: column;
  align-items: ${props => props.$align === 'center' ? 'center' : 'flex-start'};
  width: 100%;

  @media (min-width: 768px) {
    max-width: 100%;
    padding-left: ${props => props.$align === 'left' ? '80px' : '0'};
  }
`;

const StyledTitle = styled(Heading)`
  margin: 0 0 0.5rem 0;
  text-transform: uppercase;
  background: var(--accent-colour);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: -0.02em;

  @media (min-width: 768px) {
    font-size: 5rem;
    letter-spacing: -0.04em;
    margin: 0 0 1.5rem 0;
    line-height: 0.9;
    max-width: 1200px;
  }
`;

const StyledSubtitle = styled(Heading)`
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  line-height: normal;
  font-size: 1rem;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    opacity: 0.8;
    letter-spacing: -0.02em;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0.5rem 0 0 0;
  color: rgba(255, 255, 255, 0.9);
  max-width: ${props => props.$align === 'center' ? '600px' : 'none'};
  font-size: 0.875rem;
  line-height: 1.6;

  @media (min-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.7;
    margin-top: 1rem;
    opacity: 0.7;
    max-width: 500px;
  }
`;

const TypographyGroup = ({
  title,
  subtitle,
  paragraph,
  className,
  align = 'left',
  ...props
}) => {
  return (
    <Container 
      data-testid="typography-group" 
      className={className}
      $align={align}
      {...props}
    >
      {subtitle && (
        <StyledSubtitle 
          data-testid="typography-group-subtitle" 
          level={6}
        >
          {subtitle}
        </StyledSubtitle>
      )}
      {title && (
        <StyledTitle 
          data-testid="typography-group-title"
          level={1}
        >
          {title}
        </StyledTitle>
      )}
      {paragraph && (
        <StyledParagraph 
          data-testid="typography-group-paragraph"
          $align={align}
          // maxChars={120}
        >
          {paragraph}
        </StyledParagraph>
      )}
    </Container>
  );
};

TypographyGroup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  paragraph: PropTypes.string,
  className: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

TypographyGroup.displayName = 'TypographyGroup';

export default TypographyGroup;
