import styled from 'styled-components';
import { borderRadius } from '../../tokens/theme';

const Box = styled.div`
  padding: ${props => props.p || '0'};
  margin: ${props => props.m || '0'};
  background: ${props => props.bg || 'transparent'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  
  /* Regular border radius */
  ${props => props.radius && `border-radius: ${borderRadius[props.radius] || props.radius}`};
  
  /* Asymmetric border radius (top-left and bottom-right) */
  ${props => props.asymRadius && `border-radius: ${
    props.reverse 
      ? borderRadius.asymmetric.reversed[props.asymRadius] 
      : borderRadius.asymmetric[props.asymRadius]
  }`};
  
  /* Full rounded corners */
  ${props => props.rounded && `border-radius: ${borderRadius.full}`};

  /* Display properties */
  ${props => props.display && `display: ${props.display}`};
  ${props => props.flex && `flex: ${props.flex}`};
  ${props => props.flexDirection && `flex-direction: ${props.flexDirection}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent}`};
  ${props => props.gap && `gap: ${props.gap}`};
  
  /* Position */
  ${props => props.position && `position: ${props.position}`};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.zIndex && `z-index: ${props.zIndex}`};
`;

export default Box;