import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import {HelmetProvider} from 'react-helmet-async'
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import * as Sentry from "@sentry/react";
import {AuthProvider} from './features/authentication/index.js'
import {ModelsProvider} from './hooks/contexts/ModelsContext.jsx'
import {EcommerceProvider} from './features/ecommerce/js/context/EcommerceContext.jsx'
import {CardProvider} from './features/cards/js/context/CardContext.jsx'
import {DrawerProvider} from './js/hooks/context/DrawerContext.jsx'
import {CheckoutProvider} from './features/ecommerce/js/context/CheckoutContext.jsx'
import {OrderProvider} from './features/ecommerce/orders/hooks/OrderContext.jsx'
import {EventProvider} from './features/events/js/hooks/index.js'
import ErrorFallback from "./pages/Error.jsx";
import { handleError } from './services/errorHandling.js'
import {APP_ENV} from "./js/Helper.js";
import {RewardsProvider} from "./features/rewards/js/hooks/RewardsContext.jsx";
import { ThemeProvider } from './atomic/providers/ThemeProvider.jsx';
import ModalProvider from './atomic/molecules/Modal/ModalContext.jsx'
import { BasketProvider } from './featuresV2/ecommerce/basket/context/BasketContext.jsx'

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://e487bb02ba7fc2edb2a10a8b7ff84c1f@o4508665914392576.ingest.de.sentry.io/4508665982222416",
  environment: APP_ENV || 'local',
  release: import.meta.env.VITE_APP_VERSION || 'development',
  
  // Performance Monitoring
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [
        "localhost",
        "dev-api.companyfour.co.uk",
        /^https:\/\/dev-api\.companyfour\.co\.uk\/api/,
        /^http:\/\/localhost:5173/
      ],
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    new Sentry.BrowserProfilingIntegration(),
  ],

  // Session Replay
  replaysSessionSampleRate: 0.1, // Sample 10% of sessions
  replaysOnErrorSampleRate: 1.0, // Sample 100% of sessions with errors

  // Performance Monitoring
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,

  // Error Handling
  beforeSend(event, hint) {
    const error = hint.originalException;
    // Don't send errors from development
    if (APP_ENV === 'development') {
      return null;
    }
    
    // Add additional context for API errors
    if (error && error.response) {
      event.extra = {
        ...event.extra,
        apiResponse: error.response.data,
        apiStatus: error.response.status,
        apiEndpoint: error.response.config?.url,
        apiMethod: error.response.config?.method,
      };
    }
    return event;
  },

  // Ignore specific errors
  ignoreErrors: [
    'Network request failed',
    'ResizeObserver loop limit exceeded',
    'Non-Error promise rejection captured'
  ],
});

window.addEventListener('unhandledrejection', (event) => {
  handleError(event.reason, 'HIGH');
});

window.addEventListener('error', (event) => {
  handleError(event.error, 'HIGH');
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} resetErrorBoundary={resetError} />
        )}
      >
        <BrowserRouter>
          <AuthProvider>
            <ModelsProvider>
              <HelmetProvider>
                <EventProvider>
                  <EcommerceProvider>
                    <OrderProvider>
                      <CheckoutProvider>
                        <CardProvider>
                          <BasketProvider>
                            <DrawerProvider>
                              <CheckoutProvider>
                                <RewardsProvider>
                                <ThemeProvider>
                                  <ModalProvider>
                                      <App />
                                  </ModalProvider>
                                </ThemeProvider>
                              </RewardsProvider>
                            </CheckoutProvider>
                          </DrawerProvider>
                          </BasketProvider>
                        </CardProvider>
                      </CheckoutProvider>
                    </OrderProvider>
                  </EcommerceProvider>
                </EventProvider>
              </HelmetProvider>
            </ModelsProvider>
          </AuthProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
)
