import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from "@sentry/react";

import { Form } from '../../../components/forms/formStyles';
import styled from 'styled-components';
import { ButtonComponent } from '../../../components/ui/buttons';

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    height: 100%;
    width: 100%;
    h2 {
        font-size: 1.5em;
        color: var(--accent-colour);
    }

    h2, p{
        text-align: left;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 0 20px;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
`;

const UserFeedbackForm = ({ eventId, onClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Send feedback to Sentry
        Sentry.captureUserFeedback({
            name,
            email,
            message: feedback,
            associatedEventId: eventId,
        });

        onClose();
    };

    return (
        <FormContainer onSubmit={handleSubmit}>
            <InfoContainer>
                <h2>We're sorry you've experienced an issue</h2>
                <p>Please provide any additional details that might help us resolve this problem:</p>
            </InfoContainer>
            <InputContainer>
                <input 
                    type="text" 
                    placeholder="Name" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <input 
                    type="email" 
                    placeholder="Email" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <textarea 
                    placeholder="Enter your feedback here" 
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    required
                />
            </InputContainer>
            <ButtonComponent variant={'solid'} text={'Submit Feedback'} type="submit" />
        </FormContainer>
    );
};

UserFeedbackForm.propTypes = {
    eventId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

export default UserFeedbackForm;
