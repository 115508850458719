import {toast} from "react-toastify";
import * as Sentry from '@sentry/react';
import ErrorFallback from "../../../../pages/Error.jsx";
import { handleError } from '../../../../services/errorHandling';

export const useHandleError = () => {

    const showErrorToast = (error, message) => {
        handleError(error, 'MEDIUM');
        console.log('ERRORR', error);
        toast.error(error?.response?.data?.message || error?.message || error || 'There has been an error')
    }

    const showErrorPage = (error, message) => {
        handleError(error, 'HIGH');
    }

    const reportError = (error, message) => {
        handleError(error, 'LOW');
    }



    return {
        showErrorToast,
        showErrorPage,
        reportError,
    }

}
