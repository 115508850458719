import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Image from '../../atoms/Image';

/**
 * Container for the entire card with relative positioning
 */
const CardContainer = styled(motion.div)`
  position: relative;
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || 'auto'};
  border-radius: ${props => props.$borderRadius || '0'};
  overflow: hidden;
`;

/**
 * Gradient overlay with configurable colors and direction
 */
const Overlay = styled(motion.div)`
  position: absolute;
  inset: 0;
  background: ${props => `linear-gradient(
    to ${props.$direction || 'top'},
    ${props.$startColor || 'var(--primary-colour)'} ${props.$startPosition || '0%'},
    ${props.$endColor || 'transparent'} ${props.$endPosition || '100%'}
  )`};
  pointer-events: none;
`;

/**
 * Container for text content with configurable positioning and alignment
 */
const TextContainer = styled(motion.div)`
  position: absolute;
  ${props => props.$position === 'top' ? 'top: 0;' : 'bottom: 0;'}
  left: 0;
  right: 0;
  width: 100%;
  padding: ${props => props.$padding || '24px'};
  color: ${props => props.$textColor || 'var(--text-colour)'};
  z-index: 1;
  text-align: ${props => props.$textAlign || 'left'};
  
  /* Remove horizontal padding when centered to prevent offset */
  ${props => props.$textAlign === 'center' && `
    padding-left: 0;
    padding-right: 0;
  `}
  
  /* Adjust right padding when right-aligned */
  ${props => props.$textAlign === 'right' && `
    padding-left: 0;
  `}
`;

/**
 * Headline text with configurable styling
 */
const Headline = styled(motion.h2)`
  margin: 0;
  font-size: ${props => props.$fontSize || '24px'};
  font-weight: ${props => props.$fontWeight || '600'};
  line-height: 1.2;
  color: inherit;
  
  /* Add horizontal padding here instead of container for better alignment */
  padding-left: ${props => props.$horizontalPadding || '0'};
  padding-right: ${props => props.$horizontalPadding || '0'};
`;

/**
 * ImageCard component that combines an image with gradient overlay and headline text
 * @param {string} src - Source URL of the image
 * @param {string} alt - Alt text for the image
 * @param {string} headline - Headline text to display
 * @param {string} width - Width of the card
 * @param {string} height - Height of the card
 * @param {string} aspectRatio - Aspect ratio of the card
 * @param {string} borderRadius - Border radius of the card
 * @param {string} overlayDirection - Direction of the gradient overlay
 * @param {string} overlayStartColor - Start color of the gradient overlay
 * @param {string} overlayEndColor - End color of the gradient overlay
 * @param {string} overlayStartPosition - Start position of the gradient
 * @param {string} overlayEndPosition - End position of the gradient
 * @param {string} textPosition - Position of the text ('top' or 'bottom')
 * @param {string} textAlign - Alignment of the text ('left', 'center', or 'right')
 * @param {string} textColor - Color of the text
 * @param {string} fontSize - Font size of the headline
 * @param {string} fontWeight - Font weight of the headline
 * @param {string} padding - Padding around the text
 */
const ImageCard = ({
  src,
  alt,
  headline,
  width,
  height,
  aspectRatio = '9/16',
  borderRadius = '30px',
  overlayDirection = 'top',
  overlayStartColor = 'var(--primary-colour)',
  overlayEndColor = 'transparent',
  overlayStartPosition = '0%',
  overlayEndPosition = '100%',
  textPosition = 'bottom',
  textAlign = 'left',
  textColor = '#ffffff',
  fontSize = '24px',
  fontWeight = '600',
  padding = '24px',
  ...props
}) => {
  // Calculate horizontal padding based on text alignment
  const getHorizontalPadding = () => {
    switch (textAlign) {
      case 'center':
        return padding;
      case 'right':
        return `0 ${padding}`;
      default:
        return `${padding} 0`;
    }
  };

  return (
    <CardContainer
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
      {...props}
    >
      <Image
        src={src}
        alt={alt}
        width="100%"
        height="100%"
        aspectRatio={aspectRatio}
        objectFit="cover"
      />
      
      <Overlay
        $direction={overlayDirection}
        $startColor={overlayStartColor}
        $endColor={overlayEndColor}
        $startPosition={overlayStartPosition}
        $endPosition={overlayEndPosition}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      />
      
      <TextContainer
        $position={textPosition}
        $textColor={textColor}
        $padding={padding}
        $textAlign={textAlign}
        initial={{ opacity: 0, y: textPosition === 'top' ? -20 : 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <Headline
          $fontSize={fontSize}
          $fontWeight={fontWeight}
          $horizontalPadding={getHorizontalPadding()}
        >
          {headline}
        </Headline>
      </TextContainer>
    </CardContainer>
  );
};


export default ImageCard;
