import React from 'react'
import { ImageWithFade } from '../images/ImageWithFade'
import styled from 'styled-components'
import { Paragraph } from '../typography'
import { displayLocation } from '../../../js/Helper'
import { Link } from 'react-router-dom'

const CardContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 80dvw;
    width: 80dvw;
    min-height: 80dvw;
    height: 80dvw;
    margin-bottom: 20px;
    border-radius: var(--border-radius-large);
    overflow: hidden;
    box-shadow: var(--shadow-primary);
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        box-shadow: var(--shadow-hover-default);
    }

    @media screen and (min-width: 768px) {
        min-width: 350px;
        width: 350px;
        min-height: 350px;
        height: 350px;
    }
    `

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2px;
    position: absolute;
    width: 80dvw;
    max-width: 275px;
    min-height: 100%;
    /* height: 55px; */
    max-height: fit-content;
    padding: 20px;
    transform: rotate(2deg);
    box-sizing: border-box;
    z-index: 1;
`

const HoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    position: absolute;
    opacity: 0;
    top: 0;
    padding: 40px 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    z-index: 1;
    background: linear-gradient(to bottom, var(--default-primary-colour-faded) 20%, rgba(255, 0, 0, ) 20%);
    backdrop-filter: blur(5px);
    transition: opacity 350ms ease-in-out;

    h4 {
        font-size: 1.5rem;
    }

    button {
        height: 45px;
        background-color: var(--accent-colour);
        text-transform: uppercase;
        font-weight: bold;
        color: white;
    }

    &:hover {
        opacity: 1;
        transition: opacity 350ms ease-in-out;
    }


`

const Title = styled.h4`
    width: 100%;
    height: fit-content;
    padding: 2px 0px;
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
    border-radius: var(--border-radius-small);
    background:  ${props => props.color}; //linear-gradient(to right, transparent 0%, ${props => props.color || 'var(--default-accent-colour)'} 50%, transparent 100%);
    z-index: 1;
`

export const SquareCardComponent = (props) => {
    const { data, id, data_model, layout } = props

    const paragraphStyle1 = {
        margin: 0,
        alignSelf: 'flex-start' 
    }

    const paragraphStyle2 = {
        margin: 0,
        alignSelf: 'flex-end'
    }

     const image = data.imagePath ? data.imagePath : '/nocturnal-logo-clear.png'

     const imgixUrlParams = `?auto=format&losless=true&bri=15&con=50&q=100`

  return (
    <CardContainer id={`square-card-component-${data.id}`}>
        <ImageWithFade image={`${image}${imgixUrlParams}`} width={'100%'}/>
        <ContentContainer>
            <Paragraph style={paragraphStyle1} content={data?.start_date}/>
            <Title color={data.theme && data.theme.header_colour || data.theme.primary_colour}>{data.name}</Title>
            <Paragraph style={paragraphStyle2} content={data.location && displayLocation(data.location)}/>
        </ContentContainer>
        <HoverContainer>
            <h4>{data.name}</h4>
            {data.short_description}
            <Link to={`/event/${data.slug}`}>
                <button style={{background:data.theme.header_colour || data.theme.accent_colour}}>VIEW EVENT</button>
            </Link>
        </HoverContainer>
    </CardContainer>
  )
}
