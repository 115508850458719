import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import useInViewAnimation from '../../hooks/useInViewAnimation';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedContainer = styled.div`
  opacity: 0;
  transform: translateY(20px);

  ${({ $isVisible, $delay, $duration, $easing }) => $isVisible && css`
    animation: ${fadeInUp} ${$duration}s ${$easing} forwards;
    animation-delay: ${$delay}s;
  `}
`;

/**
 * AnimatedElement - A wrapper component that adds fade-in-up animation on scroll
 */
const AnimatedElement = ({ 
  children, 
  delay = 0.4, 
  duration = 1, 
  easing = 'cubic-bezier(0.22, 1, 0.36, 1)',
  threshold = 0.1,
  rootMargin = '0px 0px -50px 0px',
  as = 'div',
  className 
}) => {
  const [ref, isVisible] = useInViewAnimation({ threshold, rootMargin });

  return (
    <AnimatedContainer
      ref={ref}
      as={as}
      className={className}
      $isVisible={isVisible}
      $delay={delay}
      $duration={duration}
      $easing={easing}
    >
      {children}
    </AnimatedContainer>
  );
};

AnimatedElement.propTypes = {
  /** Content to be animated */
  children: PropTypes.node.isRequired,
  /** Delay before animation starts (in seconds) */
  delay: PropTypes.number,
  /** Duration of the animation (in seconds) */
  duration: PropTypes.number,
  /** CSS easing function for the animation */
  easing: PropTypes.string,
  /** Intersection Observer threshold */
  threshold: PropTypes.number,
  /** Intersection Observer root margin */
  rootMargin: PropTypes.string,
  /** HTML element type */
  as: PropTypes.string,
  /** Optional className */
  className: PropTypes.string,
};

export default AnimatedElement; 