import React from 'react';
import styled from "styled-components";
import AbsoluteSection from "../../layout/sections/AbsoluteSection.jsx";
import {FullPageContainer} from "../../layout/styles/layoutStyles.js";

const HeroContainer = styled(FullPageContainer)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: var(--accent-colour);
    background-size: cover;
    background-position: center;
    position: relative; /* Needed for the overlay positioning */
    color: var(--text-colour);

    /* Gradient overlay */
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, var(--primary-colour), transparent);
        z-index: 1;
    }

    /* Ensuring text is on top of the gradient */
    > * {
        position: relative;
        z-index: 2;
    }
`;

const UpperText = styled.p`
    width: 70%;
    text-align: center;
`;

const MainText = styled.h1`
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
`;

const LowerText = styled.p`
    width: 70%;
    text-align: center;
`;

const MinimalHero = ({ title, upperTitle, lowerTitle, image }) => {
    return (
        <HeroContainer style={{ backgroundImage: `url(${image})` }}>
            <UpperText>{upperTitle}</UpperText>
            {/*<MainText>{title}</MainText>*/}
            <LowerText>{lowerTitle}</LowerText>
        </HeroContainer>
    );
};


export default MinimalHero;