import React from 'react';
import {CheckoutSection} from "../styles/checkoutPage.styles.js";
import LogoComponent from "../../../../components/ui/LogoComponent.jsx";
import {CheckoutBasket, CheckoutOptions} from "./index.js";
import {Divider} from "../../../../components/ui/Divider.jsx";
import {useCheckout} from "../../js/context/CheckoutContext.jsx";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";

const OrderSummarySection = () => {

    const { checkout, total, fees } = useCheckout();
    const { basket } = useEcommerce();

    return (
        <CheckoutSection id='order-summary'>
            {/*<LogoComponent style={{zIndex:5}}  variant={'light'} />*/}
            <CheckoutBasket
                orderLines={basket?.orderItems}
                fees={basket.fees}
                total={basket.total}
            />
            <Divider />
            <CheckoutOptions checkout={basket} dueToday={basket.total} />
        </CheckoutSection>
    );
};

export default OrderSummarySection;