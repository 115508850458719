import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import { makeRequest } from '../../../services/axios'
import { toast } from 'react-toastify'
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import { useDrawer } from '../../../js/hooks/context/DrawerContext.jsx';
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {AuthContext} from "../AuthContext.jsx";
import {useMutation} from "@tanstack/react-query";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: fit-content; */
    padding: 20px 40px;
    max-width: 100%;
`

const PasswordResetForm = () => {

    const { user } = useContext(AuthContext)


    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [displaySupport, setDisplaySupport] = useState('none');
    const [email, setEmail] = useState(user?.email || null);
    const { openDrawer, openPreviousDrawer } = useDrawer();
    const { showErrorToast } = useHandleError();


    const mutation = useMutation(
        (email) => makeRequest('POST', `req_password_reset/${email}`),
        {
            onMutate: () => {
                setDisplaySupport('');
                setMessage('');
                setIsLoading(true);

                if (!email) {
                    setMessage('Please enter your email.');
                    setIsLoading(false);
                    return;
                }
            },
            onError: (error) => {
                showErrorToast(error.message);
            },
            onSuccess: (res) => {
                console.log('res', res);

                if (res.status === 'success') {
                    toast.success(res.message);
                } else {
                    toast.warn(res.message);
                }
            },
            onSettled: () => {
                setIsLoading(false);
            },
        }
    );



    const handleSubmit = async (event) => {
        event.preventDefault();
        mutation.mutate(email)
    }

  return (
    <>
    <Form onSubmit={handleSubmit}>
        <input type='email' name='email' placeholder='Your email?' onChange={(e) => setEmail(e.target.value)} value={email} required={true} />
        <ButtonComponent
            variant={'solid'}
            isLoading={isLoading}
            text="Send Reset Instructions"
            onClick={handleSubmit}
            />
    </Form>
    <ButtonComponent
        variant={'tertiary'}
        text={'Back to Login'}
        onClick={() => openPreviousDrawer()}
    />
    </>
  )
}

export default PasswordResetForm