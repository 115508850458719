import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {convertToCurrency} from "../../../../../../js/helpers/helpers.js";
import {ButtonComponent} from "../../../../buttons/index.js";
import {Paragraph} from "../../../../typography/index.js";
import {useDrawer} from "../../../../../../js/hooks/context/DrawerContext.jsx";

const Container = styled.div`
    //background: black;
    padding: 20px;
    
    @media screen and (min-width: 1024px){
        padding: 30px;
        //background-color: red;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-colour);
    border-radius: 20px;
    padding: 10px;
    min-height: 400px;
    height: 100%;
    width: 70vw;
    -webkit-box-shadow: 10px 10px 44px 0px rgba(0,0,0,0.62);
    -moz-box-shadow: 10px 10px 44px 0px rgba(0,0,0,0.62);
    box-shadow: 10px 10px 44px 0px rgba(0,0,0,0.62);
    border: 1px solid rgba(255, 255, 255, 0.1);
    //max-width: 600px;
    //margin: 0 auto;
    color: var(--text-colour);
    
    @media screen and (min-width: 768px) {
        //margin-right: 20px;
        width: 300px;
    }


`

const TitleAndPriceContainer = styled.div`
  display: flex;
    flex-direction: column;
  justify-content: space-between;
    gap: 5px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  text-align: center;
    font-size: 2rem;
    font-weight: bolder;
    line-height: 2rem;

  color: white;
    text-transform: uppercase;
`;

const Price = styled.h4`
`


const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: white;
    //text-align: center;

  &::before {
    content: "${props => (props.included ? '✔️' : '❌')}";
    margin-right: 30px;
  }
`;

const FeatureBreakdown = ({ title, features, price, onClick }) => {

    const { openDrawer } = useDrawer();
    return (
        <Container>
            <Content>
                <TitleAndPriceContainer>
                    <Title>{title}</Title>
                    <Paragraph></Paragraph>
                    { price > 0 && <Price>{convertToCurrency(price)}</Price> }
                </TitleAndPriceContainer>



                <List>
                    {features
                        ?.slice() // Create a shallow copy to avoid mutating the original array
                        .sort((b, a) => {
                            if (a?.metadata?.included && !b?.metadata?.included) return -1;
                            if (!a?.metadata?.included && b?.metadata?.included) return 1;
                            return 0;
                        })
                        .map((feature, index) => (
                            <ListItem key={index} included={feature?.metadata?.included} onClick={() => openDrawer(<Paragraph style={{margin: '50px 10px'}}>{feature?.description}</Paragraph> )}>
                                {feature.name}
                            </ListItem>
                        ))}
                </List>

                <ButtonComponent
                    variant={'solid'}
                    text={'Select'}
                    onClick={() => onClick(title)}
                />
            </Content>

        </Container>
    );
};

export default FeatureBreakdown;

FeatureBreakdown.propTypes = {
    title: PropTypes.string,
    features: PropTypes.array,
    price: PropTypes.string,
};