import React, {useState} from 'react'
import styled from 'styled-components'
import {StandardSection} from '../sections/StandardSection'
import {HorizontalFeaturedListLayout} from '../ListLayouts/HorizontalFeaturedListLayout';
import {API_URL, getLocalStorageJSON, SetLocalStorageJSON} from '../../js/Helper';
import {Loading} from '../../components/ui/Loading';

import {Link, useNavigate, useParams} from 'react-router-dom'
import {getModelData} from '../../js/maps/modelsMap'
import {Section} from '../components/LayoutSection'
import {InstructionTitle} from '../../components/ui/typography/InstructionTitle'
import {StyledTitle} from '../../components/ui/typography/StyledTitle'
import LogoComponent from '../../components/ui/LogoComponent'
import {NoResultsComponent} from '../../components/ui/NoResultsComponent'
import ErrorBoundary from '../../components/ErrorBoundary'
import {useApiQuery} from '../../hooks/customHooks';
import {Footer} from "../components/Footer.jsx";
import {updateTheme} from "../../features/themes/js/utils.js";
import SearchBox from "../../components/ui/SearchBox.jsx";
import {mainMenu} from "../menus/js/mainMenu.js";
import {FloatingNav} from "../components/FloatingNav/index.jsx";
import {listComponentMap} from "../js/listComponentMap.js";


const primaryColorR = 42; // Replace with your color value
const primaryColorG = 45; // Replace with your color value
const primaryColorB = 58; // Replace with your color value

const HeroSection = styled.section`
    display: grid;
    grid-template-columns: repeat(1fr, 4);
    place-items: center;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100dvh;
    padding-top: 30px;
    background-image:
    linear-gradient(to bottom,
    rgba(${primaryColorR}, ${primaryColorG}, ${primaryColorB}, 0) 0%,
    rgba(${primaryColorR}, ${primaryColorG}, ${primaryColorB}, 1) 100%,
    rgba(${primaryColorR}, ${primaryColorG}, ${primaryColorB}, 1) 0%),
    url(${props => props.image});
    background-position: center top;
    z-index: 999999;
`


 const PrimaryFeedLayout = () => {

    updateTheme()
    //  alert('hello')
    
    const [currentCity, setCurrentCity] = useState(getLocalStorageJSON('city'));
    const [currentArea, setCurrentArea] = useState(getLocalStorageJSON('area'));

    const navigate = useNavigate();
    const params = useParams();
    const model = params.model;
    const key = params.key;
    const value = params.value;

    const theModel = getModelData(model)

    let path = key && value ? `${model}?${key}=${value}` : model;

    if (theModel?.relations) {
      path += `${key && value ? '&' : '?'}with=${theModel?.relations}`;
    }

    if(currentCity && theModel?.relations?.includes('location')) {
      path += `&city=${currentCity.value}`
    }

    if(currentCity && currentArea && theModel?.relations?.includes('location')) {
      path += `&area=${currentArea.value}`
    }



    // Make the data request
    const { data, isLoading, isError, error } = useApiQuery(`${API_URL}/${path}`,
    data => {

    },
    error => console.error(error.message)

    );
  
    if(isLoading) {
        return <Loading/>
    }

    if(isError) {
      return <ErrorBoundary hasError={true} />
    }


    const responseData = data && data.data;
    const featuredData = data && responseData.filter((item) => item.featured == true)
    const featuredImage = data && data.data.featured_image;

    const videos = [
      {url: 'https://api.nocturnallifestyle.co.uk/media/videos/Monsta-Showreel.mp4', thumbnail: 'https://api.nocturnallifestyle.co.uk/images/users/20B0DF12-19F9-4D70-ACA8-BD93AF1573C1.jpeg'},
      // {url: 'https://api.nocturnallifestyle.co.uk/media/videos/Hevi-Video.mp4', thumbnail: 'https://api.nocturnallifestyle.co.uk/images/users/20B0DF12-19F9-4D70-ACA8-BD93AF1573C1.jpeg'},
    ];

    const handleCityChange = (item) => {
      SetLocalStorageJSON('city', item)
      localStorage.removeItem('area');
      setCurrentCity(item)
      setCurrentArea(null)

    }

    const handleAreaChange = (item) => {
      SetLocalStorageJSON('area', item)
      setCurrentArea(item)
    }

    const handleClick = (item) => {
        // navigate(`/${theModel.single.charAt(0)}/${item.slug}?back=true`)
    }


    const ListComponent = listComponentMap[theModel.plural];



      
  return (
    <>
    <HeroSection name='hero' image={featuredImage}>
      <LogoComponent />
      <StyledTitle title={''} />
       {/*<GradientOverlayComponent/>*/}
      <SearchBox
      handleCityChange={handleCityChange}
      handleAreaChange={handleAreaChange}
      />
    </HeroSection>

    {
      responseData?.length == 0
      ? <NoResultsComponent image={'/homefire.jpg'}/>
      : 
      <>
      {
        featuredData?.length > 0 &&
        <Section theme={'clipped'} clip id="featured">
            <StyledTitle title={`Featured ${theModel.plural}`} />
            <HorizontalFeaturedListLayout
            data={featuredData}
            card={theModel.featuredCard}
            />
        </Section>
      }

    <StandardSection id='main-feed-section' style={{paddingBottom: '100px', gap: '12px'}}>

        { innerWidth < 768 && <InstructionTitle title={`${theModel.plural} LIST`} style={{
             marginLeft: innerWidth < 768 ? '0px' : '55px',
            textTransform:'uppercase',
            minWidth: '200px'
         }} />}

            <ListComponent
                data={responseData}
                onClick={handleClick}
                model={theModel.single}
            />



    </StandardSection>
    </>

      }

    <FloatingNav items={mainMenu} theme={'default'}/>

    <Footer/>

    </>
  )
}

export default PrimaryFeedLayout
