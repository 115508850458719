import { FaChartLine, FaServicestack, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaChartPie, FaCircle, FaThList } from "react-icons/fa";
import {
  IoCardOutline,
  IoSettingsSharp,
  IoStarSharp,
  IoHomeOutline,
  IoHomeSharp,
  IoTicketSharp,
  IoCardSharp,
  IoPersonSharp,
  IoMusicalNoteSharp,
  IoInformationCircleSharp,
  IoLocationSharp,
  IoImagesOutline,
  IoStarOutline,
  IoListOutline,
  IoImagesSharp,
  IoInformationCircleOutline,
  IoMenuSharp,
  IoCashSharp,
  IoBarChartSharp,
  IoFastFood,
  IoArrowBackSharp,
  IoGiftOutline,
  IoGiftSharp,
  IoDocumentTextOutline
} from "react-icons/io5";
import { AiFillCalendar, AiFillPlusSquare, AiFillMoneyCollect } from "react-icons/ai";
import { MdDashboard } from "react-icons/md";
import { ImCross, ImMenu, ImMenu2, ImCoinPound } from 'react-icons/im'
import {BsBasket} from "react-icons/bs";



export const iconsMap = {
    FaChartLine,
    FaChartPie,
    FaThList,
    AiFillMoneyCollect,
    IoFastFood,
    ImCoinPound,
    IoCashSharp,
    IoBarChartSharp,
    IoHomeSharp,
    FaCircle,
    IoCardOutline,
    IoHomeOutline,
    IoTicketSharp,
    AiFillCalendar,
    AiFillPlusSquare,
    IoStarOutline,
    IoDocumentTextOutline,
    IoListOutline,
    MdDashboard,
    IoCardSharp,
    IoPersonSharp,
    ImMenu,
    ImCross,
    IoStarSharp,
    IoSettingsSharp,
    IoMusicalNoteSharp,
    IoInformationCircleOutline,
    IoInformationCircleSharp,
    IoLocationSharp,
    IoImagesOutline,
    IoImagesSharp,
    FaServicestack,
    IoMenuSharp,
    BsBasket,
    IoArrowBackSharp,
    FaInstagram,
    FaTiktok,
    IoGiftOutline,
    IoGiftSharp
  };
