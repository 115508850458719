import React from 'react';
import styled from 'styled-components';
import { RectangleCardComponent } from '../../components/ui/cards/RectangleCardComponent/RectangleCardComponent';
import { cardComponentMap } from '../../components/ui/cards/cardComponentMap';

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    min-width: var(--small);
    width: var(--small);
    min-height: fit-content;
    /* padding: 0px 20px; */
    box-sizing: border-box;
    //padding-bottom: 100px;


    > * {
        margin-bottom: ${props => props.card === 'LoyaltyCard' ? '-50px' : '0px'};
    }

    > *:not(:first-child) {
        box-shadow: ${props => props.card === 'LoyaltyCard' ? '5px -10px 20px rgba(0, 0, 0, 0.35)' : '0px'};
    }

       /* Hide the scrollbar */
    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }

    @media screen and (min-width: 1024px){
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }


`;

export const StandardListLayout = ({ children, style } ) => {

      
    return (
        <ListContainer name='standard-list-layout' style={style}>
          {children}
        </ListContainer>
    );
};


// {data &&
//     data.map((item, index) => {
//         const CardComponent = cardComponentMap[card];
//         return (
//                 <CardComponent
//                     key={index}
//                     data={item}
//                     data_model={data_model}
//                     card={item}
//                     tier={item?.default_tier || item}
//                     theme={item?.default_tier?.theme}
//                     layout={layout && layout}
//                     onClick={onClick}
//                 />
//         );
//     })}