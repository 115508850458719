import React from 'react'
import styled from 'styled-components'
import { ImageComponent } from './images/ImageComponent';
import { CDN_URL } from '../../js/Helper';

const primaryColorR = 42; // Replace with your color value
const primaryColorG = 45; // Replace with your color value
const primaryColorB = 58; // Replace with your color value
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
  background-color: red;
  background: linear-gradient(to bottom, var(--default-primary-colour), transparent), linear-gradient(to top, var(--default-primary-colour), transparent);
  background-position: center;
`

export const NoResultsComponent = ({ image }) => {
  return (
    <Container>
      This search has produced no results.
    </Container>
  )
}
