import React, {useState} from 'react';
import {FullPageAbsoluteContainer} from "../../../layout/styles/layoutStyles.js";
import {Product} from "../../ecommerce/products/components/index.js";
import {useApiQuery} from "../../../hooks/customHooks.js";
import SliderComponent from "../../../components/ui/SliderComponent.jsx";
import PaymentSection from "../../ecommerce/checkout/components/PaymentSection.jsx";
import StripePaymentForm from "../../ecommerce/services/stripe/components/StripePaymentForm.jsx";
import {TextBlock1} from "../../../components/ui/sections/components/TypgographySections/index.js";
import TextBlock2 from "../../../components/ui/sections/components/TypgographySections/TextBlock2.jsx";

const Sandbox = () => {
    // const { data } = useApiQuery('products/232?with=fileLinks');
    // if(!data) return null;

    const [sliderValue, setSliderValue] = useState(50);

    const handleSliderChange = (newValue) => {
        setSliderValue(newValue);
        console.log(newValue / 0.25);
    };

    return (
        <FullPageAbsoluteContainer style={{height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
            {/*<Product product={data} />*/}
            {/*<StripePaymentForm totalAmountOverride={2000}  />*/}
            <TextBlock2  />
        </FullPageAbsoluteContainer>
    );
};

export default Sandbox;
