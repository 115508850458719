import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const CardContaier = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 150px;
    width: 150px;
    /* height: 150px; */
    background-color: var(--default-primary-colour);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--shadow-primary);
    overflow: hidden;
    cursor: pointer;
    transform: scale(1);
    transition: box-shadow 250ms ease-in-out, transform 3s ease-in-out;
    animation: fadeIn 300ms ease-in-out;

    &:hover {
        box-shadow: var(--shadow-hover-default);
        transition: box-shadow 250ms ease-in-out;
    }
    
    &:active {
        transition: transform 25ms ease-in-out;
        transform: scale(0.95);
    }

    @media screen and (min-width: 768px){
        max-width: 200px;
        max-height: 200px;
    }

    `
const ImageContainer = styled.div`
    display: inline-block;
    position: relative;
    background-color: var(--default-primary-colour);
    height: 150px;
    transition: opacity 250ms ease-in-out;

    
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2; /* Make sure the pseudo-element is on top of the image */
        background-image:  linear-gradient(to top, var(--default-primary-colour), rgba(255, 0, 0, 0));
    }
 
    &:hover {
        opacity: 1;
        transition: opacity 250ms ease-in-out;
    }
`

const CardImage = styled.img`
    object-fit: cover;
`

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    opacity: 0;
    background-color: rgba(42,45,58,0.6);
    backdrop-filter: blur(2px);
    transition: opacity 350ms ease-in-out;

    &:hover {
        opacity: 1;
        transition: opacity 350ms ease-in-out;
    }
`

const Text = styled.p`
    font-size: 0.7em;
    color: white;
`

const CardTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    max-width: 100%;
    margin:0px;
    padding: 10px 5px;
    border-top: solid 1px rgba(255,255,255,0.1);
    border-radius: var(--border-radius-small);
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
    z-index: 4;
`

const TagsContainer = styled.div``

const imgixUrlParams = `?auto=format&w=150&fit=crop&losless=true&bri=15&con=25`


export const FeaturedCardComponent = (props) => {
    const { data, id, data_model, layout } = props

    console.log('FeaturedCardComponent', data, id, data_model, layout)

    const image = data.imagePath ? data.imagePath : '/nocturnal-logo-clear.png'
    const path = `/${data_model && data_model}/${data && data.slug || id}`

  return (
    <CardContaier>
        <Link to={path} >
            <ImageContainer>
                <CardImage src={`${image}${imgixUrlParams}`} width={'150px'} height={'150px'}/>
            </ImageContainer>
            <TextContainer>
                <Text>{data?.about}</Text>
            </TextContainer>
        </Link>
        <Link to={path} >
            <CardTitle>{data?.name}</CardTitle>
        </Link>
    </CardContaier>
  )
}
