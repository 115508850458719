import React from 'react';
import styled from 'styled-components';
import {extractColorCodeFromCssVariable} from '../../../themes/js/utils';
import PropTypes, {string} from "prop-types";

// #region constants

// #endregion

// #region styled-components

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    width: 100%;

    
    @media screen and (min-width: 768px){
      
    }
    `

const ContentContainer = styled.div`
  display: ${(props) => props?.display};
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  
  *:only-child {
    grid-column: 1 / -1; /* Stretch the only child across all columns */
  }

  & > :nth-child(odd) {
    text-align: left;
  }

  & > :nth-child(even) {
    text-align: right;
  }
`;

const Title = styled.p`
    font-size: large;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    color: ${extractColorCodeFromCssVariable('--accent-colour')};
`

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const Section = ({ title, children, style, display = 'grid' }) => {

    return (
        <SectionContainer  name='section'>
            <Title>{title}</Title>
            <ContentContainer style={style}  display={display}>
                {children}
            </ContentContainer>
        </SectionContainer>
    )
}

Section.propTypes = {
    title: string,


};
// #endregion

export default Section;