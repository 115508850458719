import React from 'react';
import ErrorFallback from '../pages/Error';


class CustomErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error caught by error boundary:', error, errorInfo);
    console.debug(errorInfo)

    // Bugsnag.notify(new Error(error?.message))

    // alert(error.message);
      
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when an error occurs
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}

export default CustomErrorBoundary;
