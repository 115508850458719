import React from 'react'
import styled from 'styled-components'
import { Section } from '../components/LayoutSection'
import { ImageComponent } from '../../components/ui/images/ImageComponent'
import { GradientOverlayComponent } from '../../components/GradientOverlayComponent'

const SectionContainer = styled.section`
  display: flex;
  flex-direction: ${props => props.direction ? props.direction : 'column'};
  justify-content: flex-start;
  align-items: center;
  min-width: 100dvw;
  // max-width: 100dvw;
  /* background-color: var(--default-primary-colour); */
  padding: 20px;
  box-sizing: border-box;
  gap: 10px;
  z-index: 1; 

  p {
    padding: 5px;
  }
  
  &#featured-section,
  &#main-feed-section {
    padding: 10px 0px;  
  }

  &#main-feed-section {
    /* background-color: red; */
  }
  
  &#featured-section {
    padding-top: 60px;
    width: 100dvw;
    max-width: 100dvw;
    background-color: rgba(0,0,0,0.1);
    background-image: linear-gradient(to top, var(--default-primary-colour), transparent), url(https://nctrl.imgix.net/images/reward/pic-X0cZSJ.jpg);
    background-size: cover;
    z-index: 0;
  }
  
  &#hero {
    position: relative;
    padding: 0px;
    margin-bottom: -60px;
    z-index: 0;
  }

  img {

  }

  @media screen and (min-width: 1024px) {
    min-width: unset;
    width: var(--laptop);
    max-width: 80%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;
    
        
    &#featured-section {
      position: absolute;
      top:0;
      left: 0;
      min-width: 100dvw;
      max-width: 100dvw;
    }
  }

  @media screen and (min-width: 1920px) {
    width: var(--desktop);
    max-width: var(--desktop);
 
    &#featured-section {
     
    }
  }




`

const GradientOverlay = styled.div`
  position: ${props => props.position ? props.position : 'absolute'};
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: linear-gradient(transparent 0%, var(--default-primary-colour));
  z-index: 2;
  margin-bottom: -20px;

  
  @media screen and (min-width: 768px) {
      margin-bottom: -20px;
      height: 100%;
      width: var(--secondBreakMaxWidth);
      margin: 0 auto;
  }

  @media (min-width: 1100px) {
    max-width: var(--secondBreakMaxWidth);
  }
`;


export const StandardSection = ( props ) => {
  const { children, id, image, style } = props

  
  return (
    <SectionContainer image={image} id={id} style={style}>
      {
        id === 'featured-section' && <GradientOverlay />
      } 

      {children}

    </SectionContainer>
  )
}
