import React from 'react'
import { Section } from '../../../../../layout/components/LayoutSection'
import { HorizontalFeaturedListLayout } from '../../../../../layout/ListLayouts/HorizontalFeaturedListLayout'

const VideoSection = ({ videos }) => {

    if(!videos) return;


  return (
    <>
    {
        videos?.length > 0 && (

            <Section
            id={'media'}
            title={'Media'}
            style={{
            backgroundColor: 'var(--primary-colour)',
            color:'white',
            minHeight: 'fit-content',
            }}
            >

            <HorizontalFeaturedListLayout card={'VideoComponent'} data={videos}/>
            
            </Section>
        )
    }
    </>
)
}

export default VideoSection