import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseErrorBoundary, { 
  BaseFallback,
  ActionButton,
  SecondaryButton 
} from '../../../components/error-boundary/BaseErrorBoundary';
import styled from 'styled-components';

// Extend base styles if needed
const EventErrorContainer = styled.div`
  // Add any event-specific styling
`;

const EventErrorFallback = (props) => {
  const navigate = useNavigate();
  
  return (
    <BaseFallback
      {...props}
      title="Unable to Load Event"
      message="We're having trouble loading this event. This could be because the event doesn't exist or there's a temporary issue."
      showHome={true}
      customActions={null}
    />
  );
};

const EventErrorBoundary = ({ children }) => {
  return (
    <BaseErrorBoundary
      feature="events"
      fallback={EventErrorFallback}
      onError={(error) => {
        // Add any event-specific error handling
        console.warn('Event loading failed:', error);
      }}
      extraContext={{
        // Add any event-specific context
        component: 'EventPage'
      }}
    >
      {children}
    </BaseErrorBoundary>
  );
};

export default EventErrorBoundary;
