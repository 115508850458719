import React, {createContext, useContext, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { ConvertBase64IdFromParams } from "../../../../js/hooks/useParam";

export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {

    const [checkout, setCheckout] = useState(null);
    const [fees, setFees] = useState(null);
    const [stripe, setStripe] = useState(null);
    const [event, setEvent] = useState(null);
    const [total, setTotal] = useState(null);
    const [questions, setQuestions] = useState(null);

  return (
    <CheckoutContext.Provider value={{
        checkout,
        setCheckout,
        fees,
        setFees,
        stripe,
        setStripe,
        total,
        setTotal,
        event,
        setEvent,
        questions,
        setQuestions
    }}>
        {children}
    </CheckoutContext.Provider>
  )
};

export const useCheckout = () => {
    const context = useContext(CheckoutContext);

    if(!context) {
        throw new Error('useCheckout must be used within a CHeckoutProvider')
    }

    return context;
};

