// External packages 
import React, { useEffect, useCallback, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// Components
import { FileNotFoundPage } from '../../../pages/FileNotfoundPage'
import EventSkeleton from './PrimaryEventPage/components/EventSkeleton'

import { scrollToElement, setSessionStorageJSON, USE_NEW_EVENT_PAGE } from '../../../js/Helper'
import { useApiQuery } from '../../../hooks/customHooks';
import {filterPartnersByRoles, filterProductsByCategory} from '../js/utils';
import PrimaryEventPage  from './PrimaryEventPage';
import { useEvent } from '../js/hooks/index.js'
import useUpdateTheme from '../../themes/js/hooks/useUpdateTheme'
import { useHandleError } from "../../error-handling/js/hooks/index.js";
import { useDrawer } from "../../../js/hooks/context/DrawerContext.jsx";
import { updateTheme } from "../../themes/js/utils.js";
import { setBookingId } from "../../bookings/js/utils/storage.js";
import {PRODUCT_TABLE, PRODUCT_TICKET} from "../js/constants.js";
import { useAffiliate } from "../../.feature_folder/js/hooks/useAffiliate.jsx";
import { LOCAL_STORAGE_AFFILIATE } from "../../.feature_folder/js/constants.js";
import EventErrorBoundary from '../components/EventErrorBoundary';

export const Event = React.memo(() => {

  // hooks
  const { setEvent, event: contextEvent } = useEvent();
  const { checkIfAffiliateExists, storeAffiliate } = useAffiliate()
  const { showErrorPage, reportError, showErrorToast } = useHandleError();
  const params = useParams();
  const location = useLocation();
  const { isOpen } = useDrawer();

  const slug = params.slug;
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const section = searchParams.get('section')

  useEffect(() => {
    setBookingId(slug);
    handleAffiliate();
  }, [slug]);

  useEffect(() => {
    if (!isOpen && contextEvent?.theme) {
      updateTheme(contextEvent.theme);
    }
  }, [isOpen, contextEvent?.theme]);

  // Handle affiliate logic
  const handleAffiliate = useCallback(() => {
    const affiliateId = searchParams.get(LOCAL_STORAGE_AFFILIATE);
    if (!checkIfAffiliateExists(affiliateId, slug) && affiliateId) {
      const affiliate = { id: affiliateId, type: 'event', slug: slug };
      storeAffiliate(affiliate);
    }
  }, [searchParams, checkIfAffiliateExists, slug, storeAffiliate]);

  // Construct API query path
  const relations = '?with=location,business,theme,partners.image,products.dependencies,fileLinks.file,eventDates'
  const referralParam = searchParams.get(LOCAL_STORAGE_AFFILIATE) 
    ? `referral=${searchParams.get(LOCAL_STORAGE_AFFILIATE)}` 
    : ''
  const path = useMemo(() => `event/${slug}${relations}&${referralParam}`, [slug, referralParam])

  console.warn('ARE WE USING THIS PAGE OR WHAT')

  // Fetch event data
  const onSuccess = useCallback((response) => {
    console.log('Event data:', response);
    if (response.status === 'success' && response.data) {
      const eventData = response.data;
      setSessionStorageJSON('event', eventData);
      setEvent(eventData?.data);
    } else {
      console.error('Unexpected data structure:', response);
    }
  }, []);

  const onError = useCallback((error) => {
    console.error('Error fetching event: ', error.message);
    showErrorToast(error);
  }, [showErrorToast]);

  const { data, isLoading, isError, error } = useApiQuery(path, onSuccess, onError);


  const eventData = data?.data || contextEvent;
  useUpdateTheme(eventData?.theme);


  const tickets = useMemo(() => eventData ? filterProductsByCategory(eventData.products, PRODUCT_TICKET) : [], [eventData]);
  const tables = useMemo(() => eventData ? filterProductsByCategory(eventData.products, PRODUCT_TABLE) : [], [eventData]);
  const djs_artists = useMemo(() => eventData ? filterPartnersByRoles(eventData.partners, ['dj', 'artist']) : [], [eventData]);
  const sponsors = useMemo(() => eventData ? filterPartnersByRoles(eventData.partners, ['sponsor']) : [], [eventData]);

  // Feature flag for new event page

  if (isLoading) return <EventSkeleton />;
  if (isError) {
    reportError(error);
    return <FileNotFoundPage />;
  }

  if (!eventData) {
    return <EventSkeleton />;
  }

  const {
    eventDates,
    business,
    location: venue,
  } = eventData;

  const hasDates = !!eventDates;

  if (section) {
    scrollToElement(section);
  }

  return (
    <EventErrorBoundary>
      <Helmet>
        <title>{`${business?.name} | ${eventData?.name} ~ Nocturnal Lifestyle`}</title>
        <meta name='description' content={eventData?.short_description} />
        <meta property="og:title" content={`${business?.name} | ${eventData?.name} ~ Nocturnal Lifestyle`} />
        <meta property="og:description" content={eventData?.short_description} />
        <meta property="og:url" content={window.location.hostname} />
        <meta property='og:image' content={eventData?.image?.cdnUrl} />
        <meta property='og:type' content='Website' />
        <link rel="canonical" href={window.location.hostname} />
      </Helmet>
        <PrimaryEventPage
          event={eventData}
          tickets={tickets}
          business={business}
          organiser={business}
          venue={venue}
          djs_artists={djs_artists}
          sponsors={sponsors}
          tables={tables}
          hasDates={hasDates}
        />

    </EventErrorBoundary>
  );
})

Event.displayName = 'Event';

// Wrap the exported component with the error boundary
const EventWithErrorBoundary = () => (
  <EventErrorBoundary>
    <Event />
  </EventErrorBoundary>
);

EventWithErrorBoundary.displayName = 'EventWithErrorBoundary';
export default EventWithErrorBoundary;
