import React from 'react';
import styled from 'styled-components';
import {StandardSection} from "../../../../layout/sections/StandardSection.jsx";
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import { VideoComponent } from '../../../../components/ui/VideoComponent.jsx';

const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto;
    padding-bottom: 120px;
    -webkit-overflow-scrolling: touch;
`;

const FixedButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    padding-top: 60px;
    background: linear-gradient(to bottom, 
        rgba(var(--primary-colour-rgb), 0) 0%,
        rgba(var(--primary-colour-rgb), 0.8) 30%,
        rgba(var(--primary-colour-rgb), 1) 100%
    );
    z-index: 100;
`;

const ContentContainer = styled(StandardSection)`
    height: 100dvh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
`;

const AddonProduct = ({ product }) => {
    const { openPreviousDrawer } = useDrawer();

    const files = product.files ?? [];
    const hasFiles = files && files.length > 0;
    const video = hasFiles ? files.find(file => file.category === 'videos') : null;

    console.log('video', video)

    return (
        <ContentContainer style={{maxWidth: '90dvw'}}>
            <ScrollableContent>
                { video && <VideoComponent data={video} /> }
                { product.description && (
                    <div 
                        style={{
                            color:'var(--text-colour)', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            gap: '10px', 
                            textAlign: 'left', 
                            maxWidth: '90dvw'
                        }} 
                        dangerouslySetInnerHTML={{ __html: product.description}}
                    />
                )}
            </ScrollableContent>
            
            <FixedButtonContainer>
                <ButtonComponent 
                    style={{
                        height: '40px',
                        width: '100%'
                    }} 
                    variant={'solid'} 
                    onClick={() => openPreviousDrawer()}
                >
                    Go Back
                </ButtonComponent>
            </FixedButtonContainer>
        </ContentContainer>
    );
};

export default AddonProduct;