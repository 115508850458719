import React from 'react';
import { StandardSection } from '../../../layout/sections/StandardSection';
import { LoyaltyCard } from './LoyaltyCard/LoyaltyCard';
import { Paragraph } from '../../../components/ui/typography';
import { useDrawer } from '../../../js/hooks/context/DrawerContext';
import { useCard } from '../js';
import {updateTheme} from "../../themes/js/utils.js";

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const CardSelectorComponent = ({ tiers }) => {

    const { openDrawer, isOpen } = useDrawer();
    const { setCard, setSelectedTier } = useCard()



    if(tiers.length === 0) return;

    const handleClick = (tier) => {
        setCard(tier.card)
        setSelectedTier(tier)
        updateTheme(tier.theme)
        openDrawer(<PrimaryCardPage card={tier.card} activeTier={tier} setActiveTier={(tier) => useHandleTierChange(tier)} />, null)
    }

    return (
        <StandardSection id={'card-selector'} style={{gap: '20px', padding: '0px'}}>

            <div style={{padding: '20px'}}>
            <Paragraph>This item is exclusive to users that are enrolled in any of the cards displayed below.</Paragraph>
            <Paragraph>If you wish to purchase this product simply tap on this card to sign up.</Paragraph>

            {
                // eslint-disable-next-line react/prop-types
                tiers.length > 0 && tiers.map((tier, index) => {
                    return (
                        <LoyaltyCard
                            key={index}
                            card={tier.card}
                            tier={tier}
                            onClick={() => handleClick(tier)}
                        />
                    )
                })
            }
            </div>

        </StandardSection>
    )
}

CardSelectorComponent.propTypes = propTypes;
CardSelectorComponent.defaultProps = defaultProps;
// #endregion

export default CardSelectorComponent;