import React from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Base styled components that can be extended
export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  min-height: 50vh;
`;

export const ErrorTitle = styled.h2`
  margin-bottom: 1rem;
  color: ${props => props.theme.colors?.error || '#dc3545'};
`;

export const ErrorMessage = styled.p`
  margin-bottom: 2rem;
  color: ${props => props.theme.colors?.text || '#666'};
`;

export const ActionButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${props => props.theme.colors?.primary || '#007bff'};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

export const SecondaryButton = styled(ActionButton)`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors?.primary || '#007bff'};
  color: ${props => props.theme.colors?.primary || '#007bff'};
  margin-left: 1rem;
`;

// Base fallback component that can be customized
export const BaseFallback = ({ 
  error,
  resetError,
  title = "Oops! Something went wrong",
  message,
  showHome = true,
  customActions
}) => {
  const navigate = useNavigate();

  const handleRetry = () => {
    resetError();
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <ErrorContainer>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorMessage>
        {message || error.message || 'We encountered an unexpected issue. Please try again.'}
      </ErrorMessage>
      <div>
        <ActionButton onClick={handleRetry}>
          Try Again
        </ActionButton>
        {showHome && (
          <SecondaryButton onClick={handleGoHome}>
            Go Home
          </SecondaryButton>
        )}
        {customActions}
      </div>
    </ErrorContainer>
  );
};

// Base error boundary that handles common error reporting
const BaseErrorBoundary = ({ 
  children,
  fallback = BaseFallback,
  feature = 'unknown',
  onError,
  extraContext = {}
}) => {
  const handleError = (error) => {
    // Common error handling logic
    Sentry.captureException(error, {
      tags: {
        feature,
        severity: 'high'
      },
      extra: {
        location: window.location.href,
        timestamp: new Date().toISOString(),
        ...extraContext
      }
    });

    // Allow feature-specific error handling
    onError?.(error);
  };

  return (
    <Sentry.ErrorBoundary
      fallback={fallback}
      onError={handleError}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default BaseErrorBoundary;
