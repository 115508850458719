import React from 'react';
import {StandardListLayout} from "../../../layout/ListLayouts/StandardListLayout.jsx";
import {RectangleCardComponent} from "../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent.jsx";
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {Link} from "react-router-dom";

const EventListComponent = ({ data, onClick, model }) => {

    if(!data) return null;

    return (
        <StandardListLayout>
            {
                data.map((event, index) => {

                    return (
                        <Link key={index} to={`/${model.charAt(0)}/${event.slug}?back=true`}>
                        <RectangleCardComponent
                            key={index}
                            title={event.name}
                            description={event.short_description}
                            image={<ImageComponent src={event?.image?.cdnUrl} width={'100px'} height={'100px'} />}
                            onClick={() => onClick(event)}
                        />
                        </Link>
                    )
                })
            }
        </StandardListLayout>
    );
};

export default EventListComponent;