import {useState, useCallback} from "react";
import {useEvent} from "./EventContext.jsx";
import {updateTheme} from "../../../themes/js/utils.js";
import * as Sentry from '@sentry/react';
import { validateCriticalEventData, createSafeEvent } from '../../utils/validation';
import { useEventLoadingState, LoadingState } from './useEventLoadingState';
import { useApiQuery } from "../../../../hooks/customHooks.js";

const MAX_RETRIES = 2;
export const RETRY_DELAY = 1000; // 1 second

export const useEventApi = () => {
    const { setEvent } = useEvent();
    const [retryCount, setRetryCount] = useState(0);
    const loadingState = useEventLoadingState();

    const trackTiming = useCallback((action, duration, extra = {}) => {
        Sentry.addBreadcrumb({
            category: 'performance',
            message: `${action}: ${Math.round(duration)}ms`,
            level: 'info',
            data: {
                duration: Math.round(duration),
                ...extra
            }
        });

        // Also track as a performance measurement
        Sentry.startTransaction({
            name: action,
            op: 'event.load',
            data: {
                duration: Math.round(duration),
                ...extra
            }
        }).finish();
    }, []);

    const handleApiSuccess = useCallback((response) => {
        const endTime = performance.now();
        
        if (response.status === 'success') {
            const rawEvent = response?.data;
            if (!rawEvent) {
                const error = new Error('Event data is missing');
                loadingState.setError(error);
                Sentry.captureException(error);
                return; // Don't throw, just return
            }

            // Validate critical event data
            const { isValid, missingFields } = validateCriticalEventData(rawEvent);
            
            // Create a safe event object with fallbacks
            const safeEvent = createSafeEvent(rawEvent);
            
            setEvent(safeEvent);
            updateTheme(safeEvent.theme);

            if (!isValid) {
                // Log validation errors to Sentry
                Sentry.captureMessage('Invalid event data received', {
                    level: 'warning',
                    extra: {
                        missingFields,
                        eventId: safeEvent.id,
                        eventSlug: safeEvent.slug
                    }
                });
                
                // Set partial state since we have some data but it's incomplete
                loadingState.setPartial(75, { missingFields });
                
                // Track partial success metrics
                Sentry.addBreadcrumb({
                    category: 'event',
                    message: 'Event loaded with missing fields',
                    level: 'warning',
                    data: {
                        eventId: safeEvent.id,
                        eventSlug: safeEvent.slug,
                        hasValidation: !isValid,
                        retryCount,
                        missingFields
                    }
                });
                
                return; // Return early, don't set success state
            }

            // Track success metrics
            Sentry.addBreadcrumb({
                category: 'event',
                message: 'Event loaded successfully',
                level: 'info',
                data: {
                    eventId: safeEvent.id,
                    eventSlug: safeEvent.slug,
                    hasValidation: !isValid,
                    retryCount
                }
            });

            // Set success state only for valid data
            loadingState.setSuccess({
                eventId: safeEvent.id,
                eventSlug: safeEvent.slug
            });

            // Reset retry count on success
            setRetryCount(0);
        }
    }, [setEvent, retryCount, trackTiming, loadingState]);

    const handleApiError = useCallback((error) => {
        const endTime = performance.now();

        Sentry.captureException(error, {
            extra: {
                retryCount,
                timestamp: new Date().toISOString()
            }
        });

        // Track error metrics
        Sentry.addBreadcrumb({
            category: 'event',
            message: 'Event load failed',
            level: 'error',
            data: {
                error: error.message,
                retryCount,
                willRetry: retryCount < MAX_RETRIES
            }
        });

        // Set error state
        loadingState.setError(error, {
            retryCount,
            willRetry: retryCount < MAX_RETRIES
        });
    }, [retryCount, loadingState]);

    const getEvent = useCallback((slug) => {
        const startTime = performance.now();
        const loadRelations = '?with=location,business,theme,partners.image,products.dependencies,fileLinks.file,eventDates';
        
        try {
            // Set initial loading state before making the API call
            loadingState.setLoading({ slug });

            // Start a new transaction for this event load
            const transaction = Sentry.startTransaction({
                name: 'event.load',
                op: 'event.load',
                data: {
                    slug,
                    retryCount
                }
            });

            // Set the transaction as current
            Sentry.configureScope(scope => {
                scope.setSpan(transaction);
            });

            // Make the API call after setting loading state
            const { isLoading } = useApiQuery(`event/${slug}${loadRelations}`, handleApiSuccess, (error) => {
                handleApiError(error);
                
                // Handle retry logic here
                if (retryCount < MAX_RETRIES) {
                    const delay = RETRY_DELAY * Math.pow(2, retryCount);
                    setTimeout(() => {
                        if (loadingState.state === LoadingState.ERROR) {
                            setRetryCount(prev => prev + 1);
                            loadingState.setLoading({ slug, retryCount: retryCount + 1 });
                            useApiQuery(`event/${slug}${loadRelations}`, handleApiSuccess, handleApiError);
                        }
                    }, delay);
                }
            });
            
            // Update loading state based on query status
            if (isLoading) {
                loadingState.setLoading({ slug });
            }

            // Track timing for successful API call
            trackTiming('event_load', performance.now() - startTime, {
                slug,
                retryCount,
                success: true
            });

            transaction.finish();
        } catch (error) {
            // Track timing for failed API call
            trackTiming('event_load', performance.now() - startTime, {
                slug,
                retryCount,
                success: false,
                error: error.message
            });

            handleApiError(error);
        }
    }, [handleApiSuccess, handleApiError, retryCount, trackTiming, loadingState]);

    return {
        getEvent,
        retryCount,
        loadingState: {
            state: loadingState.state,
            progress: loadingState.progress,
            error: loadingState.error,
            isLoading: loadingState.isLoading,
            isPartial: loadingState.isPartial,
            isSuccess: loadingState.isSuccess,
            isError: loadingState.isError,
            isStale: loadingState.isStale
        }
    };
};