import { RectangleCardComponent } from "./RectangleCardComponent/RectangleCardComponent"
import { FeaturedCardComponent } from "./FeaturedCardComponent"
import { SquareCardComponent } from "./SquareCardComponent"
import { LoyaltyCard } from "../../../features/cards/components/LoyaltyCard/LoyaltyCard"
import { VideoComponent } from "../VideoComponent"
import MiniLoyaltyCard from "../../../features/cards/components/LoyaltyCard/MiniLoyaltyCard.jsx";
import {ImageComponent} from "../images/ImageComponent.jsx";
import {ListItemComponent} from "../../Lists/ListItemComponent.jsx";
import { MembersCardComponent } from "../../../features/cards/components/indx.js"

export const cardComponentMap = {
    RectangleCardComponent,
    FeaturedCardComponent,
    MembersCardComponent,
    SquareCardComponent,
    LoyaltyCard,
    VideoComponent,
    MiniLoyaltyCard,
    ImageComponent,
    ListItemComponent
}