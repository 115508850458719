import { useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';

// Loading states hierarchy
export const LoadingState = {
    INITIAL: 'initial',      // First render, no data fetching started
    LOADING: 'loading',      // Full loading state, first data fetch
    PARTIAL: 'partial',      // Some data available, still loading more
    STALE: 'stale',         // Data available but being refreshed
    SUCCESS: 'success',      // All data loaded successfully
    ERROR: 'error'          // Error state
};

export const useEventLoadingState = (initialState = LoadingState.INITIAL) => {
    const [loadingState, setLoadingState] = useState(initialState);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);

    const trackStateChange = useCallback((from, to, context = {}) => {
        Sentry.addBreadcrumb({
            category: 'loading_state',
            message: `Loading state changed from ${from} to ${to}`,
            level: 'info',
            data: {
                previousState: from,
                newState: to,
                progress,
                ...context
            }
        });
    }, [progress]);

    const setLoading = useCallback((context = {}) => {
        const prevState = loadingState;
        const newState = prevState === LoadingState.SUCCESS 
            ? LoadingState.STALE 
            : LoadingState.LOADING;
        
        setLoadingState(newState);
        setProgress(0);
        setError(null); // Reset error state when loading starts
        trackStateChange(prevState, newState, context);
    }, [loadingState, trackStateChange]);

    const setPartial = useCallback((newProgress, context = {}) => {
        const prevState = loadingState;
        setLoadingState(LoadingState.PARTIAL);
        setProgress(Math.min(Math.max(0, newProgress), 100));
        setError(null); // Reset error state when partial data is received
        trackStateChange(prevState, LoadingState.PARTIAL, {
            progress: newProgress,
            ...context
        });
    }, [loadingState, trackStateChange]);

    const setSuccess = useCallback((context = {}) => {
        const prevState = loadingState;
        setLoadingState(LoadingState.SUCCESS);
        setProgress(100);
        setError(null); // Reset error state when data is loaded successfully
        trackStateChange(prevState, LoadingState.SUCCESS, context);
    }, [loadingState, trackStateChange]);

    const setErrorState = useCallback((err, context = {}) => {
        const prevState = loadingState;
        setLoadingState(LoadingState.ERROR);
        setError(err);
        trackStateChange(prevState, LoadingState.ERROR, {
            error: err.message,
            ...context
        });
    }, [loadingState, trackStateChange]);

    const reset = useCallback((context = {}) => {
        const prevState = loadingState;
        setLoadingState(LoadingState.INITIAL);
        setProgress(0);
        setError(null); // Reset error state when loading is reset
        trackStateChange(prevState, LoadingState.INITIAL, context);
    }, [loadingState, trackStateChange]);

    return {
        state: loadingState,
        progress,
        error,
        isLoading: loadingState === LoadingState.LOADING || loadingState === LoadingState.STALE,
        isPartial: loadingState === LoadingState.PARTIAL,
        isSuccess: loadingState === LoadingState.SUCCESS,
        isError: loadingState === LoadingState.ERROR,
        isStale: loadingState === LoadingState.STALE,
        setLoading,
        setPartial,
        setSuccess,
        setError: setErrorState,
        reset
    };
};
