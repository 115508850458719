import {useApiQuery} from "../../../../hooks/customHooks.js";

export const useCollection = () => {
    const getCollection = (id) => {
        return useApiQuery(`collection/${id}`, () => {}, () => {}, {});
    };



    return  { getCollection };
}