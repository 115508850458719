import React from 'react'
import styled from 'styled-components';
import { Section } from '../../../../../layout/components/LayoutSection';
import { ImpactTitle } from '../../../../../components/ui/typography';

const SponsorContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* padding: 0px 20px; */
    gap: 10px;
`
const Sponsor = styled.div`
    padding: 10px;
    font-size: 12px;
    text-align: center;
    border-radius: var(--border-radius-default);
    background-color: rgba(255,255,255,0.02);
    border: solid 1px var(--accent-colour);
`

const SponsorsSection = ({ sponsors }) => {

    if(sponsors.length === 0) return;

  return (
    <>
    <Section>
            <ImpactTitle header={'Sponsors'} />
            <SponsorContainer>
            {
            sponsors.map((sponsor, index) => {
                return (
                        <Sponsor>Sponsor {index + 1}</Sponsor>
                        )
                    })
            }
            </SponsorContainer>
    </Section>
    </>
  )
}

export default SponsorsSection