import React, {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import axios from "axios";
import {API_URL} from "../../js/Helper.js";
import {DividerWithText} from "../../components/ui/LogoWall/DividerWithText.jsx";
import {extractColorCodeFromCssVariable} from "../themes/js/utils.js";
import {Paragraph} from "../../components/ui/typography/index.js";
import {ButtonComponent} from "../../components/ui/buttons/index.js";
import {
    ReviewCard,
    ReviewerName, ReviewFormContainer,
    ReviewList,
    ReviewSectionContainer,
    ReviewText,
    StarRating
} from "./reviews.styles.js";
import styled from "styled-components";



// Add these styled components near other styled components
const CustomFormInputs = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
  `;

const FormInput = styled.input`
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    border: 1px solid ${extractColorCodeFromCssVariable('accent-colour')};
    border-color: ;

    &::placeholder {
      color: white;
    }

    &:focus {
      outline: none;
      border-color:  ${extractColorCodeFromCssVariable('accent-colour')};
    }
  `;


// TODO: Detach this from the product page and make it dynamic

/**
 * Review Section
 * @param {*} product
 * @returns
 */

export const Reviews = ({ product }) => {
    const [reviews, setReviews] = useState(product?.reviews || []);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [reviewFormData, setReviewFormData] = useState({
        full_name: '',
        business_name: '',
        rating: 0,
        review: '',
        rating_type: 'product',
        type: 'product',
        id: product.id
    });

    const mutation = useMutation({
        mutationFn: (data) => createReview(data),
        onSuccess: (response) => {
            // Create a new review object from the form data and response
            const newReview = {
                id: response.data.id, // Assuming the API returns the new review's ID
                name: reviewFormData.full_name,
                business_name: reviewFormData.business_name,
                rating: reviewFormData.rating,
                review: reviewFormData.review,
                created_at: new Date().toISOString()
            };

            // Add the new review to the beginning of the reviews array
            setReviews(prevReviews => [newReview, ...prevReviews]);

            // Reset the form
            setReviewFormData({
                full_name: '',
                business_name: '',
                rating: 0,
                review: '',
                rating_type: 'product',
                type: 'product',
                id: product.id
            });

            // Hide the form
            setShowReviewForm(false);

            toast.success('Review submitted successfully');
        },
        onError: (error) => {
            toast.error(error?.response?.data?.message || error?.message || error || 'There has been an error');
        }
    });

    const createReview = (data) => {
        return axios.post(`${API_URL}/reviews`, data);
    }

    const handleReviewInputChange = (e) => {
        setReviewFormData({ ...reviewFormData, [e.target.name]: e.target.value });
    }

    const handleRatingClick = (rating) => {
        setReviewFormData({ ...reviewFormData, rating });
    }

    const handleSubmitReview = () => {
        mutation.mutate(reviewFormData);
    }


    return (
        <div id='reviews' style={{padding: '20px'}}>
            <DividerWithText text='Customer Reviews' style={{margin: '20px 0'}} />
            {reviews && reviews.length > 0 ? (
                <ReviewSectionContainer style={{borderBottom: `1px solid ${extractColorCodeFromCssVariable('--accent-colour')}`}}>
                    <ReviewList>
                        {reviews.map(review => (
                            <ReviewCard key={review.id}>
                                <ReviewerName>{review.name}</ReviewerName>
                                <ReviewText>{review.review}</ReviewText>
                                <StarRating>{'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}</StarRating>
                            </ReviewCard>
                        ))}
                    </ReviewList>
                </ReviewSectionContainer>
            ) : (
                <Paragraph style={{textAlign: 'center', padding: '20px'}}>
                    No reviews yet
                </Paragraph>
            )}
            <ReviewSectionContainer style={{borderTop: `1px solid ${extractColorCodeFromCssVariable('--accent-colour')}`}}>


                <ButtonComponent
                    onClick={() => setShowReviewForm(!showReviewForm)}
                    style={{margin: '20px auto', display: 'block'}}
                    variant='outlined'
                >
                    {showReviewForm ? 'Cancel' : 'Leave a Review'}
                </ButtonComponent>

                {showReviewForm && (
                    <ReviewFormContainer isOpen={showReviewForm}>
                        <CustomFormInputs>
                            <FormInput
                                type="text"
                                name="full_name"
                                placeholder="Full Name"
                                value={reviewFormData.full_name}
                                onChange={handleReviewInputChange}
                            />
                            <FormInput
                                type="text"
                                name="business_name"
                                placeholder="Business Name"
                                value={reviewFormData.business_name}
                                onChange={handleReviewInputChange}
                            />
                            <StarRating style={{fontSize: '24px', cursor: 'pointer'}}>
                                {[1,2,3,4,5].map(star => (
                                    <span
                                        key={star}
                                        onClick={() => handleRatingClick(star)}
                                        style={{color: star <= reviewFormData.rating ? extractColorCodeFromCssVariable('--accent-colour') : 'gray'}}
                                    >
                    ★
                  </span>
                                ))}
                            </StarRating>
                            <FormInput
                                as="textarea"
                                name="review"
                                placeholder="Write your review here..."
                                value={reviewFormData.review}
                                onChange={handleReviewInputChange}
                                style={{minHeight: '100px', resize: 'vertical'}}
                            />
                            <ButtonComponent onClick={handleSubmitReview} variant='solid' isLoading={mutation.isLoading}>
                                Submit Review
                            </ButtonComponent>
                        </CustomFormInputs>
                    </ReviewFormContainer>
                )}
            </ReviewSectionContainer>
        </div>
    )
};
