/**
 * PrimaryEventHero section component
 * @description Hero section for displaying primary event information with background image
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import PrimaryEventCard from '../../components/PrimaryEventCard';
import EventInfoStrips from '../../components/EventInfoStrips/EventInfoStrips';
import { ANIMATION_TIMING } from '../../../../atomic/atoms/AnimatedElement/animations';
import Image from '../../../../atomic/atoms/Image';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const HeroContainer = styled.section`
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100svh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 20px;
  padding-bottom: 15vh;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.$backgroundImage});
  background-size: cover;
  background-position: center;
  z-index: 0;
  opacity: 0;
  animation: ${scaleIn} ${ANIMATION_TIMING.NORMAL.duration}s ${ANIMATION_TIMING.NORMAL.easing} forwards;
  animation-delay: ${ANIMATION_TIMING.NORMAL.delay + 0.3}s;

  @media (min-width: 768px) {
    filter: blur(8px);
    transform: scale(1.1); /* Compensate for blur edge effect */
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.$gradient || 'rgba(0, 0, 0, 0.5)'};
  z-index: 1;
  opacity: 0;
  animation: ${fadeIn} ${ANIMATION_TIMING.FAST.duration}s ${ANIMATION_TIMING.FAST.easing} forwards;
  animation-delay: ${ANIMATION_TIMING.FAST.delay}s;

  @media (min-width: 768px) {
    background: ${props => props.$desktopGradient || props.$gradient || 'linear-gradient(90deg, var(--primary-colour) 0%, var(--primary-colour) 60%, rgba(42,45,58,0) 100%)'};
  }
`;

const LeftSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 3;
  
  @media (min-width: 768px) {
    width: 45%;
    min-width: 450px;
  }
`;

const RightSection = styled.div`
  display: none;
  
  @media (min-width: 768px) {
    display: block;
    width: 45%;
    height: 70vh;
    position: relative;
    z-index: 3;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeIn} ${ANIMATION_TIMING.FAST.duration}s ${ANIMATION_TIMING.FAST.easing} forwards,
             transform ${ANIMATION_TIMING.FAST.duration}s ${ANIMATION_TIMING.FAST.easing} forwards;
  animation-delay: ${ANIMATION_TIMING.FAST.delay}s;
  
  @media (min-width: 768px) {
    margin-top: 0;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`;

const StyledEventInfoStrips = styled(EventInfoStrips)`
  display: none;
  z-index: 4;
  
  @media (min-width: 768px) {
    display: flex;
  }
`;

const EventImage = styled.div`
  width: 80%;
  height: 80%;
  border-radius: 12px;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} ${ANIMATION_TIMING.FAST.duration}s ${ANIMATION_TIMING.FAST.easing} forwards;
  animation-delay: ${ANIMATION_TIMING.FAST.delay + 0.3}s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PrimaryEventHero = ({
  backgroundImage,
  image,
  eventImage,
  overlayGradient = 'linear-gradient(to bottom, rgba(42,45,58,0) 30%, rgba(42,45,58,0.4) 50%, rgba(42,45,58,0.8) 70%, var(--primary-colour) 80%)',
  desktopOverlayGradient = 'linear-gradient(90deg, var(--primary-colour) 0%, var(--primary-colour) 60%, rgba(42,45,58,0) 100%)',
  title,
  date,
  time,
  location,
  dressCode,
  organiser,
  description,
}) => {
  return (
    <HeroContainer>
      <BackgroundImage $backgroundImage={backgroundImage || image} />
      <Overlay 
        $gradient={overlayGradient}
        $desktopGradient={desktopOverlayGradient} 
      />
      
      <LeftSection>
        <CardWrapper>
          <PrimaryEventCard
            title={title}
            date={date}
            organiser={organiser}
            description={description}
          />
        </CardWrapper>
        <StyledEventInfoStrips
          location={location}
          time={time}
          date={date}
          dressCode={dressCode}
          baseDelay={0}
          staggerDelay={0.1}
        />
      </LeftSection>

      <RightSection>
        <EventImage>
          <Image src={eventImage} alt="Event Image" aspectRatio="1/1" borderRadius="50px 0px" />
        </EventImage>
      </RightSection>
    </HeroContainer>
  );
};

PrimaryEventHero.propTypes = {
  backgroundImage: PropTypes.string,
  image: PropTypes.string,
  eventImage: PropTypes.string,
  overlayGradient: PropTypes.string,
  desktopOverlayGradient: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  location: PropTypes.string,
  dressCode: PropTypes.string,
  organiser: PropTypes.string,
  description: PropTypes.string,
};

export default PrimaryEventHero;
