import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineEmail } from 'react-icons/md';
import Input from '../Input/Input';

const EmailInput = forwardRef(({ 
  placeholder = 'Enter your email',
  ...props 
}, ref) => {
  return (
    <Input
      ref={ref}
      type="email"
      placeholder={placeholder}
      icon={<MdOutlineEmail color='var(--text-colour)' />}
      pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
      {...props}
    />
  );
});

EmailInput.propTypes = {
  placeholder: PropTypes.string,
  ...Input.propTypes
};

EmailInput.displayName = 'EmailInput';

export default EmailInput; 