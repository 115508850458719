import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { Footer } from './components/Footer'

const MainContent = styled.main`
    position: relative;
    display: grid;
    grid-template-columns:1fr;
    width: 100dvw;
    max-width: 100dvw;
    height: fit-content;
    min-height: 100dvh;
  
    box-sizing: border-box;
    overflow: hidden;

    
    @media screen and (min-width: 1024px){

    }
`
export const StandardPageLayout = () => {


  const items = [
    {
      name: 'Home',
      destination: `/`,
      icon: 'IoHomeOutline',
      position: 1,
    },
    {
      name: 'Events',
      destination: `/events`,
      position: 2,
      icon: 'AiFillCalendar',

    },
    {
      name: 'User',
      destination: `/user`,
      position: 3,
      icon: 'IoPersonSharp',

    },
    {
      name: 'Cards',
      destination: `cards`,
      position: 4,
      icon: 'IoCardSharp',

    },
    {
      name: 'Menu',
      destination: `{showMenu}`,
      position: 5,
      icon: 'ImMenu',

    },
  ]

  return (
    <>
    {/* <HeaderComponent/> */}
    <MainContent id='main-content'>
        <Outlet/>
    </MainContent>
    <Footer/>
    </>
  )
}
