import React, {useState} from 'react';
import PackForm from "../../packs/components/PackForm.jsx";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";
import {useEvent} from "../js/hooks/index.js";
import {useParams} from "react-router-dom";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {setSessionStorageJSON} from "../../../js/Helper.js";
import {updateTheme} from "../../themes/js/utils.js";
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {Paragraph} from "../../../components/ui/typography/index.js";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {copyToClipboard} from "../../../js/utils/browserUtils.js";


const inputs = [
    {name: 'first_name', type: 'text', label: 'First Name', placeholder: 'Jack'},
    {name: 'last_name', type: 'text', label: 'Last Name', placeholder: 'Black'},
    {name: 'email', type: 'text', label: 'Email', placeholder: 'j.black@yourbusiness.com'},

]



const AffiliateSignup = () => {
    const { setEvent, event } = useEvent();
    const [code, setCode] = useState();
    const params = useParams();
    const slug = params.slug;

    const relations = '?with=location,business,theme,partners.image,products.dependencies,fileLinks.file,eventDates';


    let path = `event/${slug}${relations}`

    const { data, isLoading, isError } = useApiQuery(path,
        data => {
           setEvent(data?.data)
            updateTheme(data?.data?.theme)
        },
        error => console.error(error.message),
    );

    const handleSuccess = (data) => {
        const code = data.data.code;
        setCode(code)
    }

    const handleCopyLink = () => {

    }

    return (
        <StandardSection>
            <ImageComponent src={event?.image?.cdnUrl} width={'100%'}/>
            <Paragraph>Fill in the details below. Once complete, copy and save the link for re use.</Paragraph>

            { code && <ButtonComponent
                variant={'outlined'}
                text={'Copy Link'}
                onClick={() => copyToClipboard(
                    `https://nctrnl.app/e/${slug}?affiliate=${code}`
                )}
            /> }

            { !code && <PackForm endpointUrl={`affiliate/create/`} onSuccess={handleSuccess} inputs={inputs} /> }
        </StandardSection>
    );
};

export default AffiliateSignup;