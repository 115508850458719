import styled from "styled-components";

const OffersSection = styled.div`
    position: relative;
    padding: 0;
    margin: 20px 0;
  `;

const OffersScrollContainer = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 0;

    &::-webkit-scrollbar {
      display: none;
    }
  `;

const OfferCard = styled.div`
    display: inline-block;
    background: ${props => props.isSelected ? 'var(--accent-colour)' : 'var(--secondary-colour)'};
    border: 2px solid var(--accent-colour);
    border-radius: 10px;
    padding: 10px 15px;
    margin-right: 15px;
    max-width: 250px;
    cursor: pointer;
    transition: all 0.3s ease;
    word-wrap: break-word;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    ${props => props.isSelected && `
      color: var(--secondary-colour);
      transform: translateY(-5px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    `}
  `;

const OfferName = styled.h4`
    font-size: 1.1em;
    color: ${props => props.isSelected ? 'var(--secondary-colour)' : 'var(--accent-colour)'};
    margin-bottom: 5px;
    text-wrap: balance;
  `;

const OfferDescription = styled.p`
    font-size: 0.9em;
    color: ${props => props.isSelected ? 'var(--secondary-colour)' : 'var(--text-colour)'};
    margin-bottom: 5px;
  `;

const OfferValue = styled.span`
    font-weight: bold;
    color: ${props => props.isSelected ? 'var(--secondary-colour)' : 'var(--success-colour)'};
  `;

const PriceDisplay = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `;

const OriginalPrice = styled.span`
    text-decoration: ${props => props.hasDiscount ? 'line-through' : 'none'};
    color: ${props => props.hasDiscount ? '#F44336' : 'inherit'};
  `;

const DiscountedPrice = styled.span`
    font-weight: bold;
    color: #4CAF50;
  `;


export {
    OffersSection,
    OffersScrollContainer,
    OfferCard,
    OfferName,
    OfferDescription,
    OfferValue,
    PriceDisplay,
    OriginalPrice,
    DiscountedPrice
}