/**
 * @fileoverview Hook for handling item removal from basket with optimistic updates
 * 
 * This hook provides functionality to:
 * 1. Remove items (products or addons) from the basket
 * 2. Handle dependency checks before removal
 * 3. Provide immediate UI feedback with optimistic updates
 * 4. Sync changes with the server
 * 5. Handle error cases with automatic rollback
 */

import { useCallback } from 'react';
import { useEcommerce } from '../context/EcommerceContext';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import axios from "axios";
import {API_URL} from "../../../../js/Helper.js";
import {useHandleError} from "../../../error-handling/js/hooks/index.js";

/**
 * Custom hook to handle item removal with optimistic updates
 * @returns {Function} Callback function to handle item removal
 * 
 * @example
 * const handleRemoveItem = useHandleRemoveOrderItem();
 * // In component:
 * <button onClick={(e) => handleRemoveItem(e, null, itemToRemove)}>Remove</button>
 */
const useHandleRemoveOrderItem = () => {
    const { setOrderItems, basket, basketItems, setBasket } = useEcommerce();
    const { closeDrawer } = useDrawer();
    const { showErrorToast } = useHandleError();

    /**
     * Helper function to make API requests
     * @private
     */
    const makeRequest = async (method, url, data, headers) => {
        try {
            const res = await axios({
                method,
                url,
                data,
                headers
            });
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    /**
     * Helper function to get request headers
     * @private
     */
    const header = () => {
        // implement your header logic here
        return {};
    };

    return useCallback(async (e, old, itemToRemove) => {
        const previousItems = basketItems();

        try {
            // Check dependencies before removal
            const itemsWithDependencies = basket.orderItems.filter(item => 
                item.dependencies?.length > 0 && 
                item.dependencies.some(dep => dep.id === itemToRemove.id)
            );

            // Prevent removal if item is required by other items
            if (itemsWithDependencies.length > 0) {
                const dependentItem = itemsWithDependencies[0];
                showErrorToast(`${itemToRemove.name} is required by ${dependentItem.name}`);
                return;
            }

            // Optimistically update UI
            let updatedItems;
            let isAddon = false;

            // Handle addon removal
            updatedItems = previousItems.map(item => {
                if (item.addons?.length > 0) {
                    const updatedAddons = item.addons.filter(addon => addon.id !== itemToRemove.id);
                    if (updatedAddons.length !== item.addons.length) {
                        isAddon = true;
                        return { ...item, addons: updatedAddons };
                    }
                }
                return item;
            });

            // Handle main item removal
            if (!isAddon) {
                updatedItems = updatedItems.filter(item => {
                    const isMainItem = item.id !== itemToRemove.id;
                    const isDependency = !itemToRemove.dependencies?.some(dep => dep.id === item.id);
                    return isMainItem && isDependency;
                });
            }

            // Update UI immediately
            setOrderItems(updatedItems);

            // Close drawer if basket is empty
            if (updatedItems.length === 0) {
                closeDrawer();
            }

            // Sync with server
            const res = await makeRequest(
                'POST',
                `${API_URL}/basket/${basket.id}/remove-product/${itemToRemove.id}`,
                null,
                header()
            );

            if (res?.basket) {
                setBasket(res.basket);
            }

        } catch (err) {
            // Revert to previous state on error
            setOrderItems(previousItems);
            showErrorToast('Failed to remove item. Please try again.');
            console.error('Error removing item:', err);
        }

    }, [basket, basketItems, setOrderItems, setBasket, closeDrawer, showErrorToast]);
};

export default useHandleRemoveOrderItem;
