import React from 'react'
import styled from 'styled-components'
import {StandardSection} from '../layout/sections/StandardSection'
import {HorizontalFeaturedListLayout} from '../layout/ListLayouts/HorizontalFeaturedListLayout'
import {API_URL, getLocalStorageJSON} from '../js/Helper'
import {StandardListLayout} from '../layout/ListLayouts/StandardListLayout'
import {useParams} from 'react-router-dom'
import {modelsMap} from '../js/maps/modelsMap'
import {FloatingNav} from '../layout/components/FloatingNav'
import {Loading} from '../components/ui/Loading'
import {NoResultsComponent} from '../components/ui/NoResultsComponent'
import LogoComponent from '../components/ui/LogoComponent'
import {useApiQuery} from '../hooks/customHooks'
import {InstructionTitle} from "../components/ui/typography/index.js";
import {mainMenu} from "../layout/menus/js/mainMenu.js";
import cardListComponent from "../features/cards/components/CardListComponent.jsx";
import {cardComponentMap} from "../components/ui/cards/cardComponentMap.js";

const PageContainer = styled.section`
    display: flex;
    flex-direction: column;
    height: fit-content;
  
    padding-bottom: 100px;
    background-color: var(--default-primary-colour);

;


 
`

export const StandardFeedpage = () => {


    // Get the model, key and value from the url 
    // Use this to determine what data to get

    const params = useParams();
    const model = params.model;
    const key = params.key;
    const value = params.value;

    // Fetch the info for the model
    const theModel = modelsMap[model];

    // Set the path for fetching the data
    let path = key && value ? `${model}?${key}=${value}` : model;

    // Check to see if the model has relations, if so add them to the path
    if (theModel?.relations) {
      path += `${key && value ? '&' : '?'}with=${theModel.relations}`;
    }

    // Add city location dat to the request
    const currentCity = getLocalStorageJSON('city')

    if(currentCity && theModel?.relations?.includes('location')) {
      path += `&city=${currentCity.value}`
    }

    // Make the data request
    const { data, isLoading, isError, error } = useApiQuery(`${API_URL}/${path}`,
        data => {

        },
        error => console.error(error.message)

        );
  
        
      

    if(isLoading) {
        return <Loading/>
    }
    
    if(isError) {
        return <Loading/>
    }



    const response = data && data.data;
    const pageTitle = data && data.title;
    const featuredImage = data && data.featured_image;
    
    // seperate all the features items 
    const featuredList = response?.filter((item) => item.featured == true)
        
    /**
     * 
     */
    
    const mainFeedSectionStyle = response?.length > 0
    && innerWidth >= 1024
    ? {marginTop:'300px'}
    : featuredList?.length > 0
    ? {}
    : {marginTop: ''}
    
    const featuredFeedSectionStyle = innerWidth >= 1024
    ? {
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        width: innerWidth >= 1920 ? '1500px' : '1100px', alignSelf:'center'}
        : {}
        
  return (
    <>

        { response?.length === 0 && <NoResultsComponent/> }

        {
            response?.length > 0 &&
            <PageContainer>

                {   
                    <StandardSection id="featured-section" image={featuredImage}>
                      {
                      featuredList?.length > 0 ?

                      <div style={featuredFeedSectionStyle}>
                          <HorizontalFeaturedListLayout model={theModel.single} data={featuredList}/>
                      </div>
                      : <LogoComponent/>
                      }
                    </StandardSection>
                }
                

                {/* <StandardSection id='search-section'>
                </StandardSection> */}

                <StandardSection id='main-feed-section' style={mainFeedSectionStyle} >
                   <InstructionTitle style={{textTransform:'uppercase'}} title={pageTitle.header} />
                    {
                        <StandardListLayout>
                            {Array.isArray(response) && response.map((item) => {
                                const Card = cardComponentMap[theModel.card || 'RectangleCardComponent'];
                                return (
                                    <Card
                                        key={item.id} // Use a unique identifier if available
                                        data={item}
                                        data_model={theModel.single}
                                    />
                                );
                            })}
                        </StandardListLayout>
                    }

                </StandardSection>


            </PageContainer>
        }

        <FloatingNav items={mainMenu} showBack={true} theme={'default'}/>
    </>
  )
}
