import {CALENDAR_ICON, CARD_ICON, HOME_ICON, MENU_ICON, MUSIC_ICON, USER_ICON} from "../../../js/icons.js";

const home = {
    name: 'Home',
    destination: `/`,
    icon: HOME_ICON,
    position: 1,
}

const events = {
    name: 'Events',
    destination: `/events`,
    icon: CALENDAR_ICON,
    position: 2,
}

const user = {
    name: 'User',
    destination: `/user/my-account`,
    icon: USER_ICON,
    position: 3,
}

const cards = {
    name: 'Cards',
    destination: `/cards`,
    icon: CARD_ICON,
    position: 4,
}

const menu = {
    name: 'Menu',
    destination: `menu`,
    icon: MENU_ICON,
    position: 5,
}


export const mainMenu = [home, events, user, cards, menu]