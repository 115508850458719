import styled from 'styled-components'

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 100vw;



`

export const CheckoutSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 100svh;
    width: 100%;
    /* max-width: 600px; */
    flex: 1 1;
    padding: 20px;
    height: fit-content;
    box-sizing: border-box;

    @media screen and (min-width: 768px){
        /* max-width: unset; */
    }
`