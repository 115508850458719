import styled from "styled-components"

export const TopBottomGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent 0%, ${props => props.color} 100%),
    url(${props => props.image});
    background-position: top center;
    background-size: cover;
    min-height: 300px;
    min-width: ${innerWidth > 768 ? '50%' : '100%'};
    z-index: 1;


    
`
