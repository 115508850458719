import React, {useContext} from 'react';
import styled from 'styled-components';
import {AuthContext} from "../../features/authentication/index.js";
import {useEcommerce} from "../../features/ecommerce/js/context/EcommerceContext.jsx";
import {useDrawer} from "../../js/hooks/context/DrawerContext.jsx";
import {BsBasket} from "react-icons/bs";
import Basket from "../../features/ecommerce/basket/index.js";
import {AiOutlineUser} from "react-icons/ai";
import UserHomePage from "../../features/user/pages/UserArea/UserHomePage.jsx";

const TopNavigationContainer = styled.div`
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    color: var(--text-colour);
    background: transparent;
    
    @media screen and (min-width: 768px){
        justify-content: center;
        max-width: 100vw;
    }
`

const TopNavigationContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    height: 50px;
    padding: 20px 20px;
    background: var(--accent-colour);
    box-shadow: var(--default-shadow);
    //border: 1px solid var(--accent-colour);
    z-index: 999;
    border-radius: var(--border-radius-default);
    //border-bottom-right-radius: var(--border-radius-default);
    //border-bottom-left-radius: var(--border-radius-default);
    
      @media screen and (min-width: 768px){
        justify-content: space-between;
        max-width: 500px;
        margin: 0 auto;
    }
`

const UserContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
`


const TopNavigation = () => {

    const { isAuthenticated, user } = useContext(AuthContext);
    const { basket } = useEcommerce();
    const { isOpen, openDrawer } = useDrawer();

    if(!isAuthenticated && basket.orderItems.length === 0 || isOpen) return;

    let navStyle = { };

    if(isAuthenticated) {
        navStyle.width = '100%'
        navStyle.background = 'var(--accent-colour)'
    }

    return (
        <TopNavigationContainer id={'top-nav'} name={'top-nav'}>
            <TopNavigationContent style={navStyle}>

                { isAuthenticated && user &&
                    <UserContainer onClick={() => openDrawer(<UserHomePage/>, '', 'bottom')}  >
                        <AiOutlineUser />
                        <p>{user.first_name}</p>
                    </UserContainer>
                }

                { basket.orderItems.length !== 0 && <BsBasket style={{cursor: 'pointer'}} size={23} onClick={() => openDrawer(<Basket />, 'Basket', 'bottom') } />}

            </TopNavigationContent>
        </TopNavigationContainer>
    );
};

export default TopNavigation;