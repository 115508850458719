import React from 'react'
import { Section } from '../../../../../layout/components/LayoutSection'
import { ImageComponent } from '../../../../../components/ui/images/ImageComponent'
import styled from 'styled-components'
import { FaInstagram, FaInstagramSquare } from 'react-icons/fa'
import { ImEarth, ImFacebook, ImFacebook2, ImInstagram } from 'react-icons/im'
import { IoLogoInstagram } from 'react-icons/io5'
import { AiFillInstagram, AiOutlineInstagram } from 'react-icons/ai'

const ShareIconsContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
`


const OrganiserSection = ({ logo }) => {

    if(!logo) return; 

  return (
    <Section style={{padding:'0px'}} height="fit-content" id="organiser" >
    <ImageComponent src={logo.cdnUrl} width={'200px'} />
    <ShareIconsContainer>
        <ImInstagram size={22}/>
        <ImFacebook size={22}/>
        <ImEarth size={22} />
    </ShareIconsContainer>
    </Section>  )
}

export default OrganiserSection