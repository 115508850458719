import React from 'react';
import styled from "styled-components";
import {LoyaltyCard} from "../components/LoyaltyCard/LoyaltyCard.jsx";
import {AddToAppleWallet} from "../../../components/ui/AddToAppleWallet.jsx";
import {FullPageContainer} from "../../../layout/styles/layoutStyles.js";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";
import {QrCodeComponent} from "../../../components/ui/QrCodeComponent.jsx";
import {FadedBgImage} from "../../../components/ui/FadedBgImage.jsx";

const PageContainer = styled(FullPageContainer)`
    align-items: center;
    justify-content: space-between;
    min-height: 80svh;
    padding-bottom: 0px;
`

const FadedBgImageContainer = styled.div`
    position: fixed;
    width: 100%;
    margin-top: -20px;
    height: 300px;
    background-size: cover;
`

const LinearGradient = styled.div`
    position: fixed;
    width: 100%;
    margin-top: -20px;
    height: 300px;
    background: linear-gradient(to top, var(--primary-colour), transparent);    
    background-size: cover;
`

const CardholderPage = ({ cardholder }) => {


    return (
        <PageContainer id={'cardholder-page'} style={{background: cardholder.card_tier.theme.primary_colour}}>
            {/*<FadedBgImageContainer style={{backgroundImage: `url(${cardholder.card_tier?.image?.cdnUrl})`}}/>*/}

            <StandardSection>
                {/*<FadedBgImage image={cardholder.card_tier?.image?.cdnUrl + '?blur=25'} tier={cardholder.card_tier} />*/}
                <LoyaltyCard
                    tier={cardholder.card_tier}
                    card={cardholder.card_tier.card}
                    member={cardholder}
                />

            </StandardSection>

            <StandardSection>
                <QrCodeComponent value={cardholder.member_id} size={120} style={{
                    background_colour: cardholder.card_tier.theme.primary_colour,
                    foreground_colour: 'white',
                }} />
                <AddToAppleWallet type={'cardholder'} id={cardholder.member_id} style={{width: '150px'}}/>

            </StandardSection>


        </PageContainer>
    );
};

export default CardholderPage;