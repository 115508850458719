import React, { createContext, useContext, useEffect, useState } from 'react'
import { SetLocalStorageJSON } from '../../js/Helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeRequest } from '../../services/axios';
import { AuthContext } from '../../features/authentication';

export const ModelContext = createContext();

export function ModelsProvider ({ children }) {

    const [model, setModel] = useState(() => {
        const storedModel = localStorage.getItem('model');
        return typeof storedModel === 'object' ? JSON.parse(storedModel) : null;
    });

    const [models, setModels] = useState();

    const navigate = useNavigate();
    const location = useLocation();
    const { header } = useContext(AuthContext);


    useEffect(() => {

      
        // if (typeof model !== 'object' || model === null || model == undefined)  {


        //   let path = location.pathname;

        //   let pathname = location.pathname;
        //   let parts = pathname.split("/");
        //   let extractedContent = parts[1];
          
        //   console.log(extractedContent)
        //   const req = makeRequest('GET', `models?path=${extractedContent}`, null, header())
        //   .then((res) => {

        //       if(!res.success) navigate('/login')

        //       setModel(res.data[0])
        //       SetLocalStorageJSON('model', res.data[0])

        //     }).catch((error) => {
        //       console.error('Models Request Error', error)
        //     })
        //   } else {
        //     setModel(model)
        //     SetLocalStorageJSON('model',model)
        
        //   }
   

    }, [model])

    const getNewModel = () => {
      const req = makeRequest('GET', `models?path=${extractedContent}`, null, header())
      .then((res) => {

          if(!res.success) navigate('/login')

          setModel(res.data[0])
          SetLocalStorageJSON('model', res.data[0])

        }).catch((error) => {
          console.error('Models Request Error', error)
        })
    }
  

  return (
    <ModelContext.Provider value={{model, setModel, getNewModel}}>
        {children}
    </ModelContext.Provider>
  )
}
