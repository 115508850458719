import React, { memo } from 'react';
import { useDrawer } from './DrawerContext';
import Drawer from './Drawer';

const DrawerItem = memo(({ drawerId, drawerState, onClose, onBack }) => {
  if (!drawerState?.isOpen) return null;

  return (
    <Drawer
      key={drawerId}
      isOpen={drawerState.isOpen}
      onClose={() => onClose(drawerId)}
      onBack={drawerState.history.length > 0 ? () => onBack(drawerId) : null}
      title={drawerState.title}
      position={drawerState.position}
    >
      {drawerState.content}
    </Drawer>
  );
});

DrawerItem.displayName = 'DrawerItem';

/**
 * Container component that renders all active drawers
 * This component should be placed at the root level of your app
 */
export const DrawerContainer = memo(() => {
  const { getDrawerState, closeDrawer, goBack } = useDrawer();
  const drawerStates = getDrawerState();

  if (!drawerStates) return null;

  return Object.entries(drawerStates).map(([drawerId, drawerState]) => (
    <DrawerItem
      key={drawerId}
      drawerId={drawerId}
      drawerState={drawerState}
      onClose={closeDrawer}
      onBack={goBack}
    />
  ));
});

DrawerContainer.displayName = 'DrawerContainer';

export default DrawerContainer;
