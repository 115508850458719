import styled from "styled-components";

export const LoyaltyCardContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 220px;
    border-radius: var(--border-radius-default);
    background-color: ${props => props.data.theme ? props.data.theme.primary_colour  : 'var(--success-colour)'};
    border: ${props => props.selectedTier === props.data ? `solid 2px var(--accent-colour)` : ''};
    box-shadow: ${props => props.selectedTier === props.data ? `inset 10px 10px 25px rgba(0,0,0,0.2), inset -20px -10px 25px rgba(0,0,0,0.2), 5px 5px 15px rgba(0,0,0,0.5)` : ''};
    background-image: url(${props => props.data?.image?.cdnUrl});
    background-size: cover;
    background-position: center;

    /* This pseudo-element forces the 16:9 aspect ratio */
    &::before {
        content: '';
        display: block;
        padding-top: 56.25%;  /* 9/16 * 100% = 56.25% */
    }

    

`