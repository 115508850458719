import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Sentry from "@sentry/react";
import { PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import { useHandleError } from '../../../../error-handling/js/hooks';

// Styled components for consistent styling
const ErrorContainer = styled.div`
    padding: 1.5rem;
    border-radius: 8px;
    background-color: var(--error-bg, #fff1f0);
    border: 1px solid var(--error-border, #ffccc7);
    margin: 1rem 0;
`;

const ErrorHeading = styled.h3`
    color: var(--error-text, #cf1322);
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;
`;

const ErrorMessage = styled.p`
    color: var(--error-text-secondary, #434343);
    margin: 0 0 1rem 0;
`;

const ErrorActions = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
`;

const Button = styled.button`
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    &.retry-button {
        background-color: var(--primary-color, #1890ff);
        color: white;
        border: none;

        &:hover {
            background-color: var(--primary-color-dark, #096dd9);
        }
    }

    &.reset-button {
        background-color: transparent;
        border: 1px solid var(--border-color, #d9d9d9);
        color: var(--text-color, #434343);

        &:hover {
            background-color: var(--background-hover, #f5f5f5);
        }
    }
`;

/**
 * Error boundary component specifically for payment processing
 * Catches and handles payment-related errors in a user-friendly way
 */
class PaymentErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error
        };
    }

    componentDidCatch(error, errorInfo) {
        // Send error to Sentry
        Sentry.withScope((scope) => {
            scope.setTag("feature", "payment");
            scope.setLevel("error");
            scope.setContext("payment_error", {
                code: error?.code,
                recoverable: error?.recoverable,
                action: error?.action,
                originalError: error?.originalError,
                timestamp: error?.timestamp || new Date().toISOString()
            });
            scope.setContext("error_info", {
                componentStack: errorInfo?.componentStack
            });
            Sentry.captureException(error);
        });

        // Log error for debugging
        console.error('Payment Error:', {
            error,
            errorInfo,
            timestamp: new Date().toISOString(),
            componentStack: errorInfo?.componentStack,
            url: window.location.href
        });

        this.setState({
            errorInfo
        });
    }

    logError = (error, errorInfo) => {
        // TODO: Integrate with your error logging service
        console.error('Payment Error:', {
            error,
            errorInfo,
            timestamp: new Date().toISOString(),
            componentStack: errorInfo?.componentStack,
            url: window.location.href
        });
    };

    reset = () => {
        this.setState({
            hasError: false,
            error: null,
            errorInfo: null
        });
        this.props.onReset?.();
    };

    render() {
        if (this.state.hasError) {
            return (
                <PaymentErrorFallback
                    error={this.state.error}
                    onReset={this.reset}
                    onRetry={this.props.onRetry}
                />
            );
        }

        return this.props.children;
    }
}

/**
 * Fallback component to display when a payment error occurs
 */
const PaymentErrorFallback = ({ error, onReset, onRetry }) => {
    const { showErrorToast } = useHandleError();

    React.useEffect(() => {
        if (error) {
            showErrorToast(error.message);
        }
    }, [error, showErrorToast]);

    return (
        <ErrorContainer role="alert" aria-live="polite">
            <ErrorHeading>Payment Processing Issue</ErrorHeading>
            <ErrorMessage>
                {error?.message || 'An unexpected error occurred during payment processing.'}
            </ErrorMessage>
            <ErrorActions>
                <Button
                    onClick={onRetry}
                    className="retry-button"
                    aria-label="Retry payment"
                >
                    Retry Payment
                </Button>
                <Button
                    onClick={onReset}
                    className="reset-button"
                    aria-label="Reset payment form"
                >
                    Start Over
                </Button>
            </ErrorActions>
        </ErrorContainer>
    );
};

PaymentErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    onReset: PropTypes.func,
    onRetry: PropTypes.func.isRequired
};

PaymentErrorFallback.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string
    }),
    onReset: PropTypes.func.isRequired,
    onRetry: PropTypes.func.isRequired
};

export default PaymentErrorBoundary;
