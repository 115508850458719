import { SetLocalStorageJSON, scrollToElement } from "../../../../js/Helper";
import { useCard } from "../context/CardContext";

const useHandleTierChange = () => {

    const { setSelectedTier } = useCard();

    const handleTierChange = (newTier) => {
        SetLocalStorageJSON('selectedTier', newTier)
        setSelectedTier(newTier)
        setTimeout(() => {
            scrollToElement('tier-content')
        }, 500);
    } 

    return  handleTierChange;
}

export default useHandleTierChange

