import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Container = styled.section``

const CoverPage = ({ title }) => {
    return (
        <Container>
            {title}
        </Container>
    );
};

CoverPage.propTypes = {
    title: PropTypes.string
};

export default CoverPage;