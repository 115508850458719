const setBookingId = (slug = null) => {
    try {
        const searchParams = new URLSearchParams(window.location.search);
        const param = searchParams.get('bid');

        if (param) {
            // Use a regular expression to match the first digit and the rest of the characters
            const match = param.split('-');
            console.log('match:', match)

            if (match.length === 2) {
                const timeId = match[0];
                const bookingId = match[1];

                console.log('First digit:', timeId);
                console.log('Remaining characters:', bookingId);

                if (bookingId) {
                    localStorage.setItem('bookingId', JSON.stringify({
                        bookingId,
                        timeId,
                        slug
                    }));
                    console.log('Booking ID set in localStorage');
                } else {
                    console.error('Booking ID is not valid');
                }
            } else {
                console.error('No digit found at the beginning of the parameter');
            }
        } else {
            // console.error('Parameter bid is not found');
        }
    } catch (error) {
        console.error('An error occurred while setting the booking ID:', error);
    }
};


const getBookingId = () => {
    try {

        const booking = JSON.parse(localStorage.getItem('bookingId'));

        return {
            bookingId: Number(booking?.bookingId),
            slug: booking?.slug,
            timeId: Number(booking?.timeId)
        };
    } catch (error) {
        console.error('Error getting bookingId from localStorage:', error);
        return null; // or some default value
    }
}

const removeBookingId = () => {
    localStorage.removeItem('bookingId');
}

export {
    setBookingId,
    getBookingId,
    removeBookingId
}
