import styled from "styled-components";
import { FullPageAbsoluteContainer, FullPageContainer } from "../../../../layout/styles/layoutStyles";

export const OrdersPageContainer = styled(FullPageContainer)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
`