import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import { AiOutlineClose } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import PropTypes from 'prop-types';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000000000;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px); /* For Safari */
  min-height: 100vh;
  height: 100dvh;

  @media (min-width: 768px) {
    right: 40vw; /* Leave space for drawer */
  }
`;

const DrawerContainer = styled(motion.div)`
  position: fixed;
  backdrop-filter: blur(20px);
  z-index: 1000000000;
  display: flex;
  flex-direction: column;
  background: var(--surface-colour);
  min-height: 100vh;
  height: 100%;
  
  @media (min-width: 768px) {
    top: 0;
    right: 0;
    bottom: 0;
    width: 40vw;
    border-radius: 0;
    min-height: 100vh;
    height: 100dvh;
    max-height: none;
    overflow: hidden;
  }

  /* Mobile styles */
  ${props => props.$position === 'bottom' && `
    @media (max-width: 767px) {
      height: auto;
      bottom: 0;
      left: 0;
      right: 0;
      min-height: 100px;
      max-height: 90vh;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  touch-action: none; /* Prevents scrolling while dragging header */
  flex-shrink: 0;
  min-height: 70px;
`;

const Title = styled.h2`
  margin: 0;
  flex-grow: 1;
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-colour);
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: var(--text-colour);
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &:first-child {
    margin-right: auto;
  }

  &:last-child {
    margin-left: auto;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px;
  height: calc(100% - 70px); /* Use percentage instead of vh */
  min-height: 0; /* Allow flex child to shrink below content size */
  
  /* Hide scrollbar but keep functionality */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    height: auto;
  }
`;

const DRAG_THRESHOLD = 0.5; // 50% of drawer height needed to trigger close

const Drawer = ({
  isOpen = false,
  onClose = () => {},
  onBack,
  title = '',
  children,
  position = 'bottom'
}) => {
  const controls = useAnimation();
  const y = useMotionValue(0);
  const x = useMotionValue(0);
  const scale = useTransform(y, [0, 200], [1, 0.95]);
  const opacity = useTransform(y, [0, 200], [1, 0.5]);
  const drawerRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  // Lock body scroll when drawer is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.height = '';
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.height = '';
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Reset position when drawer opens
  useEffect(() => {
    if (isOpen) {
      y.set(0);
      x.set(0);
      controls.start('visible');
    }
  }, [isOpen, y, x, controls]);

  const handleDragEnd = useCallback((event, info) => {
    if (isDesktop) return; // Disable drag on desktop

    const height = drawerRef.current?.getBoundingClientRect().height || 0;
    const dragPercentage = info.offset.y / height;
    
    if (dragPercentage > DRAG_THRESHOLD) {
      controls.start('exit').then(onClose);
    } else {
      controls.start('visible');
    }
  }, [controls, onClose, isDesktop]);

  const overlayVariants = {
    hidden: { 
      opacity: 0,
      backdropFilter: 'blur(0px)',
      WebkitBackdropFilter: 'blur(0px)',
      transition: { duration: 0.2 }
    },
    visible: { 
      opacity: 1,
      backdropFilter: 'blur(8px)',
      WebkitBackdropFilter: 'blur(8px)',
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0,
      backdropFilter: 'blur(0px)',
      WebkitBackdropFilter: 'blur(0px)',
      transition: { duration: 0.2, delay: 0.1 }
    }
  };

  const drawerVariants = {
    hidden: (isDesktop) => ({ 
      y: !isDesktop && isDesktop !== undefined ? '100%' : 0,
      x: isDesktop || isDesktop === undefined ? '100%' : 0,
      transition: { type: 'spring', stiffness: 300, damping: 30 }
    }),
    visible: { 
      y: 0,
      x: 0,
      transition: { 
        type: 'spring', 
        stiffness: 300, 
        damping: 30,
        opacity: { duration: 0.3 }
      }
    },
    exit: (isDesktop) => ({ 
      y: !isDesktop && isDesktop !== undefined ? '100%' : 0,
      x: isDesktop || isDesktop === undefined ? '100%' : 0,
      transition: { 
        type: 'spring', 
        stiffness: 400,
        damping: 40
      }
    })
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <Overlay
            data-testid="drawer-overlay"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            onClick={onClose}
          />
          <DrawerContainer
            data-testid="drawer-container"
            ref={drawerRef}
            $position={position}
            initial="hidden"
            animate={controls}
            exit="exit"
            custom={isDesktop}
            variants={drawerVariants}
            style={{ 
              y: !isDesktop ? y : 0,
              x: isDesktop ? x : 0,
              scale: !isDesktop ? scale : 1
            }}
            drag={!isDesktop ? "y" : false}
            dragElastic={0.2}
            dragConstraints={{ top: 0 }}
            dragDirectionLock
            onDragEnd={handleDragEnd}
          >
            <Header data-testid="drawer-header">
              {onBack && (
                <IconButton data-testid="drawer-back-button" onClick={onBack}>
                  <BiArrowBack size={24} />
                </IconButton>
              )}
              <Title data-testid="drawer-title">{title}</Title>
              <IconButton data-testid="drawer-close-button" onClick={onClose}>
                <AiOutlineClose size={24} />
              </IconButton>
            </Header>
            <Content data-testid="drawer-content" style={{ opacity: !isDesktop ? opacity : 1 }}>
              {children}
            </Content>
          </DrawerContainer>
        </>
      )}
    </AnimatePresence>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  position: PropTypes.oneOf(['bottom', 'right'])
};

export default Drawer;
