/**
 * Product Context and Provider for the e-commerce system
 * @description Provides product state and actions to the component tree.
 * This context handles the global state for product selection and interactions.
 * 
 * @example
 * // Wrap your app or product section with ProductProvider
 * <ProductProvider>
 *   <YourComponent />
 * </ProductProvider>
 * 
 * // Use the context in your components
 * const { selectedProduct, handleProductClick } = useProduct();
 */
import React, { createContext, useContext, useState, useCallback } from 'react';
import { PRODUCT_PAGES } from '../constants';
import { useDrawer } from '../../../../atomic/molecules/Drawer/DrawerContext';
import { BasketProvider } from '../../basket/context/BasketContext';
import TicketProductPage from '../pages/TicketProductPage/TicketProductPage';
import TableProductPage from '../pages/TableProductPage/TableProductPage';

/** @type {React.Context} */
const ProductContext = createContext(null);

const PRODUCT_PAGE_COMPONENTS = {
  'ticket': TicketProductPage,
  'table': TableProductPage,
};

// Unique drawer ID for product pages
const PRODUCT_DRAWER_ID = 'product-page-drawer';

/**
 * Custom hook for accessing product context
 * @returns {Object} Product context value
 */
export const useProduct = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider');
  }
  return context;
};

/**
 * Provider component for product-related state and actions
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 */
export const ProductProvider = ({ children }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { openDrawer } = useDrawer();

  const handleProductClick = useCallback((product) => {
    console.log('Product clicked:', product);
    setSelectedProduct(product);

    const ProductPageComponent = PRODUCT_PAGE_COMPONENTS[product.category];
    
    if (!ProductPageComponent) {
      console.error(`No page component found for product type: ${product.category}`);
      return;
    }

    openDrawer(
      PRODUCT_DRAWER_ID,
      <BasketProvider>
        <ProductPageComponent product={product} />
      </BasketProvider>,
      product.name,
      'bottom',
      false // This is not a nested drawer
    );
  }, [openDrawer]);

  const value = {
    // State
    selectedProduct,
    
    // Actions
    handleProductClick,
    
    // Constants
    PRODUCT_DRAWER_ID
  };

  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;
