import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { timings, easings } from '../../tokens/animations';
import LiquidBackground from '../LiquidBackground/LiquidBackground';

const Background = styled.div`
  position: absolute;
  background: var(--accent-colour);
  opacity: 0.08;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  pointer-events: none;
  transition: transform ${timings.interaction} ${easings.smooth};
  
  &::before {
    content: '';
    position: absolute;
    inset: -2px;
    border-radius: inherit;
    background: inherit;
    filter: blur(4px);
    opacity: 0.5;
  }
`;

const MorphingBackground = ({ targetRef, isActive, useLiquidEffect = true }) => {
  const backgroundRef = useRef(null);

  // Use new liquid effect if enabled
  if (useLiquidEffect) {
    try {
      return <LiquidBackground targetRef={targetRef} isActive={isActive} />;
    } catch (error) {
      console.error('Liquid effect failed:', error);
      // Fall through to default implementation
    }
  }

  useEffect(() => {
    if (!targetRef?.current || !backgroundRef.current || !isActive) return;

    const updatePosition = () => {
      const target = targetRef.current;
      const background = backgroundRef.current;
      
      if (!target || !background) return;

      const targetRect = target.getBoundingClientRect();
      const parentRect = target.parentElement.getBoundingClientRect();

      // Calculate position relative to parent
      const x = targetRect.left - parentRect.left;
      const y = targetRect.top - parentRect.top;

      background.style.transform = `translate(${x}px, ${y}px)`;
      background.style.width = `${targetRect.width}px`;
      background.style.height = `${targetRect.height}px`;
    };

    updatePosition();

    // Update position on resize
    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(targetRef.current);

    // Update position on scroll
    window.addEventListener('scroll', updatePosition, { passive: true });

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('scroll', updatePosition);
    };
  }, [targetRef, isActive]);

  return <Background ref={backgroundRef} style={{ opacity: isActive ? 0.08 : 0 }} />;
};

export default MorphingBackground;
