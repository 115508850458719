function isSafari() {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  }

function isiPhone() {
    return /iPhone/.test(navigator.userAgent);
  }
  
function isAndroid() {
    return /Android/.test(navigator.userAgent);
  }

  function isiOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
  }

function copyToClipboard(text) {


  // Copy the combined text to the clipboard
  navigator.clipboard.writeText(text).then(() => {
    alert('Copied to clipboard');
  }).catch(err => {
    console.error('Failed to copy text: ', err);
  });
}

  export {
    isSafari,
    isiPhone,
    isAndroid,
    isiOS,
    copyToClipboard
  }