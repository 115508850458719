import { useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';

/**
 * Loading states for ecommerce operations
 * @readonly
 * @enum {string}
 */
export const LOADING_STATE = {
    INITIAL: 'initial',
    LOADING: 'loading',
    PARTIAL: 'partial',
    SUCCESS: 'success',
    ERROR: 'error'
};

/**
 * Custom hook to manage loading states for ecommerce operations
 * @returns {Object} Loading state management methods and current state
 */
export const useEcommerceLoadingState = () => {
    const [state, setState] = useState({
        state: LOADING_STATE.INITIAL,
        progress: 0,
        error: null,
        metadata: {}
    });

    /**
     * Set loading state
     * @param {Object} metadata - Additional metadata about the loading operation
     */
    const setLoading = useCallback((metadata = {}) => {
        setState(prev => ({
            ...prev,
            state: LOADING_STATE.LOADING,
            progress: 25,
            error: null,
            metadata
        }));

        // Add breadcrumb for loading start
        Sentry.addBreadcrumb({
            category: 'ecommerce',
            message: 'Started loading',
            level: 'info',
            data: metadata
        });
    }, []);

    /**
     * Set partial loading state (some data loaded)
     * @param {Object} metadata - Additional metadata about the partial state
     */
    const setPartial = useCallback((metadata = {}) => {
        setState(prev => ({
            ...prev,
            state: LOADING_STATE.PARTIAL,
            progress: 75,
            metadata: { ...prev.metadata, ...metadata }
        }));

        // Add breadcrumb for partial load
        Sentry.addBreadcrumb({
            category: 'ecommerce',
            message: 'Partial load',
            level: 'info',
            data: metadata
        });
    }, []);

    /**
     * Set success state
     * @param {Object} metadata - Additional metadata about the success state
     */
    const setSuccess = useCallback((metadata = {}) => {
        setState(prev => ({
            ...prev,
            state: LOADING_STATE.SUCCESS,
            progress: 100,
            error: null,
            metadata: { ...prev.metadata, ...metadata }
        }));

        // Add breadcrumb for success
        Sentry.addBreadcrumb({
            category: 'ecommerce',
            message: 'Load success',
            level: 'info',
            data: metadata
        });
    }, []);

    /**
     * Set error state
     * @param {Error} error - Error that occurred
     * @param {Object} metadata - Additional metadata about the error
     */
    const setError = useCallback((error, metadata = {}) => {
        setState(prev => ({
            ...prev,
            state: LOADING_STATE.ERROR,
            error,
            metadata: { ...prev.metadata, ...metadata }
        }));

        // Add breadcrumb for error
        Sentry.addBreadcrumb({
            category: 'ecommerce',
            message: 'Load error',
            level: 'error',
            data: {
                error: error.message,
                ...metadata
            }
        });

        // Capture error in Sentry
        Sentry.captureException(error, {
            extra: metadata
        });
    }, []);

    /**
     * Reset loading state to initial
     */
    const reset = useCallback(() => {
        setState({
            state: LOADING_STATE.INITIAL,
            progress: 0,
            error: null,
            metadata: {}
        });

        // Add breadcrumb for reset
        Sentry.addBreadcrumb({
            category: 'ecommerce',
            message: 'Reset loading state',
            level: 'info'
        });
    }, []);

    return {
        ...state,
        setLoading,
        setPartial,
        setSuccess,
        setError,
        reset
    };
};
