
import React from 'react';
import styled from 'styled-components';
import { Title } from '../ui/typography';
import { theme } from '../../js/Helper';

const HeroImageContainer = styled.div`
  position: relative;
  top: 0;
  width: 100dvw;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-default);
  justify-self: center;

  @media screen and (min-width: 768px) {
    object-fit: cover;
  }

  @media screen and (min-width: 1024px) {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 80dvh;
  }

`;

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100dvw;
  height: 350px;
  background: linear-gradient(transparent, var(--default-primary-colour));
  z-index: 5;
  margin-bottom: 5px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const Image = styled.img`
  position: relative;
  min-width: 100%;
  width: 100%;
  height: ${props => props.height};
  max-width: 100%;
  object-fit: cover;  // To ensure the image covers the entire container
  
  @media screen and (min-width: 768px) {
    border-radius: var(--border-radius-medium) 0px 0px var(--border-radius-medium);
    object-fit: cover;
    margin-bottom: 0px;
    max-height: 60dvh;
  }
  
  @media screen and (min-width: 1025px) {
    max-height: 80dvh;
  }

  @media screen and (min-width: 1920px) {
    max-height: 65dvh;
  }
`;

export const HeroImage = (props) => {

  const { image, alt, title, style } = props
  const height = style?.height || '60dvh'

  return (
    <HeroImageContainer id={'hero-image-container'}>
      <Image src={image} alt={alt} title={title} height={innerWidth < 768 ? height : '100%'} loading={'lazy'} />
      <GradientOverlay height={height} />
    </HeroImageContainer>
  );
};
