// src/featuresV2/ecommerce/basket/components/BasketErrorBoundary.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfoStrip from '../../../../atomic/atoms/InfoStrip/InfoStrip';
import Button from '../../../../atomic/atoms/Button/Button';
import { motion } from 'framer-motion';
import { FiRefreshCw, FiTrash2 } from 'react-icons/fi';

const ErrorContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const StyledInfoStrip = styled(InfoStrip)`
  max-width: 100%;
  background: var(--error-surface);
  
  &:hover {
    background: var(--error-surface-hover);
  }
`;

const ErrorMessage = styled.p`
  color: var(--text-secondary);
  font-size: 0.875rem;
  text-align: center;
  margin: 0;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 8px;
`;

class BasketErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      error: error
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Basket Error:', error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload();
  };

  handleReset = () => {
    // Clear basket from localStorage and refresh
    localStorage.removeItem('nocturnal_basket');
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return this.props.fallback || (
        <ErrorContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <StyledInfoStrip
            icon="alert"
            text="There was a problem with your basket"
          />
          <ErrorMessage>
            We encountered an unexpected error while processing your basket.
            {this.state.error?.message && (
              <> Error: {this.state.error.message}</>
            )}
          </ErrorMessage>
          <ButtonContainer>
            <Button
              variant="secondary"
              size="small"
              icon={<FiRefreshCw />}
              onClick={this.handleRefresh}
            >
              Try Again
            </Button>
            <Button
              variant="danger"
              size="small"
              icon={<FiTrash2 />}
              onClick={this.handleReset}
            >
              Reset Basket
            </Button>
          </ButtonContainer>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

BasketErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node
};

export default BasketErrorBoundary;