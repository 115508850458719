const calculateDeposit = (product, quantity) => {

    if(!product) return ;

    const depositAmount = product?.depositAmount;
    const addonProducts = product.addons.filter(addon => addon.depositAmount > 0);
    const addonDepositAmount = addonProducts.reduce((acc, addon) => {
        return acc + (addon.depositAmount * addon.quantity);
    }, 0);

    if(addonDepositAmount > 0) {
        return (depositAmount * quantity) + addonDepositAmount
    }

    return depositAmount * quantity;
}

const productAcceptsDeposit = (product) => {
    return product.depositAmount > 0;
};

const allAddonsAcceptDeposit = (product) => {
    // Check if product is valid and has addons
    if (!product || !Array.isArray(product.addons)) {
        return false;
    }

    // Check if there are no addons
    if (product.addons.length === 0) {
        return false; // Or return true if the absence of addons should be considered as all accepting deposits
    }

    // Check if all addons accept deposit
    return product.addons.every(addon => {
        if (typeof addon.depositAmount !== 'number') {
            return false;
        }
        return addon.depositAmount > 0;
    });
};


/**
 * Check if product and all addons accept deposit
 * @param product
 * @returns {false}
 */
const productAndAddonsAcceptDeposit = (product) => {
    const mainProductAcceptsDeposit = productAcceptsDeposit(product);
    const addonsAcceptDeposit = allAddonsAcceptDeposit(product);
    return mainProductAcceptsDeposit && addonsAcceptDeposit;
};

const allProductsAcceptDeposits = (products) => {
    console.log('PRODUCT', products)
    if (!Array.isArray(products)) {
        return false;
    }
    return products.every(product => productAcceptsDeposit(product));
};





export {
    calculateDeposit,
    productAcceptsDeposit,
    allAddonsAcceptDeposit,
    productAndAddonsAcceptDeposit,
    allProductsAcceptDeposits
}