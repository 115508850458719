/**
 * @fileoverview Hook for handling product quantity changes with optimistic updates
 * 
 * This hook provides functionality to:
 * 1. Update item quantities in the basket
 * 2. Handle both main products and their addons
 * 3. Provide immediate UI feedback with optimistic updates
 * 4. Sync changes with the server
 * 5. Handle error cases with automatic rollback
 */

import {useCallback} from 'react';
import {useEcommerce} from '../context/EcommerceContext';
import {updateItemQuantity} from '../utils';
import {updateOrderItem} from "../../basket/js/api.js";
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import { toast } from 'react-toastify';

/**
 * Find parent product of an addon in basket
 * @param {Array} orderItems - Array of order items
 * @param {Object} addon - Addon to find parent for
 * @returns {Object|null} Parent product or null if not found
 */
const findParentProduct = (orderItems, addon) => {
    return orderItems.find(item => 
        item.addons?.some(a => a.id === addon.id)
    );
};

/**
 * Validate addon quantity against parent product
 * @param {number} quantity - New quantity to validate
 * @param {Object} addon - Addon being updated
 * @param {Object} parentProduct - Parent product of the addon
 * @returns {number} Valid quantity
 */
const validateAddonQuantity = (quantity, addon, parentProduct) => {
    if (quantity > parentProduct.quantity) {
        toast.warning('Addon quantity cannot exceed parent product quantity');
        return parentProduct.quantity;
    }
    return quantity;
};

/**
 * Custom hook to handle quantity changes with optimistic updates
 * @returns {Function} Callback function to handle quantity changes
 * 
 * @example
 * const handleQuantityChange = useHandleQuantityChange();
 * // In component:
 * <input onChange={(e) => handleQuantityChange(e, item)} value={quantity} />
 */
const useHandleQuantityChange = () => {
    const { basket, setOrderItems, setBasket } = useEcommerce();
    const { showErrorToast } = useHandleError();

    return useCallback(async (e, activeItem) => {
        let newQuantity = Number(e.target.value);
        const previousItems = basket.orderItems;

        try {
            // Check if this is an addon
            const parentProduct = findParentProduct(basket.orderItems, activeItem);
            
            // If it's an addon, validate quantity against parent product
            if (parentProduct) {
                newQuantity = validateAddonQuantity(newQuantity, activeItem, parentProduct);
            }

            const updatedItem = updateItemQuantity(activeItem, newQuantity);

            // Optimistically update UI
            const newOrderItems = basket.orderItems.map(item => {
                if (item.id === activeItem.id) {
                    // Update main item
                    const updatedMainItem = { ...updatedItem };
                    
                    // If main product quantity decreased, adjust addon quantities
                    if (item.addons?.length > 0 && newQuantity < item.quantity) {
                        updatedMainItem.addons = item.addons.map(addon => ({
                            ...addon,
                            quantity: Math.min(addon.quantity, newQuantity),
                            sub_total: addon.unit_price * Math.min(addon.quantity, newQuantity)
                        }));
                    }
                    
                    return updatedMainItem;
                } else if (item.addons?.length > 0) {
                    // Update addon within the main item's addons array
                    const updatedAddons = item.addons.map(addon =>
                        addon.id === activeItem.id 
                            ? {
                                ...updatedItem,
                                sub_total: updatedItem.unit_price * newQuantity
                            } 
                            : addon
                    );
                    return { ...item, addons: updatedAddons };
                }
                return item;
            });

            // Update UI immediately for better UX
            setOrderItems(newOrderItems);

            // Dispatch event for immediate UI feedback
            window.dispatchEvent(new CustomEvent('quantityChange', {
                detail: {
                    productId: activeItem.productId,
                    quantity: newQuantity,
                    isAddon: !!parentProduct
                }
            }));

            // Sync with server
            const res = await updateOrderItem(basket.id, activeItem.productId, updatedItem);
            
            if (res?.data?.basket) {
                setBasket(res.data.basket);
                // Notify successful update
                window.dispatchEvent(new CustomEvent('quantityChanged', {
                    detail: {
                        productId: activeItem.productId,
                        quantity: newQuantity,
                        isAddon: !!parentProduct
                    }
                }));
            }

        } catch (err) {
            // Revert to previous state on error
            setOrderItems(previousItems);
            showErrorToast(err.response?.data?.message || 'Failed to update quantity. Please try again.');
            console.error('Error updating quantity:', err);
        }

    }, [basket, setOrderItems, setBasket, showErrorToast]);
};

export default useHandleQuantityChange;
