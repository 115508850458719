/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import StripePaymentButtons from '../../services/stripe/components/StripePaymentButtons';
import { useHandleError } from "../../../error-handling/js/hooks/index.js";
import { AuthContext } from '../../../authentication/AuthContext.jsx';
import { useEcommerce } from '../../js/context/EcommerceContext.jsx';
import { useHandleStripePayment } from '../../services/stripe/hooks/useHandleStripePayment.js';

export const CheckoutForm = ({
    total,
    onSuccess
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { isAuthenticated } = useContext(AuthContext);
    const { basket, setClientSecret } = useEcommerce();
    const navigate = useNavigate();

    const intent = typeof basket?.payment_intent === 'string' ? basket.paymentIntent : basket?.paymentIntent?.gateway_transaction_id;

    console.log('Payment intent', intent)

    const {
        confirmPayment,
        formValidation,
        getClientSecret,
        isPaymentAllowed,
        hasCompletedQuestions,
    } = useHandleStripePayment();

    const stripe = useStripe();
    const elements = useElements();
    const { showErrorToast } = useHandleError();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const validationState = {
                isPaymentAllowed: basket?.isPaymentAllowed ?? false,
                isAuthenticated,
                hasQuestions: basket?.hasQuestions ?? false,
                questionsComplete: basket?.questionsComplete ?? false
            };

            console.log('Payment validation starting...', validationState);

            // These validations will throw errors that are caught by the boundary
            await isPaymentAllowed(validationState.isPaymentAllowed, validationState.isAuthenticated);
            console.log('Payment allowed check passed');
            
            await hasCompletedQuestions(validationState.hasQuestions, validationState.questionsComplete);
            console.log('Questions check passed');
            
            await formValidation(elements);
            console.log('Form validation passed');
            
            const { clientSecret, order, user, error } = await getClientSecret(intent);
            console.log('Got client secret', { hasError: !!error });
            
            if(error) {
                throw error;
            }
            
            await confirmPayment(clientSecret, order, user, stripe, elements);
            console.log('Payment confirmed successfully');
            
            // If we get here, payment was successful
            onSuccess?.();
            navigate(`/confirmation/${order?.id}`);
        } catch (error) {
            console.error('Payment processing failed:', {
                error,
                message: error.message,
                code: error.code,
                type: error.type
            });
            showErrorToast(error.message || 'Payment processing error')
        } finally {
            setIsLoading(false);
        }
    };

    const [paymentRequest, setPaymentRequest] = useState();

    useEffect(() => {


        if (!stripe || !elements || !basket) {
            return;
        }
       

        console.log('Initializing payment request with basket:', {
            total,
            isPaymentAllowed: basket.isPaymentAllowed,
            hasQuestions: basket?.hasQuestions,
            questionsComplete: basket?.questionsComplete
        });

        const pr = stripe.paymentRequest({
            country: 'GB',
            currency: 'gbp',
            total: {
                label: 'Total',
                amount: total
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {

            console.log('Payment request availability:', { canMakePayment: !!result });

            if (result) {
                setPaymentRequest(pr)        
            }

            pr.on('paymentmethod', async (ev) => {

                console.log('Payment method received');

                const { clientSecret, order, user, error } = await getClientSecret(intent)
                setClientSecret(clientSecret)

                if(error) {
                    console.error('Failed to get client secret:', error);
                    ev.complete('fail')
                    showErrorToast(error)
                    return;
                }

                // Confirm the PaymentIntent without handling potential next actions (yet).
                const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
                    clientSecret,
                    {payment_method: ev.paymentMethod.id},
                )

                // TODO - we can update the order status from here
      
                if (confirmError) {
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    showErrorToast(confirmError)
                    ev.complete('fail');
                } else {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
                    // handle the flow. If using an API version older than "2019-02-11"
                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                    if (paymentIntent.status === "requires_action") {
                        // Let Stripe.js handle the rest of the payment flow.
                        const {error} = await stripe.confirmCardPayment(clientSecret);
                        if (error) {
                            showErrorToast(error)
                            // The payment failed -- ask your customer for a new payment method.
                        } else {
                            // The payment has succeeded -- show a success message to your customer.



                            toast.success('Payment Confirmed')
                            navigate(`/confirmation/${order?.id}`)

                        }
                    } else {



                        toast.success('Payment Confirmed')
                        navigate(`/confirmation/${order?.id}`)
                    }
                }
            });


        }).catch((error) => {
            showErrorToast('Can not payment payment: ' + error.message)
        });


    }, [stripe, basket]);

    return (
        <form onSubmit={handleSubmit} id='payment-form'>
            <StripePaymentButtons
                paymentRequest={paymentRequest}
                stripe={stripe}
                elements={elements}
                amount={total}
                isLoading={isLoading}
            />
        </form>
    );
};

export default CheckoutForm;
