import React from 'react';
import {ComponentContainer} from "../../styles/sections.styles.js";
import {Paragraph, Title} from "../../../typography/index.js";
import {H2} from "../../../typography/typography.styles.js";

const TextBlock2 = ({ title, text, align = 'left'}) => {
    return (
        <ComponentContainer>
            <H2 style={{textAlign: align}}>{title}</H2>
            <Paragraph style={{textAlign: align}}>{text}</Paragraph>
        </ComponentContainer>
    );
};

export default TextBlock2;