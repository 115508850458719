import React, { useRef, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import Box from '../../atoms/Box/Box';
import NavItem from '../../atoms/NavItem/NavItem';
import MorphingBackground from '../../atoms/MorphingBackground/MorphingBackground';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { slideUpKeyframes, timings, easings } from '../../tokens/animations';
import { useNavigation } from '../../../contexts/NavigationContext';
import { createMenuConfig } from '../../../config/menuConfig';
import { borderRadius } from '../../tokens/theme';
import { extractColorCodeFromCssVariable } from '../../../features/themes/js/utils';

const getPositionStyles = (position, isVertical) => {
  if (isVertical) {
    return position === 'right' ? {
      right: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
      bottom: 'auto',
      left: 'auto'
    } : {
      left: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
      bottom: 'auto',
      right: 'auto'
    };
  }
  
  return {
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    top: 'auto',
    right: 'auto'
  };
};

const getContainerAnimation = (isVertical, isVisible, position) => {
  if (!isVertical) {
    return `translateX(-50%) translateY(${isVisible ? '0' : '100px'})`;
  }
  if (position === 'right') {
    return `translateY(-50%) translateX(${isVisible ? '0' : '100px'})`;
  }
  return `translateY(-50%) translateX(${isVisible ? '0' : '-100px'})`;
};

const NavContainer = styled(Box)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 80%;
  padding: ${props => props.$isVertical ? '40px 8px' : '8px 40px'};
  background: var(--accent-colour);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(255, 255, 255, 0.05);
  animation: ${slideUpKeyframes} ${timings.entry} ${easings.smooth} backwards;
  flex-direction: ${props => props.$isVertical ? 'column' : 'row'};
  z-index: 100;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  ${props => {
    const posStyles = getPositionStyles(props.$position, props.$isVertical);
    return Object.entries(posStyles)
      .map(([key, value]) => `${key}: ${value};`)
      .join('\n');
  }}
  
  svg {
    color: rgba(255, 255, 255, 1);
    transition: color ${timings.normal} ${easings.inOutCubic};
  }

  .active svg {
    color: ${() => extractColorCodeFromCssVariable('--accent-colour')};
  }
  
  @media (min-width: 768px) {
    width: unset;
    border-radius: ${props => {
      const radius = props.reverse 
        ? borderRadius.asymmetric.reversed[props.asymRadius] 
        : borderRadius.asymmetric[props.asymRadius];
      return radius;
    }};
    transform: ${props => {
      if (!props.$isVisible) {
        return props.$position === 'right' 
          ? 'translateY(-50%) translateX(150%)' 
          : 'translateY(-50%) translateX(-150%)';
      }
      return props.$position === 'right'
        ? 'translateY(-50%) translateX(0)'
        : 'translateY(-50%) translateX(0)';
    }};
  }
  
  @media (max-width: 767px) {
    border-radius: ${props => {
      const radius = props.reverse 
        ? borderRadius.asymmetric.reversed[props.asymRadius] 
        : borderRadius.asymmetric[props.asymRadius];
      return radius;
    }};
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) translateY(${props => props.$isVisible ? '0' : '150%'});
    flex-direction: row;
    padding: 8px 40px;
    ${props => props.$isVertical && `
      top: auto;
      right: auto;
    `}
  }
`;

const NavItemsContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: inherit;
`;

const scrollToElement = (elementId, offset = 0) => {
  const element = document.getElementById(elementId);
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    return true;
  }
  return false;
};

const FloatingNav = ({ 
  position = 'right',
  vertical = true,
  configId = 'main',
  useLiquidEffect = true,
  asymRadius = 'xxl',
  reverse = false
}) => {
  const { isVisible } = useScrollDirection(10);
  const itemRefs = useRef({});
  const [openSubMenu, setOpenSubMenu] = useState(null);
  
  const { 
    activeItem,
    handleNavigation,
    getBadge,
    isVisible: isItemVisible
  } = useNavigation();

  // Get menu configuration
  const menuConfig = useMemo(() => 
    createMenuConfig(getBadge)[configId], 
    [configId, getBadge]
  );

  // Filter visible items
  const visibleItems = useMemo(() => 
    menuConfig.items.filter(item => isItemVisible(item.key))
    .map(item => ({
      ...item,
      subItems: item.subItems?.filter(subItem => 
        isItemVisible(subItem.key || `${item.key}-${subItem.label}`))
    }))
    .filter(item => item.subItems?.length > 0 || !item.subItems),
    [menuConfig.items, isItemVisible]
  );

  const getItemRef = useCallback((key) => {
    if (!itemRefs.current[key]) {
      itemRefs.current[key] = React.createRef();
    }
    return itemRefs.current[key];
  }, []);

  const handleMouseEnter = useCallback((key) => {
    const item = visibleItems.find(i => i.key === key);
    if (item?.subItems?.length) {
      setOpenSubMenu(key);
    }
  }, [visibleItems]);

  const handleMouseLeave = useCallback(() => {
    setOpenSubMenu(null);
  }, []);

  const handleItemClick = useCallback((item) => {
    // First try to scroll if scrollTo is defined
    if (item.scrollTo) {
      const scrolled = scrollToElement(item.scrollTo, item.scrollOffset || 0);
      if (scrolled) {
        handleNavigation(item);
        return;
      }
    }
    
    // If no scrollTo or element not found, just navigate
    handleNavigation(item);
  }, [handleNavigation]);

  const handleSubItemClick = useCallback((item, parentKey) => {
    handleItemClick(item);
    setOpenSubMenu(null);
  }, [handleItemClick]);

  return (
    <NavContainer 
      asymRadius={asymRadius}
      reverse={reverse}
      $isVisible={isVisible}
      $isVertical={vertical}
      $position={position}
    >
      <NavItemsContainer onMouseLeave={handleMouseLeave}>
        <MorphingBackground 
          targetRef={activeItem ? itemRefs.current[activeItem] : null}
          isActive={!!activeItem}
          useLiquidEffect={useLiquidEffect}
        />
        {visibleItems.map((item) => {
          const isActive = activeItem === item.key;
          const IconComponent = isActive ? item.activeIcon : item.inactiveIcon;
          return (
            <NavItem
              ref={getItemRef(item.key)}
              key={item.key}
              icon={IconComponent}
              active={isActive}
              onClick={() => handleItemClick(item)}
              onMouseEnter={() => handleMouseEnter(item.key)}
              badge={item.badge?.()}
              subItems={item.subItems}
              isSubMenuOpen={openSubMenu === item.key}
              onSubItemClick={(subItem) => handleSubItemClick(subItem, item.key)}
            />
          );
        })}
      </NavItemsContainer>
    </NavContainer>
  );
};

export default FloatingNav;
