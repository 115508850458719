import styled from "styled-components";

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    margin-bottom: 20px;
    
    * {
        width: 100%;
        max-width: 400px;
    }
`;

export { ComponentContainer };
