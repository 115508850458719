import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacing,
  textStyles,
} from '../../tokens/typography';

/**
 * Base heading styles
 */
const baseStyles = css`
  margin: 0;
  font-family: var(--font-tommy);
  color: ${props => props.$color || 'var(--text-primary)'};
  text-align: ${props => props.$align || 'left'};
  text-transform: ${props => props.$transform || 'none'};
  
  /* Gradient text support */
  ${props => props.$gradient && css`
    background: ${props.$gradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  `}
  
  /* Optional highlight */
  ${props => props.$highlight && css`
    display: inline;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    background: ${props.$highlight};
    padding: 0 0.5em;
  `}
`;

/**
 * StyledHeading component with style variations
 */
const StyledHeading = styled.h1`
  ${baseStyles}
  
  /* Apply predefined style based on level */
  font-size: ${props => textStyles[`h${props.$level || 1}`].fontSize};
  font-weight: ${props => props.$weight || textStyles[`h${props.$level || 1}`].fontWeight};
  line-height: ${props => props.$lineHeight || textStyles[`h${props.$level || 1}`].lineHeight};
  letter-spacing: ${props => props.$spacing || textStyles[`h${props.$level || 1}`].letterSpacing};
  
  /* Responsive font size adjustments */
  @media (max-width: 768px) {
    font-size: ${props => {
      const currentSize = parseFloat(textStyles[`h${props.$level || 1}`].fontSize);
      return `${currentSize * 0.85}rem`;
    }};
  }

  /* Animation support */
  ${props => props.$animate && css`
    opacity: 0;
    animation: fadeSlideIn 0.5s ease-out forwards;
    animation-delay: ${props.$delay || '0s'};

    @keyframes fadeSlideIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `}
`;

/**
 * Heading component for displaying titles and section headers
 * 
 * @param {Object} props - Component props
 * @param {number} [props.level=1] - Heading level (1-6)
 * @param {string} [props.weight] - Font weight override
 * @param {string} [props.color] - Text color (CSS variable or direct color value)
 * @param {string} [props.align] - Text alignment
 * @param {string} [props.transform] - Text transform
 * @param {string} [props.lineHeight] - Line height override
 * @param {string} [props.spacing] - Letter spacing override
 * @param {string} [props.gradient] - CSS gradient for text
 * @param {string} [props.highlight] - Background highlight color
 * @param {boolean} [props.animate] - Enable animation
 * @param {string} [props.delay] - Animation delay (if animated)
 */
const Heading = ({
  level = 1,
  weight,
  color,
  align,
  transform,
  lineHeight,
  spacing,
  gradient,
  highlight,
  animate,
  delay,
  className,
  children,
  style,
  ...props
}) => {
  const Tag = `h${level}`;
  
  return (
    <StyledHeading
      style={style}
      as={Tag}
      $level={level}
      $weight={weight}
      $color={color}
      $align={align}
      $transform={transform}
      $lineHeight={lineHeight}
      $spacing={spacing}
      $gradient={gradient}
      $highlight={highlight}
      $animate={animate}
      $delay={delay}
      className={className}
      {...props}
    >
      {children}
    </StyledHeading>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  weight: PropTypes.oneOf(Object.keys(fontWeights)),
  color: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  transform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'none']),
  lineHeight: PropTypes.oneOf(Object.keys(lineHeights)),
  spacing: PropTypes.oneOf(Object.keys(letterSpacing)),
  gradient: PropTypes.string,
  highlight: PropTypes.string,
  animate: PropTypes.bool,
  delay: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Heading;
