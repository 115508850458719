const pressAnimationVariant = {
    scale: 0.98,
    transition: { duration: 0.1 }
};

const slideUpFadeInVariant = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.3 }
};



export {
    slideUpFadeInVariant,
    pressAnimationVariant
}