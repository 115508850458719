import React, { useRef } from 'react';
import styled from 'styled-components';
import { IoChevronDownOutline } from 'react-icons/io5';

const ChevronContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  // min-height: 50vh;
  justify-content: center;
  scroll-snap-align: start;
`;

const Text = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: ${props => props.theme.colors?.text || '#ffffff'};
  margin: 0;
`;

const ChevronButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 1rem;
  color: ${props => props.theme.colors?.primary || '#ffffff'};
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(5px);
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

const ScrollContainer = styled.div`
  // height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
`;

const ScrollingTextChevron = ({ sections }) => {
  const sectionRefs = useRef([]);

  const scrollToNextSection = (currentIndex) => {
    if (currentIndex < sections.length - 1) {
      sectionRefs.current[currentIndex + 1]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ScrollContainer>
      {sections.map((section, index) => (
        <TextSection
          key={index}
          ref={(el) => (sectionRefs.current[index] = el)}
        >
          <Text>{section}</Text>
          {index < sections.length - 1 && (
            <ChevronButton onClick={() => scrollToNextSection(index)}>
              <IoChevronDownOutline />
            </ChevronButton>
          )}
        </TextSection>
      ))}
    </ScrollContainer>
  );
};

export default ScrollingTextChevron;
