const filterPartnersByRoles = (partners, roles) => {
    return partners?.filter(partner => roles.includes(partner.role));
  }

  const filterProductsByCategory = (products, category) => {
    return products?.filter(product => product.category === category);
  }

  export {
    filterPartnersByRoles,
    filterProductsByCategory
  }