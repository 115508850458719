import React from 'react';
import styled from "styled-components";
import {BoldTitle, Paragraph} from "../../../typography/index.js";
import {ComponentContainer} from "../../styles/sections.styles.js";


const TextBlock1 = ({ subtitle, title, paragraph}) => {
    return (
        <ComponentContainer>
            <Paragraph>{subtitle}</Paragraph>
            <BoldTitle title={title}  />
            <Paragraph style={{marginTop: '5px'}}>{paragraph}</Paragraph>
        </ComponentContainer>
    );
};

export default TextBlock1;