import React from 'react';
import {LOCAL_STORAGE_AFFILIATES} from "../constants.js";
import {object} from "prop-types";
import {generateUUID} from "../../../../js/utils/utils.js";

export const useAffiliate = () => {

    const getAffiliatesArray = () => {
        const storedAffiliateArray = localStorage.getItem(LOCAL_STORAGE_AFFILIATES);
        try {
            return storedAffiliateArray ? JSON.parse(storedAffiliateArray) : [];
        } catch (error) {
            localStorage.removeItem(LOCAL_STORAGE_AFFILIATES);
            return [];
        }
    }

    const getAffiliateBySlug = (slug) => {
        const affiliates = getAffiliatesArray();
        return affiliates.filter((affiliate) => affiliate.slug === slug);
    }


    const getAffiliateById = (id) => {
        const affiliates = getAffiliatesArray();
        return affiliates.find((affiliate) => affiliate.id === id);
    }

    const checkIfAffiliateExists = (code, slug) => {
        return getAffiliateById() && getAffiliateBySlug(slug);
    }

    const checkIfReferred = (slug) => {
        return getAffiliateBySlug(slug)
    }

    const storeAffiliate = (newAffiliate) => {
        const affiliates = getAffiliatesArray();
        newAffiliate.timestamp = Date.now();
        newAffiliate.sessionId = generateUUID();

        affiliates.push(newAffiliate);
        localStorage.setItem(LOCAL_STORAGE_AFFILIATES, JSON.stringify(affiliates));
    }


    return {
        getAffiliatesArray,
        getAffiliateBySlug,
        checkIfReferred,
        getAffiliateById,
        checkIfAffiliateExists,
        storeAffiliate,
    }
};
