import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { borderRadius } from '../../../tokens/theme';
import { premiumInteractive } from '../../../tokens/animations';

const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledTextarea = styled(motion.textarea)`
  width: 100%;
  min-height: 120px;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: var(--font-weight-generic);
  color: var(--text-colour);
  background: var(--primary-colour);
  border: 1px solid rgba(191, 181, 147, 0.1);
  border-radius: ${props => borderRadius.asymmetric[props.$asymRadius]};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  resize: vertical;
  font-family: inherit;
  line-height: 1.5;
  ${premiumInteractive}

  &::placeholder {
    color: var(--text-colour);
    opacity: 0.3;
    transition: opacity 0.3s ease;
  }

  &:hover:not(:disabled) {
    border-color: rgba(191, 181, 147, 0.3);
    transform: translateY(-1px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

    &::placeholder {
      opacity: 0.5;
    }
  }

  &:focus:not(:disabled) {
    border-color: var(--accent-colour);
    box-shadow: 0 0 0 1px var(--accent-colour-faded),
                0 5px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);

    &::placeholder {
      opacity: 0.7;
    }
  }

  ${props => props.$error && css`
    border-color: var(--error-colour);
    &:focus {
      border-color: var(--error-colour);
      box-shadow: 0 0 0 1px var(--error-colour),
                  0 5px 15px rgba(181, 89, 89, 0.2);
    }
  `}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: var(--primary-colour);
    border-color: rgba(191, 181, 147, 0.05);
    transform: none;
    box-shadow: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(191, 181, 147, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(191, 181, 147, 0.2);
    border-radius: 4px;
    
    &:hover {
      background: rgba(191, 181, 147, 0.3);
    }
  }
`;

const ErrorMessage = styled(motion.div)`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.9;
`;

const Textarea = forwardRef(({
  error = false,
  errorMessage,
  asymRadius = 'sm',
  className,
  ...props
}, ref) => {
  return (
    <TextareaWrapper className={className}>
      <StyledTextarea
        ref={ref}
        $error={error}
        $asymRadius={asymRadius}
        {...props}
      />
      <AnimatePresence>
        {error && errorMessage && (
          <ErrorMessage
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </AnimatePresence>
    </TextareaWrapper>
  );
});

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  asymRadius: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  className: PropTypes.string,
};

export default Textarea;
