import React from 'react';
import styled from "styled-components";
import {FullPageContainer} from "../../../../../layout/styles/layoutStyles.js";
import {ContactDetails} from "./components/index.js";

const ContactSectionContainer = styled(FullPageContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--accent-colour);

    /* Gradient overlay */
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, var(--primary-colour), transparent);
        z-index: 1;
    }

    /* Ensuring text is on top of the gradient */
    > * {
        position: relative;
        z-index: 2;
    }
`

const ContactSection = ({ prefix, name, phone, email}) => {
    return (
        <ContactSectionContainer>
            <ContactDetails
                prefix={prefix}
                name={name}
                phone={phone}
                email={email}

            />
        </ContactSectionContainer>
    );
};

export default ContactSection;