import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { premiumAnimation } from '../../tokens/animations';
import Input from '../../atoms/form-elements/Input';
import DateInput from '../../atoms/form-elements/DateInput';
import Select from '../../atoms/form-elements/Select';
import Checkbox from '../../atoms/form-elements/Checkbox';
import Radio from '../../atoms/form-elements/Radio';
import Textarea from '../../atoms/form-elements/Textarea';
import Switch from '../../atoms/form-elements/Switch';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  ${premiumAnimation}
`;

const ErrorMessage = styled.span`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 4px;
`;

const Label = styled.label`
  color: var(--text-colour);
  font-size: 14px;
  margin-bottom: 8px;
  ${premiumAnimation}
  
  ${props => props.$required && `
    &::after {
      content: '*';
      color: var(--error-colour);
      margin-left: 4px;
    }
  `}
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    width: 100%;
  }

  & > div {
    width: 100%;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 16px;
`;

// Component mapping
const componentMap = {
  input: Input,
  date: DateInput,
  select: Select,
  checkbox: Checkbox,
  radio: Radio,
  textarea: Textarea,
  switch: Switch,
  radioGroup: ({ options, ...props }) => (
    <RadioGroup>
      {options.map(option => (
        <Radio
          key={option.value}
          {...props}
          {...option}
        />
      ))}
    </RadioGroup>
  )
};

// Field renderer component
const FieldRenderer = ({ field, hideLabels }) => {
  // Handle custom component type
  if (field.type === 'custom' && field.component) {
    const CustomComponent = field.component;
    return (
      <Field 
        label={field.label} 
        required={field.required}
        hideLabel={hideLabels}
      >
        <CustomComponent {...field.props} hideLabel={hideLabels} />
      </Field>
    );
  }

  // Handle standard component types
  const Component = componentMap[field.type];
  
  if (!Component) {
    console.warn(`Unknown field type: ${field.type}`);
    return null;
  }

  return (
    <Field 
      label={field.label} 
      required={field.required}
      hideLabel={hideLabels}
    >
      <Component {...field.props} hideLabel={hideLabels} />
    </Field>
  );
};

FieldRenderer.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    props: PropTypes.object,
    order: PropTypes.number,
    component: PropTypes.elementType
  }).isRequired,
  hideLabels: PropTypes.bool
};

const Form = ({
  fields = [],
  onSubmit,
  className,
  children,
  defaultValues = {},
  validationSchema,
  style,
  hideLabels = false
}) => {
  const { 
    handleSubmit, 
    formState: { errors }, 
    setValue,
    watch,
    register 
  } = useForm({
    defaultValues,
    ...(validationSchema && { resolver: validationSchema })
  });

  // Watch all fields for controlled components
  const watchedValues = watch();

  const getFieldProps = (field) => {
    const baseProps = {
      ...field.props,
      value: watchedValues[field.props.name] || '',
      error: !!errors[field.props.name],
      errorMessage: errors[field.props.name]?.message,
    };

    // Register the field with react-hook-form
    const registration = register(field.props.name);

    switch (field.type) {
      case 'checkbox':
      case 'switch':
        return {
          ...baseProps,
          checked: !!watchedValues[field.props.name],
          onChange: (newValue) => {
            // Handle both event objects and direct boolean values
            const value = typeof newValue === 'boolean' ? newValue : newValue?.target?.checked;
            setValue(field.props.name, value, { 
              shouldValidate: true,
              shouldDirty: true 
            });
          },
          ref: registration.ref
        };
      
      case 'select':
        return {
          ...baseProps,
          onChange: (value) => setValue(field.props.name, value, { 
            shouldValidate: true,
            shouldDirty: true 
          }),
          ref: registration.ref
        };
      
      case 'radioGroup':
        return {
          ...baseProps,
          options: field.props.options.map(option => ({
            ...option,
            checked: watchedValues[field.props.name] === option.value,
            onChange: () => setValue(field.props.name, option.value, { 
              shouldValidate: true,
              shouldDirty: true 
            })
          }))
        };
      
      default:
        return {
          ...baseProps,
          onChange: registration.onChange,
          onBlur: registration.onBlur,
          ref: registration.ref
        };
    }
  };

  // Sort fields by order if provided
  const sortedFields = [...fields].sort((a, b) => 
    (a.order || 0) - (b.order || 0)
  );

  return (
    <FormContainer 
      style={style}
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      id='form-container'
    >
      {sortedFields.map(field => (
        <div style={{width: '100%'}} key={field.id} id='field-container'>
          <FieldRenderer 
            field={{
              ...field,
              props: getFieldProps(field)
            }}
            hideLabels={hideLabels}
          />
          {errors[field.props.name] && (
            <ErrorMessage>
              {errors[field.props.name].message}
            </ErrorMessage>
          )}
        </div>
      ))}
      {children}
    </FormContainer>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    order: PropTypes.number,
    props: PropTypes.object
  })),
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  defaultValues: PropTypes.object,
  validationSchema: PropTypes.func,
  hideLabels: PropTypes.bool
};

export const Field = ({ label, required, children, hideLabel = false }) => (
  <FieldWrapper id='field-wrapper'>
    {label && !hideLabel && (
      <Label $required={required}>
        {label}
      </Label>
    )}
    {children}
  </FieldWrapper>
);

Field.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hideLabel: PropTypes.bool
};

export default Form;
