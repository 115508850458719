import React from 'react';
import styled from 'styled-components';
import { IntroSection as IntroSectionComponent } from './sections/IntroSection.jsx';

const IntroSection = ({ campaign, backgroundImage }) => {

  const hostedBy = {
    'bmf-campaign': 'Lil Meech',
    'clermont-twins-campaign': 'Clermont Twins',
  }

  console.log('campaign', campaign);

  return (
    <IntroSectionComponent
      title={campaign.name}
      subtitle={campaign.subtitle || 'Be Part Of The'}
      guest={hostedBy[campaign.slug]}
      guestRole={'Hosted By'}
      logo={'/loud-logo.png'}
      backgroundImage={backgroundImage?.cdnUrl}
    />
  );
};

export default IntroSection;
