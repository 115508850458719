import React from 'react';
import styled from 'styled-components';
import { StandardSection } from '../../../layout/sections/StandardSection';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';

const OverviewSection = ({ campaign, contentData }) => {
  return (
    <StandardSection id="overview">
      <ImpactTitle main={campaign.name} />
      <Paragraph>{contentData.overview}</Paragraph>
    </StandardSection>
  );
};

export default OverviewSection;
