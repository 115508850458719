import React from 'react';
import styled from 'styled-components'
import {useCard} from "../features/cards/js/index.js";
import {LoyaltyCard} from "../features/cards/components/LoyaltyCard/LoyaltyCard.jsx";
import {getSessionStorage} from "../js/Helper.js";
import {AddToAppleWallet} from "../components/ui/AddToAppleWallet.jsx";
import {AddToWalletAction} from "../features/ecommerce/orders/components/actions/index.js";
import {ImpactTitle, Paragraph} from "../components/ui/typography/index.js";
import {updateTheme} from "../features/themes/js/utils.js";


const CardContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px
`

const SignupConfirmation = () => {
    // const { member } = useCard();

    const member = getSessionStorage('member')
    if(member?.card_tier?.theme) updateTheme(member.card_tier.theme)
    if(!member) return;

    return (
        <CardContainer>
            <ImpactTitle  header={'Confirmation'} />
            <Paragraph style={{marginBottom: '20px'}}>{member.card_tier.card.success_message}</Paragraph>
            <LoyaltyCard tier={member.card_tier} card={member.card_tier.card} />
            <AddToAppleWallet type={'cardholder'} id={member.member_id}/>
        </CardContainer>

    );
};

export default SignupConfirmation;