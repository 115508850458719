import React from 'react';
import {
    OfferCard, OfferDescription, OfferName,
    OffersScrollContainer,
    OffersSection, OfferValue
} from "../../ecommerce/products/components/offers/js/productOffers.styles.js";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import {useRewards} from "../js/hooks/RewardsContext.jsx";

const StandardOffersSection = () => {

    const { selectedReward, setSelectedReward, rewards } = useRewards();

    return (
        <div>
            <OffersSection id='offers'>
                <DividerWithText
                    text='Available Offers'
                    style={{
                        marginBottom: '30px',
                        textTransform: 'uppercase',
                        position: 'sticky',
                        top: 0,
                        background: 'var(--primary-colour)',
                        zIndex: 1
                    }}

                />
                <OffersScrollContainer>
                    {rewards.sort((a, b) => b.value - a.value).map((reward) => (
                        <OfferCard
                            key={reward.id}
                            onClick={() => setSelectedReward(reward)}
                            isSelected={selectedReward && selectedReward.id === reward.id}
                        >
                            <OfferName isSelected={selectedReward && selectedReward.id === reward.id}>
                                {reward.name}
                            </OfferName>
                            <OfferDescription isSelected={selectedReward && selectedReward.id === reward.id}>
                                {reward.description}
                            </OfferDescription>
                            <OfferValue isSelected={selectedReward && selectedReward.id === reward.id}>
                                {reward.value}% OFF
                            </OfferValue>
                            <OfferDescription>
                                {reward?.redemptionsLeft} Available
                            </OfferDescription>
                        </OfferCard>
                    ))}
                </OffersScrollContainer>
            </OffersSection>
        </div>
    );
};

export default StandardOffersSection;