import styled from "styled-components";

const ProposalSection = styled.section`
    height: fit-content;
    padding: 20px;
`




export { ProposalSection}