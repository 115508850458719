import React, {createContext, useContext, useState} from "react";

const RewardsContext = createContext();

export const RewardsProvider = ({children}) => {

    const [rewards, setRewards] = useState([]);
    const [selectedReward, setSelectedReward] = useState(null);

    const discountedProductPrice = (product) => {

        if(!selectedReward) {
            console.log('no reward')
            return product.price;
        }

        console.log('reward', selectedReward)

        return product.price - (product.price * (selectedReward.value / 100));
    }

    const values = {
        rewards,
        setRewards,
        selectedReward,
        setSelectedReward,
        discountedProductPrice
    }


    return (
        <RewardsContext.Provider value={values}>
            {children}
        </RewardsContext.Provider>
    );
}
export const useRewards = () => {
    const context = useContext(RewardsContext);

    if (!context) {
        throw new Error('useRewards must be used within a RewardsProvider');
    }

    return context;
};
