import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  color: var(--text-colour);
  margin-bottom: 16px;
`;

const TableProductPage = ({ product }) => {
  return (
    <Container>
      <Title>Table Product Page</Title>
      <p>Product Name: {product.name}</p>
    </Container>
  );
};

export default TableProductPage;
