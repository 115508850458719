import React, {useContext} from 'react';
import {CheckoutSection} from "../styles/checkoutPage.styles.js";
import {Paragraph, StyledTitle} from "../../../../components/ui/typography/index.js";
import {Form} from "../../../../components/forms/formStyles.js";
import {inputComponentMap} from "../../../../components/forms/js/inputMap.js";
import {TextInput} from "../../../../components/forms/index.js";
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import Accordian from "../../../../components/ui/Accordian/Accordian.jsx";
import {useCheckout} from "../../js/context/CheckoutContext.jsx";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useForm} from "react-hook-form";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {API_URL, scrollToElement} from "../../../../js/Helper.js";
import {toast} from "react-toastify";
import {AuthContext} from "../../../authentication/index.js";

const QuestionsSection = () => {

    const { register,reset, handleSubmit, formState: { errors } } = useForm();
    const { openDrawer } = useDrawer();
    const { questions, checkout } = useCheckout();
    const { config, updateConfig } = useEcommerce();
    const { isAuthenticated, header } = useContext(AuthContext)


    const updateCheckoutMutation = useMutation({

        mutationFn: (data) => axios.put(`${API_URL}/checkout/${checkout.id}`, data, {
            headers: header()
        }),
        onSuccess: (data) => {
        }
    })

    const onSubmit = async (submitData) => {


        if(!isAuthenticated && !config.confirmed) {
            toast.warn('Please login or complete the form');
            scrollToElement('order-summary')
            return;
        }


        let formData = new FormData();

        Object.entries(submitData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        const data = Object.fromEntries(formData)

        updateCheckoutMutation.mutate({additional_info: data})

        updateConfig({questionsComplete: true });
        scrollToElement('payment-form');
    }

    if( config.hasQuestions === false || config?.questionsComplete === true) return;

    return (
        <CheckoutSection
            id='questions'
            style={{
                background: 'var(--accent-colour)',
                clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 80%)',
                width: '100%',
                height: 'fit-content',
            }}
            clip={'true'}>
            <StyledTitle title={'Questions'}/>
            <Paragraph onclick={() => openDrawer(<Accordian data={questions} />, 'Descriptions')}>Need help answering these questions?</Paragraph>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {
                    questions.map((question, index) => {

                        const Input = inputComponentMap[question.input_type] ?? TextInput;

                        // Check if checkout.additional_info has a key matching question.name and has a value
                        // const inputValue = parsedInfo ? parsedInfo[question.name] ?? null : null;

                        return (
                            <Input
                                key={index}
                                register={register}
                                name={question.name}
                                required={question.required}
                                placeholder={question.placeholder}
                            />
                        )
                    })
                }



                <ButtonComponent
                    variant={'solid'}
                    style={{background:'var(--primary-colour)'}}
                    text={'Proceed to Payment'}
                />

            </Form>

        </CheckoutSection>
    );
};

export default QuestionsSection;