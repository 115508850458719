import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

const ModalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
});

export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: '',
    content: null,
    disableBackdropClick: false,
  });

  const openModal = useCallback(({ title, content, disableBackdropClick = false }) => {
    setModalConfig({
      isOpen: true,
      title,
      content,
      disableBackdropClick,
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal
        isOpen={modalConfig.isOpen}
        onClose={closeModal}
        title={modalConfig.title}
        disableBackdropClick={modalConfig.disableBackdropClick}
      >
        {modalConfig.content}
      </Modal>
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export default ModalProvider; 