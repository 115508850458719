import React, {useState} from 'react';
import styled from 'styled-components';
import {OrderComponentContainer} from '../styles/order.style';
import {useOrder} from '../hooks/OrderContext';
import OrderItems from './OrderItems';
import OrderDetails from './OrderDetails';
import OrderActions from './OrderActions';
import {StandardSection} from '../../../../layout/sections/StandardSection';
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {InfoItem} from "../../../../components/ui/InfoItemComponent.jsx";
import {Paragraph} from "../../../../components/ui/typography/index.js";
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {copyToClipboard} from "../../../../js/utils/browserUtils.js";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import {Controller, useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {makeRequest} from "../../../../services/axios.js";
import {toast} from "react-toastify";
import {useHandleError} from "../../../error-handling/js/hooks/index.js";

// #region constants

// #endregion

// #region styled-components
const BookingContainer = styled(InfoItem)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
`

const AddFriendContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding: 20px;
`

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

/**
 *
 * @param fresh
 * @param orderProp
 * @returns {React.JSX.Element|string}
 * @constructor
 */
const Order = ({ fresh, orderProp }) => {

    const {  order } = useOrder()
  
    return (
        <StandardSection>
            <OrderComponentContainer>
                <OrderDetails />
                {/*<Divider />*/}
                <OrderItems items={order?.orderItems} />
                <Bookings order={order}/>
                <DividerWithText text={'Actions'}  />

                <OrderActions preset={'full'}  />
            </OrderComponentContainer>
        </StandardSection>
    );
}

const Bookings = ({order}) => {

    if(order?.bookings?.length === 0) return;

    return (
        <>
            <DividerWithText text={'Bookings'}  />

            {
                order?.bookings?.map((booking, index) => {
                    return (
                        <Booking key={index} booking={booking} />
                    )
                })
            }

        </>

    )
}

/**
 *
 * @param booking
 * @returns {Element}
 * @constructor
 */
const Booking = ({booking}) => {

    return (
        <BookingContainer>
            <p style={{opacity: 0.5}}>Booking Starts</p>
            <Paragraph>{`${booking.time.startDate} - ${booking.time.startTime}`}</Paragraph>
            <p style={{opacity: 0.5}}>Booking Ends</p>
            <Paragraph>{`${booking.time.endDate} - ${booking.time.endTime}`}</Paragraph>
            <AddFriend booking={booking} />
            <ButtonComponent
                variant={'tertiary'}
                style={{ marginTop: '10px', height: '40px', backgroundColor: 'red'}}
                text={'Copy Link'}
                onClick={() => {
                    copyToClipboard(booking.link);
                }}
            />

        </BookingContainer>
    )

}


/**
 *
 * @param booking
 * @returns {Element}
 * @constructor
 */
const AddFriend = ({ booking }) => {

    const {openDrawer} = useDrawer();

    return (
        <ButtonComponent
            variant={'tertiary'}
            style={{marginTop: '10px', height: '40px', backgroundColor: 'red'}}
            text={'Add Friends To Booking'}
            onClick={() => {
                openDrawer(<HowManyFriends booking={booking} />)
            }}
        />
    )
}


const HowManyFriends = ({ booking }) => {

    const [numberOfFriends, setNumberOfFriends] = useState(1);
    const { openDrawer } = useDrawer();

    const handleConfirmedNumberOfFriends = (e) => {
        e.preventDefault();

        openDrawer(<FriendsForm numberOfFriends={numberOfFriends} booking={booking} />)

    }
    return (
        <AddFriendContainer>
            <form onSubmit={handleConfirmedNumberOfFriends}>
                <input
                    type="number"
                    placeholder="How many people?"
                    inputMode="numeric"
                    value={numberOfFriends}
                    // min={1}
                    onChange={(e) => {
                        const value = e.target.value
                        setNumberOfFriends(value);
                    }}
                />


                <ButtonComponent
                    variant={'solid'}
                    style={{marginTop: '10px', height: '40px', backgroundColor: 'red'}}
                    text={`Add ${numberOfFriends} friend${numberOfFriends > 1 ? 's' : ''} To Booking`}
                    onClick={() => {

                    }}
                />
            </form>
        </AddFriendContainer>

    )

}


const FriendsForm = ({ numberOfFriends, booking }) => {
    const { handleSubmit, control } = useForm();
    const {showErrorToast} = useHandleError();
    const { order } = useOrder();

    const submitFriendsMutation = useMutation({
        mutationFn: (data) => sendToFriends(data, booking, order),
        onSuccess: (res) => {
            toast.success(res.message ?? 'Success')
        },
        onError: (error) => {
            showErrorToast(error)
        }
    })

    const onSubmit = (data) => {
        console.log(data); // Data contains all the input values

        submitFriendsMutation.mutate(data)
    };

    return (
        <AddFriendContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                {Array.from({ length: numberOfFriends }, (_, index) => (
                    <Controller
                        key={index}
                        name={`friend${index + 1}`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <input
                                {...field}
                                type="email"
                                placeholder={`Friend ${index + 1} email`}
                                style={{
                                    padding: '0.5rem',
                                    borderRadius: 'var(--border-radius-default)',
                                    border: `1px solid var(--default-primary-colour)`,
                                }}
                            />
                        )}
                    />
                ))}
                <ButtonComponent
                    variant={'solid'}
                    style={{ marginTop: '10px', height: '40px', backgroundColor: 'red' }}
                    text={`Send to ${numberOfFriends} friend${numberOfFriends > 1 ? 's' : ''}`}
                    // onClick={handleSubmit(onSubmit)}
                    isLoading={submitFriendsMutation.isLoading}
                />
            </form>
        </AddFriendContainer>
    );
};


const sendToFriends = (data, booking, order) => {
    return makeRequest('PUT', `bookings/${booking.id}/${order.id}/add-friends`, data, null)
}


Order.propTypes = propTypes;
// #endregion

export default Order;
