import React from 'react';
import {StandardListLayout} from "../../../layout/ListLayouts/StandardListLayout.jsx";
import {LoyaltyCard} from "./LoyaltyCard/LoyaltyCard.jsx";
import {Link} from "react-router-dom";

const CardListComponent = ({ data, onClick, model }) => {

    if(!data) return null;

    return (
        <StandardListLayout style={{

        }}>
            {
                data.sort((a, b) => b.id - a.id).map((card, index) => {
                    return (
                        <Link key={index} to={`/${model.charAt(0)}/${card.slug}?back=true`}>
                            <LoyaltyCard
                                card={card}
                                tier={card?.default_tier}
                                onClick={() => onClick(card)}
                                style={{
                                    marginBottom: "-50px",
                                }}
                            />
                        </Link>

                    )
                })
            }
        </StandardListLayout>
    );
};

export default CardListComponent;