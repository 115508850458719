import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../authentication';
import { LoginForm } from '../../../authentication/components/LoginForm';
import { StyledTitle } from '../../../../components/ui/typography/StyledTitle';
import { getUsersOrders } from '../../../user/api/orders';
import { LoadingAnimationComponent } from '../../../../components/ui/LoadingAnimationComponent';
import { useApiQuery } from '../../../../hooks/customHooks';
import { StandardListLayout } from '../../../../layout/ListLayouts/StandardListLayout';
import { RectangleCardComponent } from '../../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent';
import { RectangleCardImage } from '../../../../components/ui/cards/RectangleCardComponent/components/styles/rectangleCardImage.style';
import Order from './Order';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import { useOrder } from '../hooks/OrderContext';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

/**
 * 
 */
const Orders = () => {


    const { isAuthenticated, user } = useContext(AuthContext);
    const { openDrawer, setTitle } = useDrawer('Your Orders');
    const { setOrder, setOrders, orders } = useOrder();

    if(!isAuthenticated) {
        return <LoginForm />
    }

    const { data, isLoading, error } = useApiQuery('user/orders?user_id=' + user?.id);

    useEffect(() => {
        console.log(data)
        if(data?.status === 'success') setOrders(data.data)
    }, [data])

    if(isLoading) {
        return <LoadingAnimationComponent />
    }

    const handleClick = (item) => {
        setOrder(item)
        openDrawer(<Order />, 'Order Summary')
    }

    if(orders && orders.length === 0) {
        return <StyledTitle title={"You don't have any orders yet"} size={'1rem'} />
    }

    return  (
        <StandardListLayout>
            {
                orders && orders.length > 0 && 
                
                orders.map((item, index) => {
                
                        return (
                            <RectangleCardComponent
                            key={index}
                            image={<RectangleCardImage source={item.image}  />}
                            title={item.name}
                            description={`Order Total: ${item.amount}`}
                            handleClick={() => handleClick(item)}
                            
                            />
                        )
                    })
            }
        </StandardListLayout>
    );
}

Orders.propTypes = propTypes;
// #endregion

export default Orders;