import React from 'react'
import styled from 'styled-components'
import { Input, InputContainer, Label } from '../formStyles'



export const CheckboxInput = ({
  disabled,
  value,
  name,
  placeholder,
  onChange,
  autoCorrect,
  required,
register
}) => {


  return (
    <InputContainer>
      <Label htmlFor={name}>{placeholder}</Label>
      <Input
      {...register(name)}
      disabled={disabled}
      value={value}
      type={'checkbox'}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      autoCorrect={autoCorrect}
      required={required}
      />
    </InputContainer>
  )
}
