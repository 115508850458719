import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    width: 100%;
    color: var(--text-colour);
    padding: 0.5rem 0;
    z-index: 1;
    border-radius: var(--border-radius-default);
    

    @media (min-width: 768px) {
        width: 300px;
        justify-self: center;
        margin: 0 auto;
        padding: 1rem 0;
    }
`
const Title = styled.h1`
    font-size: 2rem;
    letter-spacing: 3px;
    border-radius: var(--border-radius-small);
    box-sizing: border-box;
    margin: 0;
    color: var(--header-colour);
    background: var(--header-colour);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 768px) {
        font-size: 1rem;
    }
`
const Text = styled.p`
    font-size: 1rem;
    letter-spacing: 0.25rem;
    font-weight: 400 ;
    margin: 0;
    
    `
const Text2 = styled.p`
    letter-spacing: 0.25rem;
    font-weight: 100;
    margin: 7.5px 0px;


`

const ImpactTitle = ({header, subHeader, main, style }) => {


  return (
    <Container style={style}>
        {header && <Text>{header}</Text>}
        {subHeader && <Text2>{subHeader}</Text2>}
        {main && <Title style={style?.main}>{main}</Title>}
    </Container>
  )
}

export default ImpactTitle