import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { timings, easings } from '../../tokens/animations';

const ItemContainer = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--text-colour);
  transition: all ${timings.interaction} ${easings.smooth};
  
  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
    transition: all ${timings.interaction} ${easings.smooth};
    opacity: ${props => props.active ? 1 : 0.6};
    color: ${props => props.active ? 'var(--accent-colour)' : 'inherit'};
  }
`;

const Badge = styled.div`
  position: absolute;
  top: 6px;
  right: 4px;
  min-width: 12px;
  height: 12px;
  padding: 0 3px;
  border-radius: 6px;
  background: ${props => props.$active ? 'var(--background-colour)' : 'var(--accent-colour)'};
  color: ${props => props.$active ? 'var(--accent-colour)' : 'var(--background-colour)'};
  font-size: 9px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.visible ? 1 : 0};
  transform: scale(${props => props.visible ? 1 : 0.5});
  transition: all ${timings.interaction} ${easings.smooth};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SubItemsContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 12px); /* Add 12px gap */
  left: 50%;
  transform: translateX(-50%) translateY(${props => props.isOpen ? '0' : '10px'});
  background: var(--primary-colour);
  border-radius: 12px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  opacity: ${props => props.isOpen ? 1 : 0};
  pointer-events: ${props => props.isOpen ? 'all' : 'none'};
  transition: all ${timings.interaction} ${easings.smooth};
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  min-width: 120px;
  
  /* Add floating effect with subtle border */
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);

  /* Add arrow */
  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background: var(--primary-colour);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const SubItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background: none;
  color: var(--text-colour);
  opacity: 0.8;
  cursor: pointer;
  border-radius: 8px;
  transition: all ${timings.interaction} ${easings.smooth};
  width: 100%;
  text-align: left;
  font-size: 13px;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.05);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const NavItem = forwardRef(({ 
  icon: Icon, 
  active, 
  onClick,
  badge,
  subItems,
  isSubMenuOpen,
  onSubItemClick,
  onMouseEnter,
  onMouseLeave
}, ref) => {
  return (
    <ItemContainer
      ref={ref}
      active={active}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {Icon && <Icon />}
      {badge && (
        <Badge visible={!!badge} $active={active}>
          {typeof badge === 'number' && badge > 99 ? '99+' : badge}
        </Badge>
      )}
      {subItems && subItems.length > 0 && (
        <SubItemsContainer isOpen={isSubMenuOpen}>
          {subItems.map((item, index) => {
            const ItemIcon = item.icon;
            return (
              <SubItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  onSubItemClick?.(item);
                }}
              >
                {ItemIcon && <ItemIcon />}
                {item.label}
              </SubItem>
            );
          })}
        </SubItemsContainer>
      )}
    </ItemContainer>
  );
});

NavItem.displayName = 'NavItem';

export default NavItem;
