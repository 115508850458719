import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FiUser } from 'react-icons/fi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from '../../../../../atomic/molecules/Form/Form';
import Button from '../../../../../atomic/atoms/Button/Button';
import EmailInput from '../../../../../atomic/atoms/form-elements/EmailInput/EmailInput';
import { AuthContext } from '../../../../../features/authentication';
import { useBasket } from '../../../basket/context/BasketContext';

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  date_of_birth: yup.string()
    .required('Birthday is required')
    .test('valid-date', 'Please enter a valid date', value => {
      if (!value) return false;
      
      // Split the date and handle both single and double digits
      const parts = value.split('/');
      if (parts.length !== 3) return false;
      
      const [day, month, year] = parts.map(part => parseInt(part, 10));
      
      // Basic range checks
      if (isNaN(day) || isNaN(month) || isNaN(year)) return false;
      if (day < 1 || day > 31) return false;
      if (month < 1 || month > 12) return false;
      if (year < 1900 || year > new Date().getFullYear()) return false;
      
      // Check if it's a valid date (handles months with different lengths)
      const date = new Date(year, month - 1, day);
      if (date.getDate() !== day || date.getMonth() !== month - 1 || date.getFullYear() !== year) {
        return false;
      }
      
      return true;
    })
    .test('age-limit', 'You must be at least 18 years old', value => {
      if (!value) return false;

      const [day, month, year] = value.split('/').map(num => parseInt(num, 10));
      const birthDate = new Date(year, month - 1, day);
      const today = new Date();
      
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      
      // Adjust age if birthday hasn't occurred this year
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      
      return age >= 18;
    }),
  gender: yup.string().required('Gender is required'),
});

const CheckoutForm = ({ onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useContext(AuthContext);
  const { updateBasket, basket } = useBasket();

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const result = await onSubmit(data);
      // If we get here, it means the submission was successful
      // because onSubmit throws an error on failure

      updateBasket('customerDetails', data);

      return result;
    } catch (error) {
      console.error('Form submission failed:', error);
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form
      style={{width: '100%', maxWidth: '300px', gap: '10px'}}
      fields={[
        {
          id: 'first_name',
          type: 'input',
          label: 'First Name',
          required: true,
          order: 1,
          props: {
            name: 'first_name',
            placeholder: 'Enter your first name',
            icon: <FiUser />,
            autoComplete: 'first_name'
          }
        },
        {
          id: 'last_name',
          type: 'input',
          label: 'Last Name',
          required: true,
          order: 2,
          props: {
            name: 'last_name',
            placeholder: 'Enter your last name',
            icon: <FiUser />,
            autoComplete: 'last_name'
          }
        },
        {
          id: 'email',
          type: 'custom',
          label: 'Email',
          required: true,
          order: 3,
          component: EmailInput,
          props: {
            name: 'email',
            placeholder: 'Enter your email',
            onChange: undefined,
            onBlur: undefined,
            value: undefined,
            autoComplete: 'email'
          }
        },
        {
          id: 'date_of_birth',
          type: 'date',
          label: 'Date of Birth',
          required: true,
          order: 4,
          props: {
            name: 'date_of_birth',
            placeholder: 'DD/MM/YYYY',
            autoComplete: 'bday'
          }
        },
        {
          id: 'gender',
          type: 'select',
          label: 'Gender',
          required: true,
          order: 5,
          props: {
            name: 'gender',
            placeholder: 'Select your gender',
            autoComplete: 'sex',
            options: [
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'other', label: 'Other' },
              { value: 'prefer_not_to_say', label: 'Prefer not to say' }
            ]
          }
        }
      ]}
      onSubmit={handleSubmit}
      defaultValues={{ 
        first_name: user?.first_name || basket?.customerDetails?.first_name,
        last_name: user?.last_name || basket?.customerDetails?.last_name,
        email: user?.email || basket?.customerDetails?.email,
        date_of_birth: user?.date_of_birth || basket?.customerDetails?.date_of_birth,
        gender: user?.gender || basket?.customerDetails?.gender
      }}
      validationSchema={yupResolver(schema)}
      hideLabels={true}
    >
      <Button
        variant="cta"
        size="large"
        loading={isSubmitting}
        type="submit"
        useGradient
      >
        {isSubmitting ? 'Processing...' : 'Continue to Payment'}
      </Button>
    </Form>
  );
};

CheckoutForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CheckoutForm; 