import React from 'react'
import styled from 'styled-components'

const TitleContainer = styled.div`
    z-index: 100;
`

const Title = styled.h1`
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.8rem;
    margin: 0;
    text-align: center;
    color: var(--text-colour);

    @media screen and (min-width: 768px){
        font-size: 2rem;
    }

    @media screen and (min-width: 1024px){
        font-size: 1.25rem;
    }
`

const Subtitle = styled.p`
    font-weight: bold;
    text-align: center;
    margin: 0;
    
    @media screen and (min-width: 1024px){
        font-size: 2rem;
        text-transform: uppercase;
    }
`


export const StyledTitle = ({title, subtitle, style, textstyle, size}) => {
  return (
    <TitleContainer style={style}>
        <Title style={{color: style?.color || 'white', fontSize: size, ...textstyle}}>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
    </TitleContainer>
)
}
