import React from 'react';
import styled from "styled-components";
import { useApiQuery } from "../../../hooks/customHooks.js";
import { useNavigate, useParams } from "react-router-dom";
import { useHandleError } from "../../error-handling/js/hooks/index.js";
import { Loading } from "../../../components/ui/Loading.jsx";
import { FullPageContainer } from "../../../layout/styles/layoutStyles.js";
import { HeroImage } from "../../../components/heroes/HeroImage.jsx";
import { updateTheme } from "../../themes/js/utils.js";
import { RectangleCardComponent } from "../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent.jsx";
import { ImageComponent } from "../../../components/ui/images/ImageComponent.jsx";
import { DividerWithText } from "../../../components/ui/LogoWall/DividerWithText.jsx";
import { ButtonComponent } from "../../../components/ui/buttons/index.js";
import { useDrawer } from "../../../js/hooks/context/DrawerContext.jsx";
import { ProductList } from "../../ecommerce/products/components/index.js";
import GuestlistForm from "../components/GuestlistForm.jsx";
import { useEvent } from "../js/hooks/index.js";
import { Footer } from "../../../layout/components/Footer.jsx";

const PageContainer = styled(FullPageContainer)`
    padding-top: 0;
`

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    width: 100%;
    gap: 20px;
    padding: 0 20px;
`

const ProductListContainer = styled.div`
    width: 100%;
    padding: 20px 20px 40px;
`

const EventHome = () => {

    const params = useParams();
    const slug = params.slug;
    const guestlistId = params.id;
    const {showErrorPage} = useHandleError();
    const {openDrawer} = useDrawer();
    const {setEvent, event} = useEvent();
    const navigate = useNavigate();

    const {isLoading, isSuccess, error} = useApiQuery(`event/${slug}/home`, (data) => {
        if(data.status === 'success') {
            setEvent(data?.data)
        }
    }, {
        onError: (error) => showErrorPage(error),
    });


    if (isLoading) return <Loading/>
    if (error) showErrorPage(error)
    if (!isSuccess) showErrorPage(error)

    if (!event) return;

    updateTheme(event?.theme)

    console.log('event', event)

    const handleClickGuestlist = () => {

        /**
         *  If there's a id in the url then select that guestlist from the guestlist array
         *  if not then just default to the first guestlist in the array
         */

        try {
            const guestlist = event.guestlists.find((list) => list.id === Number(guestlistId)) ?? event.guestlists[0];
            openDrawer(<GuestlistForm guestlist={guestlist}/>, guestlist.name);
        } catch (e) {
            console.log('Error finding guestlist');
            // showErrorToast(e)
        }

        const events = useApiQuery(`events`, (data) => {
            console.log(data)
        })
    }


    return (
        <>
            <PageContainer>
                <HeroImage image={event.imageUrl ?? event?.image?.cdnUrl}/>

                <ContentContainer style={{
                    marginTop: '-125px',
                    zIndex: 10
                }}>

                    <RectangleCardComponent
                        image={<ImageComponent src={event.imageUrl ?? event?.image?.cdnUrl} width={100}/>}
                        title={`${event?.business?.name} Presents ${event.name}`}
                        description={event.shortDescription ?? event.shortDescription}
                        handleClick={() => navigate(`/e/${slug}`)}
                    />

                    <DividerWithText text={'Quick Links'} style={{marginTop: '20px'}}/>

                    { event?.tables?.length > 0 && <ButtonComponent variant={'solid'} text={'Tables'} onClick={() => openDrawer(<ProductListComponent data={event.tables} />, `Tables for ${event.name}`)}/> }
                    { event?.tickets?.length > 0 && <ButtonComponent variant={'outlined'} text={'Tickets'} onClick={() => openDrawer(<ProductListComponent data={event.tickets} />, `Tickets for ${event.name}`) }/> }
                    {event?.guestlists?.length > 0 && <ButtonComponent variant={'outlined'} text={'Guestlist'} onClick={() => handleClickGuestlist()}/>}
                    {/*<DividerWithText text={'Having issues?'} style={{marginTop: '20px'}}/>*/}

                    {/*<ButtonComponent variant={'tertiary'} text={'Support'} onClick={() => handleClickGuestlist()}/>*/}


                </ContentContainer>


            </PageContainer>
            <Footer/>
        </>

    );
};

export default EventHome;
const ProductListComponent = ({data}) => {
    return (
        <ProductListContainer>
            <ProductList data={data}/>
        </ProductListContainer>
    )
}
