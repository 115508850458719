import React from 'react'
import styled from 'styled-components'

const AbsoluteSectionWrapper = styled.section`

    width: 100dvw;
    min-height: 100dvh;
    background-position: center left;
`

const AbsoluteSection = ({children, preset, customStyle}) => {


  return (
    <AbsoluteSectionWrapper name='absolute-section' style={customStyle}>
        {children}
    </AbsoluteSectionWrapper>
  )
}

export default AbsoluteSection