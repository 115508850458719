import React from "react";
import {BsLightningCharge} from "react-icons/bs";
import {ListItemContent, ListItemTitle, StyledListItem} from "./list.styles.js";
import PropTypes from "prop-types";
import styled from "styled-components";
import {motion} from "framer-motion";
import {pressAnimationVariant} from "../../js/styles/animations.js";


// Memoized ListItem component to prevent unnecessary re-renders
const ActionButton = styled(motion.button)`
    background-color: var(--accent-colour);
    color: white;
    border: none;
    border-radius: var(--border-radius-default);
    padding: 8px 16px;
    font-size: 12px;
    cursor: pointer;
    margin-top: 8px;
    opacity: 0.9;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 1;
    }
`;

export const ListItemComponent = React.memo(({
                                          title,
                                          content,
                                          icon: Icon = BsLightningCharge,
                                          isFirst,
                                            data,
                                          isLast,
                                          buttonText,
                                          onButtonClick,
                                            style

                                      }) => (
    <StyledListItem
        whileTap={pressAnimationVariant}
        style={{
            ...(isFirst && {
            }),
            ...(isLast && {
                marginRight: '20px',
            }),
                ...style
        }}
    >
        <ListItemTitle>
            {title || data.name}
            <Icon />
        </ListItemTitle>
        <ListItemContent>
            {content}
        </ListItemContent>
        {buttonText && onButtonClick && (
            <ActionButton
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={onButtonClick}
            >
                {buttonText}
            </ActionButton>
        )}
    </StyledListItem>
));

ListItemComponent.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func
};

ListItemComponent.displayName = 'ListItemComponent';