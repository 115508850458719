import styled from "styled-components";

const H2 = styled.h2`
    font-size: 1rem;
    color: var(--header-colour);
    background: var(--header-colour);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`



export {H2}