import React, { Children, createContext, useContext, useState } from 'react';
import { getSingleOrder } from '../api/orderApi';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {

    const [order, setOrder] = useState();
    const [orders, setOrders] = useState();

    return (
        <OrderContext.Provider value={{
            setOrders,
            setOrder,
            orders,
            order
        }}>
            {children}
        </OrderContext.Provider>
    )
}

export const useOrder = () => {
    const context = useContext(OrderContext);
    
    if (!context) {
      throw new Error('OrderContext must be used within a OrderProvider');
    }
    
    
    
    return context;
  };
  