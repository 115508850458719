/**
 * EventErrorBoundary component
 * @description Error boundary for the Event page
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FileNotFoundPage } from '../../../../../pages/FileNotfoundPage';

class EventErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Event Error:', error, errorInfo);
    // TODO: Add error reporting service integration
  }

  render() {
    if (this.state.hasError) {
      return <FileNotFoundPage />;
    }

    return this.props.children;
  }
}

EventErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EventErrorBoundary;
