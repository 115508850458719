import React from 'react'
import styled from 'styled-components'
import { CDN_URL } from '../../../js/Helper'
import { Title } from '../../../components/ui/typography'
import { StyledTitle } from '../../../components/ui/typography/StyledTitle'


const FeaturedItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  min-width: 250px;
  height: 500px;
  border-radius: var(--border-radius-default);
  box-shadow: 2px -8px 10px rgba(0,0,0,0.1);
  padding: 20px 10px;
  box-sizing: border-box;
  margin-top: 20px;
  background-color: var(--primary-colour);
  background-size: ${props => props.show == 'image' ? 'cover' : 'contain'};
  background-position: center;
  background-image: linear-gradient(to top, var(--primary-colour), transparent 50%),
                    url(${props => props.show == 'image' ? props.image : props.logo});



  @media screen and (min-width: 1024px) {
    height: 22rem;
    min-width: 12rem;
    width: 12rem;
    padding-bottom: 3rem; 
  }

  @media screen and (min-width: 1920px) {
    height: 35rem;
    width: 15rem;
    padding-bottom: 3rem; 
  }
  
`




export const FeaturedItem = (props) => {

  // const [image, setImage] = useState();

  const { data } = props;

  const partner = data?.partner
  const files = data?.partner?.files
  const pressImage = files?.find(file => file.name == 'PressImage')?.file?.cdnUrl
  
  let bookingImage =  data?.image?.cdnUrl;       
  const logo = files?.logo?.cdnUrl;


  return (
    <FeaturedItemContainer 
    show={pressImage != null ? 'image' : 'logo' }
    image={pressImage ? pressImage : ''}
    logo={logo}
    style={{
      backgroundImage: data.status == 'confirmed' ? pressImage ? bookingImage : pressImage : 'none'
    }}
    >

      <StyledTitle title={data.status == 'confirmed' ? data?.partner?.name : 'TO BE ANNOUNCED'}/>

    </FeaturedItemContainer>
  )
}
