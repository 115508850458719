import React from 'react';
import {useParams} from "react-router-dom";
import styled from 'styled-components'
import Confirmation from "./Confirmation.jsx";
import SignupConfirmation from "./SignupConfirmation.jsx";
import {FullPageContainer} from "../layout/styles/layoutStyles.js";
import {useDrawer} from "../js/hooks/context/DrawerContext.jsx";
import {StandardSection} from "../layout/sections/StandardSection.jsx";
import {Section} from "../layout/components/LayoutSection.jsx";
import {useEvent} from "../features/events/js/hooks/index.js";


const PageContainer = styled.section``

const ConfirmationV2 = ({ propType, propId}) => {

    const {isOpen} = useDrawer();
    const { event } = useEvent();

    const params = useParams();
    const type = propType ? propType : params.type ;
    const id = propId ? propId : params.id;

    const confirmationContentMap = {
        order: Confirmation,
        card: SignupConfirmation
    }

    const ConfirmationContent = confirmationContentMap[type];

    const style = {
        justifyContent: 'space-around',
        alignItems: 'center',
        minHeight: isOpen ? 'fit-content' : '100dvh'
    }

    return (
        <FullPageContainer id={'confirmation-page'} style={style}>
            <Section theme={'clipped'}>
                <ConfirmationContent id={id}  />
            </Section>
        </FullPageContainer>
    )
}

export default ConfirmationV2;