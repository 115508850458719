import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ActivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    gap: 2px;
    width: var(--small);
    max-width: 400px;
    border-radius: var(--border-radius-large);
    border-top: solid 1px var(--default-accent-colour);
    border-bottom: solid 1px var(--default-accent-colour);
    box-shadow: var(--shadow-primary);
    overflow: hidden;
    z-index: 2;

    a {
        min-width: 100%;
        color: white;
        font-size: 1.3em;
        font-weight: 800;

        transition: color 250ms ease-in-out;

        &:hover {
            color: var(--default-accent-colour);
            transition: color 250ms ease-in-out;
        }
    }
`

const ActivityItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    height: 80px;
    max-height: 100px;
    /* background-color: ${props => props.odd == true ? 'var(--accent-colour)' : ''}; */
    background-color: var(--default-primary-colour-faded);
    backdrop-filter: blur(10px);
    box-shadow: inset 5px 0px 30px rgba(25,25,25, 0.2);
    cursor: pointer;

`
const ActivityTitle = styled.p`
 font-size: 1.75rem;
 letter-spacing: 2px;
 text-transform: uppercase;
 max-width: 100%;
`

export const ActivitySelector = () => {

    const activities = ['eat', 'DRINK',, 'party']

  return (
    <ActivityContainer>

        {
            activities && activities.map((activity, index) => {

                const isOdd = index % 2 === 1;
      
                return (
                    <Link to={'/businesses/category/' + activity} key={index}>
                        <ActivityItem odd={isOdd}>
                            <ActivityTitle>{activity}</ActivityTitle>
                        </ActivityItem>
                    </Link>
                )
            })
        }
    </ActivityContainer>
  )
}

