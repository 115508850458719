import React from 'react';
import styled from "styled-components";
import propTypes from "prop-types";

const ContactDetailsContainer = styled.div`
    font-weight: 800;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
`
const Name = styled.p`
  
`
const Phone = styled.p``
const Email = styled.p`
    font-size: 1rem;
`



const ContactDetails = ({ prefix, name, phone, email}) => {
    return (
        <ContactDetailsContainer id={'contact-details'}>
            <Name>{prefix} {name} </Name>
            <Email>{email}</Email>
            <Phone>{phone}</Phone>
        </ContactDetailsContainer>
    );
};

export default ContactDetails;

ContactDetails.propTypes = {
    prefix: propTypes.string,
    name: propTypes.string,
    phone: propTypes.string,
    email: propTypes.string
}