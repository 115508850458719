import { useMemo } from 'react';

export const useMultiTenant = () => {
    const hostname = window.location.hostname;

    const isSubdomain = useMemo(() => {
        const parts = hostname.split('.');
        return parts.length > 2 || (parts.length === 2 && parts[0] !== 'www');
    }, [hostname]);

    const getSubdomain = useMemo(() => {
        if (!isSubdomain) {
            return '';
        }

        const parts = hostname.split('.');
        if (parts[0] === 'www') {
            return parts[1];
        }

        return parts[0];
    }, [hostname, isSubdomain]);

    return { isSubdomain, getSubdomain };
}
