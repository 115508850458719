import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaLock, FaCreditCard } from 'react-icons/fa';

const TagContainer = styled.span`
  background: ${props => props.$background || 'rgba(0, 0, 0, 1)'};
  color: ${props => props.$color || 'white'};
  padding: 2px 16px;
  border-radius: 6px;
  font-size: 0.65rem;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 0.7rem;
`;

const getIcon = (type) => {
  switch (type) {
    case 'password':
      return <FaLock />;
    case 'member':
      return <FaCreditCard />;
    default:
      return null;
  }
};

/**
 * Tag component for displaying labels with optional icons
 */
const Tag = ({ 
  children, 
  background,
  color,
  icon,
  className 
}) => {
  return (
    <TagContainer 
      className={className}
      $background={background}
      $color={color}
    >
      {icon && <IconWrapper>{getIcon(icon)}</IconWrapper>}
      {children}
    </TagContainer>
  );
};

Tag.propTypes = {
  /** Content of the tag */
  children: PropTypes.node.isRequired,
  /** Background color of the tag */
  background: PropTypes.string,
  /** Text color of the tag */
  color: PropTypes.string,
  /** Icon type to display */
  icon: PropTypes.oneOf(['password', 'member']),
  /** Optional className for styling */
  className: PropTypes.string
};

export default Tag;
