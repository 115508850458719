/**
 * Payment error types and their corresponding user-friendly messages
 * @constant {Object}
 */
export const PAYMENT_ERROR_TYPES = {
    CARD_ERROR: {
        code: 'card_error',
        message: 'There was an issue with your card. Please verify your card details.',
        recoverable: true,
        action: 'UPDATE_CARD'
    },
    AUTHENTICATION_ERROR: {
        code: 'authentication_error',
        message: 'Your payment requires authentication. Please complete the verification process.',
        recoverable: true,
        action: 'AUTHENTICATE'
    },
    NETWORK_ERROR: {
        code: 'network_error',
        message: 'Network connection issue detected. Please check your internet connection.',
        recoverable: true,
        action: 'RETRY'
    },
    PROCESSING_ERROR: {
        code: 'processing_error',
        message: 'There was an issue processing your payment. Please try again.',
        recoverable: true,
        action: 'RETRY'
    },
    RATE_LIMIT_ERROR: {
        code: 'rate_limit_error',
        message: 'Too many payment attempts. Please wait a moment before trying again.',
        recoverable: true,
        action: 'WAIT'
    },
    INVALID_REQUEST_ERROR: {
        code: 'invalid_request_error',
        message: 'Invalid payment request. Please refresh and try again.',
        recoverable: true,
        action: 'REFRESH'
    },
    SESSION_EXPIRED: {
        code: 'session_expired',
        message: 'Your payment session has expired. Please start over.',
        recoverable: true,
        action: 'RESET'
    },
    UNKNOWN_ERROR: {
        code: 'unknown_error',
        message: 'An unexpected error occurred. Our team has been notified.',
        recoverable: false,
        action: 'CONTACT_SUPPORT'
    }
};
