import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { 
  FiInfo, 
  FiAlertCircle, 
  FiCalendar, 
  FiMapPin,
  FiClock,
  FiUser,
  FiTag,
  FiStar
} from 'react-icons/fi';
import AnimatedElement from '../AnimatedElement/AnimatedElement';

const StripContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 40px;
  padding: 0 24px;
  gap: 16px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(12px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Asymmetrical border radius */
  border-radius: 12px 4px 16px 8px;
  
  /* Gradient border with glow */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.15) 100%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  /* Enhanced shadow and glow effects */
  box-shadow: 
    0 4px 24px -8px rgba(0, 0, 0, 0.2),
    0 0 12px rgba(255, 255, 255, 0.02),
    inset 0 0 8px rgba(255, 255, 255, 0.02);

  &:hover {
    transform: translateY(-1px) scale(1.005);
    background: rgba(255, 255, 255, 0.04);
    
    &::before {
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.25) 100%
      );
    }
    
    box-shadow: 
      0 8px 32px -8px rgba(0, 0, 0, 0.3),
      0 0 16px rgba(255, 255, 255, 0.03),
      inset 0 0 12px rgba(255, 255, 255, 0.03);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
  transition: all 0.3s ease;

  /* Subtle glow behind icon */
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 100%;
    background: var(--accent-colour);
    filter: blur(15px);
    opacity: 0.2;
    z-index: -1;
    transition: all 0.3s ease;
  }

  svg {
    width: 20px;
    height: 20px;
    stroke-width: 1;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
  }

  &:hover::after {
    opacity: 0.4;
    filter: blur(6px);
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 70%;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.15) 20%,
    rgba(255, 255, 255, 0.15) 80%,
    transparent
  );
  margin: 0 8px;
  position: relative;

  /* Glow effect */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(2px);
    opacity: 0.5;
  }
`;

const Text = styled.span`
  color: var(--text-colour);
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  /* Gradient text effect */
  background: linear-gradient(
    to right,
    var(--text-colour) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledIcon = styled(({ icon: Icon, ...props }) => <Icon {...props} />)`
  color: var(--accent-colour);
`;

const iconMap = {
  info: FiInfo,
  alert: FiAlertCircle,
  calendar: FiCalendar,
  location: FiMapPin,
  time: FiClock,
  user: FiUser,
  tag: FiTag,
  star: FiStar
};

const InfoStrip = ({ icon, text, className, delay }) => {
  const IconComponent = iconMap[icon] || FiInfo;

  return (
    <AnimatedElement delay={delay} duration={1}>
      <StripContainer className={className}>
        <IconWrapper>
          <StyledIcon icon={IconComponent} />
        </IconWrapper>
        <Divider />
        <Text>{text}</Text>
      </StripContainer>
    </AnimatedElement>
  );
};

InfoStrip.propTypes = {
  /** Icon name from the available icons */
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  /** Text to display */
  text: PropTypes.string.isRequired,
  /** Optional className for styled-components */
  className: PropTypes.string,
  /** Delay before animation starts (in seconds) */
  delay: PropTypes.number,
};

export default InfoStrip; 