import React, { createContext, useContext, useState, useCallback } from 'react';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [badges, setBadges] = useState({});
  const [visibility, setVisibility] = useState({});

  const handleNavigation = useCallback((item) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.path) {
      // Handle route navigation here
      console.log('Navigating to:', item.path);
    }
    setActiveItem(item.key);
  }, []);

  const updateBadge = useCallback((key, value) => {
    setBadges(prev => ({
      ...prev,
      [key]: value
    }));
  }, []);

  const getBadge = useCallback((key) => {
    return badges[key];
  }, [badges]);

  const updateVisibility = useCallback((updates) => {
    setVisibility(prev => ({
      ...prev,
      ...updates
    }));
  }, []);

  const isVisible = useCallback((key) => {
    return visibility[key] !== false; // Default to true if not explicitly set to false
  }, [visibility]);

  const clearBadges = useCallback(() => {
    setBadges({});
  }, []);

  const value = {
    activeItem,
    handleNavigation,
    updateBadge,
    getBadge,
    clearBadges,
    updateVisibility,
    isVisible
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
