import React, {useEffect} from 'react';
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {convertToCurrency} from "../../../../js/helpers/helpers.js";
import styled from "styled-components";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {productAcceptsDeposit, productAndAddonsAcceptDeposit} from "../js/utils/depositUtils.js";
import {priceIncludingAddons} from "../../products/js/utils/productUtils.js";
import {
    DEPOSIT_PAYMENT_OPTION,
    FULL_PAYMENT_OPTION,
    PRICE_TO_PAY_PREFIX,
    PRICE_TO_PAY_SUFFIX
} from "../js/constants.js";

const DepositContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;

`
const DepositOptionsButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-sizing: content-box;
    //padding: 0 20px;
    border-radius: var(--border-radius-default);
    border: solid 1px white;
    border: solid 1px var(--accent-colour);
    gap: 10px;
    
    button {
        //background-color: red;
        font-size: 0.6rem;
        height: fit-content;
        font-weight: bold;
    }
`

const DepositOptions = ({product}) => {

    if(!product) return;

    const { updateOrderItemValue } = useEcommerce();
    const [paymentOption, setPaymentOption] = React.useState('full');
    const [shouldUpdateAddons, setShouldUpdateAddons] = React.useState(false);


    const priceToPay = `${PRICE_TO_PAY_PREFIX} ${PRICE_TO_PAY_SUFFIX}`

    // State to track whether we should update addons

    // useEffect to update addons when the state changes
    useEffect(() => {
        if (shouldUpdateAddons) {
            product?.addons?.forEach(addon => {
                if (addon.depositAmount > 0) {
                    updateOrderItemValue(addon.id, 'paymentOption', paymentOption);
                    // updateOrderItemValue(addon.id, 'quantity', product.quantity);
                }
            });
            setShouldUpdateAddons(false); // Reset the flag
        }
    }, [shouldUpdateAddons, paymentOption, product?.addons, updateOrderItemValue]);


    const handleUpdatePaymentOption = (value) => {
        setPaymentOption(value);

        // if(productAcceptsDeposit(product)) {
            // Update the main product immediately
            updateOrderItemValue(product.id, 'paymentOption', value);

            // Use a state to trigger updating addons
            setShouldUpdateAddons(true);
        // };

    };




    return (
        <DepositContainer id={product.id + '-deposit-container'} style={{justifyContent: 'space-between', alignItems: 'space-between'}}>
            {/*<DividerWithText style={{margin: '12px 0px', textTransform: 'uppercase'}} text={'Pay Deposit or Full'} />*/}
            <DepositOptionsButtonContainer>
                <ButtonComponent
                    variant={ paymentOption === FULL_PAYMENT_OPTION ? 'solid' : 'tertiary'}
                    text={'Pay in full'}
                    onClick={() => handleUpdatePaymentOption(FULL_PAYMENT_OPTION)}
                    style={{
                        borderColor: paymentOption === FULL_PAYMENT_OPTION ? 'var(--accent-colour)' : 'none',
                        outline: paymentOption === FULL_PAYMENT_OPTION ? 'var(--accent-colour)' : '',
                        minWidth: '100%',
                        backgroundColor: 'white'
                    }}
                />
                <ButtonComponent
                    variant={ paymentOption === DEPOSIT_PAYMENT_OPTION ? 'solid' : 'tertiary'}
                    text={priceToPay}
                    onClick={() => handleUpdatePaymentOption(DEPOSIT_PAYMENT_OPTION)}
                    style={{
                        borderColor: paymentOption === DEPOSIT_PAYMENT_OPTION ? 'var(--accent-colour)' : '',
                        outline: paymentOption === DEPOSIT_PAYMENT_OPTION ? 'var(--accent-colour)' : '',
                        minWidth: '100%'

                    }}
                />
            </DepositOptionsButtonContainer>


        </DepositContainer>
    )
}

export default DepositOptions;