import CardListComponent from "../../features/cards/components/CardListComponent.jsx";
import EventListComponent from "../../features/events/components/EventListComponent.jsx";

const listComponentMap = {
    'cards': CardListComponent,
    'events': EventListComponent


}


export {
    listComponentMap
}
