import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AccessAccountPage } from '../../features/user/pages/AccessAccountPage/AccessAccountPage';
import { AuthContext } from '../../features/authentication';
import { useDrawer } from '../../js/hooks/context/DrawerContext';
import { LoginForm } from '../../features/authentication/components/LoginForm';

export const UnauthenticatedFooterLinks = () => {

    const { isAuthenticated } = useContext(AuthContext);
    const { openDrawer } = useDrawer();

  return (
    <>
    {
        !isAuthenticated &&
        <>
        <Link onClick={() => openDrawer(<LoginForm />, 'Login Form')}>Log In</Link>
        {/*<Link onClick={() => openDrawer(<AccessAccountPage />, 'Access Account Form')}>Can't Access My Account</Link>*/}
        </>
    }
    </>
  )
}
