import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from '../../../../atomic/atoms/Heading';
import AnimatedElement from '../../../../atomic/atoms/AnimatedElement/AnimatedElement';
import Image from '../../../../atomic/atoms/Image';
import EventInfoStrips from '../../components/EventInfoStrips/EventInfoStrips';

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  
  @media (min-width: 768px) {
    display: none; /* Hide on desktop */
  }
`;

const PrimaryEventInfoSection = ({ 
  location,
  time,
  date,
  dressCode,
  image,
  className 
}) => {
  return (
    <SectionContainer className={className} id="info">
      <AnimatedElement delay={0.2} duration={0.8}>
        <Image src={image} alt="Event Image" borderRadius="50px 0px" />
      </AnimatedElement>

      <br />

      <AnimatedElement delay={0.2} duration={0.8}>
        <Heading level={4} weight='900' gradient='var(--accent-colour)'>
          EVENT INFO
        </Heading>
      </AnimatedElement>

      <EventInfoStrips
        location={location}
        time={time}
        date={date}
        dressCode={dressCode}
        baseDelay={0.2}
        staggerDelay={0.15}
        isAnimated={false}
      />
    </SectionContainer>
  );
};

PrimaryEventInfoSection.propTypes = {
  /** Location text to display */
  location: PropTypes.string,
  /** Time text to display */
  time: PropTypes.string,
  /** Date text to display */
  date: PropTypes.string,
  /** Dress code text to display */
  dressCode: PropTypes.string,
  /** Image URL */
  image: PropTypes.string,
  /** Optional className for styled-components */
  className: PropTypes.string,
};

export default PrimaryEventInfoSection; 