import {useApiQuery} from "../../../../hooks/customHooks.js";

export const useCards = () => {

    const getCard = (cardId) => {
        return useApiQuery(`card/${cardId}`, () => {}, () => {}, {});
    }

    const getCards = () => {
        return useApiQuery(`cards`, () => {}, () => {}, {});
    }


      return {
        getCard,
          getCards
      };
}