// useHandleItemClick.js
import { useCallback, useContext } from 'react';
import {ProductClickContext, useProduct} from '../../products/hooks/index.js';
import { toast } from 'react-toastify';
import { useCardHolderCheck } from '../../../cards/js/hooks/useCardHolderCheck';

/**
 * Custom hook to handle the selection of an orderable item.
 * @param {Function} setSelectedItem - Function to set the selected item.
 * @param {Function} setItem - Function to set the item.
 * @return {Function} - Function to call when an item is clicked.
 */
const useHandleItemClick = (setSelectedItem, time) => {
  const checkCardHolder = useCardHolderCheck();
  const { setIsMember, setNeedsPassword, setIsAllowed, setIsClicked, item } = useContext(ProductClickContext);

  return useCallback(async (clickedItem, isAuthenticated) => {
    setIsClicked(true);  // Mark as clicked to trigger UI changes
    
    let isMember = false;
    let isAllowed = true;
    let needsPassword = false;

    // If you want to access item within the provider, you can also use it here, else use clickedItem.
    const actualItem = item || clickedItem;

    if (actualItem?.sold_out || actualItem?.remaining <= 0) return [false, { isMember: false, needsPassword: false }];

      /**
       * Check if this item has any dependencies
       * If it does then loop through them and add each dependency to the basket
       */

      const hasInvalidDependency = actualItem.dependencies ? actualItem.dependencies.some(dependency => dependency.remaining <= 0) : false;

      // If such a dependency is found, cancel the function execution
      if (hasInvalidDependency) {
        toast.warn(`${actualItem.name} requires products that are not currently available`);
        isAllowed = false;
      }

    /**
     * Check for cardholders only
     */

    if (actualItem?.card_holders_only) {
      isMember = await checkCardHolder(actualItem, isAuthenticated);
      if (!isMember) isAllowed = false;
    }

    /**
     * Check for products that require a password
     * to purchase
     */

    if (actualItem?.password_protected) {
      needsPassword = true;
    }

    if(time) {
      actualItem.time = {
        id: time.id,
        startDate: time.startDate,
        startTime: time.startTime,
        endTime: time.endTime,
        endDate: time.endDate,
      };
    }


    if (isAllowed) {
      setSelectedItem(actualItem);
    }

    setIsMember(isMember);
    setIsAllowed(isAllowed);
    setNeedsPassword(needsPassword);
    setIsClicked(true)

    return [{ isAllowed: isAllowed, isMember: isMember, needsPassword: needsPassword }];
    
  }, [setIsMember, setIsAllowed, setNeedsPassword, setIsClicked, setSelectedItem,  checkCardHolder]);
};






export default useHandleItemClick;
