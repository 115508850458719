import React from 'react'
import { LoyaltyCardContainer } from './styles/loyaltyCardStyles'
import { useCard } from '../../js';

const MiniLoyaltyCard = (props) => {

    const { onClick, data } = props;
    const { selectedTier } = useCard();

  return (
    <LoyaltyCardContainer onClick={() => onClick(data)} selectedTier={selectedTier} data={data}>
        {!data.image && data.name}
    </LoyaltyCardContainer>
  )
}

export default MiniLoyaltyCard