import React from 'react';
import { useEffect } from 'react';
import { changeCSSVariableProperty, changeHtmlBackground } from '../utils';
import { APP_THEME } from '../theme';


const useUpdateTheme = (theme, backgroundImage = null, backgroundColour = null) => {
  useEffect(() => {
    const changeTheme = () => {
      changeCSSVariableProperty('--primary-colour', theme?.primary_colour || APP_THEME.primaryColour);
      changeCSSVariableProperty('--default-primary-colour', theme?.primary_colour || APP_THEME.primaryColour);
      changeCSSVariableProperty('--accent-colour', theme?.accent_colour || APP_THEME.AccentColour);
      changeCSSVariableProperty('--default-accent-colour', theme?.accent_colour || APP_THEME.AccentColour);
      changeCSSVariableProperty('--ticket-section-bg-colour', theme?.ticket_section_bg_colour || 'var(--colour-light)');
      changeCSSVariableProperty('--featured-section-bg-colour', theme?.featured_section_bg_colour || 'var(--accent-colour)');
      changeCSSVariableProperty('--header-colour', theme?.header_colour || 'var(--default-header-colour)');
      changeCSSVariableProperty('--text-colour', theme?.text_colour || 'var(--colour-light)');
      changeCSSVariableProperty('--icon-colour', theme?.icon_colour || 'var(--default-icon-colour)');
      changeCSSVariableProperty('--menu-bg-colour', theme?.menu_colour || 'var(--default-menu-colour)');
      changeCSSVariableProperty('--background-colour', theme?.background_colour || 'var(--primary-colour)');
      changeCSSVariableProperty('--cta-background-colour', theme?.cta_background_colour || 'var(--accent-colour)');
      changeCSSVariableProperty('--cta-text-colour', theme?.cta_text_colour || 'var(--colour-light)');
      changeCSSVariableProperty('--theme', theme?.theme_mode || theme?.theme_mode || 'dark');

      // changeCSSVariableProperty('--input-background-colour', theme?.theme_mode == 'light' ? 'var(--dark-colour)' : 'red');

    };

    // Execute the theme change
    changeTheme();

    // Optionally, change the HTML background if set
    if (backgroundImage || backgroundColour) {
      changeHtmlBackground(backgroundImage, backgroundColour);
    }
  }, [theme, backgroundImage, backgroundColour]);
};

export default useUpdateTheme;