import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../js/Helper'

const QrContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    width: fit-content;
`

const ValueText = styled.p`
  font-size: 0.7em;
`
export const QrCodeComponent = ({ value, style, size, level }) => {

  return (
    <QrContainer>
        <QRCodeSVG
        value={value}
        bgColor={style ? style.background_colour : theme.primaryColour}
        fgColor={style ? style.foreground_colour : 'white'}
        size={size ? size : '200'}
        level={level ? level : 'M'}
        />
        {/*<ValueText>{value}</ValueText>*/}
    </QrContainer>
  )
}
