import React from 'react'
import LogoComponent from '../components/ui/LogoComponent'

export const PrivacyPolicy = () => {
  return (
    <div style={{padding: '20px', backgroundColor:'var(--primary-colour)'}}>
      <LogoComponent />
        <h1 style={{fontSize: '2em'}}>Nocturnal terms and conditions of supply of tickets</h1>

<p>
  This page (together with the documents referred to on it) tells you the terms and conditions on which we supply any of the Tickets listed on our website to you. Please read these terms and conditions carefully before ordering any Tickets from our site. You should understand that by ordering any of our Tickets, you agree to be bound by these terms and conditions.
</p>

<p>
  You should print a copy of these terms and conditions for future reference. Please understand that if you refuse to accept these terms and conditions, you will not be able to order any Tickets from our site.
</p>

<h2>Information about us</h2>

<p>
  We are registered in England and Wales with our registered office at 20-22 Wenlock Road, London, N1 7GU.
</p>

<h2>Your status</h2>

<p>
  By placing an order through our site, you warrant that:
</p>

<ul>
  <li>(a) You are legally capable of entering into binding contracts;</li>
  <li>(b) You are at least 18 years old.</li>
</ul>

<h2>How the contract is formed between you and us</h2>

<p>
  After placing an order, you will receive an e-mail from us acknowledging that we have received your order. Please note that this does not mean that your order has been accepted. Your order constitutes an offer to us to buy Tickets. All orders are subject to acceptance by us following acceptance by the third party supplier.
</p>

<p>
  We store the contract's content and will send you the details of your order as well as our general terms via e-mail. You can find the terms here at all times. The details about your recent orders can be found in your customer login.
</p>

<h2>Our status</h2>

<p>
  Please note that in most cases, we accept orders as agents on behalf of third party event organiser. The resulting legal contract is between you and that event organiser, and is subject to the terms and conditions of that third party supplier, which they will advise you of directly. You should carefully review their terms and conditions applying to the transaction.
</p>

<p>
  These terms and conditions will govern any decisions on cancellation, exchanges and refunds. The third party supplier may enforce these terms and conditions in accordance with the provisions of the Contracts (Rights of Third Parties) Act 1999.
</p>

<p>
  We may provide links on our site to the websites of other companies, whether affiliated with us or not. We cannot give any undertaking, that Tickets you purchase from third party supplier through our site, or from companies to whose website we have provided a link on our site, will be of satisfactory quality, and any such warranties are DISCLAIMED by us absolutely. This DISCLAIMER does not affect your statutory rights against the third party supplier. We will notify you when a third party is involved in a transaction, and we may disclose your customer information related to that transaction to the third party supplier.
</p>


<h2>Your obligations</h2>

<p>
  You represent and warrant that the Tickets are purchased for personal use only, and that they have not been purchased as part of any form of business or commercial activity and in particular, that the Tickets may not be resold or offered for resale by anyone whether at a premium or for any other trade purposes. Resale or the attempted resale of the Tickets is grounds for seizure or cancellation of the ticket without refund or compensation.
</p>

<p>It is your responsibility:</p>
<ul>
  <li>(a) for checking your ticket details, mistakes cannot always be rectified;</li>
  <li>(b) for any loss, theft or damage to your Tickets after dispatch;</li>
  <li>(c) to ascertain whether an event, performance or activity has been cancelled and the date and/or time rearranged. If the event, performance or activity is cancelled you will be offered Tickets for the rescheduled time and date. We will use reasonable endeavours to inform you of any cancellation once we have received notification of the same from the third party supplier;</li>
  <li>(d) to arrive at the event on time.</li>
  <li>(e) to comply with all relevant statutes, venue regulations including age restrictions and safety announcements whilst attending the event, performance or activity.</li>
</ul>

<p>
  The venue and/or third party supplier reserves the right to refuse admission if in its reasonable opinion, your admission to the venue might be a risk to:
</p>
<ul>
  <li>(a) the safety of the audience and/or yourself;</li>
  <li>(b) affect the enjoyment of the member of the audience; and/or</li>
  <li>(c) affect the running of the event, performance or activity for example if you act aggressively and/or appear to be under the influence of alcohol and/or drugs.</li>
</ul>
<p>
  Should this occur you will not be entitled to a refund.
</p>

<p>
  Unauthorised use of photographic and recording equipment at any of the events, performances or activities is prohibited and any tapes or films may be destroyed.
</p>
<p>
  Laser pens, mobile phones, dogs (except guide dogs) and your own food and drink may also be prohibited.
</p>

<p>
  We, The venue, third party supplier have no responsibility for any personal property.
</p>
<p>
  You will consent to the filming and/or sound recording of any event, performance or activity as a member of the audience.
</p>

<h2>Availability and delivery</h2>

<p>
  For posted tickets, your order will be fulfilled by the delivery date set out in the Dispatch Confirmation or, if no delivery date is specified, then within a reasonable time of the date of the Dispatch Confirmation, unless there are exceptional circumstances.
</p>

<p>
  We will endeavour to post Tickets with plenty of time for their arrival prior to the event. However, it is your responsibility to order Tickets in plenty of time to allow for delivery, especially if you are travelling to an event.
</p>

<p>
  If it becomes impractical for us to post Tickets, due to the proximity of the event, performance or activity or in circumstances beyond our control, we reserve the right to make the Tickets available for collection at the venue immediately prior to the event, performance or activity. You will be notified if this is necessary.
Tickets may be restricted to a maximum number per person, per credit card and for some events per household. We reserve the right to cancel tickets without prior notice purchased in excess of this number.
eTickets are made available for most events, and require no delivery. To redeem these tickets you need to attend the event, provide the event promoter with your name and show your payment debit/credit card as proof of ID
When purchasing eTickets, all customers on one order should arrive together. If purchasing for a large group who may arrive at different times you should purchase tickets separately.

</p>

<h2>Risk and title</h2>

<p>
  Posted tickets will be at your risk from the time of dispatch from Nocturnal or third party ticket providers.
</p>
<p>
  eTickets will be at your risk from the time of order confirmation.
</p>
<p>
  Ownership of the Tickets will only pass to you when we receive full payment of all sums due in respect of the Tickets, including delivery charges where applicable.
</p>
<p>
  Tickets sent by Royal Mail delivery are not insured against loss or non-delivery. If your ticket is non-replaceable and you need to claim for non-delivery or loss, please contact Royal Mail customer services.
</p>

<h2>Price and payment</h2>

<p>
  The price of any Tickets will be as quoted on our website from time to time, except in cases of obvious error. We sell Tickets on behalf of promoters, bands and venues which means that we do not set the Ticket prices.
</p>
<p>
  These prices include VAT but exclude delivery costs, booking fees and other administration costs, which may be added to the total amount due as shown in your shopping basket.
</p>
<p>
  We may charge a Booking Fee per ticket, which covers the costs Nocturnal incur to process the transaction (including, but not limited to, card processing fees, VAT and other taxes, customer services and the provision of our services, including profit).
</p>
<p>
  We may charge a Delivery/Handling Fee per order, which covers the costs Nocturnal incur to make the ticket available to you and the event promoter (including, but not limited to, postage, hardware provision, software provision, including profit).
</p>
<p>
  Prices are liable to change at any time, but changes will not affect orders in respect of which have already been placed.
</p>
<p>
  Our website contains a large number of Tickets and it is always possible that, despite our best efforts, some of the Tickets listed on our website may be incorrectly priced. We will normally verify prices as part of our dispatch procedures so that, where a Ticket's correct price is less than our stated price, we will charge the lower amount when dispatching the Ticket to you. If a Ticket's correct price is higher than the price stated on our website, we will normally, at our discretion, either contact you for instructions before dispatching the Ticket, or reject your order and notify you of such rejection.
</p>
<p>
  We are under no obligation to provide the Ticket to you at the incorrect (lower) price, even after we have sent you a Dispatch Confirmation, if the pricing error is obvious and unmistakable and could have reasonably been recognised by you as a mispricing.
</p>
<p>
  Payment for all Tickets must be by credit or debit card, which can be paid through the apple/android pay facility.
</p>
<p>
  We reserve the right to cancel tickets if the venue requires us to reduce an allocation or if we have oversold an event due to human error. In this case, we will provide you with a full refund.
</p>

<h2>Refunds and exchanges</h2>

<p>We regret that tickets cannot be exchanged or refunded after purchase unless the event is cancelled. This is to be arranged by the third party provider of the Ticket.</p>

<p>If an event is cancelled, ticket holders will be offered tickets at any rescheduled event (subject to availability) up to the face value of the tickets or, if the ticket holder is unable to attend the rescheduled event or the event is not rescheduled, a refund of the face value of the tickets.</p>

<p>The promoter reserves the right to alter or vary the programme of an event without being obliged to refund or exchange tickets.</p>

<p>Refunds for cancelled events (without a rescheduled date) will be processed by the third party provider however we (Nocturnal) will help with this process.</p>

<p>Refunds for rescheduled events, whereby you cannot attend the rescheduled date, will be offered via an automated email sent from Nocturnal. You must follow the link within the email to request your refund within 7 days of the cancellation email being sent, or before the rescheduled event takes place (whichever is sooner). If you are aware of a rescheduled event, but do not receive an email, please contact us immediately.</p>

<p>We cannot accept any refund requests outside of the periods stated above.</p>

<p>Other fees such as Booking Fees, Handling/Postage fees, Charity Donations and Ticket Refund Protection are non-refundable under any circumstances.</p>

<p>Refunds will be issued only to the original purchaser or to the original credit card used for the purchase.</p>

<p>Refunds covered will be issued by the event promoter or by Nocturnal on the event promoter's behalf to the customer in the case that Nocturnal hold the ticket funds at the time the refund is due.</p>

<p>In the case where a refund is due and Nocturnal do not hold the ticket funds, the responsibility and liability for issuing refunds lies with the event promoter.</p>

<p>Nocturnal will not be responsible or liable in any way for refunds, errors in issuing refunds, or lack of refunds in connection with the Services.</p>

<h2>Our liability</h2>

<p>Our liability for losses you suffer as a result of us breaking this contract is strictly limited to the purchase price of the Ticket you purchased.</p>

<p>This does not include or limit in any way our liability:</p>

<ul>
  <li>(a) For death or personal injury caused by our negligence;</li>
  <li>(b) Under section 2(3) of the Consumer Protection Act 1987;</li>
  <li>(c) For fraud or fraudulent misrepresentation; or</li>
  <li>(d) For any matter for which it would be illegal for us to exclude, or attempt to exclude, our liability.</li>
</ul>

<p>Events, performances and activities may be cancelled by the third party supplier for a variety of reasons. In this event we will not be responsible for any cancellations or postponements.</p>

<p>Where you buy any Ticket from a third party supplier through our website, the supplier's individual liability will be set out in the supplier's terms and conditions which should be available from their website, or upon request.</p>

<h2>Written communications</h2>

<p>Applicable laws require that some of the information or communications we send to you should be in writing. When using our site, you accept that communication with us will be mainly electronic. We will contact you by e-mail or provide you with information by posting notices on our website. For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing. This condition does not affect your statutory rights.</p>

<h2>Notices</h2>

<p>All notices given by you to us must be given to Nocturnal Lifestyle & Technology Limited by email. We may give notice to you at either the e-mail or postal address you provide to us when placing an order, or in any of the ways specified in previous clauses. Notice will be deemed received and properly served, 24 hours after an e-mail is sent, or three days after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an e-mail, that such e-mail was sent to the specified e-mail address of the addressee.</p>

<h2>Transfer of rights and obligations</h2>

<p>The contract between you and us is binding on you and us and on our respective successors and assigns.</p>
<p>You may not transfer, assign, charge or otherwise dispose of a Contract, or any of your rights or obligations arising under it, without our prior written consent.</p>
<p>We may transfer, assign, charge, subcontract or otherwise dispose of a Contract, or any of our rights or obligations arising under it, at any time during the term of the Contract.</p>

<h2>Events outside our control</h2>

<p>We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a Contract that is caused by events outside our reasonable control (Force Majeure Event).</p>

<p>A Force Majeure Event includes any act, event, non-happening, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:</p>
<ul>
  <li>(a) Strikes, lock-outs or other industrial action.</li>
  <li>(b) Civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war.</li>
  <li>(c) Fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster.</li>
  <li>(d) Impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport.</li>
  <li>(e) Impossibility of the use of public or private telecommunications networks.</li>
  <li>(f) The acts, decrees, legislation, regulations or restrictions of any government.</li>
</ul>

<p>Our performance under any Contract is deemed to be suspended for the period that the Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our reasonable endeavours to bring the Force Majeure Event to a close or to find a solution by which our obligations under the Contract may be performed despite the Force Majeure Event.</p>

<h2>Waiver</h2>

<p>If we fail, at any time during the term of a Contract, to insist upon strict performance of any of your obligations under the Contract or any of these terms and conditions, or if we fail to exercise any of the rights or remedies to which we are entitled under the Contract, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with such obligations.</p>
<p>A waiver by us of any default shall not constitute a waiver of any subsequent default.</p>
<p>No waiver by us of any of these terms and conditions shall be effective unless it is expressly stated to be a waiver and is communicated to you in writing.</p>

<h2>Severability</h2>

<p>If any of these terms and conditions or any provisions of a Contract are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</p>

<h2>Entire agreement</h2>

<p>These terms and conditions and any document expressly referred to in them represent the entire agreement between us in relation to the subject matter of any Contract and supersede any prior agreement, understanding or arrangement between us, whether oral or in writing.</p>
<p>We each acknowledge that, in entering into a Contract, neither of us has relied on any representation, undertaking or promise given by the other or be implied from anything said or written in negotiations between us prior to such Contract except as expressly stated in these terms and conditions.</p>
<p>Neither of us shall have any remedy in respect of any untrue statement made by the other, whether orally or in writing, prior to the date of any Contract (unless such untrue statement was made fraudulently) and the other parties only remedy shall be for breach of contract as provided in these terms and conditions.</p>

<h4>OR</h4>

<p>We intend to rely upon these terms and conditions and any document expressly referred to in them in relation to the subject matter of any Contract. While we accept responsibility for statements and representations made by our duly authorised agents, please make sure you ask for any variations from these terms and conditions to be confirmed in writing.</p>

<h2>Our right to vary these terms and conditions</h2>

<p>We have the right to revise and amend these terms and conditions from time to time to reflect changes in market conditions affecting our business, changes in technology, changes in payment methods, changes in relevant laws and regulatory requirements and changes in our system's capabilities.</p>
<p>You will be subject to the policies and terms and conditions in force at the time that you order Tickets from us, unless any change to those policies or these terms and conditions is required to be made by law or governmental authority (in which case it will apply to orders previously placed by you), or if we notify you of the change to those policies or these terms and conditions before we send you the Dispatch Confirmation (in which case we have the right to assume that you have accepted the change to the terms and conditions, unless you notify us to the contrary within seven working days of receipt by you of the Tickets).</p>

<h2>Law and jurisdiction</h2>

<p>Contracts for the purchase of Tickets through our site will be governed by English law. Any dispute arising from, or related to, such Contracts shall be subject to the non-exclusive jurisdiction of the courts of England and Wales.</p>

<h2>Complaints and dispute resolution</h2>

<p>If you have a complaint regarding a sale placed through Nocturnal that you feel is not being resolved to your satisfaction you have a few options.</p>
<p>Please ensure you have emailed us and waited the 24 hours for a response as per above, so your complaint is in writing, and ask for your complaint to be escalated to the Customer Care Manager.</p>
<p>You can write to us at: Customer Care Manager, 20-22 Wenlock Road, London, N1 7GU  or email <a href="mailto:support@nocturnallifestyle.com">support@nocturnallifestyle.com</a>.</p>
<p>We always strive to resolve your issues with you directly in a resonable timeframe. However if you feel this has not been acheived, you can also use the EU Online Dispute Resolution platform at <a href="http://ec.europa.eu/odr" target="_blank" rel="noopener noreferrer">http://ec.europa.eu/odr</a></p>
<p><em>Last updated: 30 July 2023</em></p>


    </div>
  )
}

