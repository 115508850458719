export const waveEmoji = '👋'
export const angrySteamEmoji = '😤'
export const CongratsEmoji = '🎉'
export const PartyEmoji = '🥳'
export const smirkingFaceEmoji = '😏'
export const gearsEmoji = '⚙️'
export const padlockUnlockedEmoji = '🔓'
export const personSilihouette = '👤'
export const faceScreamEmoji = '😱';
export const ticketEmoji = '🎟️';


