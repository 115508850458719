import React from 'react'

import styled from 'styled-components'

const DividerComponent = styled.div`
  height: 1px;
  max-height: 2px;
  width: 100%;
  max-width: 100px;
  background-color: rgba(255,255,255,0.15);
  margin-top: 3px;
  margin-bottom: 3px;
`

export const Divider = ({style}) => {
  return (
    <DividerComponent style={style}/>
  )
}
