import styled from "styled-components";


export const Form = styled.form`
  display: ${props => props.display};
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  min-width: 350px;
  /* padding-left: 20px;
  padding-right: 20px; */
  box-sizing: border-box;

  @media screen and (min-width: 1920px){
    /* max-width: 30rem; */
    font-size: 1.5em;
  }
`
export const TwoColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 5px;

`

export const Label = styled.label`
  color: var(--text-colour);

`

export const Select = styled.select`
  flex-grow: 1;
  width: 100%;
  max-width: 450px !important;
  height: 40px;
  padding: 0px 20px;
  -webkit-box-shadow: 3px 3px var(--accent-colour);
  -moz-box-shadow: 3px 3px var(--accent-colour);
  box-shadow: 3px 3px var(--accent-colour);
  border: none;
  outline: none;
  border-radius: var(--border-radius-default);
  background-color: var(--colour-light);
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  animation: fadeIn 1s;


`

export const Option = styled.option`
`

export const Input = styled.input`
`

export const Button = styled.button`
  height: 45px;
  width: 100%;
  background-color: ${props => props.alternative == true ? 'rgba(255,255,255,0.025)' : 'var(--accent-colour)'};
  font-size: 0.8rem;
  padding: 0px 20px;
  appearance: none;
  color: white;
  color: ${props => props.alternative == true ? 'rgba(255,255,255,0.5)' : 'white'};
  `

  export const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
    height: 45px;
    border-radius: var(--border-radius-default);
    background-color: var(--primary-colour);
    color: var(--text-colour);
  `
  
