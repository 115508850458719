/**
 * Enhanced ProductList component with integrated product management
 * @description Displays a list of products with built-in product selection handling
 */
import React from 'react';
import styled from 'styled-components';
import { ProductProvider } from '../../context/ProductContext';
import ProductCard from '../ProductCard';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.$gap}px;
  width: 100%;
  max-width: ${props => props.$maxWidth}px;
  margin: 0 auto;
  padding: ${props => props.$padding}px;
`;

/**
 * ProductList component
 * @param {Object} props - Component props
 * @param {Array<Object>} props.products - Array of product objects
 * @param {React.ComponentType} props.cardComponent - Optional custom card component
 * @param {number} props.gap - Space between products in pixels
 * @param {number} props.maxWidth - Maximum width of the list container
 * @param {number} props.padding - Padding around the list
 * @param {Object} props.event - Event object to pass to each product card
 * @param {string} props.className - Additional CSS classes
 */
const ProductList = ({
  products = [],
  cardComponent: CardComponent = ProductCard,
  gap = 30,
  maxWidth = 800,
  padding = 20,
  event,
  className
}) => {
  if (!products?.length) return null;
  return (
    <ProductProvider>
      <ListContainer 
        className={className}
        $gap={gap}
        $maxWidth={maxWidth}
        $padding={padding}
      >
        {products.map((product, index) => (
          <CardComponent
            key={product.id || index}
            {...product}
            event={event}
          />
        ))}
      </ListContainer>
    </ProductProvider>
  );
};

export default ProductList;
