import React from 'react';
import styled from 'styled-components';
import { IoChevronDownOutline } from 'react-icons/io5';

const SelectContainer = styled.div`
  position: relative;
  width: ${props => props.$fullWidth ? '100%' : 'auto'};
`;

const StyledSelect = styled.select`
  appearance: none;
  background: var(--surface-colour);
  color: var(--text-colour);
  padding: 12px 40px 12px 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;

  /* Remove default focus outline and add custom one */
  &:focus {
    outline: none;
    border-color: var(--accent-colour);
    box-shadow: 0 0 0 2px rgba(var(--accent-colour-rgb), 0.2);
  }

  /* Hover state */
  &:hover {
    border-color: rgba(255, 255, 255, 0.2);
  }

  /* Disabled state */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: rgba(255, 255, 255, 0.1);
  }

  /* Option styling */
  option {
    background: var(--surface-colour);
    color: var(--text-colour);
    padding: 12px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--text-colour-60);
  
  /* Rotate icon when select is focused */
  ${StyledSelect}:focus + & {
    transform: translateY(-50%) rotate(180deg);
  }
`;

const Label = styled.label`
  display: block;
  color: var(--text-colour);
  font-size: 0.9rem;
  margin-bottom: 8px;
`;

const Select = ({
  options = [],
  value,
  onChange,
  label,
  placeholder = 'Select an option',
  disabled = false,
  fullWidth = false,
  className,
  autoComplete,
  ...props
}) => {
  return (
    <SelectContainer $fullWidth={fullWidth} className={className}>
      {label && <Label>{label}</Label>}
      <StyledSelect
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        {...props}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      <IconWrapper>
        <IoChevronDownOutline size={20} />
      </IconWrapper>
    </SelectContainer>
  );
};

export default Select;
