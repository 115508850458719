import React from 'react'
import styled from 'styled-components'
import { getLocalStorageJSON } from '../../../js/Helper';
import { Title } from '../../../components/ui/typography';
import { AddToAppleWallet } from '../../../components/ui/AddToAppleWallet';

  const Image = styled.img`
    height: 300px;
    border-radius: var(--border-radius-default);
    box-shadow: -10px 10px 20px rgba(0,0,0,0.2), 10px -10px 20px rgba(0,0,0,0.3);
    margin-top: 20px;

    @media screen and (min-width: 1024px){
      height: 100%;
    }
  `

  const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: left center;

    @media screen and (min-width: 1024px){
    padding: 0px;
  }
  `

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;


  `
export const EventConfirmationPage = () => {

  const event = getLocalStorageJSON('event');

  let order = sessionStorage.getItem('order');
  order = JSON.parse(order);


  const ConfirmationText = `Confirmation`
  const confirmationTextComp =  <Title text={ConfirmationText} subtext="Purchase Confirmed"/>

  const bodyEl = document.querySelector('body')
  bodyEl.style.backgroundImage =`url(${event?.image}?blur=25)`

  return (
    <>
    <LeftSide image={`${event?.image}&blur=100&&bri=-2`} id='left' >
      <Container>
      { innerWidth < 768 && confirmationTextComp}
      <Image src={event?.image}/>
      {/* {innerWidth < 768 &&  <InstallButton/>} */}
      <p>Your tickets will be sent to your email shortly</p>
      </Container>
      <AddToAppleWallet type={'EventTicket'} id={order.id}/>
    </LeftSide>

    <div id='right'>
      {confirmationTextComp}
      <h2>You're going to {event.name}</h2>
    </div>
    </>
  )
}
