import styled from "styled-components"
import { defaultTheme } from "../../js/styles/defaultTheme"



export const StandardFlexLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.gap};
    max-width: 100dvw;
    padding: 20px 20px;
    flex-wrap: wrap;

`