import React, {useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { BsLightningCharge } from 'react-icons/bs';
import { PiSpinner } from 'react-icons/pi';
import Paragraph from '../../../../../atomic/atoms/Paragraph/Paragraph';
import Button from '../../../../../atomic/atoms/Button/Button';
import { convertToCurrency } from '../../../../../js/helpers/helpers';
import { useBasket } from '../../../basket/context/BasketContext';
import ExpressCheckout from '../../../../../atomic/molecules/ExpressCheckout/ExpressCheckout';
import { useEvent } from '../../../../../features/events/js/hooks';
import QuantitySelect from '../../../basket/components/QuantitySelect/QuantitySelect';
import { useBuyNowModal } from '../../hooks/useBuyNowModal.jsx';
import useProductCalculations from '../../hooks/useProductCalculations.js';

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: var(--surface-colour);
  min-height: 300px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-bottom: 8px;
`;

const Price = styled(Paragraph)`
  color: var(--text-colour);
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0;
`;

const FeeText = styled.p`
  color: var(--text-colour);
  font-size: 0.675rem;
  margin-top: 4px;
`;

const Description = styled(Paragraph)`
  color: var(--text-colour-60);
  line-height: 1.6;
  font-size: 1rem;
`;

const InfoGroup = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`;

const PaymentContainer = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.p`
  color: var(--error-colour);
  font-size: 0.875rem;
  margin-top: 8px;
`;

const LoadingSpinner = styled(motion(PiSpinner))`
  font-size: 1.2rem;
  color: var(--text-colour);
  display: inline-block;
`;

const ActionGroup = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
`;

const spinnerVariants = {
  animate: {
    rotate: 360,
    transition: {
      duration: 1,
      ease: "linear",
      repeat: Infinity,
      repeatType: "loop"
    }
  }
};

const TicketProductPage = ({ product }) => {
  const { basket, addToBasket, emptyBasket, isProductInBasket } = useBasket();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [error, setError] = useState(null);
  const [isAddingToBasket, setIsAddingToBasket] = useState(false);
  const [isQuantityUpdating, setIsQuantityUpdating] = useState(false);
  const { event } = useEvent();
  const { totalPrice, totalFees, totalPriceWithFees } = useProductCalculations(product, selectedQuantity);

  const orderItem = basket?.orderItems.find(item => item.productId === product.id);
  const { openBuyNowModal } = useBuyNowModal({
    product,
    quantity: selectedQuantity,
    basketableId: event?.id,
    basketableType: "event",
    basketId: basket?.id,
    onSubmit: () => {
      console.log('running onSubmit', { orderItem });
      window.location.href = `${window.location.origin}/checkout/complete?type=event&orderId=${orderItem?.id}`;
    }
  });


  
  useEffect(() => {
    // emptyBasket();
    if(!isProductInBasket(product.id)) {
      setIsAddingToBasket(true);
       addToBasket(product, 1);
      setIsAddingToBasket(false);
    }
  }, []);


  // Listen for quantity change events
  useEffect(() => {
    const handleQuantityChangeStart = (event) => {
      console.log('🔄 Quantity change started:', {
        detail: event.detail,
        quantity: event.detail?.quantity,
        productId: event.detail?.productId
      });
      setIsQuantityUpdating(true);
      if (event.detail?.quantity) {
        setSelectedQuantity(event.detail.quantity);
      }
    };

    const handleQuantityChangeComplete = (event) => {
      console.log('✅ Quantity change completed:', {
        detail: event.detail,
        quantity: event.detail?.quantity,
        productId: event.detail?.productId
      });
      setIsQuantityUpdating(false);
      if (event.detail?.quantity) {
        setSelectedQuantity(event.detail.quantity);
      }
    };

    window.addEventListener('quantityChange', handleQuantityChangeStart);
    window.addEventListener('quantityChanged', handleQuantityChangeComplete);

    return () => {
      window.removeEventListener('quantityChange', handleQuantityChangeStart);
      window.removeEventListener('quantityChanged', handleQuantityChangeComplete);
    };
  }, []);

  const handleClickBuyNow = async () => {
    try {
      setIsAddingToBasket(true);
      setError(null);

      /**
       * Add to basket first
       * This will update the basket and return the basket item
       * 
       * Wee only add to basket if the product is not already in the basket
       */

      console.log('selectedQuantity', selectedQuantity);

      // if (!isProductInBasket(product.id)) {
      //   let result = await addToBasket(product, selectedQuantity);
      // }

      // await new Promise(resolve => setTimeout(resolve, 500));
      
      if (true) {
        // Only open modal if basket update was successful
        openBuyNowModal();
      } else {
        setError('Failed to add item to basket');
      }
    } catch (err) {
      console.error('Error in buy now flow:', err);
      setError(err.message || 'Failed to process buy now request');
    } finally {
      setIsAddingToBasket(false);
    }
  };

  const handleCheckoutSuccess = ({ order, message }) => {
    console.log('Payment successful', { order, message });
    // Handle success (e.g., redirect to confirmation page)
    window.location.href = `${window.location.origin}/checkout/complete?type=event&orderId=${order.id}`;
  };

  console.log('isProductInBasket', basket.orderItems.find(item => item.productId === product.id));

  const handleCheckoutError = (error) => {
    console.error('Payment failed:', error);
    setError(error.message);
  };

  const renderButtonContent = () => {
    if (isQuantityUpdating || isAddingToBasket) {
      return <><LoadingSpinner variants={spinnerVariants} animate="animate" /> Processing...</>;
    }
    
    return (
      <>
        <BsLightningCharge />
        Buy Now
      </>
    );
  };

  return (
    <>
      <Container>
        <Header>
          <Price>{convertToCurrency(product.price_inc_mandatory_fees * selectedQuantity)}</Price>
          {product.total_mandatory_fees > 0 && (
            <FeeText>
              Including {convertToCurrency(product.total_mandatory_fees * selectedQuantity)} fees
            </FeeText>
          )}
        </Header>
        
        {product.description && (
          <Description>{product.description}</Description>
        )}

        <InfoGroup>
          <QuantitySelect
            product={product}
            maxQuantity={product?.remaining < product?.maxPerOrder ? product?.remaining : product?.maxPerOrder}
            onQuantityChange={setSelectedQuantity}
            onError={(error) => console.error('❌ Failed to update quantity:', error)}
          />

          { isAddingToBasket && <LoadingSpinner variants={spinnerVariants} animate="animate" />
          }
          <ActionGroup>
            <ButtonGroup>
              <Button
                variant="cta"
                size="large"
                onClick={handleClickBuyNow}
                useGradient
                disabled={isAddingToBasket || isQuantityUpdating}
              >
                {renderButtonContent()}
              </Button>
            </ButtonGroup>
            <PaymentContainer>
              <ExpressCheckout
                amount={totalPrice}
                quantity={selectedQuantity}
                product={product}
                paymentType="payment"
                businessName="Platform Four"
                onSuccess={handleCheckoutSuccess}
                onError={handleCheckoutError}
                returnUrl={`${window.location.origin}/checkout/complete`}
                testMode={window.location.hostname === 'localhost'}
                basketableId={event?.id}
                basketableType="event"
              />
            </PaymentContainer>
          </ActionGroup>
        </InfoGroup>

        {error && (
          <ErrorMessage>
            {error}
          </ErrorMessage>
        )}
      </Container>
    </>
  );
};

export default TicketProductPage;
