import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaInstagram, FaFacebookF, FaTwitter, FaTiktok } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const IconLink = styled.a`
  color: var(--text-colour);
  font-size: 1.25rem;
  opacity: 0.8;
  transition: opacity 0.2s ease, transform 0.2s ease;
  
  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;

const getIcon = (platform) => {
  switch (platform.toLowerCase()) {
    case 'instagram':
      return FaInstagram;
    case 'facebook':
      return FaFacebookF;
    case 'twitter':
      return FaTwitter;
    case 'tiktok':
      return FaTiktok;
    default:
      return null;
  }
};

const SocialMediaIcons = ({ links = [], className }) => {
  return (
    <Container className={className}>
      {links.map(({ platform, url }, index) => {
        const Icon = getIcon(platform);
        if (!Icon) return null;
        
        return (
          <IconLink 
            key={`${platform}-${index}`}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Visit our ${platform} page`}
          >
            <Icon />
          </IconLink>
        );
      })}
    </Container>
  );
};

SocialMediaIcons.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      platform: PropTypes.oneOf(['instagram', 'facebook', 'twitter', 'tiktok']).isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  className: PropTypes.string,
};

export default SocialMediaIcons;
