import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Typography Configuration
const TYPOGRAPHY = {
  fonts: {
    creative: 'var(--font-creative, var(--font-primary, Arial), sans-serif)',
    small: 'var(--font-small, var(--font-secondary, Times New Roman), serif)',
    generic: 'var(--font-generic, var(--font-primary, Arial), sans-serif)',
  },
  weights: {
    creative: 'var(--font-weight-creative, 700)',
    small: 'var(--font-weight-small, 400)',
    generic: 'var(--font-weight-generic, 400)',
  },
  sizes: {
    creative: '2rem',
    small: '0.5rem',
    generic: '1.5rem',
  },
  spacing: {
    containerGap: '0.5rem',
    minLetterSpacing: '0.05em',
    maxLetterSpacing: '0.2em',
    contentPadding: '40px',
  },
  colors: {
    creative: 'var(--accent-colour, #C5B898)',
    small: 'var(--text-colour, #C5B898)',
    generic: 'var(--text-colour, #C5B898)',
  },
  lineHeight: 1.1,
  transitionSpeed: '0.3s',
  minWordLength: 3,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${TYPOGRAPHY.spacing.containerGap};
  width: 100%;
  max-width: ${props => props.$maxWidth || '100%'};
`;

const TextBase = styled.div`
  margin: 0;
  width: 100%;
  text-align: ${props => props.$textAlign || 'center'};
  letter-spacing: ${props => props.$letterSpacing || TYPOGRAPHY.spacing.minLetterSpacing};
  line-height: ${TYPOGRAPHY.lineHeight};
  white-space: ${props => props.$wrap ? 'normal' : 'nowrap'};
  overflow-wrap: break-word;
  transition: all ${TYPOGRAPHY.transitionSpeed} ease;
  color: ${props => props.$color || 'var(--text-colour, #C5B898)'};
  font-family: ${props => props.$fontFamily};
  font-weight: ${props => props.$fontWeight};
  
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    visibility: hidden;
  }
`;

const CreativeText = styled(TextBase)`
  text-transform: uppercase;
  font-size: ${props => props.$fontSize || TYPOGRAPHY.sizes.creative};
  font-family: ${props => props.$fontFamily || TYPOGRAPHY.fonts.creative};
  font-weight: ${props => props.$fontWeight || TYPOGRAPHY.weights.creative};
  color: var(--header-colour);
  background: var(--header-colour);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SmallText = styled(TextBase)`
  font-size: ${props => props.$fontSize || TYPOGRAPHY.sizes.small};
  font-family: ${props => props.$fontFamily || TYPOGRAPHY.fonts.small};
  font-weight: ${props => props.$fontWeight || TYPOGRAPHY.weights.small};
  font-style: italic;
  color: ${props => props.$color || TYPOGRAPHY.colors.small};
`;

const GenericText = styled(TextBase)`
  font-size: ${props => props.$fontSize || TYPOGRAPHY.sizes.generic};
  font-family: ${props => props.$fontFamily || TYPOGRAPHY.fonts.generic};
  font-weight: ${props => props.$fontWeight || TYPOGRAPHY.weights.generic};
  color: ${props => props.$color || TYPOGRAPHY.colors.generic};
`;

const TextComponents = {
  creative: CreativeText,
  small: SmallText,
  generic: GenericText,
};

const useTextWidth = (ref, text, type) => {
  const [width, setWidth] = useState(0);
  const [letterSpacing, setLetterSpacing] = useState(TYPOGRAPHY.spacing.minLetterSpacing);
  const [fontSize, setFontSize] = useState(TYPOGRAPHY.sizes[type]);

  useEffect(() => {
    if (!ref.current) return;

    const container = ref.current.parentElement;
    const containerWidth = container.offsetWidth;
    const measure = document.createElement('span');
    measure.style.visibility = 'hidden';
    measure.style.position = 'absolute';
    measure.style.whiteSpace = 'nowrap';
    measure.style.fontSize = fontSize;
    measure.style.fontFamily = type === 'small' ? TYPOGRAPHY.fonts.small : TYPOGRAPHY.fonts.generic;
    measure.textContent = text;
    document.body.appendChild(measure);

    const textWidth = measure.offsetWidth;
    const availableSpace = containerWidth - parseInt(TYPOGRAPHY.spacing.contentPadding);

    // Handle text fitting and spacing
    if (textWidth > availableSpace) {
      // If text is too long, reduce font size first
      const newFontSize = (availableSpace / textWidth) * parseFloat(fontSize);
      setFontSize(`${Math.max(1, newFontSize)}rem`);
      setLetterSpacing(TYPOGRAPHY.spacing.minLetterSpacing);
    } else if (textWidth < availableSpace * 0.8) {
      // If text is too short, adjust letter spacing within bounds
      const spacing = Math.min(
        (availableSpace - textWidth) / (text.length - 1),
        parseFloat(TYPOGRAPHY.spacing.maxLetterSpacing)
      );
      setLetterSpacing(`${Math.max(parseFloat(TYPOGRAPHY.spacing.minLetterSpacing), spacing * 0.8)}em`);
    }

    document.body.removeChild(measure);
    setWidth(textWidth);
  }, [ref, text, type]);

  return { width, letterSpacing, fontSize };
};

// Helper function to prevent orphaned words
const processText = (text) => {
  const words = text.split(' ');
  if (words.length <= 1) return text;

  // Join short words with the previous word using non-breaking space
  return words.reduce((acc, word, i) => {
    if (word.length <= TYPOGRAPHY.minWordLength && i > 0) {
      return `${acc}\u00A0${word}`;
    }
    return acc + (i === 0 ? '' : ' ') + word;
  }, '');
};

const ModernTextBlock = ({ textItems = [], maxWidth = '100%' }) => {
  const containerRef = useRef(null);
  
  return (
    <Container ref={containerRef} $maxWidth={maxWidth}>
      {textItems.map((item, index) => {
        const TextComponent = TextComponents[item.type] || GenericText;
        const textRef = useRef(null);
        const { letterSpacing, fontSize } = useTextWidth(textRef, item.content, item.type);
        const processedContent = processText(item.content);

        return (
          <TextComponent
            key={index}
            ref={textRef}
            $letterSpacing={letterSpacing}
            $fontSize={fontSize}
            $textAlign="center"
            $wrap={true}
            $color={item.color}
            $fontFamily={item.fontFamily}
            $fontWeight={item.fontWeight}
          >
            {processedContent}
          </TextComponent>
        );
      })}
    </Container>
  );
};

ModernTextBlock.propTypes = {
  textItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['creative', 'small', 'generic']).isRequired,
      content: PropTypes.string.isRequired,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  maxWidth: PropTypes.string,
};

export default ModernTextBlock;