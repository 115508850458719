import React from 'react'
import styled from 'styled-components';


const GradientOverlay = styled.div`
  position: ${props => props.position ? props.position : 'absolute'};
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent 0%, var(--default-primary-colour));
  z-index: 2;
  margin-bottom: -20px;

  
  @media screen and (min-width: 768px) {
      margin-bottom: -20px;
      height: 100%;
  }
`;
export const GradientOverlayComponent = (props) => {
  const { position } = props;

  return (
    <GradientOverlay position={position}/>
  )
}
