import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from "./CheckoutForm.jsx";
import { CheckoutSection } from "../styles/checkoutPage.styles.js";
import { useEcommerce } from '../../js/context/EcommerceContext.jsx';
import { useCheckout } from '../../js/context/CheckoutContext.jsx';
import PaymentErrorBoundary from '../../services/stripe/components/PaymentErrorBoundary.jsx';
import { useEvent } from '../../../events/js/hooks/EventContext.jsx';
import { useBasket } from '../../../../featuresV2/ecommerce/basket/context/BasketContext.jsx';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

const PaymentSection = () => {

    const { config, total, basket } = useBasket();
    const { stripe } = useCheckout();
    const { event } = useEvent();

    const basketTotal = total;

    const options = {
        mode: 'payment',
        amount: basketTotal,
        currency: 'gbp',
        automatic_payment_methods: {
            enabled: true
        },
        appearance: {
            labels: "floating",
            variables: {
                fontWeightNormal: '500',
                borderRadius: '12px',
                colorBackground: event?.theme?.primary_colour || 'black',
                colorPrimary: event?.theme?.accent_colour,
                accessibleColorOnColorPrimary: '#1A1B25',
                colorText: 'white',
                colorTextSecondary: 'white',
                colorTextPlaceholder: '#727F96',
                tabIconColor: 'white',
                logoColor: 'dark',
                width: '100%',
                gridRowSpacing: '10px',
            },
        },
    };

    const handleRetry = () => {
        // Reset payment state and retry
        if (stripe) {
            // Clear any existing payment errors
            stripe.elements().update({ disabled: false });
        }
    };

    const handleReset = () => {
        // Complete reset of payment form
        if (stripe) {
            stripe.elements().update({ disabled: false });
            // Clear any saved payment method
            stripe.elements().clear();
        }
    };



    return (
        <CheckoutSection
            id='payment'
            style={{
                clipPath: !config?.hasQuestions && 'polygon(0 0, 100% 20%, 100% 100%, 0 80%)',
                background: !config?.hasQuestions && 'rgba(0,0,0,0.1)',
            }}
        >

            {basketTotal > 0 && basket?.status !== 'payment_confirmed' && (
                  <Elements stripe={stripePromise} options={options}>
                  <PaymentErrorBoundary
                      onRetry={handleRetry}
                      onReset={handleReset}
                  >
                      <StripePaymentForm
                          payment_intent={stripe?.gateway_transaction_id}
                          theme={event?.theme}
                          total={basketTotal}
                      />
                  </PaymentErrorBoundary>
              </Elements>
            )}

            { basket?.status === 'payment_confirmed' && (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', fontSize: '1.2em', color: 'white'}}>
                    <span style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', fontSize: '1.2em', color: 'white'}}>
                        This payment has <br /> already been confirmed.
                    </span>
                </div>
            )}

          
        </CheckoutSection>
    );
};

export default PaymentSection;