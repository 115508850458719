import React from 'react'
import {Footer} from '../layout/components/Footer'
import {FullPageContainer} from '../layout/styles/layoutStyles';
import Header from '../layout/components/Header';
import {getEvents} from '../features/events/js/data';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {updateTheme} from '../features/themes/js/utils';


const Container = styled(FullPageContainer)`
    justify-content: center;
    align-items: center;
`

function TempHome() {

    const navigate  = useNavigate();

    updateTheme()

    const res = getEvents('business_id=5');
    const events = res?.data?.data;

    const handleClick = (event) => {
        navigate(`/e/${event.slug}`)
    }
  return (
    <>
    <Header />
    <Footer />
    </>
  )
}

export default TempHome