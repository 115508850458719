import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'
import { IoArrowDownSharp } from 'react-icons/io5'
import styled from 'styled-components'
import { StandardSection } from '../../../layout/sections/StandardSection'

/**
 * Accordian Component
 * 
 * This component renders a list of expandable accordion items using Material-UI's Accordion components.
 * Each accordion item displays a placeholder text when collapsed and a description when expanded.
 * 
 * @param {Object} props - The component props
 * @param {Array} props.data - An array of objects representing accordion items
 * @param {string} props.data[].placeholder - The text to display in the accordion summary (when collapsed)
 * @param {string} props.data[].description - The text to display in the accordion details (when expanded)
 * 
 * @returns {React.ReactElement|null} The rendered Accordian component or null if no data is provided
 */
const Accordian = ({ data, accordionStyle }) => {
    // Return early if data is not provided or is an empty array
    if (!data || data.length === 0) return null;

    return (
        <StandardSection style={{ maxWidth: window.innerWidth > 768 ? '30vw' : 'auto', margin: '0 auto', minWidth: 'unset' }}>
            {data.map((item, index) => (
                // Each Accordion item
                <Accordion key={index} style={{ minWidth: '100%', ...accordionStyle, borderRadius: 'var(--border-radius-default)' }}>
                    <AccordionSummary
                        expandIcon={<IoArrowDownSharp color='var(--accent-colour)' />}
                    
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                    >
                        {item.placeholder}
                    </AccordionSummary>
                    <AccordionDetails>
                        {item.description}
                    </AccordionDetails>
                </Accordion>
            ))}
        </StandardSection>
    )
}

export default Accordian