import styled from "styled-components";

export const FullPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100dvw;
    min-height: 100dvh;
    padding-bottom: 100px;
    padding-top: 20px;
    box-sizing: border-box;
`
export const FullPageAbsoluteContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: ${props => props?.style?.flexDirection ?? 'column'};
    min-width: 100dvw;
    min-height: 100dvh;
    z-index: 0;
    background-color: transparent;
`


