import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: var(--border-radius-default);
    border-top-right-radius: var(--border-radius-default);
    max-width: 80%;
    height: fit-content;
    text-align: center;
    font-weight: 600;
    padding: 5px 20px;
    margin-bottom: -15px;
    z-index: 1;
    background: var(--default-accent-colour);
    box-shadow: inset 10px 10px 10px rgba(0,0,0,0.15);
    color:var(--text-colour);
`

const Title = styled.div``

const Subtitle = styled.div``

export const InstructionTitle = ({ title, subtitle, style, onClick }) => {
  return (
    <Container style={style} onClick={onClick}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
    </Container>
  )
}
