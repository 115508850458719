import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Paragraph} from "./Paragraph.jsx";

const Container = styled.section`
    min-width: 200px;
`
const SubTitle = styled.h2`
    line-height: normal;
    color: var(--accent-colour);
`

const InfoSection = ({ subtitle, paragraph}) => {
    return (
        <Container>
            <SubTitle>{subtitle}</SubTitle>
            <Paragraph>{paragraph}</Paragraph>
        </Container>
    );
};

InfoSection.propTypes = {
    subtitle: PropTypes.string,
    paragraph: PropTypes.string
};

export default InfoSection;