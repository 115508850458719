import React from 'react';
import styled, { keyframes } from 'styled-components';
import Box from '../../atoms/Box/Box';
import { borderRadius } from '../../tokens/theme';
import PropTypes from 'prop-types';
import Paragraph from '../../atoms/Paragraph/Paragraph';

const OuterContainer = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    width: 500px;
  }
`;

const HeaderBanner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  // align-items: center;
  width: 70%;
  height: 50px;
  margin: 0 auto;
  background: var(--accent-colour);
  color: white;
  padding: 10px 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  z-index: 1;
  margin-bottom: -15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px 12px 0 0;


`;

const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const getHeaderBorderRadius = (variant, asymRadius, reverse) => {
  if (variant === 'asymRadius') {
    return reverse 
      ? borderRadius.asymmetric.reversed[asymRadius]  // top-right and bottom-left
      : borderRadius.asymmetric[asymRadius];          // top-left and bottom-right
  }
  
  // Default variant
  const radius = borderRadius[asymRadius];
  return reverse 
    ? `${radius} 0 ${radius} 0` // top-right and bottom-left
    : `${radius} 0 ${radius} 0`; // top-left and bottom-right
};

const CardHeader = styled(Box)`
  background: ${props => props.$headerImage ? 'none' : 'var(--primary-colour)'};
  color: white;
  width: 100%;
  min-height: 160px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 1);
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: ${props => props.$headerImage ? '0' : '24px'};
  padding-top: ${props => props.$hasBanner ? '48px' : props.$headerImage ? '0' : '24px'};
  border-radius: ${props => getHeaderBorderRadius(props.$variant, props.$asymRadius, props.$reverse)};

    @media (min-width: 768px) {
      align-self: center;
      justify-self: center;
      height: 200px;
  }
`;

const HeaderImage = styled.div`
  position: absolute;
  inset: 0;
  background-image: url(${props => props.$src});
  background-size: cover;
  background-position: center;
  
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 100%);
  }
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 14px 0;
`;

const expandContent = keyframes`
  from {
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    max-height: 800px;
    transform: translateY(0);
  }
`;

const ContentWrapper = styled(Box)`
  position: relative;
  margin-top: -90px;
  z-index: 0;
`;

const CardContent = styled(Box)`
  background: #d9d9d9;
  color: var(--text-colour-inverse);
  padding: ${props => props.$noPadding ? '0' : '104px 24px 24px'};
  min-height: 100px;
  height: fit-content;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: ${props => 
    props.$variant === 'extended' ? ` 0 ${borderRadius[props.asymRadius]} ${borderRadius[props.asymRadius]}` : '0'};
  
  animation: ${expandContent} 1s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: top center;
  will-change: transform, opacity, max-height;

  @media (min-width: 768px) {
    justify-self: center;
    align-content: center;
    height: 300px;
  }
`;

const AsymmetricCard = ({ 
  variant = 'default',
  asymRadius = 'xl',
  reverse = false,
  headerContent,
  headerImage,
  headerBanner,
  children,
  contentBackground,
  className,
  ...props 
}) => {
  const hasContent = React.Children.count(children) > 0;
  const effectiveVariant = hasContent ? 'extended' : variant;
  const isLayoutContent = React.Children.toArray(children).some(
    child => child?.type?.name === 'CurvedDividerLayout'
  );

  return (
    <OuterContainer  data-testid="asymmetric-card" className={className}>
      {headerBanner && <HeaderBanner><Paragraph data-testid="asymmetric-card-header-banner" weight='bold'>{headerBanner}</Paragraph></HeaderBanner>}
      <CardContainer 
        name="asymmetric-card-container"
        data-testid="asymmetric-card-container"
        {...props}
      >
        <CardHeader
          name="asymmetric-card-header"
          data-testid="asymmetric-card-header"
          $variant={effectiveVariant}
          $asymRadius={asymRadius}
          $reverse={reverse}
          $headerImage={!!headerImage}
          $hasBanner={!!headerBanner}
          id="asymmetric-card-header"
        >
          {headerImage && <HeaderImage $src={headerImage} />}
          {headerContent && <HeaderContent>{headerContent}</HeaderContent>}
        </CardHeader>
        {hasContent && (
          <ContentWrapper name="asymmetric-card-content-wrapper">
            <CardContent
              name="asymmetric-card-content"
              data-testid="asymmetric-card-content"
              $variant={effectiveVariant}
              $background={contentBackground}
              $noPadding={isLayoutContent}
              asymRadius={asymRadius}
            >
              {children}
            </CardContent>
          </ContentWrapper>
        )}
      </CardContainer>
    </OuterContainer>
  );
};

AsymmetricCard.propTypes = {
  variant: PropTypes.oneOf(['default', 'extended']),
  asymRadius: PropTypes.string,
  reverse: PropTypes.bool,
  headerContent: PropTypes.node,
  headerImage: PropTypes.string,
  headerBanner: PropTypes.string,
  children: PropTypes.node,
  contentBackground: PropTypes.string,
  className: PropTypes.string,
};

export default AsymmetricCard;
