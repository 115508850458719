import { useParams } from "react-router-dom";
import axios from "axios";

const versionResponse = axios.get('/version.json');
const clientData = versionResponse?.data;
export const BUILD_VERSION =  clientData?.deploymentId?.slice(0,6) //import.meta.env.VITE_APP_BUILD_VERSION;
export const API_URL = import.meta.env.VITE_API_URL;
export const SERVER_URL = import.meta.env.VITE_SERVER_URL;
export const CMS_API_URL = import.meta.env.VITE_CMS_API_URL;
export const CDN_URL = import.meta.env.VITE_CDN_URL;
export const LOGO_URL = '/nocturnal-logo-clear.png'
export const CDN_CONFIG = import.meta.env.VITE_CDN_CONFIG;
export const SUPPORT_EMAIL = import.meta.env.VITE_SUPPORT_EMAIL;
export const REPORT_BUG_URL = import.meta.env.VITE_REPORT_BUG_URL;
export const APP_URL = import.meta.env.VITE_APP_URL;
export const PASS_API = import.meta.env.VITE_PASSES_API;
export const PORTAL_URL = import.meta.env.VITE_PORTAL_URL;
export const JIRA_API_KEY = import.meta.env.VITE_JIRA_API_KEY;
export const APP_ENV = import.meta.env.VITE_NODE_ENV || "development";
export const SENTRY_AUTH = import.meta.env.VITE_APP_SENTRY_AUTH_TOKEN || "development";
export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_KEY;
export const USE_NEW_EVENT_PAGE = import.meta.env.VITE_USE_NEW_EVENT_PAGE || false;


export const RETURN_URL = `${window.location.origin}/confirmation?type=event`
export const waveEmojo = '👋'

export const imgixUrlParams = `?auto=format&w=${innerWidth}&h=${innerHeight}&fit=crop&losless=true`

export function clearSearchParams() {
  const url = new URL(window.location.href);
  url.search = '';
  const newUrl = url.toString();
  window.history.replaceState({}, '', newUrl);
}

export const imagePath = (path, filename) => {
  const imagePath = `${CDN_URL}/${path}/${filename}`;
  return imagePath
}

export const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const theme = {
    primaryColour: '#2A2D3A',
    accentColour: '#BFB593',
    lightColour: '#d9d9d9',
    textColour: 'white'
}

export const THEME = {
  PRIMARY_COLOUR: '#2A2D3A',
  ACCENT_COLOUR: '#BFB593',
}

export function consoleLog(type, value) {
    
    if(type == 'log' ) console.log(value)
    if(type == 'error' ) console.error(value)
    if(type == 'debug' ) console.debug(value)

}

export function SetLocalStorageJSON(item, value) {
  if(item && item != undefined && value != undefined) {
    return localStorage.setItem(item, JSON.stringify(value))
  }
}

export function getLocalStorageJSON(item) {
  
  if(item) {
    return JSON.parse(localStorage.getItem(item))
  }
  
}

export function setSessionStorageJSON(item, value) {
  if(item && value !== undefined) {
    sessionStorage.setItem(item, JSON.stringify(value))
  }
}

export function getSessionStorage(item) {
  if(item) {
    return JSON.parse(sessionStorage.getItem(item))
  }
}

export function setTheme(theme) {

    console.log(theme);
    
      const headerStyle = {
        color: theme.accent_colour,
      }
    
      const textStyle = {
        color: theme.text_colour,
    
      }
    
      const activeThemeStyle = {
        theme,
        headerStyle,
        textStyle
      }

      return activeThemeStyle;
}

export function replaceIdInPath(path, id) {
  return path.replace("{id}", id);
}

export const replaceVariables = (text, variable, value = null) => {

  const params = useParams();

  switch (variable) {
    case '{value}':
      return text.replace(variable, params.value);
      break;
    case '{key}':
      return text.replace(variable, params.key);
      break;
    case '{name}':
      return text.replace(variable, params.value);
      break;
    case '{id}':
      return text.replace(variable, params.slug);
      break;
  
    default:
      return text
      break;
  }


}

export function countLetters(str) {
  const lettersOnly = str.match(/[a-zA-Z]/g); // matches only letters
  return lettersOnly ? lettersOnly.length : 0;
}

export function wordsToLines(str) {
  return str.replace(/\s+/g, '\n');
}

export function displayLocation(location) {

  if(!location.business) {
    return ;
  }
  
  if(location?.name === location?.business?.name  || location?.name == null) {
    return location.business.name;
  }

  return `${location.business.name}: ${location.name}`
}




// export async function getRelatedApps() {
//   const relatedApps = await navigator.getInstalledRelatedApps();
//   const PWAisInstalled = relatedApps.length > 0;
//
//   return [{'relatedApps':relatedApps}, {'pwaIsInstalled': PWAisInstalled}]
// }

export const unslug = (slug) => {
  return slug
    .replace(/-/g, ' ')
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};