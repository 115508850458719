import React from 'react';

const HtmlParagraph = ({ text, style }) => {
    return (
            <div style={style}
                 dangerouslySetInnerHTML={{__html: text}}/>

    );
};

export default HtmlParagraph;