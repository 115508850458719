import React from 'react';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import { motion } from 'framer-motion';
import { premiumFloating } from '../../tokens/animations';
import { AiOutlineCloudSync } from 'react-icons/ai';

const UpdateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  gap: 1.5rem;
`;

const IconWrapper = styled(motion.div)`
  ${premiumFloating}
  color: var(--accent-colour);
  font-size: 3rem;
`;

const UpdateMessage = styled.p`
  font-size: 1.2rem;
  color: var(--text-colour);
  margin: 1rem 0;
  line-height: 1.5;
`;

const UpdateButton = styled(motion.button)`
  background: var(--accent-colour);
  color: var(--surface-colour);
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
  }
`;

const buttonVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.2,
      ease: "easeInOut"
    }
  },
  tap: {
    scale: 0.95
  }
};

const UpdateRequestUI = ({ isOpen, onClose, onUpdate }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Update Available"
      disableBackdropClick={true}
    >
      <UpdateContent>
        <IconWrapper>
          <AiOutlineCloudSync />
        </IconWrapper>
        
        <UpdateMessage>
          A new version of the application is available. Update now to get the latest features and improvements.
        </UpdateMessage>

        <UpdateButton
          as={motion.button}
          variants={buttonVariants}
          whileHover="hover"
          whileTap="tap"
          onClick={onUpdate}
        >
          Update Now
        </UpdateButton>
      </UpdateContent>
    </Modal>
  );
};

export default UpdateRequestUI; 