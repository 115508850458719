import { PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import React from 'react'
import { ButtonComponent } from '../../../../../components/ui/buttons'
import { convertToCurrency } from '../../../../../js/helpers/helpers'
import { IoCardSharp } from 'react-icons/io5'
import { Divider } from '../../../../../components/ui/Divider'
import styled from 'styled-components'


const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
`

const WalletContainer = styled.div`

    min-width: 300px;
    width: 100%;
    min-height: fit-content;
`

const StripePaymentButtons = ({ paymentRequest, isLoading, stripe, elements, amount }) => {
    
  
    return (
    <Container >

        { paymentRequest && (
            <div style={{
                minWidth:'100px',
                width:'100%',
                minHeight:'fit-content',
                height: 'fit-content',
                }}>
                <PaymentRequestButtonElement options={{paymentRequest}} /> 
            </div>
        )} 
        
        { paymentRequest && <Divider  /> }

        <PaymentElement />
        <Divider />
        <ButtonComponent 
            isLoading={isLoading}
            isLoadingText={'Processing Payment'}
            isDisabled={!stripe || !elements || isLoading}
            text={`PAY ${convertToCurrency(amount)}`} 
            icon={<IoCardSharp />}
            colour={'var(--success-colour)'}
            style={{
                // backgroundColor:'var(--success-colour)',
                height: '45px'
            }}
        />
    </Container>
  )
}

export default StripePaymentButtons