import styled from "styled-components";
import {motion} from "framer-motion";

const ListItemIcon = styled.span`
    color: var(--success-colour);
    display: flex;
    align-items: center;
    font-size: 1.2em;
    margin-left: 8px;
`;

const ListItemTitle = styled.span`
    font-size: 1.1em;
    font-weight: 600;
    background: var(--accent-colour);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
`;

const ListItemContent = styled.span`
    color: var(--glass-text);
    opacity: 0.9;
    font-size: 0.9em;
`;

const ListItem = styled(motion.li)`
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    gap: 10px;
    position: relative;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 25px;
    border-radius: var(--border-radius-default);
    background: var(--glass-background);
    border: 1px solid var(--glass-border);
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.07);
    height: fit-content;

    @media (max-width: 768px) {
        min-width: 250px;
        max-width: 60vw;
        flex: 0 0 auto;
        scroll-snap-align: start;
        
        /* Text wrapping */
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    /* Glass morphism effect */
    &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: var(--border-radius-default);
        padding: 1px;
        background: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.05) 50%,
            transparent 50%
        );
        -webkit-mask:  linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }
    
    @media (hover: hover) {
        &:hover {
            background: var(--glass-background-hover);
            transform: translateX(10px);
            border-color: var(--glass-border-hover);
            
            ${ListItemIcon} {
                transform: scale(1.1);
            }
        }
    }
    
    &:active {
        background: var(--glass-background-hover);
        transform: scale(0.99);
    }
`;

const StyledListItem = styled(ListItem)`
    @media (max-width: 768px) {
        &:first-child {
            margin-left: 0;
        }
        
        &:last-child {
            margin-right: 40px;
        }
    }
`;

const ListContainer = styled.ul`
    list-style: none;
    margin: 20px 0;
    position: relative;
    transition: all 0.3s ease;
    
    @media (max-width: 768px) {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        gap: 20px;
        margin-left: 0px;
        margin-right: -20px;
        // margin: 20px calc(-20px - 5vw);
        padding: 20px;
        padding-left: 40px;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        
        /* Hide scrollbar */
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }

        /* Ensure the last item can scroll fully to the left */
        &::after {
            content: '';
            padding-right: 40px;
        }
    }
`;

export {ListItem, ListItemIcon, ListItemTitle, ListItemContent, ListContainer, StyledListItem};