/**
 * PrimaryEventHeroSkeleton component
 * @description Loading skeleton for the PrimaryEventHero section
 */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import LogoComponent from '../../../../components/ui/LogoComponent';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const HeroContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background: var(--primary-colour);
  
  @media (min-width: 768px) {
    padding: 0 40px;
  }
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  animation: ${pulse} 2s ease-in-out infinite;
  z-index: 3;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

const SkeletonBlock = styled.div`
  background: linear-gradient(to right, var(--primary-colour) 8%, transparent 18%, var(--primary-colour) 33%);
  background-size: 2000px 100%;
  animation: ${shimmer} 2s linear infinite;
  border-radius: ${props => props.$radius || '4px'};
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || '20px'};
  margin: ${props => props.$margin || '0'};
  position: relative;
  overflow: hidden;
   animation: ${pulse} 2s ease-in-out infinite;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transform: translateX(-100%);
    animation: ${shimmer} 2s infinite;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }
`;

const PrimaryEventHeroSkeleton = () => {
  return (
    <HeroContainer>
      
      <ContentWrapper>
      <LogoWrapper>
        <LogoComponent width="180px" />
      </LogoWrapper>
        <CardWrapper>
          {/* Title Skeleton */}
          <SkeletonBlock $height="48px" $margin="0 0 1rem" />
          
          {/* Date Skeleton */}
          <SkeletonBlock $height="24px" $width="200px" $margin="0 0 1rem" />
          
          {/* Organiser Skeleton */}
          <SkeletonBlock $height="20px" $width="150px" $margin="0 0 1.5rem" />
          
          {/* Description Skeleton - Multiple lines */}
          <SkeletonBlock $height="16px" $width="100%" $margin="0 0 0.5rem" />
          <SkeletonBlock $height="16px" $width="90%" $margin="0 0 0.5rem" />
          <SkeletonBlock $height="16px" $width="95%" />
        </CardWrapper>
      </ContentWrapper>
    </HeroContainer>
  );
};

export default PrimaryEventHeroSkeleton; 