import React from 'react'
import styled from 'styled-components'
import { Input } from '../formStyles'

export const TextInput = ({
  disabled,
  value,
  name,
  placeholder,
  onChange,
  autoCorrect,
  required,
    type,
  register
}) => {


  return (
    <Input
    disabled={disabled}
    value={value}
    {...register(name)}
    type={type}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    autoCorrect={autoCorrect}
    required={required}
    />
  )
}
